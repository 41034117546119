export default function EN() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <a id="top" />
      <h3 className="text-danger text-left">TERMS & CONDITIONS</h3>
      <h6>General Terms & Conditions</h6>
      <ol>
        <li key={1}>
          <a href={`#${1}`}> Basics</a>
        </li>
        <li key={2}>
          <a href={`#${2}`}> Your Account</a>
        </li>
        <li key={3}>
          <a href={`#${3}`}> Payments and Security</a>
        </li>
        <li key={4}>
          <a href={`#${4}`}> Restrictions on the use of the Game90 website</a>
        </li>
        <li key={5}>
          <a href={`#${5}`}> The website</a>
        </li>
        <li key={6}>
          <a href={`#${6}`}> Disclaimer and Game90 website availability</a>
        </li>
        <li key={7}>
          <a href={`#${7}`}> Limitation of liability</a>
        </li>
        <li key={8}>
          <a href={`#${8}`}> Intellectual property rights</a>
        </li>
        <li key={9}>
          <a href={`#${9}`}> Notification requirement</a>
        </li>
        <li key={10}>
          <a href={`#${10}`}>1 General provisions</a>
        </li>
        <li key={11}>
          <a href={`#${11}`}> Rules for placing bets / sports rules</a>
        </li>
        <li key={12}>
          <a href={`#${12}`}> Bonus rule</a>
        </li>
        <li key={13}>
          <a href={`#${13}`}> Responsible gaming</a>
        </li>
        <li key={14}>
          <a href={`#${14}`}> Data protection</a>
        </li>
        <li key={15}>
          <a href={`#${15}`}> General</a>
        </li>
        <li key={16}>
          <a href={`#${16}`}> ADR - Alternative Dispute Resolution (ADR)</a>
        </li>
        <li key={17}>
          <a href={`#${17}`}> Effective Date</a>
        </li>
      </ol>

      <div>
        <h5 id="1" className="mt-75">
          1. Basics{' '}
        </h5>
        <h6 className="mt-3"> 1.1 Definitions </h6>
        <p className="mt-3">
          1.1.1 The terms “we”, the Company or us refer to game90.bet or Game90 Ltd., a Company with
          the registered Company number C50436 in Melita Court, Level 1, Guiseppe Cali St c/w Abate
          Rigord St, XBX 1420, TaXbiex, Malta. <br />
          <br />
          1.1.2 The term “website” refers to game90.bet. The complete and sole owner and operator of
          the website is Game90. <br />
          <br />
          1.1.3 The term “downloadable application” (app for short) refers to every version of the
          mobile app and also to the future approved applications (app versions) created by Game90.
          Game90 is the full and sole owner and provider of these mobile apps. <br />
          <br />
          1.1.4 The present conditions also apply to all downloadable applications for information
          and communication terminals. In this regard, the general Terms and Conditions can be
          transferred from the website to the app for all information and communication devices. In
          the following, all application and communication devices are included in the terms
          “website” or “Game90”.
          <br />
          <br />
          1.1.5 The term “service” refers to the opportunity to participate in all bets offered by
          Game90. <br />
          <br />
          1.1.6 The term “software” refers to all computer programs that can be downloaded from the
          website or from an application shop (app store, Google Play store, etc.). <br />
          <br />
          1.1.7 The term “Game90 Service” can refer to the website, the software and the betting
          service. <br />
          <br />
          1.1.8 The terms “you” or “your” or “customer” refer to any person who uses Game90s
          services in accordance with these general Terms and Conditions. <br />
          <br />
          1.1.9 The term “account” refers to the customers player account on game90.bet. <br />
          <br />
          1.1.10 The General Data Protection Regulation, which has been in force across Europe since
          25th May 2019, is referred to in these Terms and Conditions as the GDPR. <br />
          <br />
          1.1.11 Game90 has been using the OASIS player blocking system of the Darmstadt Regional
          Council since 1st March 2020. This online player status query system is referred to as the
          OASIS system or just OASIS in these Terms and Conditions and relates completely to this
          blocking system and is mandatory for all customers.
          <br />
          <br />
          1.1.12 As a result of the regulations of the European Union, Game90 has given the option
          of a dispute settlement body. This is generally abbreviated as ADR (Alternative Dispute
          Resolution) and referred to in these Terms and Conditions as ADR. <br />
          <br />
          These definitions apply equally to Game90s privacy policy and betting and sports betting
          rules. <br /> <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="2" className="mt-75">
          2. Your Account
        </h5>
        <h6 className="mt-3">2.1 Open an account</h6>
        <p className="mt-3">
          2.1.1 In order to be able to use a service via the Game90 website, customers must register
          and create their own user account. The minimum age to register is 18 years (21 years for
          players from Estonia). No service can be concluded without registration.
          <br />
          <br /> 2.1.2 When registering, customers must provide the required requested data.
          Customers are responsible for ensuring that all information provided remains correct and
          up-to-date. If the required personal data is not provided, Game90 has the right and the
          obligation to close a customer account and to freeze any funds that have already been
          paid. Game90 also reserves the right to declare all services invalid if false or
          misleading information has been provided by customers. <br />
          <br />
          2.1.3 Each individual may open only one user account. Game90 reserves the right, at its
          own discretion, to close additional accounts and withhold any balances or to treat several
          as a single account and act accordingly. <br />
          <br />
          2.1.4 Game90 also reserves the right to have its customers checked by third-party
          companies who are entitled to receive a copy of the information provided by customers upon
          registration in accordance with our data protection provisions. <br />
          <br />
          2.1.5 Game90 advises that it is illegal to deposit illegally acquired money.
          <br />
          <br />
          2.1.6 All transactions are checked both technically and manually to prevent money
          laundering and illegal transactions. Suspicious transactions are reported to the Financial
          Intelligence Analysis Unit (FIAU) in Malta or the FIU (Financial Intelligence Unit) in
          Germany. The FIUs are supervisory authorities that record the suspicious transactions,
          evaluate them and, if necessary, pass them on to the responsible authorities (FATF -
          Financial Action Task Force or, in Germany, the BKA and customs). <br />
          <br /> 2.1.7 The customer account and the amounts contained therein are to be used
          exclusively for stakes. For pure betting stakes, accounts of German-based customers are
          charged with a concession fee (betting tax) in accordance with Section 18 of the Racing
          Betting and Lottery Act of 25th June 2021. <br />
          <br />
          2.1.8 Game90 reserves the right to carry out digital, technical, electronic and manual
          checks at different times and at different points of cooperation with customers in order
          to ensure security for customers and Company. <br />
          <br />
          2.1.9 Game90 is not a financial Company and may not be misused as such. Amounts of money
          kept for a prolonged time in a user account, which are due to deposits that are higher
          than the average per customer and which have not been used for at least 90 days, can be
          reported by Game90 to the authorities in accordance with point <br />
          <br />
          2.1.6. Game90 does not offer user accounts on a credit basis and will never provide a user
          account with credited money. This is not comparable to possible promotions in which a
          bonus is available in advance. Bonuses are tied to provisions that cannot be equated with
          a loan.
        </p>
        <h6 className="mt-3">2.2 Registration</h6>
        <p className="mt-3">
          2.2.1 Registration takes place online by entering the required personal data, such as
          name, address, email contact, date of birth, username and password. The password can be
          changed at any time by the customer on the website. The account cannot be created without
          the required information.
          <br />
          <br /> 2.2.2 Without the separate confirmation of the consent to the submission of the
          personal data of the General Data Protection Regulation according to Article 7, a player
          account cannot be opened. The consent is also required to meet legal requirements for
          verification. According to Article 6 (1) f of the GDPR, Game90 has the legitimate and
          necessary right to request the data for processing.
          <br />
          <br /> 2.2.3 Game90 reserves the right to automatically delete unused customer accounts
          with no credit after expiry of a period of three months. In this case, you can register
          again at any time.
          <br />
          <br /> 2.2.4 The registration automatically includes the opening of a personal virtual
          player account. The opening of this player account is free and does not involve any
          obligation for the customer. <br />
          <br />
          2.2.5 All monetary transactions (in particular deposits, withdrawals, bonus credits and
          stakes) are carried out exclusively via the personal player account. Claims and complaints
          about credits, pay-outs or other transactions that are booked to the players account must
          be received by Game90 within 14 working days by email or in writing. After expiry of this
          period all booked transactions are considered to have been approved by the customer. 2.2.6
          Game90 reserves the right to refuse registration without giving any reason.
        </p>
        <h6 className="mt-3">2.3. Data protection</h6>
        <p className="mt-3">
          2.3.1 In accordance with Article 12 of the General Data Protection Regulation, every user
          is free to request from Game90 an inventory of their data every six months. These are made
          available to the customer in a readable format in accordance with applicable law. A
          request for information triggers a separate verification, which is carried out in
          accordance with clauses 5.3.7. ff of these Terms and Conditions. Further information on
          this is also provided in our data protection policy. <br />
          <br />
          2.3.2 Customers have the right to keep their data up to date. For this purpose, customers
          can also request that their personal data be completed in accordance with Article 16 of
          the General Data Protection Regulation. <br />
          <br />
          2.3.3 Customer data is stored in accordance with statutory provisions. If the deletion of
          this data is requested, this period begins on the date of the requested deletion and the
          customers will be informed accordingly. <br />
          <br />
          2.3.4 At Game90, personal data is also stored cryptically changed on specially secured
          storage media or servers with special security. Game90 will provide this data in a
          readable format upon request.
          <br />
          <br /> 2.3.5 Game90 does not rely only on automated classification, so-called profiling,
          i.e. an assessment of customer behaviour based on automated processes. Profiling is also
          carried out through manual inspections and assessments and do not follow any programmed
          logic. <br />
          <br />
          2.3.6 A subdivision into racial, ethnic, religious, political or ideological data or the
          processing of genetic or sexual interests-oriented data does not take place during
          personal data processing by Game90.
          <br />
          <br /> 2.3.7 All customers have the right to withdraw their consent to the processing of
          their personal data in accordance with Article 7 (3) or to restrict it in accordance with
          Article 18. All data processed up to then remain unaffected by this revocation. In
          response to the revocation, Game90 will inform all processors of the personal data about
          the revocation. <br />
          <br />
          2.3.8 Game90 Limited has appointed a data protection officer (DPO) for the Company. He can
          be reached by email at DPO@game90.bet for questions about data protection.
        </p>
        <h6 className="mt-3">2.4 Use of the account</h6>
        <p className="mt-3">
          2.4.1 Each account is for personal use only and may not be used for professional, business
          or commercial purposes.
          <br />
          <br /> 2.4.2 Customers are obliged to ensure that no third party, in particular no person
          under the age of 18, has access to the user account or the passwords (neither
          intentionally nor unintentionally) or uses their identity to access the Game90 website and
          its services. Operations made by third parties who have known the username or password are
          treated as if they had been made by the owner of the user account himself. Customers are
          responsible for all transactions made by third parties on their behalf. Game90 is not
          liable for the unauthorized use of customer accounts. Regardless of whether third parties
          access the Game90 service with or without the consent of the customer, the resulting
          losses will not be reimbursed.
          <br />
          <br /> 2.4.3 Game90 can temporarily close a customer account for security reasons if data
          abuse is suspected and request customers to change their password immediately. This also
          applies if Game90 becomes aware of circumstances under clause 2.4.2. <br />
          <br />
          2.4.4 Customers assure that they will not use the Game90 services and website during a
          stay in a country in which participating in online bets or online games violates the
          applicable laws or regulations of the place or country. Game90 reserves the right to
          restrict the use of the Game90 services for these locations or countries at any time.
          2.4.5 Game90 recommends its customers to check the current account balance after each
          registration. Customers must notify Game90 immediately in writing if corrections are
          required. If such claims are made, customers not only have to present their current
          account balance, but also all transactions since the last balance adjustment, so that a
          check can be carried out in accordance with clause 3.1.7. <br />
          <br />
          2.4.6 Each party can close the user account immediately, temporarily or permanently if the
          other party does not fulfil its obligations under these conditions. The payment of any
          remaining amounts is based on the provisions of these general Terms and Conditions. The
          temporary or permanent closure of a user account does not release the parties from their
          statutory rights and obligations.
          <br />
          <br /> 2.4.7 Game90 reserves the right to refuse all wagers for the service it offers at
          any time without giving reasons. However, Game90 endeavours to inform customers of the
          reasons for this decision. <br />
          <br />
          2.4.8 Game90 is not a financial institution and may not be treated as such. No interest is
          paid on account balances and according to clause 2.1.9 Game90 has the right as well as the
          obligation to inform the relevant competent authorities (FIAU, ZOLL or BKA etc.) about
          this fact.
          <br />
          <br /> 2.4.9 The credit can be offset against all amounts that customers owe the Company.
          <br />
          <br />
          2.4.10 Customers accept that they may lose money by wagering and accept full
          responsibility for such losses. <br />
          <br />
          2.4.11 All account movements can be displayed online in real time at any time.
          <br />
          <br />
          2.4.12 If you have any questions about your account, please contact our customer service
          at support@game90.bet. <br />
          <br />
          2.4.13 The Company reserves the right to change any of its services and its website at any
          time and without notice, and to add or remove services. <br />
          <br />
          2.4.14 The use of applications for the Game90 services, which were created exclusively by
          Game90, is left to the player and the user acts at his own risk and releases Game90 from
          liability. (see also 6.2 and clause 7 - limitation of liability). <br />
          <br />
          2.4.15 Game90 customer service and every other Game90 employee offer a professional and
          polite service for every customer contact. Any rude or other type of harassment towards
          Game90 employees can be recorded and results in the direct and immediate closure of the
          user account. Game90 also reserves the option to take legal action in such cases, if
          necessary.
        </p>
        <h6 className="mt-3">2.5 Closing your account</h6>
        <p className="mt-3">
          2.5.1 The Company as well as the customers can close an account at any time and without
          giving reasons. Game90 will endeavour to inform customers of this decision. To request
          deletion of their account, customers simply send a written notification by email, fax or
          post to the Company address mentioned. <br />
          <br /> 2.5.2 Any balance in the account at the time of closure, except in cases of doubt
          or fraud, will be credited to the bank account on record at the Companys discretion.
          Profits made after an account was closed will be credited to the customer account.
          Pay-outs are dealt with in accordance with clause 3.1.3. Withheld money is treated in
          accordance with the statutory provisions and, if necessary, handed over to the
          authorities. <br />
          <br />
          2.5.3 Notwithstanding the above provisions (§ 2.4), the Company is entitled to block or
          delete a new account with immediate effect if 2.5.3.1 the customer violates these general
          Terms and Conditions (GTC) or the rules for betting on sports betting; 2.5.3.2 there is
          legitimate reason to believe or indicate that customers deposits are associated with
          illegal or fraudulent activities. In such a case, the Company assumes no liability for
          payments made by customers that exceed the statutory limits. The Company transmits
          (without breaching its obligations arising from these general Terms and Conditions or data
          protection provisions) to the competent authorities at their request all information or
          documents relating to these customers (cf. 2.1.4 and 2.1.6); 2.5.3.3 the Company suspects
          that an unauthorized person is trying to or has an account with false or falsified
          identity data; <br />
          <br /> 2.5.3.4 the statutory provisions require the account to be blocked or closed;
          <br />
          <br />
          2.5.3.5 Customers object to the use of their personal data required to maintain business
          operations in accordance with Article 7 of the General Data Protection Regulation; <br />
          <br />
          2.5.3.6 there is a security problem or other problem the Company considers important or
          the Companys reputation is likely to be damaged; <br />
          <br />
          2.5.3.7 A chargeback was made in the past and/or was successfully requested and the amount
          at that time was not fully used once;
          <br />
          <br />
          2.5.3.8 The Company knows or becomes aware that customers already had a previous account
          that was closed to prevent gambling addiction and / or is still closed, or that customers
          make suspicious comments on the subject of gambling addiction; <br />
          <br />
          2.5.3.9 Users have a citizenship or a nationality whose state generally prohibits online
          gambling outside of its own jurisdiction (e.g. USA or Lithuania) worldwide;
          <br />
          <br /> 2.5.3.10 Customers previously owned an account that was closed due to a breach of
          these or previous terms, or if the Company learns that the account holder is the same
          person or partner or relative of the person whose account was in violation of these or
          previous terms or have violated conditions and the user accounts have been closed. <br />
          <br />
          2.5.4 Customers are prohibited from transferring, buying or selling a user account to or
          from other players. In such event, Game90 has the right to close these accounts
          immediately. (see also clause 2.5.3.1) <br />
          <br />
          2.5.5 Customers cannot and must not make transfers between user accounts. <br />
          <br />
          2.5.6 An account is considered an inactive account if customers have not logged in for a
          period of more than three months. <br />
          <br />
          2.5.6.1 Customers will be informed by email 30 days before their inactivity occurs. <br />
          <br />
          2.5.6.2 For inactive accounts that have not been used (registered) for more than 360 days,
          an administration fee of 5.00 Euros (five) will be charged for each additional inactive
          month, but without generating negative balances on the player account. Should the player
          account show no amount of money or the balance after deduction of fees falls to zero, the
          account will be blocked after the information period (clause 2.5.7.1) has expired. <br />
          <br />
          2.5.6.3 If customers have not logged into their account for a period of 60 (sixty) months
          and their account has already been closed, any amounts of money in favour of Game90 Ltd.
          will be forfeited.
          <br />
          <br /> 2.5.6.4 Prior to forfeiture (60 months inactive) Game90 Ltd. shall contact the
          customers by all possible means of communication (e-mail, telephone or post) and point out
          the imminent forfeiture of their funds. Customers should observe the deadlines set in the
          correspondences, as once the deadline has expired, they are no longer entitled to the
          payment of the money. <br />
          <br />
        </p>
        <h6 className="mt-3">
          2.6 Management of the Game90 Account Game90 reserves the right, at its own discretion and
          at any time:
        </h6>
        <p className="mt-3">
          2.6.1 to refuse to open an account and/or close an existing account without giving a
          reason; <br />
          <br />
          2.6.2 to refuse the deposit of funds without giving a reason; <br />
          <br /> 2.6.3 to request documents <br />
          <br />
          2.6.3.1 to establish or confirm the complete and unequivocal identity of the account
          holder. <br />
          <br /> 2.6.3.2 to investigate the origin of the funds deposited by the player at any time
          or to have it determined by a third party appointed by Game90. <br />
          <br /> 2.6.3.3 to verify the authority to use a particular payment method and/or <br />
          <br /> 2.6.3.4 to review other data and information provided by account holders. This can
          be done at any time and Game90 reserves the right to exclude a player account from
          possible use, even during ongoing checks, or to suspend it temporarily until the check has
          been completed. <br />
          <br /> 2.6.4 without notice <br />
          <br /> 2.6.4.1 to transfer to any country data of an account holder to other legal persons
          (third parties), which are ultimately administered or controlled by Game90 or
          commissioned. Game90 guarantees that the said data will be transferred and managed at all
          times in accordance with the applicable legislation and data protection laws and / or
          similar regulations.
          <br />
          <br /> 2.6.4.2 to transfer and/or license the rights and obligations of the account holder
          to other legal persons in any country, provided that these legal persons are ultimately
          managed or controlled or commissioned by Game90 and Game90 guarantees compliance with the
          obligations. <br />
          <br />
          2.6.5 to hold and manage the balance of account holders in accordance with generally
          accepted cash management guidelines. This may include the involvement of financial
          institutions and/or payment service providers commissioned to manage the account balance
          on behalf of and / or for the benefit of the account holder; <br />
          <br /> 2.6.6 to allow the balance of the Game90 account to expire and r to block and/or to
          refuse to satisfy a claim, in the event that directly or indirectly <br />
          <br /> 2.6.6.1 the Game90 regulations have been violated and/or <br />
          <br />
          2.6.6.2 if, for example, unauthorized activities in connection with a betting or gaming
          event and/or the operation of the Game90 account or all other Game90 services have taken
          place (such as, but not limited to, the violation of laws or other regulations, violation
          of third party rights or fraud); <br />
          <br /> 2.6.7 to suspend and/or prevent account holders from participating in games,
          promotional activities, competitions or other services whenever Game90 believes there are
          legitimate concerns that a Game90 account is being, was or could be misused for illegal,
          fraudulent or dishonest practices. This also includes preventing gambling addiction.
          <br />
          <br /> 2.6.8 to block or limit a players account if users are identified as exposed
          persons (PEP). This also applies to celebrities who are or were active in one of the
          sports offered (as active or inactive amateur or professional athletes) or media
          celebrities from radio and television as well as social media (MEP). If a player is on a
          sanctions list, the player account will be closed immediately and reported to the
          competent authority. <br />
          <br /> 2.6.9 to suspend and/or prevent the participation of account holders in the
          services and/or to block the funds on the Game90 account if the account holder has been
          convicted of fraud or Game90 discovers that the account holder has a system (including
          machines, robots, computers, software and any other automated system) that is designed or
          capable of tampering with, damaging or obtaining a financial advantage the customer
          applications and or the Game90 software. <br />
        </p>
        <h6 className="mt-3">2.7 Player protection</h6>
        <p className="mt-3">
          <br /> 2.7.1 By clicking the permanent green banner 18+ Gambling can be addictive. Play
          responsibly. The customers reach the Game90 prevention page, where they can find out more
          about gambling addiction and also request help. <br />
          <br /> 2.7.2 Customers have the option of having themselves blocked for future wagers by
          requesting the blocking of their player account in writing, by e-mail or by post, or by
          using the settings on the website. <br />
          <br /> 2.7.3 Game90 uses the official player blocking system OASIS. By entering the
          self-exclusion of a player, this information is recorded and monitored by the state
          authorities and a player is blocked for the whole of Germany (even if he goes to another
          provider). Players can set up an unlimited game ban with a minimum duration of 3 months at
          any time. An exception is the so-called panic button, which triggers a break for 24 hours
          (see 2.7.7). A block can only be lifted upon written application by the blocked person.
          This also applies if a certain period was specified when applying for the block for its
          duration. The application can be submitted after the deadline at the earliest. If no
          request for revocation is made, the ban does not end! The application to lift the player
          ban must be submitted to the Darmstadt Regional Council, the authority responsible for
          maintaining the blocking file. It is sufficient for an operator or agent to forward the
          application. Operators and brokers of games of chance cannot lift player bans! During
          registration and later when making deposits, Game90 uses the official OASIS system to
          check for possible player bans. If a player is already blocked, the player account will be
          frozen or not created at all. <br />
          <br /> 2.7.4 Customers have the option to set their own limits in their user account in
          the private area of security and limits of the website.
          <br />
          <br /> 2.7.5 Limits can be set up independently as a deposit limit (maximum deposit/EUR
          1,000 per calendar month/across providers), as a betting limit (maximum bet amount) and as
          a loss limit (maximum loss) for days, weeks or months or, if requested, it can also be set
          by customer service. <br />
          <br /> 2.7.6 Reductions in risk limits take effect immediately. Increases become active
          after a statutory period of 7 days. These settings are required by law and cannot be
          changed by exception. <br />
          <br /> 2.7.7 Furthermore, the player has the option of blocking himself for 24 hours using
          the panic button, which is displayed on the website at any time. <br />
          <br /> 2.7.8 The players will be informed of the total of the stakes, winnings and losses
          of the previous 30 days. This information will be provided after each identification and
          authentication, as well as before the start of a game if more than 24 hours have passed
          since the last information. Participation in the game may only take place after the player
          has expressly stated that he has taken note of the information.
          <br />
          <a href="#top">Back to top</a>
        </p>
      </div>

      <div>
        <h5 id="3" className="mt-75">
          Payments and Security{' '}
        </h5>
        <h6 className="mt-3">3.1 Payments </h6>
        <p className="mt-3">
          3.1.1 The account for betting is only managed in Euros (EUR, €). Other currencies may not
          be used. No lines of credit are provided. A prerequisite for a stake is a credit equal to
          or higher than the respective stake. <br />
          <br />
          3.1.2 The following payment options are available: - Paysafecard (immediately) - Credit
          card (Mastercard and Visa, immediately) insofar as these methods are permitted for online
          games in this legal area - SOFORT Banking (immediately) - GIROPAY (immediately) - Skrill
          (instant) - Bank transfer (within up to five working days) Payments are made on the
          condition that the billing address matches the address and identity provided during
          registration. Other payment methods can be accepted at Game90s discretion as long as they
          fully comply with the statutory provisions, in particular those relating to money
          laundering. Customers will be informed of all costs incurred by the use of alternative
          payment methods. Deposits can be made at any time (free of charge) by bank transfer (see
          bank account information) or credit card. After receipt of the deposit in the Companys
          bank account or electronic confirmation of the credit card payment, the deposit amount
          will be credited to the account. Only one credit card or other payment method per customer
          account may be used for deposits and/or withdrawals. Frequent or constant changes in the
          payment methods are not permitted. <br />
          <br />
          3.1.3 The following minimum and maximum amounts are possible for deposits: <br />
          <br /> 3.1.3.1 Minimum deposit: 10 Euros per payment <br />
          <br /> 3.1.3.2 Maximum deposit: 1,000 Euros per payment (across providers per month) The
          maximum deposit can be increased in individual cases with the approval of the authorities.
          <br />
          <br /> 3.1.4 Game90 does not pay out any credit from player accounts as long as the
          unambiguous identity, age, current place of residence and the payment methods used by
          users have not been clearly established and confirmed. Game90 also reserves the right to
          have all player identification details clearly verified with the help of a third party. If
          feasible, Game90 will transfer the balance of the player account to the verified player
          bank account (or eWallet or credit card) at the request of the registered and fully
          verified player and in whose name a player account has been set up. <br />
          <br /> 3.1.5 Customers are independently responsible to state authorities for declaring
          and documenting the profits made from Game90 services and, if necessary, also for paying
          the fees. <br />
          <br /> 3.1.6 The following withdrawal methods are available: - Bank transfer (within up to
          five working days) Other payment methods for deposits and withdrawals may be added in the
          future at Game90s discretion. Each payment request is checked again manually or
          technically by the Companys employees before payment. Once the pay-out has been processed
          and approved, customers will be notified via email.
          <br />
          <br /> 3.1.7 Customers are obliged to pay all outstanding balances in full. If money is
          accidentally credited or debited to an account, customers must notify Game90 immediately.
          All amounts erroneously credited to an account must be repaid immediately. All amounts
          that have been erroneously debited to an account will also be reimbursed immediately.
          Funds that have been inadvertently credited to an account may not be used by customers for
          wagering. Game90 reserves the right to void all transactions funded with such monies. Each
          party undertakes to indemnify the other party from claims relating to amounts credited or
          debited in error.
          <br />
          <br /> 3.1.8 Customers undertake not to make any credit card chargebacks or to reverse any
          payments made otherwise. All costs and damages resulting from such return debits will be
          billed to the customer. Until the situation has been fully clarified, the affected player
          account will be closed immediately and pending pay-outs will be cancelled. Game90 reserves
          the option to take legal action in such cases and to inform the authorities about possible
          abuse. <br />
          <br /> 3.1.9 The Company reserves the right to block an account on suspicion of credit
          card fraud or credit card abuse. This applies especially to the use of stolen credit cards
          or other fraudulent activities. Game90 is not responsible for the misuse of credit cards
          or stolen credit cards. <br />
          <br /> 3.1.10 Game90 can credit bonuses to customer accounts. These bonuses are a
          voluntary service by the Company and customers have no fundamental legal right to them.
          Bonus can be credited technically or manually. <br />
          <br /> 3.1.11 Game90 monitors all payment transactions through manual, technical and
          electronic measures.
        </p>
        <h6 className="mt-3">3.2 Security </h6>
        <p className="mt-3">
          3.2.1 The users account balance is covered by cash or equivalent. The Company guarantees
          that the credit on a user account can be paid out in full. All deposits and withdrawals
          from an account are made online in real time (Malta) and are secured via VeriSign. <br />
          <br /> 3.2.2 Access to a user account is only possible with an email address and the
          individual personal password of a customer. <br />
          <br />
          3.2.3 Customers are solely responsible for keeping their usernames and passwords secret.
          The Company is under no obligation to save this data in the event that customers
          misplaced, forgot or lost it. If customers save their login information on an external
          device to be able to access their account from different computers or to secure the
          account information, they do so at their own risk. <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="4" className="mt-75">
          {' '}
          4. Restrictions on the use of the Game90 website
        </h5>

        <p className="mt-3">
          4.1 Game90 is not authorized to accept bets from persons under the age of 18. When
          registering, customers declare that they have reached the legal minimum age of 18 years
          and are mentally able to take responsibility for their own actions and that there are no
          problems with gambling addiction and/or the risk of addiction to gambling. Game90 has an
          obligation to void bets made by minors (or those the Company suspects to be minors).
          Customers acknowledge that underage gambling is a criminal offense. <br />
          <br /> 4.2 All fraudulent, criminal or suspicious activity will, without exception, be
          reported to the relevant authorities and agencies. <br />
          <br /> 4.3 The Company reserves the right to void all bets placed by groups of people who
          are suspected of having acted with conspiratorial or fraudulent intent or manipulated
          bets. This group can include people, relatives, organizations, bookmakers and their
          employees/representatives. <br />
          <br /> 4.4 The employees, executives, employees, consultants, representatives or partners
          of the Company, its branches or affiliated companies and their respective dealers,
          suppliers or subcontractors are excluded from the direct and indirect use of the Game90
          website or the mobile applications. Exceptions are test accounts that have been set up for
          technical monitoring and which have no influence on the service offered. This restriction
          also applies to the relatives of these people. In this context, the term “relative” refers
          to a spouse, life partner, parent, child or sibling. <br />
          <br /> 4.5 The use of the Game90 website is only permitted from locations where
          participation in such a service is legally regulated and legal. Customers assure the
          Company that they will access the website or its services from locations where such games
          of chance or wagering are not prohibited. Game90 monitors and uses technical measures to
          prevent such access as much as possible. In addition, customers assure that they will not
          access or log in to the website if they are nationals of a state that prohibits its
          citizens from participating in games of chance, regardless of their location. Customers
          acknowledge their sole responsibility for reviewing local laws that may prohibit the use
          of the Game90 services. It is advisable to consult a legal counsel before registering to
          ensure that the use of the services is in no way contrary to these laws. The Company is
          not responsible for any violation by customers of any local or national law. <br />
          <br /> 4.6 Notwithstanding the preceding paragraph, citizens of the USA and Lithuania and
          any other person residing in the USA or Lithuania are prohibited from logging in to and
          using the website. The Company will void all wagers made or believed to have been made in
          or from the United States or Lithuania, unless the customer has a permanent residence in
          Game90s jurisdiction.
          <br />
          <br /> 4.7 Game90 reserves the right to use technical, digital or other measures to check
          the authenticity of the data transmitted by the customer to assess the continuation of the
          existing business relationship. <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="5" className="mt-75">
          5. The website
        </h5>
        <h6 className="mt-3">5.1 Sports-Betting </h6>
        <p className="mt-3">
          5.1.1 By placing a bet, the customers account is debited with the amount of the bet. The
          user account of customers residing in Germany will be debited with the stake plus a
          betting tax on the stake. Customers are obliged to check the bet on the issued betting
          slip before it is sent. Once bets have been sent, they cannot be cancelled or changed. The
          Company is entitled to refuse bets in whole or in part without giving reasons.
          <br />
          <br /> 5.1.2 If customers place several bets, these are processed in the order in which
          they are received on Game90s central computer. <br />
          <br />
          5.1.3 Multiple bets can count as a single bet if customers repeatedly place the same bets
          and odds, i.e. bets that are identical in the game, result and bet. The Company also
          reserves the right to void bets if there are indications of collusion between two or more
          customers or if the entire betting behaviour appears suspicious. If a bet is declared
          invalid, it is evaluated with the odds 1.0, in this case the player only gets his bet
          back, unless the manipulation can be assessed as a fraudulent attempt, then the bets are
          also frozen (see also Point 5.3.1.1). If the customers betting behaviour suggests
          organized or professional behaviour, Game90 has the right to take appropriate measures to
          control the betting behaviour and, if necessary, to restrict or block it. This also
          applies, for example, to simple betting on chances of winning or bets that are placed
          based on insider knowledge. If this betting behaviour is suspected, Game90 will limit the
          players bets or exclude the player. <br />
          <br /> 5.1.4 The acceptance of bets and their pay-outs can be restricted to different
          limits. This includes, in particular, betting limits, betting slip limits, maximum
          winnings and the customers personal betting limit. These limits are subject to change and
          are listed on the betting slip or user account. However, Game90 reserves the right to
          limit the amount wagered or to change or limit the chances of winning as well as the
          pay-out of the winnings. If there is suspicion of manipulation, agreements made (also with
          several other customers) or other discrepancies, Game90 can subsequently limit, change or
          cancel these bets. In the cases mentioned, the player account will be blocked and, if
          necessary, forwarded to the authorities for further investigation. A disbursement of
          funds, including a possibly unused deposit amount, is held back until the investigations
          of the authorities have come to a result which justifies a disbursement. In other cases,
          the money is frozen. <br />
          <br /> 5.1.5 Bets accidentally accepted after the official start time are void unless
          otherwise stated. <br />
          <br /> 5.1.6 Live bets (in terms of § 21 GlüStV 2021) are bets that can be placed during
          an active event. Since the betting odds are regularly adjusted to the live event in live
          bets, a bet is placed with a time delay for security reasons. Should the odds change
          during this phase, the betting slip can be rejected and instead offered to the customer
          with different odds. It is up to the customers themselves to ensure that the changed
          quotas meet their expectations.
        </p>
        <h6 className="mt-3">5.2 Valid bets </h6>
        <p className="mt-3">
          5.2.1 A bet or placed bet is considered accepted if it has been confirmed with a betting
          slip number. The bet is then displayed under My bets (see betting rules and exceptions,
          clauses 5.1.4. and 5.1.5.). <br />
          <br />
          <br />
          <br /> 5.2.2 By placing a bet, customers acknowledge the rules for placing bets and sports
          betting and confirm that they have understood them, the process and the general risks of
          online gambling. <br />
          <br /> 5.2.3 The Company is not responsible if a bet cannot or could not be placed, for
          whatever reason. These reasons also include malfunction or failure of computers, failure
          of telecommunication services or Internet connections. Game90 does not recognize bets that
          have been sent but not fully confirmed in accordance with paragraph 5.2.1. <br />
          <br /> 5.2.4 Only bets that are accepted in accordance with clause 5.2.1 and are therefore
          also displayed in the players account are considered valid. Game90 assumes no guarantee or
          liability for representations that are displayed when the betting slip is placed on the
          website or in applications, etc., but which do not match the bet booked and accepted in
          accordance with clause <br />
          <br />
          5.2.1.. Such errors can also be caused, for example, by fluctuations in the Internet
          service (Internet connections) or insufficient maintenance of end devices on the part of
          customers and are therefore not valid. Customers have no statutory right to profits that
          are suspected of being misrepresented on end devices.
        </p>
        <h6 className="mt-3"> 5.3 Profits </h6>
        <p>
          5.3.1 All winnings will be automatically credited to a players account once these results
          have been officially confirmed. This can take a long time due to various circumstances.
          Game90 tries to book promptly. <br />
          <br />
          5.3.1.1 The use of AI systems or software (AI = artificial intelligence), especially
          machines, computers, software or other automated systems or programs or electronics that
          have been developed to manipulate the software on the Game90 website and/or gaining an
          advantage in betting is strictly forbidden. Profits resulting from the unauthorized use of
          such systems expire and result in the customer account being blocked. A refund of the
          deposited amounts is hereby immediately objected and all amounts (deposits and winnings
          etc.) are frozen. Game90 will also consider taking legal action in these cases. <br />
          <br /> 5.3.1.2 Game90 reserves the right to use suitable technical, digital, electronic or
          other measures to monitor the use of the website by customers. <br />
          <br /> 5.3.2 Winnings are credited with a pay-out to the same bank account (or eWallet
          etc.) from which the deposit was initiated. This is known as a closed loop policy and
          corresponds to the European legal requirements for the avoidance of money laundering and
          terrorist financing. Game90 reserves the right to take all necessary steps to confirm all
          details of each transaction. This will be done within a reasonable timeframe. <br />
          <br />
          5.3.3 The Company is not liable for lost, delayed, illegible, incomplete, damaged or
          misdirected documents, inquiries, claims for profits or communications from customers to
          the Company or messages for which additional postage is to be paid. Any inquiries, claims
          or correspondence received by the Game90 office in Malta will become the property of the
          Company upon receipt and will not be returned to the customer. It is therefore recommended
          that only credible copies of these documents are sent. <br />
          <br /> 5.3.4 The maximum profit per customer and week is 100,000 Euros (one hundred
          thousand). When betting - if identical or similar betting slips are submitted by
          customers, only a total profit of € 100,000 (one hundred thousand) can be achieved.
          Customer households (same home address, internet connection or family members) who have
          opened more than one account and have used these accounts to place identical or similar
          bets can also only achieve a total profit of € 100,000 (one hundred thousand), whereby
          Game90 reserves the right to block these multiple accounts in the event of suspected fraud
          and to freeze the payment completely.
          <br />
          <br /> 5.3.5 The credit will be paid out via the selected and verified payment method if
          this is requested by the customer by clicking the Auszahlen button. Customers can arrange
          for a credit balance to be paid out in full or in part to their bank accounts at any time,
          unless: 5.3.5.1 the balance on the user account, regardless of whether it consists of
          bonuses or winnings from bonuses or remaining balance from deposits, has not yet been used
          for betting in accordance with the applicable turnover conditions; <br />
          <br />
          5.3.5.2 it is the first payment request from a customer and the necessary conditions have
          not yet been met; <br />
          <br />
          5.3.5.3 the verification of the customers personal data shows that the data is incorrect
          or that the customer has more than one player account (in these cases the player account
          will be blocked and all gaming activities and results on this player account will be
          cancelled); <br />
          <br />
          5.3.6 The credit is paid out to the customer free of charge, unless: <br />
          <br />
          5.3.6.1 Customers demand, with a comprehensible and understandable explanation, the
          payment of an amount that has not yet been used. In this case, if Game90 is convinced of
          the declaration, a processing fee of 8% (but at least 10 Euros) of the requested amount
          will be deducted; along with the complete blocking of the user account and the user for
          life; <br />
          <br />
          5.3.6.2 Customers require the payment of an amount of less than € 20. In this case, a
          processing fee of € 5 will be charged. (This does not apply in the case of an account
          closure if the total remaining amount is less than € 20); <br />
          <br />
          5.3.6.3 Customers initiate a transfer to a bank account that cannot be paid with an EU
          standard transfer. For such international transfers, all fees are borne by the recipient.
          <br />
          <br />
          5.3.7 The initial payment to customers can only be made after the customers identity has
          been fully verified with a copy of the passport or ID, proof of address (bills for
          electricity, water, gas or landline telephone or, alternatively, an official registration
          certificate) and proof of the payment method used. All documents can also be sent by post
          or email. Before a pay-out, the previously deposited amount (or amounts) must have been
          fully used at least once. <br />
          <br />
          5.3.8 Game90 reserves the right to combine several pay-outs from the same customer in one
          sum and also to combine further pay-outs in a certain period of time. With the payment to
          the customers, Game90 provides them with information about the combined payment and the
          amounts it contains.
          <br />
          <br /> 5.3.9 Cash-out function 5.3.9.1 Game90 voluntarily offers a cash-out function for
          certain betting offers, in which customers can sell bets back to Game90 before the betting
          slip expires. This cash-out offer is a voluntary additional option on the part of Game90.
          In general, there is no legal entitlement to the cash-out function, even if it appears on
          the devices display. The associated process is only legal once it has been confirmed by
          Game90 for this cash-out function. <br />
          <br />
          5.3.9.2 Game90 has the right to deactivate the cash-out function during an ongoing event
          for security reasons. This is particularly the case if the connection to the sporting
          event was interrupted for more than 30 seconds for technical reasons. For security
          reasons, this bet is classified as a normal bet at the end and no longer falls into live
          mode. If this bet is part of a combination or system bet with a pay-out function, the
          pay-out function is automatically switched off and can no longer be used. In such cases,
          customers also have no legal right to early payment.
        </p>
        <h6 className="mt-3"> 5.4 Disputes </h6>
        <p className="mt-3">
          5.4.1 Should a dispute arise that is not regulated by these Terms and Conditions, Game90s
          support team (support@game90.bet) will endeavour to resolve it in a fair and equitable
          manner using our internal complaints procedure. Basis for disputes always are Game90s
          General Terms and Conditions and not the explanations of terms and information or topics
          dealt with (e.g. FAQ) that are presented or published on the website or in applications.
          5.4.2 Complaints regarding wagers and/or winnings must be received in writing by email or
          fax within 14 working days after the bet has been decided. After this period all bets will
          be considered approved. <br />
          <br />
          5.4.3 Any dispute that has not been resolved to your satisfaction may, upon request, be
          referred to the Independent Betting Adjudication Service (IBAS). Please also note clause
          15 of these Terms and Conditions. <br />
          <br />
          5.4.4 If players do not agree to the proposed solutions, the dispute can be settled via
          the ADR - Alternative Dispute Resolution (see point 16). <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="6" className="mt-75">
          6. Disclaimer and Game90 website availability
        </h5>
        <p className="mt-3">
          6.1 The Company dedicates itself to the operation of the Game90 website and applications
          with appropriate expertise and care. Unless required by law, the Company does not offer
          any guarantee or warranty, express or implied, with respect to the Game90 website and its
          services. <br />
          <br /> 6.2 The Company does not guarantee that the Game90 website and services will
          completely meet the requirements of customers, will be available without interruption or
          will function promptly, securely and without errors. The Company also does not guarantee
          that defects will be rectified or that the Game90 website and applications are free from
          viruses or errors. Furthermore, the Company does not guarantee the full functionality,
          accuracy and reliability of the data provided by the Company, the success of the use of
          the website or the accuracy of the data presented to customers on the website. Faults or a
          failure of the online offer should be reported immediately by email to support@game90.bet.
          Game90 will try to correct a possible error as soon as possible. <br />
          <br />
          6.2.1 Game90 assumes no liability if customers use the Game90 service with outdated
          applications, web browsers or other technically outdated devices and resulting
          transmission of errors, display errors or other incorrect data. <br />
          <br />
          6.2.2 Customers are responsible for correctly functioning end devices and must
          independently ensure that display errors are excluded. Game90 is not liable for errors in
          the display of apps and the website if bookings to the contrary have been confirmed in
          accordance with clause 5.2.1. Example: An image shows odds of 2.30, but the actual odds
          have been confirmed as 1.60 and displayed in the player account in accordance with clause
          5.2.1. Customers cannot claim the higher quota. <br />
          <br />
          6.3 Access to the Game90 website or the applications may occasionally be restricted to
          enable repairs or maintenance work or the introduction of new offers. Should this occur,
          the Company will try to restore online service as soon as possible. <br />
          <br />
          6.3.1 There is no legal entitlement for customers to possible lost profits that could have
          been placed in times of restricted access. <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="7" className="mt-75">
          7. Limitation of liability
        </h5>
        <p className="mt-3">
          7.1 By registering, customers accept that they use the Game90 website and its applications
          created and approved by Game90 at their own risk and voluntarily. <br />
          <br />
          7.2 The Company is not liable for any loss of data and content that is uploaded or
          transferred via the Game90 website. You acknowledge that neither Game90 nor any other
          Company belonging to Game90 is liable for damages of any kind resulting from changes to
          the Game90 website or from the temporary or permanent deactivation of the Game90 website.
          <br />
          <br /> 7.3 The Company reserves the right to completely or partially discontinue the
          Game90 website at any time. If this right is exercised, customers have no basis for claims
          for damages or other claims. <br />
          <br />
          7.4 The Company is not responsible for typing errors or technical and human errors during
          the operation of the Game90 website. In the event of an error, the Company reserves the
          right to either declare the affected bets void or correct the error. If a correction made
          by the Company affects the terms of a wager, customers will be given the opportunity to
          accept the changed terms before the bet is considered corrected. <br />
          <br />
          7.5 If Game90 violates these general Terms and Conditions, Game90 is only liable for any
          direct damage caused thereby. The prerequisite for this is that the customers have also
          previously recognized the latest version of the Terms and Conditions and are not allowed
          to refer to an older version. <br />
          <br /> 7.6 Any compensation in accordance with the preceding paragraph is limited to the
          amount of the maximum winnings on the respective betting slip (see also 5.2.1). <br />
          <br /> 7.7 In no event shall the Company be liable for any indirect consequential damage
          or loss allegedly caused by the Game90 website or its content. This includes in
          particular: delays or interruptions in operation or transmission; Failure of data
          transmission lines; Use or misuse of the website, its services or content in general by
          customers or third parties; inaccurate or incomplete information on the website; Loss of
          business, lost profit, business interruption, loss of business information or other,
          including financial, consequential damage (see also clause 6.2). <br />
          <br /> 7.8 Game90 is not liable for violations of these Terms and Conditions due to
          circumstances beyond its control. <br />
          <br /> 7.9 The Company reserves the right to completely or partially discontinue the
          website and its services at any time. In such a case, the customers waive any legal claims
          to payment, with the exception of the credit balance on the customer account. <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="8" className="mt-75">
          8. Intellectual property rights
        </h5>
        <p className="mt-3">
          The Company grants customers a non-exclusive, non-transferable and non-licensable right to
          use the computer programs (the software) downloaded from the Website and to install all
          content derived therefrom only in connection with these services and in accordance with
          these Terms and Conditions. This also includes copyrights and intellectual property
          rights. Customers can install the software on a hard drive or other storage device and
          create backup copies in connection with the use of the service for personal use on a
          computer whose primary users are the customers themselves. <br />
          <br />
          8.1 Customers may not: <br />
          <br />
          8.1.1 use, copy or change the software for this purpose, nor create works derived
          therefrom or distribute the software in whole or in part with changes, transcriptions or
          merged parts thereof, except to the extent that the aforementioned actions are legally
          permitted; <br />
          <br />
          8.1.2 decode, reconstruct, disassemble, decompile, translate the software or otherwise
          transform or convert the software or any part thereof, except to the extent that the
          aforementioned acts are permitted by law; <br />
          <br />
          8.1.3 transfer, loan, lease, transfer, rent or otherwise license the software to third
          parties; <br />
          <br />
          8.1.4 remove references to copyrights, property or similar notices from the software (or
          copies thereof);
          <br />
          <br /> 8.1.5 make the software accessible to third parties via a computer network or in
          any other way. <br />
          <br />
          8.2 The Game90 brand, the website game90.bet and all other brands, service marks or trade
          names (trademarks) used by the Company and all material on the Game90 website (in
          particular all software, texts, processes, concepts, images, graphics, video and audio)
          are owned by the Company, include intellectual property rights and are subject to
          copyright law. You acknowledge that you have no right to any trademarks or materials
          referred to herein, and your use of the Game90 website does not give you any entitlement
          to such rights. Use of these marks without the prior consent of the Company is expressly
          prohibited. <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="9" className="mt-75">
          9. Notification requirement
        </h5>
        <p className="mt-3">
          requirement In the event of a disagreement regarding a result, decision or other action
          relating to a bet, a complaint must be made within 14 working days of the incident in
          writing by email to support@game90.bet or in writing to the following address to the
          Company: Game90 Ltd., Melita Court, Level 1, Giuseppe Cali St c/w Abate Rigord St,
          XBX1420, TaXbiex, Malta. All responses from the Company will be sent to the customers
          address provided during registration (unless otherwise stated in these Terms and
          Conditions). <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="10" className="mt-75">
          10. General provisions
        </h5>
        <p className="mt-3">
          <br />
          10.1 These general Terms and Conditions in their currently valid version regulate the
          business relationship between the customer and the Company with regard to the use of the
          Game90 website and services. <br />
          <br />
          10.2 By accepting these conditions, customers expressly declare their consent to these in
          their current form. The customers also declare that no ancillary agreements have been
          made. Furthermore, customers must not rely on ambiguous formulations, regardless of
          whether or not such information has become an integral part of these general Terms and
          Conditions. <br />
          <br />
          10.3 The rights granted to customers from these Terms and Conditions are not transferable
          to third parties. The Company may transfer its rights and obligations hereunder to a third
          party, provided that the rights of the customers are not impaired. 10.4 If these Terms and
          Conditions do not expressly stipulate that a third party can derive a legal claim from
          them, the legal relationship from these Terms and Conditions and all subsequent contracts
          exists exclusively between the customer and the Company. All legal claims by third parties
          under these conditions are excluded. 10.5 If any part of these terms is found to be
          invalid or unenforceable for any reason, this provision will apply separately from the
          rest and will not affect the validity or enforceability of the remaining provisions. 10.6
          The Companys waiver of any portion of these terms will only apply to a specific incident
          and will not change these terms, nor will it constitute a general waiver of claims. 10.7
          These general Terms and Conditions in no way constitute an agency, partnership or any
          other type of joint venture between the customer and the Company. 10.8 In the event of any
          conflict between these Terms and Conditions and any other document relating to these
          Terms, these General Terms and Conditions shall prevail. <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="11" className="mt-75">
          11. Rules for placing bets / sports rules
        </h5>
        <h6 className="mt-3"> 11.1 Rules for placing bets </h6>
        <p className="mt-3">
          These general Terms and Conditions include the betting and sports rules, which regulate,
          among other things, the services offered on the website or in the applications, the rules
          of the game and the processing of bets by the Company. By accepting these Terms and
          Conditions, customers declare that the current version of the betting rules and
          regulations has been read, understood and accepted.
        </p>
        <h6 className="mt-3">
          11.2 Game-relevant information, costs, betting odds and winnings according to §7 GlüStV
        </h6>
        <div className="mt-3">
          All game-relevant information such as:
          <ol>
            <li key={90} className="mt-3">
              Participation costs
            </li>
            <li key={91} className="mt-3">
              Prize amount
            </li>
            <li key={92} className="mt-3">
              Presentation of profits
            </li>
            <li key={93} className="mt-3">
              Pay-out percentage
            </li>
            <li key={94} className="mt-3">
              Deadline for placing a bet
            </li>
            <li key={95} className="mt-3">
              Determination of profits
            </li>
            <li key={96} className="mt-3">
              Crediting of winnings
            </li>
            <li key={97} className="mt-3">
              Commercial register number{' '}
            </li>
            <li key={98} className="mt-3">
              How players can file complaints{' '}
            </li>
            <li key={99} className="mt-3">
              Issue date of permit
            </li>
          </ol>
          can be viewed at any time, if possible, before and after the bet is placed on the virtual
          betting slip, in the customer account and on the website. The results of all betting
          events are published on the online portal after the official evaluation. For any queries
          or complaints that may arise, the customer is provided with a clearly visible contact
          option for the bookmaker and an official contact point at any time. <br />
          <a href="#top">Back to top</a>
        </div>
      </div>
      <div>
        <h6 id="12"> 12. Bonus rule </h6>
        <p>
          12.1 The bonus regulation is explained in the separately listed bonus conditions. Game90
          issues bonuses under certain conditions which are treated according to the general bonus
          rules. Regardless of the bonus conditions, these Terms and Conditions continue to apply.
          <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="13" className="mt-75">
          13. Responsible gaming
        </h5>
        <p className="mt-3">
          gaming 13.1 All users should be aware that operations must be handled responsibly. It is
          important for users to understand that Game90 only offers bets on sporting events and
          cannot influence those events. In betting there is also no random generator that
          determines the outcome of a bet. It is up to the users to decide on which outcome a bet is
          made and whether this results in a positive outcome. 13.2 Game90 is committed to
          supporting responsible betting by promoting awareness of problematic gambling behaviour
          and improving prevention, intervention and treatment. 13.3 Game90s Responsible Gaming
          Principles document our commitment to minimizing the negative effects of problem gambling
          and promoting responsible gaming practices. 13.4 Game90 has published its own permanent
          page for dealing with responsible gaming behaviour, which can always be reached with one
          click, even without registration. With the exception of all sub-pages according to clause
          5.3.1.1. this page is fully valid and contains the latest information and help for dealing
          with problematic gaming behaviour. 13.4.1 Game90 uses a blocking system according to § 8.
          GlüStV 2021. It is called OASIS and is administered by the federal state of Hesse,
          represented by the Darmstadt Regional Council. 13.4.2 Game90 will check its customers for
          possible blockages in this OASIS system when registering and each time they make a
          deposit. 13.4.3 Listed and blocked players cannot carry out any further activities on
          Game90 if they are registered in the status block file. 13.4.4 A block via OASIS is valid
          for at least 3 months and can only be lifted again at or by the competent authority. This
          must be done in writing with a well-founded explanation. No exceptions are possible (see
          also 2.7.3 and 2.7.7). 13.4.5 In this case, the Darmstadt Regional Council from the State
          of Hesse is responsible for all data stored in OASIS. <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="14" className="mt-75">
          14. Data protection
        </h5>
        <p className="mt-3">
          Detailed information on the use and management of personal customer data can be found in
          our data protection guideline and under clause 2.3 of these Terms and Conditions. By
          accepting these conditions, customers declare that they have read, accepted and understood
          the privacy policy. <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="15" className="mt-75">
          15. General
        </h5>
        <p className="mt-3">
          15.1 The interpretation, validity and performance of this Agreement will be governed by
          the laws of Malta. 15.2 All versions of our Terms and Conditions are intended to reflect
          the same principles. In the unlikely event of a discrepancy between a non-English version
          and the English version of these Terms and Conditions, the English version will prevail.
          15.3 Place of jurisdiction for Game90 Limited is Malta. <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="16" className="mt-75">
          16. ADR - Alternative Dispute Resolution (ADR)
        </h5>
        <p className="mt-3">
          Dispute Resolution (ADR) 16.1 Game90 endeavours to make the user experience with the
          offered service as pleasant as possible. However, there may be times when a player is
          dissatisfied with the quality and service or customer support. A player can then file a
          complaint by sending an email to Game90 customer service at support@game90.bet. The
          complaint will be forwarded to the management and Game90 will endeavour to deal with it
          within 48 hours of receipt. The result of the complaint will be communicated to the user
          within 10 days from the date of full receipt. In certain cases this period can be extended
          by a further 10 days. 16.2 If users are not satisfied with the way the complaint has been
          handled or resolved by Game90s customer service team, customers can alternatively submit
          their disputes to the Alternative Dispute Resolution (ADR) unit by clicking the EADR link
          of the service provider: https://eadr.org/eadr-form/. Once users have received the final
          decision from Game90, users have the free right to access this ADR and refer their
          disputes there. 16.2.1 In a second step, users enter all the details in the form at
          https://eadr.org/eadr-form/ and are contacted by the service provider EADR, who is
          responsible for the concerns against Game90 Ltd.. 16.3 If users are not satisfied with the
          decision, they can also contact support.mga@org.mt or
          https://www.mga.org.mt/support/Online-Gaming-support - the player support site of the
          Malta Gaming Authority (MGA) - for assistance. The prerequisite for this, however, is
          prior contact via ADR - in this case via the service provider EADR.org. <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
      <div>
        <h5 id="17" className="mt-75">
          {' '}
          17. Effective Date
        </h5>
        <p className="mt-3">
          These Terms and Conditions come into effect on 1st October 2021 at midnight (00:00)
          Central European Standard Time. Version: 4.3.9. <br />
          <a href="#top">Back to top</a>
        </p>
      </div>
    </div>
  );
}
