export function getMatchDetail(match) {
  if (match === undefined) return;
  if (match.competitors === undefined) return;
  if (match.competitors.length !== 2) return;

  const home = {
    ...match.competitors[0],
    homeScore: match.homeScore
  };

  const away = {
    ...match.competitors[1],
    awayScore: match.awayScore
  };
  return { home, away };
}

export function getTeamNameHelper(locales, locale, competitor, t) {
  if (competitor === undefined) return 'null';
  if (
    locale === undefined ||
    locales === undefined ||
    locale === 'en' ||
    locales[locale][competitor?.id] === undefined
  ) {
    let baseName = competitor?.name;
    const age =
      competitor?.ageGroup?.length > 0 &&
      baseName.toLowerCase().indexOf(competitor?.ageGroup?.toLowerCase()) === -1
        ? ' ' + competitor?.ageGroup
        : '';
    const ageList = ['II', '(R)'];
    if (
      ageList.map((x) => {
        if (baseName.includes(x)) {
          baseName = baseName.replace(x, '');
        }
      })
    );

    const gender = competitor?.gender === 'female' ? ' ' + t('womenId') : '';
    return baseName + age + gender;
  } else {
    let baseName = locales[locale][competitor.id];
    const age =
      competitor?.ageGroup?.length > 0 &&
      baseName.toLowerCase().indexOf(competitor?.ageGroup?.toLowerCase()) === -1
        ? ' ' + competitor?.ageGroup
        : '';
    const ageList = ['II', '(R)'];
    if (
      ageList.map((x) => {
        if (baseName.includes(x)) {
          baseName = baseName.replace(x, '');
        }
      })
    );

    const gender = competitor?.gender === 'female' ? ' ' + t('womenId') : '';
    return baseName + age + gender;
  }
}
