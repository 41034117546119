import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchBalanceReq,
  postLoginReq,
  fetchUserReq,
  updateSettingsReq,
  updateProfileReq
} from './services';
import { setToken, backOfficeInstance, tmmInstance } from 'libraries/axios';
import { setCustomOddsMultiplier } from 'stores/betSlip';

const initialState = {
  user: null,
  token: null,
  balance: 0,
  refundBonusBalance: 0,
  userSettings: null,
  userLimits: null,
  transactions: null
};

export const login = createAsyncThunk('auth/login', async (body, { dispatch }) => {
  const response = await postLoginReq(body);
  dispatch(setCustomOddsMultiplier(response.customer.customerLimitDto.customOddsMultiplier));
  return response;
});

export const fetchUser = createAsyncThunk('auth/fetchUser', async (body, { dispatch }) => {
  const token = localStorage.getItem('token');
  if (token) {
    setToken(token);
    const response = await fetchUserReq(token);
    dispatch(
      setCustomOddsMultiplier(
        response.loggedCustomer.customer.customerLimitDto.customOddsMultiplier
      )
    );
    return response;
  }
});

export const fetchBalance = createAsyncThunk('auth/fetchBalance', async () => {
  const response = await fetchBalanceReq();
  return response;
});

export const updateProfile = createAsyncThunk('auth/updateProfile', async (formData) => {
  await updateProfileReq(formData);
  return formData;
});

export const updateSettings = createAsyncThunk('auth/updateSettings', async (formData) => {
  await updateSettingsReq(formData);
  return formData;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      setToken(null);
      backOfficeInstance.defaults.headers.common.Authorization = null;
      tmmInstance.defaults.headers.common.Authorization = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const { customer, token } = action.payload;
        state.user = customer;
        state.token = token;
        state.userSettings = customer.customerSettingsDto;
        state.userLimits = customer.customerLimitDto;
        state.balance = customer.balance;
        setToken(token);
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        if (action.payload) {
          const { loggedCustomer, token } = action.payload;
          state.user = loggedCustomer.customer;
          state.token = token;
          state.userSettings = loggedCustomer.customer.customerSettingsDto;
          state.userLimits = loggedCustomer.customer.customerLimitDto;
        }
      })
      .addCase(fetchUser.rejected, () => {
        setToken();
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          ...action.payload
        };
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.userSettings = {
          ...state.userSettings,
          ...action.payload
        };
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        const { refundBonusBalance, balance } = action.payload;
        state.balance = balance;
        state.refundBonusBalance = refundBonusBalance;
      })
      .addCase(fetchBalance.rejected, () => {
        setToken(null);
        backOfficeInstance.defaults.headers.common.Authorization = null;
        tmmInstance.defaults.headers.common.Authorization = null;
      });
  }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
