import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import LeftSidebar from './_desktop/left_sidebar';
import MatchContainer from './_desktop/match_container';
import MatchTracker from 'components/match_tracker/match_tracker';
import { getLanguageCode } from 'toolkit/utils';
import MatchDetailsDesktop from './_desktop/match_details';
import '../betslip/forza-betslip.scss';
import {
  fetchClearFavorites,
  fetchTopMatches,
  fetchEventsBySport,
  fetchEventsByCategory,
  fetchSportCounts
} from 'stores/match';
import Today from './_desktop/today';
import RightSidebar from './_desktop/right_Sidebar';
// import FinancialSummary from './_desktop/FinancialSummary';
// import Break from './_desktop/Break';

const HomeDesktop = () => {
  const dispatch = useDispatch();
  const desktopSearchParams = useSelector((state) => state.match.desktopSearchParams);
  const location = useLocation();
  const [matchTrackerData, setMatchTrackerData] = useState(null);
  const [matchDetailId, setMatchDetailId] = useState(null);
  const [loading, setLoading] = useState(false);

  const getSportEvents = async () => {
    try {
      if (desktopSearchParams.Sport === null) return;
      setLoading(true);
      const params = {};
      let fetchFunc;
      if (desktopSearchParams.Category) {
        fetchFunc = fetchEventsByCategory;
        params.Sport = desktopSearchParams.Sport;
        params.Category = desktopSearchParams.Category;
        params.dateFilter = desktopSearchParams.dateFilter;
      } else {
        fetchFunc = fetchEventsBySport;
        params.Sport = desktopSearchParams.Sport;
        params.dateFilter = desktopSearchParams.dateFilter;
      }
      await dispatch(fetchFunc(params)).unwrap();
    } catch (error) {
      // error
    }
    setLoading(false);
  };

  const getTimeCounts = () => {
    if (!desktopSearchParams.dateFilter) return;
    dispatch(fetchSportCounts({ dateFilter: String(desktopSearchParams.dateFilter) }));
  };

  useEffect(() => {
    getSportEvents();
    getTimeCounts();
  }, [desktopSearchParams]);

  useEffect(() => {
    if (location.pathname == '/' || location.pathname == '/sport') {
      // TODO
      // setMode('topMatches');
      // if (params.Sport !== 0 && params.dateFilter !== '1') {
      //   setParams({
      //     Sport: 0,
      //     dateFilter: '1'
      //   });
      // }
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(fetchTopMatches());
    dispatch(fetchClearFavorites());
  }, []);

  const handleMatchTrackerSet = (data) => {
    if (!matchTrackerData || data.providerId !== matchTrackerData.providerId) {
      setMatchTrackerData(data);
    } else {
      setMatchTrackerData(null);
    }
  };

  const handleMatchDetailIdSet = (data) => {
    setMatchDetailId(data);
  };

  const mainContent = () => {
    if (matchDetailId) {
      return (
        <MatchDetailsDesktop
          matchDetailId={matchDetailId}
          onMatchDetailIdSet={handleMatchDetailIdSet}
        />
      );
    }
    return (
      <MatchContainer
        loading={loading}
        onMatchTrackerSet={handleMatchTrackerSet}
        onMatchDetailIdSet={handleMatchDetailIdSet}
      />
    );
  };

  return (
    <div className="container-fluid py-2">
      <div className="row flex-nowrap">
        <Col xs={2} className="filter-sidebar">
          <LeftSidebar />
        </Col>
        <Col xs={7} className="desktop-main">
          {desktopSearchParams.mode === 'today' ? (
            <Today
              onMatchTrackerSet={handleMatchTrackerSet}
              onMatchDetailIdSet={handleMatchDetailIdSet}
            />
          ) : (
            mainContent()
          )}
          {desktopSearchParams.mode === 'topMatches' && (
            <Today
              onMatchTrackerSet={handleMatchTrackerSet}
              onMatchDetailIdSet={handleMatchDetailIdSet}
            />
          )}
        </Col>
        <Col xs={3}>
          <RightSidebar />
          {matchTrackerData ? (
            <div className="mb-3">
              <MatchTracker
                matchId={matchTrackerData.radarId}
                sportId={matchTrackerData.sport}
                language={getLanguageCode().split('-')[0]}
              />
            </div>
          ) : null}
          {/* <Break /> */}
        </Col>
      </div>
      {/* <FinancialSummary /> */}
    </div>
  );
};

export default HomeDesktop;
