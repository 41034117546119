import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Search from 'components/search/search';

const SearchOffCanvas = ({ show, handleClose }) => {
  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Search</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Search />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SearchOffCanvas;
