import { Tabs, Tab } from 'react-bootstrap';
export default function TR() {
  return (
    <Tabs justify className="mb-4 py-2 text-start me-1 ms-1">
      <Tab eventKey="overview" className="py-2 text-start me-1 ms-1" title="Genel Bakış">
        <h2 className="text-danger">GENEL BAKIŞ</h2>
        <p className="mt-3">
          &quot;İçgüdülerinizi takip edin&quot; Şirketimizin sloganı, bahisçilerin özelliğini tam
          olarak karakterize etmektedir: Paranın tam karşılığını alma hissi. İçgüdülerinizi takip
          edin ve kapsamlı bahis tekliflerimizden, yüksek bahis limitlerimizden ve uzun süre boyunca
          mevcut olan ve birkaç saniye içinde güncellenen oranlarımızdan yararlanın. Game90 hakkında
          daha fazla bilgi edinmek ister misiniz? Burada sizin için bir genel bakış sunuyoruz.
          <br />
          <br />
          Ek bilgiler - oyunla ilgili.
        </p>
        <p className="mt-75">
          1. Katılımdan kaynaklanan tüm masraflar
          <br />
          <br /> Sitemizde bir spor bahisine katılım, bahis tutarı ve bahis vergisi dışında herhangi
          bir ek maliyet getirmez.
        </p>
        <p className="mt-75">
          2. tüm kârın tutarı,
          <br />
          <br /> Maksimum kar şu şekilde hesaplanabilir: bahis x bahis oranları.{' '}
        </p>
        <p className="mt-75">
          3. Tüm ödüllerin ne zaman ve nerede yayınlanacağı,
          <br />
          <br /> Kazançlar oyuncu hesabında &quot;Bahislerim&quot; altında yayınlanır; ve tüm oyun
          sonuçları game90.bet/results adresinde incelenebilir
        </p>
        <p className="mt-75">
          4. bahisten elde edilen kazançlar için yapılan ödemelerin yüzdesi (ödeme yüzdesi),
          <br />
          <br /> Spor bahisleri sonuç odaklı bir etkinlik olduğu için sabit bir ödeme oranı yoktur.
        </p>
        <p className="mt-75">
          5. Kâr ve zarar olasılığına ilişkin bilgiler,
          <br />
          <br /> Casino veya Slot oyunlarının aksine, bir spor etkinliğinin sonucunun gerçek
          olasılıkları bilinmemektedir. Bahis sağlayıcısı bu etkinlikler için örtülü olasılıklara
          sahip bireysel oranlar sunar. Olası kazançlar, bahis oynanmadan önce müşteriye
          gösterilecek ve ayrıca bahis kuponunda da gösterilecektir. Kota belirlenirken Game90 Ltd.
          %103 ile %117 arasındaki kota anahtarı kullanılır. Örneğin bir oran anahtarı. %105, bir
          oyuncunun bir bahiste aynı miktarda tüm olası olaylara bahis yapması durumunda, oyuncunun
          istatistiksel olarak bahsin %1-100 / 105 = %4.76&apos;sını kaybettiği anlamına gelir.
        </p>
        <p className="mt-75">
          6. katılım için son tarih,
          <br />
          <br /> Kabul tarihi, bahsin oynandığı zaman damgasıdır.
        </p>
        <p className="mt-75">
          7. Kazananın belirlenme yöntemi, özellikle rastgele oyun sonuçlarının oluşturulmasının
          dayandığı rastgele mekanizma hakkındaki bilgiler,
          <br />
          <br /> Bir oyun ipucunun değerlendirilmesi spor etkinliklerinin sonucuna dayanmaktadır. Bu
          sonuçları Sportsradar, Perform Group ve Betgenius gibi köklü hizmet sağlayıcıların yanı
          sıra ilgili derneklerin resmi sonuç hizmetlerinden alıyoruz. Spor bahislerinde slot
          makinelerinde ve casino oyunlarında olduğu gibi rastgele bir mekanizma yoktur.
        </p>
        <p className="mt-75">
          8. Kazananlar arasında ödüllerin nasıl paylaşılacağı,
          <br />
          <br /> Kazançlar farklı oyuncular arasında paylaşılmayacaktır. Bahis müşterisi ile bahis
          operatörü arasındaki bahis sözleşmesini gösteren bir bahis kuponu bulunmaktadır. Kazanma
          durumunda bahisçi belirtilen karı alır. At bahislerinde yaygın olan toplayıcı, Game90 Ltd.
          bahis teklifine dahil değildir.
        </p>
        <p className="mt-75">
          9. Kazananların kazançlarını talep etmeleri gereken son tarih,
          <br />
          <br /> Bahis yapıldıktan veya spor etkinlikleri sona erdikten sonra kazançlar otomatik
          olarak oyuncunun hesabına aktarılır.
        </p>
        <p className="mt-75">
          10. Lisans sahibinin adı ve iletişim bilgileri (adres, e-posta, telefon),
          <br />
          <br /> Game90 Ltd., aşağıdaki şirket adresiyle Bay Simon Sullivan tarafından temsil
          edilmektedir: 1st Floor of Melita Court, Giuseppe Cali St, c / w Abate Rigord St, XBX1420
          Ta&apos;Xbiex, Malta. Şirkete aşağıdaki e-posta adresinden ulaşılabilir: info@game90.bet
        </p>
        <p className="mt-75">
          11. Ticaret sicil numarası (varsa),
          <br />
          <br /> Game90 Ltd., Malta&apos;da şirket sicil numarasıyla kayıtlı bir şirkettir C50436.
        </p>
        <p className="mt-75">
          12. Oyuncunun şikayetlerini nasıl iletebileceği,
          <br />
          <br /> Oyuncunun bizimle canlı sohbet support@game90.bet aracılığıyla doğrudan iletişime
          geçme olanağı vardır. Oyuncu, şikayetin Game90 müşteri hizmetleri ekibi tarafından ele
          alınma veya çözülme şeklinden memnun değilse, müşteriler alternatif olarak hizmet
          sağlayıcının buradaki EADR bağlantısını tıklayarak anlaşmazlıklarını Alternatif Uyuşmazlık
          Çözümü (ADR) birimine iletebilirler: https ://eadr.org/eadr-form/. Kullanıcılar
          Game90&apos;dan nihai kararı aldıktan sonra, kullanıcılar bu ADR&apos;ye erişme ve
          anlaşmazlıklarını oraya yönlendirme hakkına sahiptir.
        </p>
        <p className="mt-75">
          13. Verilen iznin tarihi.
          <br />
          <br /> Uluslararası lisans: Malta Gaming Authority (MGA) Lisans numarası: MGA/B2C/191/2010
          Lisansın verildiği tarih: 18.12.2011
          <br />
          <br /> Alman lisansı: Federal Devletlerin Ortak Oyun Otoritesi&apos;nin (GGL) denetimi
          altında resmi olarak onaylanmış spor bahisleri operatörü. Game90 Ltd.&apos;ye internette
          ve sabit alanlarda spor bahisleri düzenleme resmi izni (ilk kez) 19 Kasım 2020 tarihli
          kararla verildi; bu, 22 Aralık 2022&apos;de uzatıldı (GGL beyaz listesi).
        </p>
      </Tab>
      <Tab
        eventKey="companyInformation"
        className="py-2 text-start me-1 ms-1"
        title="Şirket Bilgileri">
        <h2 className="text-danger">ŞİRKET BİLGİLERİ</h2>
        <p className="mt-3">
          Game90 2010 yılında Malta&apos;da kuruldu, orada bir şirket olarak tescil edildi ve
          2011&apos;den beri resmi olarak bahis sağlayıcısı olarak lisanslıdır. Game90&apos;ın
          kurucuları uzun yıllara dayanan bahis uzmanlığına ve yenilikçi fikirlere sahiptir - 2005
          yılında tamamen yeni bir format geliştirdiler LiveBet 0:0 konseptiyle. Sıklıkla maçın geri
          kalanı bahisi olarak da bilinen Canlı Bahis 0:0, bugünlerde herhangi bir bahis
          sağlayıcının yelpazesinin sabit bir unsurudur. Hızla büyüyen bu pazarda Game90, kendi oran
          yönetimi ve yenilikçi bahis türleri tarafından kabul görmeye başladı. Arka planda çalışan
          geniş bir geliştirici ekibi ve çok sayıda bahis uzmanı sayesinde kolay ve sorunsuz bir
          prosedür, saniyeler içinde bahis oranlarının güncellenmesi ve güvenli bir platform
          sağlanmıştır. Toplamda, Avrupa çapında 200&apos;den fazla personel ve dünya çapında
          3.000&apos;den fazla maç gözlemcisi, oranların ve platformun kalitesini sağlamak için
          ortaklık içinde birlikte çalışıyor.
        </p>
        <div>
          <h5 className="text-danger mt-75"> Game90&apos;daki avantajlarınız</h5>
          <ul>
            <li key={1} className="mt-3">
              <b>Daha hızlı bahis:</b> Futbolda ve en popüler bahis türlerinde uzmanız; böylece
              bizimle daha kolay ve hızlı bahis oynayabilirsiniz.
            </li>
            <li key={2} className="mt-3">
              <b>İstikrarlı oranlar:</b> Uzmanlarımızın izlemesi sayesinde, oranlarımız önemli
              ölçüde daha istikrarlı ve gözle görülür şekilde daha hızlı bir şekilde yeniden
              kullanıma sunuldu. Bu, gol gibi bir olayın gerçekleşmesinden hemen sonra yeni bir
              bahis oynayabileceğiniz anlamına gelir.
            </li>
            <li key={3} className="mt-3">
              <b>En yüksek potansiyel kazançlar:</b> En yüksek potansiyel kazançlar: Benzersiz
              derecede yüksek bahis limitleri ve bahis kuponu başına 100.000 Euro&apos;ya kadar
              potansiyel kazançlar sunuyoruz.
            </li>
            <li key={4} className="mt-3">
              <b>Oyuncuların engellenmesi yok:</b> Oyuncuların engellenmesi yok: Yüksek kazanç elde
              eden oyuncuları oyundan sınırlamıyor veya hariç tutmuyoruz.
            </li>
            <li key={5} className="mt-3">
              <b>Çeşitli bahis hizmeti:</b> Çeşitli bahis hizmeti: Seçeneğiniz var; her gün
              350&apos;ye kadar uluslararası maça bahis yapın.
            </li>
            <li key={6} className="mt-3">
              <b>Güvenli platform:</b> game90.bet en yüksek güvenlik standartlarına uygundur. Kendi
              programlaması, verilerinizi güvende tutmak için sürekli olarak daha da
              geliştirilmesini sağlar.
            </li>
            <li key={7} className="mt-3">
              <b>Deneyim ve bilgi birikimi:</b> Game90 yılların deneyimi üzerine kurulmuştur.
              Bizimle emin ellerdesiniz.
            </li>
          </ul>
          <br />
        </div>
        <h5 className="text-danger mt-75">Şirket felsefemiz</h5>
        <p>
          Vizyonumuz, bahisleri mümkün olduğunca basit hale getirirken müşterilerimize bahislerin ve
          maçın tadını çıkarmaları için daha fazla zaman tanımaktır. Game90&apos;ı istikrarlı, hızlı
          oranlara, yeni bahis türlerine ve kullanımı kolay olmaya odaklanarak rekabette öne çıkan
          bir marka olarak kurmayı hedefliyoruz - çünkü bunlar Game90&apos;ı benzersiz kılan
          niteliklerdir. Spor bahisleri alanında müşterilerimiz için güvenilir bir ortak ve
          yenilikçi bir öncü olmak ve böylece hizmetlerimizi sürekli olarak daha da geliştirmek
          istiyoruz.
        </p>
        <h5 className="text-danger mt-75">
          Değerlerimiz — müşterilerimizle ilgilenirken bu bizim için önemlidir
        </h5>
        <ul>
          <li key={8} className="mt-3">
            <b>Adillik:</b> İster müşteri ister iş ortağı olsun, herkese adil davranırız.
          </li>
          <li key={9} className="mt-3">
            <b>Yetkinlik:</b> Ne yaptığımızı biliyoruz; çünkü bizim için çalışan üst düzey uzmanlara
            sahibiz.
          </li>
          <li key={10} className="mt-3">
            <b>Dürüstlük:</b> Rahatsız edici olsa bile dürüst yanıtlar veririz.
          </li>
          <li key={11} className="mt-3">
            <b>Taahhüt: </b> Tüm enerjimizi müşterilerimize ve ortaklarımıza harcayarak onların
            bizden memnun kalmasını sağlıyoruz.
          </li>
          <li key={12} className="mt-3">
            <b>Güvenilirlik:</b>Söylediklerimize güvenebilirsiniz; hemfikiriz
          </li>
          <li key={13} className="mt-3">
            <b>Saygı</b> Müşterilerimizin ve ortaklarımızın verilerinin hassasiyetine saygı
            duyuyoruz ve bunları güvenli bir şekilde kullanıyoruz.
          </li>
        </ul>
        <h5 className="text-danger mt-75">Lisansımız</h5>
        <p className="mt-3">
          Game90, Malta&apos;da C50436 ticari sicil numarası ile aşağıdaki iş adresinde kayıtlıdır:
          Game90 Ltd., 1st Floor of Melita Court, Giuseppe Cali St, c/w Abate Rigord St, XBX1420
          Ta&apos;Xbiex, Malta. Game90 Ltd., Malta Oyun Otoritesinin (MGA) katı kurallarına tabidir
          ve bu otorite tarafından uzaktan oyun için bir bahis sağlayıcısı olarak resmi olarak
          lisanslanmıştır (MGA/B2C/191/2010). MGA&apos;nın lisanslama süreci hakkında daha fazla
          bilgiyi İngilizce olarak www.mga.org.mt adresinde bulabilirsiniz.
        </p>
        <h5 className="text-danger mt-75">Almanca lisansı</h5>
        <p className="mt-3">
          Federal Devletlerin Ortak Oyun Otoritesi&apos;nin (GGL) denetimi altında resmi olarak
          onaylanmış spor bahis operatörü. Game90 Ltd.&apos;ye internette ve sabit alanlarda spor
          bahisleri düzenleme resmi izni (ilk kez) 19 Kasım 2020 tarihli kararla verildi; bu, 22
          Aralık 2022&apos;de uzatıldı (GGL beyaz listesi).
        </p>
      </Tab>
      <Tab eventKey="dataProtection" className="py-2 text-start me-1 ms-1" title="Veri Koruması">
        <h2 className="text-danger">VERİ KORUMASI</h2>
        <p className="mt-3">
          Verilerinizin elimizde güvende olması bizim için önemlidir. Geliştirici ağımız sayesinde
          hassas bilgilerinizin her zaman en yüksek güvenlik standartlarında yönetilmesini
          sağlıyoruz.
        </p>
        <h5 className="text-danger mt-75">Hangi bilgileri topluyoruz?</h5>
        <p className="mt-3">
          Bu web sitesini ziyaret edenlerin çevrimiçi formları kullanan veya e-postayla veri
          gönderen ziyaretçilerinin kişisel verilerini topluyoruz. Ayrıca, kullandığınız kredi
          kartlarının verileri de dahil olmak üzere, tarafınızca yapılan işlemler hakkında da
          (güvenli ödeme işleme sistemimiz aracılığıyla) bilgi topluyoruz. Web sitemizi ziyaret
          ettiğinizde ek verileri otomatik olarak topluyoruz.
        </p>
        <h5 className="text-danger mt-75">
          Bu bilgiye neden ihtiyacımız var ve onu nasıl kullanırız?
        </h5>
        <p>Bu web sitesi aracılığıyla topladığımız verileriniz aşağıdaki amaçlarla kullanılır:</p>
        <ul>
          <li key={14} className="mt-3">
            Hizmetlerimizin sağlanması ve özelleştirilmesi için,
          </li>
          <li key={15} className="mt-3">
            Soru ve taleplerinizi işleme almak için,
          </li>
          <li key={16} className="mt-3">
            Pazar araştırması çalışmalarının yürütülmesi için,
          </li>
          <li key={17} className="mt-3">
            Sunduklarımız hakkında sizinle (e-posta yoluyla) iletişime geçmek ve ürün ve
            hizmetlerimiz hakkında bilgi almak için.
          </li>
        </ul>
        <p className="mt-3">
          Açıkça yaptığınız anlaşmayla verilerinizi ilginizi çekebileceğini düşündüğümüz ürün veya
          hizmetleri sunan üçüncü taraflara da aktarıyoruz. Bunu kayıt sırasında kabul
          edebilirsiniz. hesap ayarlarınızdan veya dpo@game90.bet adresine e-posta göndererek.
          Ayrıca game90.bet&apos;deki bahislerin çözülmesiyle ilgili olmayan her e-postanın alt
          kısmında, adresinizi dağıtım listesinden çıkarabileceğiniz bir bağlantı bulunur. Veri
          işleme uygulamamız ve kişisel verilerinizin kullanımına ilişkin seçenekleriniz Gizlilik
          Politikamızda düzenlenmiştir. Kişisel verileriniz hakkında bilgi almak istiyorsanız veya
          bu politika hakkında sorularınız varsa lütfen dpo@game90.bet ile iletişime geçin. Açık
          anlaşmanız ile verilerinizi, kişisel verileriniz hakkında bilgi sahibi olabileceğine
          inandığımız ürün veya hizmetleri sunan üçüncü taraflara da aktarıyoruz. ilginizi
          çekebilir. Kayıt sırasında ilgili onay kutusunu işaretleyerek bunu kabul edebilirsiniz.
          Sözleşmenizi istediğiniz zaman hesap ayarlarınızdan veya dpo@game90.bet adresine e-posta
          göndererek geri çekebilirsiniz. Ayrıca game90.bet&apos;deki bahislerin çözülmesiyle ilgili
          olmayan her e-postanın alt kısmında, adresinizi dağıtım listesinden çıkarabileceğiniz bir
          bağlantı bulunur. Veri işleme uygulamamız ve kişisel verilerinizin kullanımına ilişkin
          seçenekleriniz Gizlilik Politikamızda düzenlenmiştir. Kişisel verileriniz hakkında bilgi
          almak istiyorsanız veya bu politikaya ilişkin sorularınız varsa lütfen dpo@game90.bet ile
          iletişime geçin.
        </p>
        <h5 className="text-danger mt-75">PCI standardı – Ödemelerde PCI standardına uyuyoruz.</h5>
        <p className="mt-3">
          Game90 Ltd. yalnızca Ödeme Kartı Endüstrisi Veri Güvenliği Standartlarına (PCI) uygun
          olarak çalışan ödeme hizmeti sağlayıcılarıyla çalışır. Bu, verileri saklarken ve işlerken
          katı PCI gereksinimlerinin karşılanmasını sağlar.
        </p>
      </Tab>
      <Tab eventKey="önleme" className="py-2 text-start me-1 ms-1" title="Önleme">
        <h2 className="text-danger ">ÖNLEME</h2>
        <p className="mt-3">
          Yalnızca sorumlu ve bilinçli olarak bahis oynayanlar uzun vadede bundan keyif alacaktır.
          Kumar eğlenceli ve karlı olabilir. Ancak bahis başarısında her zaman bir miktar şans da
          vardır. Bu nedenle riski çok yüksek almamalı ve orantısız miktarda parayla kumar
          oynamamalısınız. Bahislerden keyif almanızı ve kumar davranışınızı doğru
          değerlendirebilmenizi istiyoruz. Bu nedenle, önleme ve nereden yardım alınabileceği
          konusunda bazı bilgiler veriyoruz. Game90 ayrıca kumar davranışınızı istediğiniz zaman
          düzenlemenize de yardımcı olabilir. Sorularınız veya sorunlarınız varsa, doğru türde
          yardımı bulmanıza yardımcı olabiliriz. Kumar bağımlılık yapabilir. Her zaman sorumlu bir
          şekilde oynayın.
        </p>
        <p className="mt-3">
          Küçüklerin spor bahisleri de dahil olmak üzere her türlü kumara katılımı kesinlikle
          yasaktır. Game90 Ltd.&apos;nin oyuncu koruma görevlisi Steffen Schröder&apos;dir ve
          kendisine her zaman rgaming@game90.bet adresinden e-posta yoluyla veya +356 2276 9103
          numaralı telefondan ulaşılabilir. Yasa gereği reşit olmayanların oynadığı bahisleri ve
          kazanılmış olabilecek kazançları iptal etmekle yükümlüyüz
        </p>
        <h5 className="text-danger mt-75">Kendinize sınırları nasıl belirlersiniz</h5>
        <ul>
          <li key={18} className="mt-3">
            Bir sonraki hafta veya bir sonraki ay için maksimum toplam bahis tutarınızı önceden
            belirleyin ve bu tutarı sonradan artırmayın.
          </li>
          <li key={19} className="mt-3">
            Oyun hesabınıza başlangıçta belirtilen tutardan daha fazla para ödemeyin. Bu aynı
            zamanda kaybedilen oyunlar için de geçerlidir.
          </li>
          <li key={20} className="mt-3">
            Belirli bir miktar kazanca ulaştığınızda duracağınıza önceden karar verin.
          </li>
          <li key={21} className="mt-3">
            Gerekirse, istediğinizden daha fazla parayla bahis oynayamayacağınızdan emin olmak için
            limitinizi düşürün.
          </li>
          <li key={22} className="mt-3">
            Yalnızca tamamen dinlenmiş ve konsantre olduğunuzda oynamalısınız.
          </li>
          <li key={23} className="mt-3">
            Başka bir şey yaptığınız düzenli molalar verin.
          </li>
          <li key={24} className="mt-3">
            Asla uyuşturucu, güçlü ilaç veya aşırı miktarda alkolün etkisi altında oynamayın.
          </li>
          <li key={25} className="mt-3">
            Kendinizi özellikle kötü veya depresyonda hissediyorsanız oynamayın.
          </li>
        </ul>
        <p className="mt-3">
          Tüm oyunculara kendi limitlerini belirleme veya belirli bir süre için kendi hesaplarını
          bloke etme fırsatı veriyoruz. Destek formu aracılığıyla bize mesaj göndererek hesap
          ayarlarınızdan kendi limitlerinizi değiştirebilirsiniz. Limit(ler)deki artışın yalnızca
          talebinizin alınmasından yedi gün sonra geçerli olacağını lütfen unutmayın. Bir limitin
          azaltılması durumunda, yeni limit derhal geçerli olmak üzere geçerlidir. Sonuç olarak
          Game90, müşteri tarafından belirlenen limitleri aşan bahisleri kabul etmez. Hesabınızı
          engellemek için lütfen desteğimizle iletişime geçin. Hesabınızı sınırlı bir süre için veya
          kalıcı olarak engelleyebilirsiniz.
        </p>
        <h5 className="text-danger mt-75">+18</h5>
        <p className="mt-3">
          Reşit olmayanların game90.bet&apos;de hesap açması veya bahis oynaması kanunen yasaktır.
          Game90&apos;da bahis oynayabilmek için kayıt sırasında en az 18 yaşında olduğunuzu
          onaylamanız ve doğum tarihinizi vermeniz gerekmektedir. Yasal nedenlerden ötürü, ilk ödeme
          talebinde bulunduğunuzda bunun yasal fotoğraflı kimliğinizin bir kopyasıyla doğrulanması
          gerekir. Game90 Ltd. aynı zamanda ödemeden bağımsız olarak kullanıcının yaşının kanıtını
          talep etme ve kanıt sağlanana kadar söz konusu hesabı kapatma hakkını da saklı tutar.
          Dolandırıcılığı ve reşit olmayan davranışları önlemek için tüm kullanıcı hesapları düzenli
          aralıklarla araştırılacaktır.
        </p>
        <h5 className="text-danger mt-75">Çocuklarınızı koruyun</h5>
        <p className="mt-3">
          Çocuklarınızın bu siteye erişip erişemeyeceğinden emin değil misiniz? Bilgisayarınızı
          reşit olmayan kişilerle paylaşıyorsanız kullanıcı adınıza, şifrelerinize ve ödeme
          bilgilerinize erişemeyeceklerinden emin olmalısınız. Bilgisayarlar ve veriler için çocuk
          güvenliği koruması ve filtre çözümleri hakkında faydalı bilgileri
          www.betterinternetforkids.eu adresinde bulabilirsiniz. Reşit olmayanların korunması
          amacıyla bilgi almak için aşağıdaki siteleri de ziyaret etmenizi öneririz:
          www.netnanny.com ve www.cyberpatrol.com
        </p>
        <h5 className="text-danger mt-75">Kendi kendine test</h5>
        <p className="mt-3">
          Kumar bağımlılığınızın olup olmadığından emin değilseniz, kumar davranışınızı aşağıdaki
          web sitesinden test edebilirsiniz: www.gamblersanonymous.org
        </p>
        <h5 className="text-danger mt-75">
          Kiminle iletişime geçebilirim ve nereden yardım bulabilirim?
        </h5>
        <p className="mt-3">
          Game90, kumar bağımlılığı durumunda veya kumar bağımlılığının önlenmesi için size anında
          yardım ve destek sunar. Bu konuda eğitim almış ve her türlü sorunda size yardımcı
          olabilecek oyuncu koruma görevlimiz Steffen Schröder ile istediğiniz zaman iletişime
          geçebilirsiniz. Kendisine rgaming@game90.bet adresinden e-posta yoluyla ulaşabilirsiniz.
        </p>
        <p className="mt-3">
          Elbette Game90 önleme uzmanımız Steffen Schröder&apos;den rgaming@game90.bet adresinden
          gizli tavsiye ve yardım da alabilirsiniz.
        </p>
        <p className="mt-3">
          Game90 ekibimizle doğrudan konuşmak istemiyorsanız resmi bir makamla da güvenle iletişime
          geçebilirsiniz:
        </p>
        <p className="mt-3">
          Gizli ve uzman destek için 0808 8020 133 numaralı ücretsiz yardım hattını da
          arayabilirsiniz.
        </p>
      </Tab>
    </Tabs>
  );
}
