import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { BACKOFFICE_URL } from '../../toolkit/constants';
import TitleHistoryBack from 'components/general/TitleHistoryBack';
import { useEffect } from 'react';

export default function Register() {
  const { t } = useTranslation('translations', { useSuspense: false });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [repeatPassword, setRepeatPassword] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [selectedDate, setSelectedDate] = useState();
  const [succesfull, setSuccesfull] = useState(false);
  const regex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onSubmit = (data) => {
    if (data.password !== repeatPassword) {
      const newLocal = 'Password and repeat password Doesnt Match';
      console.log(newLocal);
    } else {
      const request = axios.post(`${BACKOFFICE_URL}/api/Customer/CreateOnlineCustomer`, data);
      request
        .then(() => {
          setSuccesfull(true);
        })
        .catch(() => {
          // const resMessage =
          //   (error.response && error.response.data && error.response.data.message) ||
          //   error.message ||
          //   error.toString();
          setSuccesfull(false);
        });
      console.log('Result: ', succesfull);
      navigate('/login');
    }
  };
  return (
    <Container>
      <TitleHistoryBack title={t('registration')} />
      <div className="mt-3"></div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group controlId="formBasicUsername">
              <Form.Label htmlFor="username" className="float-start text-muted opacity-50 mt-2">
                {t('username')}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Username"
                {...register('username', { required: true })}
              />
            </Form.Group>
            {errors.username && <p>Please check the Username</p>}
          </Col>

          <Col>
            <Form.Group controlId="formBasicPassword">
              <Form.Label htmlFor="password" className="float-start text-muted opacity-50 mt-2">
                {t('password')}
              </Form.Label>
              <Form.Control type="password" placeholder="Password" {...register('password')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Row>
            <Col>
              <Form.Group controlId="formBasicRepeatPassword">
                <Form.Label
                  htmlFor="repeatpassword"
                  className="float-start text-muted opacity-50 mt-2">
                  {t('repeatpassword')}
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Repeat Password"
                  onChange={(event) => setRepeatPassword(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="doj">
                <Form.Label className="float-start text-muted opacity-50 mt-2">
                  {t('dateofbirth')}
                </Form.Label>
                <Form.Control
                  type="date"
                  name="doj"
                  placeholder="Date of Birth"
                  onChange={handleDateChange}
                  {...register('birthday')}
                />
              </Form.Group>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicFirstname">
              <Form.Label htmlFor="firstname" className="float-start text-muted opacity-50 mt-2">
                {t('firstname')}
              </Form.Label>
              <Form.Control
                className="w-1/2"
                type="text"
                placeholder="First Name"
                {...register('name')}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicLastname">
              <Form.Label htmlFor="lastname" className="float-start text-muted opacity-50 mt-2">
                {t('lastname')}
              </Form.Label>
              <Form.Control
                className="w-1/2"
                type="text"
                placeholder="Last Name"
                {...register('surname')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicPhoneNumber">
              <Form.Label htmlFor="phonenumber" className="float-start text-muted opacity-50 mt-2">
                {t('phonenumber')}
              </Form.Label>
              <Form.Control type="text" placeholder="Phone Number" {...register('telephone')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label htmlFor="email" className="float-start text-muted opacity-50 mt-2">
                {t('email')}
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                {...register('email', {
                  required: true,
                  pattern: regex
                })}
              />
            </Form.Group>
            {errors.email && <p>Please check the Email</p>}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicGender">
              <Form.Label htmlFor="gender" className="float-start text-muted opacity-50 mt-2">
                {t('gender')}
              </Form.Label>
              <Form.Control type="text" placeholder="Gender" {...register('gender')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicTitle">
              <Form.Label htmlFor="title" className="float-start text-muted opacity-50 mt-2">
                {t('title')}
              </Form.Label>
              <Form.Control type="text" placeholder="Title" {...register('title')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicRepeatProfession">
              <Form.Label htmlFor="profession" className="float-start text-muted opacity-50 mt-2">
                {t('profession')}
              </Form.Label>
              <Form.Control type="text" placeholder="Profession" {...register('profession')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicPassportNumber">
              <Form.Label
                htmlFor="passportnumber"
                className="float-start text-muted opacity-50 mt-2">
                {t('passportnumber')}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Passport Number"
                {...register('passportNumber')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicStreet">
              <Form.Label htmlFor="street" className="float-start text-muted opacity-50 mt-2">
                {t('street')}
              </Form.Label>
              <Form.Control type="text" placeholder="Street" {...register('street')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicZip">
              <Form.Label htmlFor="zip" className="float-start text-muted opacity-50 mt-2">
                {t('zip')}
              </Form.Label>
              <Form.Control type="text" placeholder="Zip" {...register('zip')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicCity">
              <Form.Label htmlFor="city" className="float-start text-muted opacity-50 mt-2">
                {t('city')}
              </Form.Label>
              <Form.Control type="text" placeholder="City" {...register('city')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicNationality">
              <Form.Label htmlFor="nationality" className="float-start text-muted opacity-50 mt-2">
                {t('nationality')}
              </Form.Label>
              <Form.Control type="text" placeholder="Nationality" {...register('nationality')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicBankDetails">
              <Form.Label htmlFor="bankdetails" className="float-start text-muted opacity-50 mt-2">
                {t('bankdetails')}
              </Form.Label>
              <Form.Control type="text" placeholder="Bank Details" {...register('bankDetails')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicBalance">
              <Form.Label htmlFor="balance" className="float-start text-muted opacity-50 mt-2">
                {t('balance')}
              </Form.Label>
              <Form.Control type="text" placeholder="Balance" {...register('balance')} />
            </Form.Group>
          </Col>
        </Row>

        <div className="row justify-content-center">
          <Button
            variant="contained"
            type="submit"
            block
            size="lg"
            className="w-25 bg-success text-white p-2 mt-3">
            {t('register')}
          </Button>
        </div>
      </Form>
      <div className="mt-2"></div>
      <Row>
        <Col xs={11} className="d-flex justify-content-center fs-9">
          <span>
            Already have an account? Sign in{' '}
            <Link to={'/login'} className="fw-bold">
              | here{' '}
            </Link>
          </span>
        </Col>
      </Row>
    </Container>
  );
}
