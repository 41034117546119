export default function TR() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <a id="top" />
      <h3 className="text-danger text-left">ŞARTLAR VE KOŞULLAR</h3>
      <h6>Şartlar ve Koşullar</h6>
      <ol>
        <li key={1}>
          <a href={`#${1}`}>Temel bilgiler</a>
        </li>
        <li anahtar={2}>
          <a href={`#${2}`}>Hesabınız</a>
        </li>
        <li anahtar={3}>
          <a href={`#${3}`}>Ödemeler ve Güvenlik</a>
        </li>
        <li anahtar={4}>
          <a href={`#${4}`}>Game90 web sitesinin kullanımına ilişkin kısıtlamalar</a>
        </li>
        <li anahtar={5}>
          <a href={`#${5}`}>Web sitesi</a>
        </li>
        <li anahtar={6}>
          <a href={`#${6}`}>Sorumluluk reddi beyanı ve Game90 web sitesinin kullanılabilirliği</a>
        </li>
        <li anahtar={7}>
          <a href={`#${7}`}>Sorumluluğun Sınırlandırılması</a>
        </li>
        <li anahtar={8}>
          <a href={`#${8}`}>Fikri mülkiyet hakları</a>
        </li>
        <li anahtar={9}>
          <a href={`#${9}`}>Bildirim gereksinimi</a>
        </li>
        <li anahtar={10}>
          <a href={`#${10}`}>Genel hükümler</a>
        </li>
        <li anahtar={11}>
          <a href={`#${11}`}>Bahis koyma kuralları / spor kuralları</a>
        </li>
        <li anahtar={12}>
          <a href={`#${12}`}>Bonus kuralı</a>
        </li>
        <li anahtar={13}>
          <a href={`#${13}`}>13. Sorumlu oyun</a>
        </li>
        <li anahtar={14}>
          <a href={`#${14}`}>Veri koruma</a>
        </li>
        <li anahtar={15}>
          <a href={`#${15}`}>Genel</a>
        </li>
        <li anahtar={16}>
          <a href={`#${16}`}>ADR - Alternatif Uyuşmazlık Çözümü (ADR)</a>
        </li>
        <li anahtar={17}>
          <a href={`#${17}`}>Geçerlilik Tarihi</a>
        </li>{' '}
      </ol>
      <br />
      <br />
      <div>
        <h5 id="1" className="mt-75">
          1. Temel Bilgiler{' '}
        </h5>
        <h6 className="mt-3"> 1.1 Tanımlar </h6>
        1.1.1 &quot;Biz&quot;, Şirket veya biz terimleri, game90.bet veya bir Şirket olan Game90
        Ltd.&apos;yi ifade eder. Melita Court, Level 1, Guiseppe Cali St c/w&apos;de kayıtlı Şirket
        numarası C50436 ile Abate Rigord St, XBX 1420, TaXbiex, Malta. <br />
        <br />
        1.1.2 &quot;Web sitesi&quot; terimi game90.bet anlamına gelir. Tam ve tek sahibi ve
        operatörü web sitesinin adı Game90&apos;dır. <br />
        <br />
        1.1.3 &quot;İndirilebilir uygulama&quot; (kısaca uygulama) terimi, uygulamanın her sürümünü
        ifade eder. mobil uygulama ve ayrıca Game90 tarafından oluşturulacak gelecekteki onaylı
        uygulamalara (uygulama sürümleri). Game90 bu mobil uygulamaların tam ve tek sahibi ve
        sağlayıcısıdır. <br />
        <br />
        1.1.4 Mevcut koşullar aynı zamanda indirilebilir tüm bilgi ve uygulama uygulamaları için de
        geçerlidir. iletişim terminalleri. Bu bağlamda, genel Hüküm ve Koşullar devredilebilir web
        sitesinden uygulamaya kadar tüm bilgi ve iletişim cihazları için. Aşağıda, tüm uygulama ve
        iletişim cihazları “web sitesi” veya “Game90” terimlerinin kapsamına girmektedir.
        <br />
        <br />
        1.1.5 &quot;Hizmet&quot; terimi, tarafından sunulan tüm bahislere katılma fırsatını ifade
        eder. Muhbir. <br />
        <br />
        1.1.6 &quot;Yazılım&quot; terimi, web sitesinden indirilebilen tüm bilgisayar programlarını
        ifade eder. web sitesinden veya bir uygulama mağazasından (uygulama mağazası, Google Play
        mağazası vb.) <br />
        <br />
        1.1.7 &quot;Game90 Hizmeti&quot; terimi web sitesini, yazılımı ve bahisleri ifade edebilir.
        hizmet.
        <br />
        <br />
        1.1.8 &quot;Siz&quot; veya &quot;sizin&quot; veya &quot;müşteri&quot; terimleri, Game90s
        hizmetlerini kullanan herhangi bir kişiyi ifade eder. bu genel Hüküm ve Koşullara uygun
        olarak. <br />
        <br />
        1.1.9 &quot;Hesap&quot; terimi, müşterinin game90.bet adresindeki oyuncu hesabını ifade
        eder.
        <br />
        <br />
        1.1.10 Avrupa çapında 2003 yılından bu yana yürürlükte olan Genel Veri Koruma Yönetmeliği 25
        Mayıs 2019, bu Şartlar ve Koşullarda GDPR olarak anılmaktadır. <br />
        <br />
        1.1.11 Game90, Darmstadt Bölgesinin OASIS oyuncu engelleme sistemini kullanıyor 1 Mart
        2020&apos;den bu yana Konsey. Bu çevrimiçi oyuncu durumu sorgulama sistemi, Bu Şartlar ve
        Koşullarda OASIS sistemi veya yalnızca OASIS yer alır ve tamamen bununla ilgilidir.
        Engelleme sistemi ve tüm müşteriler için zorunludur.
        <br />
        <br />
        1.1.12 Avrupa Birliği düzenlemeleri sonucunda Game90 aşağıdaki seçeneği vermiştir: bir
        anlaşmazlık çözüm organıdır. Bu genellikle ADR (Alternatif Anlaşmazlık) olarak kısaltılır.
        Karar) ve bu Şartlar ve Koşullarda ADR olarak anılacaktır. <br />
        <br />
        Bu tanımlar Game90s&apos;ın gizlilik politikası ve bahis ve spor bahisleri için de aynı
        şekilde geçerlidir. tüzük. <br /> <br />
        <a href="#top">Başa dön</a>
      </div>
      <div>
        <h5 id="2" className="mt-75">
          2. Hesabınız
        </h5>{' '}
        <p></p> <h6 className="mt-3">2.1 Hesap açın</h6>
        <p className="mt-3">
          2.1.1 Game90 web sitesi aracılığıyla bir hizmeti kullanabilmek için müşterilerin; kaydolun
          ve kendi kullanıcı hesabını oluşturun. Kayıt olmak için minimum yaş 18&apos;dir (21
          Estonyalı oyuncular için yıllar). Kayıt olmadan hiçbir hizmet sonlandırılamaz.
          <br />
          <br /> 2.1.2 Kayıt olurken, müşterilerin talep edilen gerekli verileri sağlaması gerekir.
          Müşteriler, sağlanan tüm bilgilerin doğru kalmasını sağlamaktan sorumludur ve güncel.
          Gerekli kişisel verilerin sağlanmaması durumunda Game90&apos;ın hakkı ve hakkı vardır.
          Müşteri hesabını kapatma ve halihazırda yatırılmış olan paraları dondurma yükümlülüğü
          paralı. Game90 ayrıca yanlış veya yanlış olması durumunda tüm hizmetleri geçersiz ilan
          etme hakkını saklı tutar. Müşteriler tarafından yanıltıcı bilgiler verildi. <br />
          <br />
          2.1.3 Her birey yalnızca bir kullanıcı hesabı açabilir. Game90 bu hakkını saklı tutar
          kendi takdirine bağlı olarak, ek hesapları kapatmak ve bakiyeleri alıkoymak veya birden
          fazla hesabı işleme koymak tek bir hesap olarak kullanın ve buna göre hareket edin. <br />
          <br />
          2.1.4 Game90 ayrıca müşterilerinin üçüncü taraflarca kontrol edilmesini sağlama hakkını da
          saklı tutar. Müşteriler tarafından sağlanan bilgilerin bir kopyasını alma hakkına sahip
          şirketler Veri koruma hükümlerimize uygun olarak kayıt. <br />
          <br />
          2.1.5 Game90, yasa dışı olarak elde edilen paranın yatırılmasının yasa dışı olduğunu
          bildirmektedir.
          <br />
          <br />
          2.1.6 Paranın engellenmesi için tüm işlemler hem teknik hem de manuel olarak kontrol
          edilir aklama ve yasa dışı işlemler. Şüpheli işlemler Maliye&apos;ye bildirildi
          Malta&apos;da İstihbarat Analiz Birimi (FIAU) veya Malta&apos;da FIU (Finansal İstihbarat
          Birimi) Almanya. FIU&apos;lar şüpheli işlemleri kaydeden denetleyici makamlardır. bunları
          değerlendirin ve gerekirse sorumlu makamlara iletin (FATF - Mali Eylem Görev Gücü veya
          Almanya&apos;da BKA ve gümrükler). <br />
          <br /> 2.1.7 Müşteri hesabı ve burada yer alan tutarlar kullanılacaktır sadece bahisler
          için. Saf bahis bahisleri için Almanya merkezli müşterilerin hesapları Yarış
          Yönetmeliğinin 18. Maddesi uyarınca bir imtiyaz ücreti (bahis vergisi) alınır 25 Haziran
          2021 tarihli Bahis ve Piyango Yasası. <br />
          <br />
          2.1.8 Game90 dijital, teknik, elektronik ve manuel işlemleri gerçekleştirme hakkını saklı
          tutar. amacıyla müşterilerle farklı zamanlarda ve farklı işbirliği noktalarında kontroller
          yapar. Müşterilerin ve Şirketin güvenliğini sağlamak. <br />
          <br />
          2.1.9 Game90 bir finans şirketi değildir ve bu şekilde kötüye kullanılamaz. Para miktarı
          daha yüksek mevduat nedeniyle bir kullanıcı hesabında uzun süre tutulur Müşteri başına
          ortalamanın üzerinde olan ve en az 90 gün boyunca kullanılmayan Game90 tarafından <br />
          maddesi uyarınca yetkililere bildirildi
          <br />
          2.1.6. Game90, kullanıcı hesaplarını kredi esasına göre sunmaz ve asla yatırılan paraya
          sahip kullanıcı hesabı. Bu, olası promosyonlarla karşılaştırılamaz. Bonus önceden
          mevcuttur. İkramiyeler, eşitlenemeyecek hükümlere bağlıdır Bir kredi.
        </p>
        <h6 className="mt-3"> 2.2 Kayıt </h6>
        <p className="mt-3">
          2.2.1 Kayıt, aşağıdaki gibi gerekli kişisel verilerin girilmesiyle çevrimiçi olarak
          gerçekleştirilir: isim, adres, e-posta iletişim kişisi, doğum tarihi, kullanıcı adı ve
          şifre. Şifre olabilir müşteri tarafından web sitesinde herhangi bir zamanda
          değiştirilebilir. Hesap olmadan oluşturulamaz gerekli bilgiler.
          <br />
          <br /> 2.2.2 Belgenin sunulmasına ilişkin rızanın ayrıca teyidi olmadan Bir oyuncunun 7.
          Maddesi uyarınca Genel Veri Koruma Yönetmeliğinin kişisel verileri hesap açılamıyor. Yasal
          gerekliliklerin yerine getirilmesi için de onay alınması gerekmektedir. doğrulama. GDPR
          Madde 6 (1) f&apos;ye göre Game90 meşru ve işlenmek üzere veri talep etmek için gerekli
          hak.
          <br />
          <br /> 2.2.3 Game90, kullanılmayan müşteri hesaplarını otomatik olarak silme hakkını saklı
          tutar Üç aylık sürenin bitiminden sonra kredisiz. Bu durumda kayıt olabilirsiniz herhangi
          bir zamanda tekrar.
          <br />
          <br /> 2.2.4 Kayıt otomatik olarak kişisel bir sanal alanın açılmasını içerir oyuncu
          hesabı. Bu oyuncu hesabının açılması ücretsizdir ve herhangi bir şey gerektirmez. müşteri
          için yükümlülük. <br />
          <br />
          2.2.5 Tüm parasal işlemler (özellikle para yatırma, çekme, bonus kredileri ve bahisler)
          yalnızca kişisel oyuncu hesabı aracılığıyla gerçekleştirilir. İddialar ve şikayetler
          oyuncunun hesabına kaydedilen krediler, ödemeler veya diğer işlemler hakkında
          Game90&apos;a 14 iş günü içinde e-posta veya yazılı olarak ulaşacaktır. Bu sürenin sona
          ermesinden sonra dönemde rezerve edilen tüm işlemler müşteri tarafından onaylanmış
          sayılır. 2.2.6 Game90 herhangi bir sebep göstermeksizin kaydı reddetme hakkını saklı
          tutar.
        </p>
        <h6 className="mt-3"> 2.3. Veri koruması </h6>
        <p className="mt-3">
          2.3.1 Genel Veri Koruma Yönetmeliği&apos;nin 12. Maddesi uyarınca her kullanıcı
          Game90&apos;dan her altı ayda bir verilerinin bir envanterini talep etmekte özgürdür.
          Bunlar yürürlükteki yasalara uygun olarak okunabilir bir formatta müşteriye sunulur. A
          bilgi talebi ayrı bir doğrulamayı tetikler; bu da şu şekilde gerçekleştirilir: Madde
          5.3.7&apos;ye uygun olarak. Bu Hüküm ve Koşulların ff. Hakkında daha fazla bilgi bu aynı
          zamanda veri koruma politikamızda da belirtilmiştir. <br />
          <br />
          2.3.2 Müşteriler verilerini güncel tutma hakkına sahiptir. Bu amaçla müşterilerin 16.
          madde uyarınca kişisel verilerinin tamamlanmasını da talep edebilir. Genel Veri Koruma
          Yönetmeliği. <br />
          <br />
          2.3.3 Müşteri verileri yasal hükümlere uygun olarak saklanır. Eğer silinmesi bu veriler
          talep edildiğinde, bu süre, silinmesinin talep edildiği tarihte başlar ve Müşteriler buna
          göre bilgilendirilecektir. <br />
          <br />
          2.3.4 Game90&apos;da kişisel veriler ayrıca özel güvenlikli platformlarda şifreli olarak
          değiştirilmiş olarak saklanır. özel güvenlikli depolama ortamı veya sunucular. Game90 bu
          verileri bir istek üzerine okunabilir format.
          <br />
          <br /> 2.3.5 Game90 yalnızca profil oluşturma adı verilen otomatik sınıflandırmaya
          güvenmez, yani müşteri davranışının otomatik süreçlere dayalı olarak değerlendirilmesi.
          Profil oluşturma aynı zamanda manuel denetimler ve değerlendirmeler yoluyla
          gerçekleştirilir ve herhangi bir programa uyulmaz mantık. <br />
          <br />
          2.3.6 Irksal, etnik, dini, politik veya ideolojik verilere veya sırasında genetik veya
          cinsel ilgiye yönelik verilerin işlenmesi gerçekleşmez. Game90 tarafından kişisel
          verilerin işlenmesi.
          <br />
          <br /> 2.3.7 Tüm müşteriler, bunların işlenmesine ilişkin onaylarını geri çekme hakkına
          sahiptir. kişisel verilerinin 7(3) maddesi uyarınca kısıtlanması veya Madde 18. O zamana
          kadar işlenen hiçbir veri bu iptalden etkilenmez. İçinde İptale yanıt olarak Game90,
          kişisel verilerin tüm işlemcilerini aşağıdakiler hakkında bilgilendirecektir: iptal.
          <br />
          <br />
          2.3.8 Game90 Limited, Şirket için bir veri koruma görevlisi (DPO) atamıştır. O Veri
          korumayla ilgili sorularınız için DPO@game90.bet adresinden e-posta yoluyla ulaşılabilir.
        </p>
        <h6 className="mt-3"> 2.4 Hesabın kullanımı </h6>
        <p className="mt-3">
          2.4.1 Her hesap yalnızca kişisel kullanım içindir ve profesyonel amaçlarla kullanılamaz.
          iş veya ticari amaçlarla.
          <br />
          <br /> 2.4.2 Müşteriler, hiçbir üçüncü tarafın, özellikle de hiç kimsenin 18 yaşın
          altında, kullanıcı hesabına veya şifrelere erişimi varsa (her ikisi de kasıtlı veya
          kasıtsız olarak) veya Game90 web sitesine erişmek için kimliklerini kullanır ve
          hizmetleri. Kullanıcı adı veya şifreyi bilen üçüncü kişiler tarafından yapılan işlemler
          sanki kullanıcı hesabının sahibi tarafından yapılmış gibi değerlendirilir. Müşteriler
          Üçüncü şahısların kendi adına yaptığı tüm işlemlerden sorumludur. Game90 değil Müşteri
          hesaplarının izinsiz kullanılmasından sorumludur. Üçüncü şahısların olup olmadığına
          bakılmaksızın Game90 hizmetine müşterinin izni olsun ya da olmasın erişim, sonuçta ortaya
          çıkan kayıplar tazmin edilmeyecektir.
          <br />
          <br /> 2.4.3 Game90, verilerin aşağıdaki durumlarda güvenlik nedenleriyle bir müşteri
          hesabını geçici olarak kapatabilir: kötüye kullanımdan şüpheleniliyor ve müşterilerden
          şifrelerini derhal değiştirmeleri isteniyor. Bu da Game90&apos;ın madde 2.4.2 kapsamındaki
          durumlardan haberdar olması durumunda geçerlidir. <br />
          <br />
          2.4.4 Müşteriler, Game90 hizmetlerini ve web sitesini bir süre boyunca kullanmayacaklarını
          garanti eder. çevrimiçi bahislere veya çevrimiçi oyunlara katılmanın, yerin veya ülkenin
          geçerli yasa veya yönetmelikleri. Game90 hakkını saklı tutar Game90 hizmetlerinin
          kullanımını bu konumlar veya ülkeler için istediğiniz zaman kısıtlayabilirsiniz. 2.4.5
          Game90, müşterilerine her işlemden sonra cari hesap bakiyesini kontrol etmelerini önerir.
          kayıt. Düzeltmeler olması durumunda müşteriler Game90&apos;ı derhal yazılı olarak
          bilgilendirmelidir. gerekli. Bu tür iddialarda bulunulması halinde müşterilerin yalnızca
          mevcut durumlarını ibraz etmeleri yeterli değildir. hesap bakiyesi değil, aynı zamanda son
          bakiye ayarlamasından bu yana yapılan tüm işlemler; kontrol madde 3.1.7&apos;ye uygun
          olarak yapılabilir. <br />
          <br />
          2.4.6 Taraflardan her biri, aşağıdaki durumlarda kullanıcı hesabını derhal, geçici veya
          kalıcı olarak kapatabilir: karşı tarafın bu koşullar altında yükümlülüklerini yerine
          getirmemesi. Herhangi bir ödeme kalan tutarlar bu genel Hüküm ve Koşulların hükümlerine
          dayanmaktadır. Bir kullanıcı hesabının geçici veya kalıcı olarak kapatılması, tarafları
          sorumluluklarından kurtarmaz. yasal haklar ve yükümlülükler.
          <br />
          <br /> 2.4.7 Game90, sunduğu hizmete ilişkin tüm bahisleri reddetme hakkını saklı tutar.
          herhangi bir zamanda sebep göstermeden. Ancak Game90, müşterileri bu konuda
          bilgilendirmeye çalışmaktadır. bu kararın nedenleri. <br />
          <br />
          2.4.8 Game90 bir finans kurumu değildir ve bu şekilde muamele görmemelidir. İlgi yok hesap
          bakiyeleri üzerinden ödenir ve madde 2.1.9&apos;a göre Game90 aşağıdaki haklara sahiptir:
          ilgili yetkili makamlara (FIAU, ZOLL veya BKA vb.) bilgi verme yükümlülüğü bu gerçek.
          <br />
          <br /> 2.4.9 Kredi, müşterilerin Şirkete borçlu olduğu tüm tutarlardan mahsup edilebilir.
          <br />
          <br />
          2.4.10 Müşteriler bahis oynayarak para kaybedebileceklerini kabul eder ve tamamını kabul
          ederler. bu tür kayıpların sorumluluğu. <br />
          <br />
          2.4.11 Tüm hesap hareketleri herhangi bir zamanda gerçek zamanlı olarak çevrimiçi olarak
          görüntülenebilir.
          <br />
          <br />
          2.4.12 Hesabınızla ilgili herhangi bir sorunuz varsa lütfen müşteri hizmetlerimizle
          iletişime geçin. support@game90.bet adresinde. <br />
          <br />
          2.4.13 Şirket, herhangi bir hizmetini ve web sitesini herhangi bir zamanda değiştirme
          hakkını saklı tutar. zamanında ve bildirimde bulunmaksızın ve hizmetleri eklemek veya
          kaldırmak için. <br />
          <br />
          2.4.14 Game90 hizmetleri için özel olarak oluşturulan uygulamaların kullanımı Game90,
          oyuncuya bırakılmıştır ve kullanıcı, riski kendisine ait olmak üzere hareket eder ve
          Game90&apos;ı serbest bırakır. yükümlülük. (ayrıca bkz. 6.2 ve madde 7 - sorumluluğun
          sınırlandırılması). <br />
          <br />
          2.4.15 Game90 müşteri hizmetleri ve diğer tüm Game90 çalışanları profesyonel ve Her
          müşteri teması için kibar hizmet. Kendisine karşı herhangi bir kaba veya başka türde taciz
          Game90 çalışanları kaydedilebilir ve bu da işletmenin doğrudan ve anında kapatılmasıyla
          sonuçlanır. Kullanıcı hesabı. Game90 ayrıca bu tür durumlarda yasal yollara başvurma
          seçeneğini de saklı tutar; gerekli.
        </p>
        <h6 className="mt-3"> 2.5 Hesabınızı kapatma </h6>
        <p className="mt-3">
          2.5.1 Şirket ve müşteriler istedikleri zaman hesap kapatabilir ve sebep göstermeden.
          Game90, müşterileri bu karar hakkında bilgilendirmeye çalışacaktır. İle Hesaplarının
          silinmesini talep eden müşterilerin e-posta yoluyla yazılı bir bildirim göndermeleri
          yeterlidir, Belirtilen Şirket adresine faks veya posta ile gönderin. <br />
          <br /> 2.5.2 Şüpheli durumlar haricinde, kapatma anında hesapta bulunan bakiye veya
          dolandırıcılık, Şirketin takdirine bağlı olarak banka hesabına kayıtlı olarak
          aktarılacaktır. Hesap kapatıldıktan sonra elde edilen karlar müşterinin hesabına
          aktarılacaktır. Ödemeler madde 3.1.3 uyarınca gerçekleştirilir. Tutuklanan paralar işlem
          görüyor yasal hükümlere uygun olarak ve gerekiyorsa idareye teslim edilir. yetkililer.
          <br />
          <br />
          2.5.3 Yukarıdaki hükümlere (§ 2.4) bakılmaksızın Şirket, bloke etme veya 2.5.3.1
          müşterinin bu genel kuralları ihlal etmesi durumunda yeni hesabın derhal geçerli olmak
          üzere silinmesi Şartlar ve Koşullar (GTC) veya spor bahislerinde bahis kuralları; 2.5.3.2
          var Müşteri mevduatlarının aşağıdakilerle ilişkili olduğuna inanmak veya bunu belirtmek
          için meşru sebep yasa dışı veya dolandırıcılık faaliyetleri. Böyle bir durumda Şirket
          hiçbir sorumluluk kabul etmez. Müşterilerin yasal limitleri aşan ödemeleri. Şirket iletir
          (bu genel Hüküm ve Koşullardan veya verilerden kaynaklanan yükümlülüklerini ihlal etmeden
          koruma hükümleri) talepleri üzerine yetkili makamlara tüm bilgileri veya bu müşterilere
          ilişkin belgeler (bkz. 2.1.4 ve 2.1.6); 2.5.3.3 Şirket şüpheleniyor Yetkisiz bir kişinin
          sahte veya tahrif edilmiş bir hesap açmaya çalıştığı veya bu hesaba sahip olduğu kimlik
          verileri; <br />
          <br /> 2.5.3.4 Yasal hükümler hesabın bloke edilmesini veya kapatılmasını gerektiriyor;
          <br />
          <br />
          2.5.3.5 Müşteriler, işlerini sürdürmek için gereken kişisel verilerinin kullanılmasına
          itiraz ediyor Genel Veri Koruma Yönetmeliği&apos;nin 7. maddesi uyarınca yapılan işlemler;
          <br />
          <br />
          2.5.3.6 Şirketin önemli olduğunu düşündüğü bir güvenlik sorunu veya başka bir sorun var
          veya Şirketin itibarının zarar görmesi muhtemeldir; <br />
          <br />
          2.5.3.7 Geçmişte bir ters ibraz yapılmış ve/veya başarıyla talep edilmiş ve tutar o
          zamanlar bir kez bile tam olarak kullanılmamıştı;
          <br />
          <br />
          2.5.3.8 Şirket, müşterilerin daha önceden bir hesaba sahip olduğunu biliyor veya farkına
          varıyor Kumar bağımlılığını önlemek amacıyla kapatılan ve/veya halen kapalı olan ya da
          müşterilerin kumar bağımlılığı konusunda şüpheli yorumlarda bulunmak; <br />
          <br />
          2.5.3.9 Kullanıcılar, eyaletlerinin çevrimiçi erişimi genel olarak yasakladığı bir
          vatandaşlığa veya uyruğa sahiptir dünya çapında kendi yetki alanı dışında (örneğin ABD
          veya Litvanya) kumar oynamak;
          <br />
          <br /> 2.5.3.10 Müşterilerin önceden bir ihlali nedeniyle kapatılmış bir hesabı vardı. bu
          veya önceki şartlar veya Şirketin hesap sahibinin aynı olduğunu öğrenmesi halinde Hesabı
          bunları ihlal eden kişi veya kişinin ortağı veya akrabası veya Daha önceki şartları ihlal
          eden veya şartları ihlal eden kullanıcı hesapları kapatılmıştır. <br />
          <br />
          2.5.4 Müşterilerin bir kullanıcı hesabını başkasına devretmesi, satın alması veya satması
          yasaktır. diğer oyunculardan. Böyle bir durumda Game90&apos;ın bu hesapları kapatma hakkı
          vardır. hemen. (ayrıca bkz. madde 2.5.3.1) <br />
          <br />
          2.5.5 Müşteriler kullanıcı hesapları arasında transfer yapamaz ve yapmamalıdır. <br />
          <br />
          2.5.6 Müşteriler belirli bir süre boyunca oturum açmamışsa, hesap etkin olmayan bir hesap
          olarak kabul edilir. üç aydan fazla bir süre. <br />
          <br />
          2.5.6.1 Müşteriler, hareketsizlik oluşmadan 30 gün önce e-posta yoluyla
          bilgilendirilecektir. <br />
          <br />
          2.5.6.2 360 günden fazla kullanılmayan (kaydedilmeyen) aktif olmayan hesaplar için, Her
          ilave aktif olmayan kişi için 5,00 Euro (beş) tutarında bir yönetim ücreti tahsil
          edilecektir. ay, ancak oyuncu hesabında eksi bakiye oluşmadan. Oyuncu olmalı mı Hesapta
          hiçbir para tutarının gösterilmemesi veya ücretler düşüldükten sonraki bakiyenin sıfıra
          düşmesi, Bilgilendirme süresi (madde 2.5.7.1) sona erdikten sonra hesap bloke edilecektir.
          <br />
          <br />
          2.5.6.3 Müşterilerin 60 (altmış) ay süreyle hesaplarına giriş yapmaması durumunda ve
          hesapları zaten kapatılmışsa, Game90 Ltd lehine herhangi bir miktar para ödenecektir.
          kaybedilecektir.
          <br />
          <br /> 2.5.6.4 Hak kaybına uğramadan önce (60 ay aktif değilse) Game90 Ltd. mümkün olan
          tüm iletişim araçlarıyla (e-posta, telefon veya posta) müşterilere fonlarının yakında
          kaybedileceği. Müşteriler, belirtilen son teslim tarihlerine uymalıdır. yazışmalar, son
          başvuru tarihi sona erdiğinde artık hak kazanamazlar. paranın ödenmesi. <br />
        </p>
        <h6 className="mt-3">
          2.6 Game90 Hesabının Yönetimi Game90, kendi takdirine bağlı olarak bu hakkı saklı tutar ve
          herhangi bir zamanda:{' '}
        </h6>
        <p className="mt-3">
          <br />
          2.6.1 herhangi bir bildirimde bulunmadan hesap açmayı reddetmek ve/veya mevcut bir hesabı
          kapatmak sebep; <br />
          <br />
          2.6.2 herhangi bir sebep göstermeksizin para yatırmayı reddetmek; <br />
          <br /> 2.6.3 belge istemek için <br />
          <br />
          2.6.3.1 hesabın tam ve kesin kimliğini oluşturmak veya onaylamak için Kulp. <br />
          <br /> 2.6.3.2 Oyuncu tarafından herhangi bir zamanda yatırılan paranın kaynağının
          araştırılması veya Game90 tarafından atanan üçüncü bir tarafça belirlenmesini sağlamak.
          <br />
          <br /> 2.6.3.3 belirli bir ödeme yöntemini kullanma yetkisini doğrulamak ve/veya <br />
          <br /> 2.6.3.4 hesap sahipleri tarafından sağlanan diğer veri ve bilgileri incelemek. Bu
          olabilir herhangi bir zamanda yapılabilir ve Game90 bir oyuncu hesabını hariç tutma
          hakkını saklı tutar. devam eden kontroller sırasında bile kullanılması veya kontrol
          tamamlanıncaya kadar geçici olarak askıya alınması tamamlandı. <br />
          <br /> 2.6.4 önceden haber verilmeksizin <br />
          <br /> 2.6.4.1 Hesap sahibinin herhangi bir ülkeye ait verilerinin başka tüzel kişilere
          aktarılması (üçüncü taraflar), nihai olarak Game90 tarafından yönetilen veya kontrol
          edilen veya görevlendirildi. Game90, söz konusu verilerin aktarılacağını ve yönetileceğini
          garanti eder. İlgili mevzuata ve veri koruma kanunlarına uygun olarak ve/veya benzer
          düzenlemeler.
          <br />
          <br /> 2.6.4.2 hesap sahibinin hak ve yükümlülüklerini devretmek ve/veya lisanslamak
          herhangi bir ülkedeki diğer tüzel kişilere, bu tüzel kişilerin nihai olarak Game90
          tarafından yönetilen, kontrol edilen veya görevlendirilen ve Game90, aşağıdaki kurallara
          uygunluğu garanti eder: yükümlülükler. <br />
          <br />
          2.6.5 Hesap sahiplerinin bakiyesini genel kurallara uygun olarak tutmak ve yönetmek kabul
          edilen nakit yönetimi kuralları. Bu, mali müdahaleyi içerebilir. hesap bakiyesini
          yönetmekle görevlendirilen kurumlar ve/veya ödeme hizmeti sağlayıcıları hesap sahibinin
          adına ve/veya yararına; <br />
          <br /> 2.6.6 Game90 hesabının bakiyesinin süresinin dolmasına ve bloke edilmesine ve/veya
          doğrudan veya dolaylı olarak bir talebi yerine getirmeyi reddetmek <br />
          <br /> 2.6.6.1 Game90 düzenlemelerinin ihlal edilmesi ve/veya <br />
          <br />
          2.6.6.2 örneğin bir bahis veya oyunla bağlantılı izinsiz faaliyetler olay ve/veya Game90
          hesabının veya diğer tüm Game90 hizmetlerinin işleyişi yer (yasaların veya diğer
          düzenlemelerin ihlali, ihlaller gibi ancak bunlarla sınırlı olmamak üzere) üçüncü taraf
          hakları veya dolandırıcılık); <br />
          <br /> 2.6.7 hesap sahiplerinin oyunlara katılımını askıya almak ve/veya engellemek,
          Game90&apos;ın inandığı durumlarda tanıtım faaliyetleri, yarışmalar veya diğer hizmetler
          Bir Game90 hesabının yasa dışı amaçlarla kötüye kullanıldığı, kullanıldığı veya
          kullanılabileceğine ilişkin meşru endişeler, hileli veya dürüst olmayan uygulamalar. Buna
          kumar bağımlılığının önlenmesi de dahildir.
          <br />
          <br /> 2.6.8 Kullanıcıların ifşa olduğu tespit edilirse oyuncu hesabını engellemek veya
          sınırlamak kişiler (PEP). Bu aynı zamanda şu ülkelerden birinde aktif olan veya geçmişte
          aktif olan ünlüler için de geçerlidir: sunulan sporlar (aktif veya aktif olmayan amatör
          veya profesyonel sporcular olarak) veya medya radyo ve televizyonun yanı sıra sosyal
          medyadan (MEP) ünlüler. Eğer bir oyuncu bir Yaptırımlar listesinde oyuncu hesabı derhal
          kapatılacak ve yetkililere bildirilecektir. yetkili makam. <br />
          <br /> 2.6.9 hesap sahiplerinin katılımlarını askıya almak ve/veya engellemek hesap
          sahibinin dolandırıcılıktan hüküm giymiş veya Game90, hesap sahibinin bir sisteme sahip
          olduğunu keşfeder (dahil tasarlanmış veya tasarlanmış makineler, robotlar, bilgisayarlar,
          yazılımlar ve diğer otomatik sistemler) Müşteriyi kurcalayabilecek, ona zarar verebilecek
          veya mali avantaj elde edebilecek uygulamalar ve/veya Game90 yazılımı. <br />
        </p>
        <h6 className="mt-3"> 2.7 Oyuncu koruması</h6>
        <p className="mt-3">
          <br /> 2.7.1 Kalıcı yeşil banner&apos;a tıkladığınızda 18+ Kumar bağımlılık yapabilir.
          Oynamak Sorumlu bir şekilde. Müşteriler daha fazla bilgi edinebilecekleri Game90 önleme
          sayfasına ulaşır kumar bağımlılığı hakkında ve ayrıca yardım isteyin. <br />
          <br /> 2.7.2 Müşteriler, gelecekteki bahisler için kendilerini bloke etme seçeneğine
          sahiptir. Oyuncu hesaplarının bloke edilmesini yazılı olarak, e-postayla, posta yoluyla ya
          da web sitesindeki ayarları kullanarak. <br />
          <br /> 2.7.3 Game90 resmi oyuncu engelleme sistemi OASIS&apos;i kullanır. Girerek Bir
          oyuncunun kendini ihraç etmesi durumunda bu bilgi devlet tarafından kaydedilir ve izlenir
          yetkililer ve bir oyuncu tüm Almanya&apos;da engellendi (başka bir yere gitse bile)
          Sağlayıcı). Oyuncular en az 3 ay süreyle sınırsız oyun yasağı uygulayabilirler. istediğin
          zaman. Bunun bir istisnası, 24 saatlik bir molayı tetikleyen panik butonudur. (bkz.
          2.7.7). Blokaj ancak bloke edilen kişinin yazılı başvurusu üzerine kaldırılabilir. Bu aynı
          zamanda bloke başvurusu sırasında belirli bir sürenin belirtilmesi durumunda da
          geçerlidir. süre. Başvuru en erken son başvuru tarihinden sonra yapılabilir. Eğer hayırsa
          İptal talebinde bulunuldu, yasak bitmiyor! Oyuncuyu kaldırmak için uygulama yasağın
          uygulanmasından sorumlu makam olan Darmstadt Bölge Konseyi&apos;ne sunulmalıdır. engelleme
          dosyasının bakımı. Operatörün veya acentenin mesajı iletmesi yeterlidir. başvuru. Şans
          oyunu işletmecileri ve komisyoncuları oyuncu yasaklarını kaldıramaz! Sırasında Game90
          kayıt sırasında ve daha sonra para yatırırken resmi OASIS sistemini kullanır. olası oyuncu
          yasaklarını kontrol edin. Bir oyuncu zaten engellenmişse, oyuncu hesabı dondurulmuş veya
          hiç oluşturulmamış.
          <br />
          <br /> 2.7.4 Müşteriler, kullanıcı hesaplarında kendi limitlerini belirleme seçeneğine
          sahiptir. Sitenin özel güvenlik alanı ve sınırları.
          <br />
          <br /> 2.7.5 Limitler, para yatırma limiti olarak bağımsız olarak ayarlanabilir (maksimum
          para yatırma/EUR) Takvim ayı başına 1.000/sağlayıcılar arasında), bahis limiti (maksimum
          bahis miktarı) ve günler, haftalar veya aylar için bir kayıp limiti (maksimum kayıp) veya
          talep edilmesi halinde ayrıca belirlenebilir müşteri hizmetleri tarafından. <br />
          <br /> 2.7.6 Risk limitlerindeki azalmalar derhal yürürlüğe girer. Artışlar aktif hale
          geliyor 7 günlük yasal sürenin ardından. Bu ayarlar kanunen zorunludur ve değiştirilemez.
          istisna nedeniyle değiştirildi. <br />
          <br /> 2.7.7 Ayrıca, oyuncu kendisini 24 saat süreyle bloke etme seçeneğine sahiptir. web
          sitesinde herhangi bir zamanda görüntülenen panik butonu. <br />
          <br /> 2.7.8 Oyuncular bahislerin toplamı, kazançlar ve kayıplar hakkında
          bilgilendirilecektir. önceki 30 günün. Bu bilgi her kimlik tespiti sonrasında
          sağlanacaktır ve kimlik doğrulamanın yanı sıra 24 saatten fazla süre geçmişse oyunun
          başlamasından önce son bilgiden bu yana. Oyuna katılım ancak oyuncunun bilgileri dikkate
          aldığını açıkça ifade etmiştir.
          <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>

      <div>
        <h5 id="3" className="mt-75">
          3. Ödemeler ve Güvenlik
        </h5>
        <h6 className="mt-3"> 3.1 Ödemeler </h6>
        <p className="mt-3">
          3.1.1 Bahis hesabı yalnızca Euro (EUR, €) cinsinden yönetilir. Diğer para birimleri
          kullanılmayabilir. Herhangi bir kredi limiti sağlanmamaktadır. Bir hissenin ön koşulu
          kredidir ilgili hisseye eşit veya daha yüksek. <br />
          <br />
          3.1.2 Aşağıdaki ödeme seçenekleri mevcuttur: - Paysafecard (hemen) - Kredi bu yöntemlere
          çevrimiçi olarak izin verildiği sürece kart (hemen Mastercard ve Visa) bu yasal alandaki
          oyunlar - SOFORT Bankacılık (hemen) - GIROPAY (hemen) - Skrill (anında) - Banka havalesi
          (beş iş gününe kadar) fatura adresinin, fatura sırasında sağlanan adres ve kimlikle
          eşleşmesi koşuluyla kayıt. Diğer ödeme yöntemleri Game90s&apos;ın takdirine bağlı olarak
          kabul edilebilir. Başta parayla ilgili olanlar olmak üzere yasal hükümlere tam olarak
          uymak aklama. Müşteriler alternatif kullanımından kaynaklanan tüm masraflar konusunda
          bilgilendirilecektir. Ödeme metodları. Para yatırma işlemleri herhangi bir zamanda
          (ücretsiz olarak) banka havalesi yoluyla yapılabilir (bkz. banka hesap bilgileri) veya
          kredi kartı. Şirketteki depozito alındıktan sonra banka hesabı veya kredi kartı ödemesinin
          elektronik onayı, depozito tutarı hesaba aktarılacaktır. Müşteri başına yalnızca bir kredi
          kartı veya başka bir ödeme yöntemi Hesap para yatırma ve/veya çekme işlemleri için
          kullanılabilir. Sık veya sürekli değişiklikler ödeme yöntemlerine izin verilmez. <br />
          <br />
          3.1.3 Para yatırma işlemleri için aşağıdaki minimum ve maksimum tutarlar mümkündür: <br />
          <br /> 3.1.3.1 Minimum depozito: Ödeme başına 10 Euro <br />
          <br /> 3.1.3.2 Maksimum depozito: Ödeme başına 1.000 Euro (sağlayıcılar arasında aylık)
          maksimum depozito bireysel durumlarda yetkililerin onayı ile artırılabilir.
          <br />
          <br /> 3.1.4 Game90, oyuncu hesaplarından herhangi bir kredi ödemez. kesin kimlik, yaş,
          mevcut ikamet yeri ve kullanılan ödeme yöntemleri kullanıcılar açıkça belirlenmemiş ve
          onaylanmamıştır. Game90 ayrıca şunları yapma hakkını saklı tutar: Tüm oyuncu kimlik
          bilgilerinin üçüncü bir tarafın yardımıyla açıkça doğrulanmasını sağlayın. Mümkünse,
          Game90 oyuncu hesabının bakiyesini kayıtlı ve tamamen doğrulanmış ve adına bir oyuncu
          hesabı oluşturulmuş oyuncunun talebi üzerine doğrulanmış oyuncu banka hesabına (veya
          e-Cüzdan veya kredi kartına) aktaracaktır. <br />
          <br /> 3.1.5 Müşteriler, Game90 hizmetlerinden elde edilen kazançların beyanı ve
          belgelenmesinden ve gerekirse ücretlerin ödenmesinden devlet yetkililerine karşı bağımsız
          olarak sorumludur. <br />
          <br /> 3.1.6 Aşağıdaki para çekme yöntemleri mevcuttur: - Banka havalesi (en fazla beş iş
          günü) Para yatırma ve çekme işlemleri için diğer ödeme yöntemleri eklenebilir. gelecek
          Game90s&apos;ın takdirine bağlıdır. Her ödeme talebi manuel olarak tekrar kontrol edilir
          veya Ödeme yapılmadan önce teknik olarak Şirket çalışanları tarafından. Ödeme işlemi
          tamamlandıktan sonra onaylandıktan sonra müşterilere e-posta yoluyla bilgi verilecektir.
          <br />
          <br /> 3.1.7 Müşteriler, ödenmemiş tüm bakiyeleri tam olarak ödemekle yükümlüdür. Eğer
          para ise yanlışlıkla bir hesaba para yatırılması veya borç kaydedilmesi durumunda
          müşterilerin Game90&apos;ı derhal bilgilendirmesi gerekir. Hesaba yanlışlıkla yatırılan
          tüm tutarlar derhal geri ödenmelidir. Tüm tutarlar Yanlışlıkla bir hesaba borç kaydedilen
          tutarlar da derhal geri ödenecektir. Bir hesaba yanlışlıkla aktarılan paralar müşteriler
          tarafından kullanılamaz. bahis. Game90, bu tür paralarla finanse edilen tüm işlemleri
          geçersiz kılma hakkını saklı tutar. Taraflardan her biri diğer tarafı tutarlarla ilgili
          taleplerden tazmin etmeyi taahhüt eder yanlışlıkla alacaklandırıldı veya borçlandırıldı.
          <br />
          <br /> 3.1.8 Müşteriler, kredi kartına herhangi bir ters ibrazda bulunmamayı veya herhangi
          bir işlemi geri almamayı taahhüt eder. aksi takdirde yapılan ödemeler. Bu tür iade
          borçlarından kaynaklanan tüm masraflar ve zararlar, müşteriye fatura edilir. Durum tam
          olarak açıklığa kavuşturuluncaya kadar etkilenen oyuncu hesap derhal kapatılacak ve
          bekleyen ödemeler iptal edilecektir. Muhbir Bu gibi durumlarda yasal yollara başvurma ve
          yetkilileri bilgilendirme seçeneğini saklı tutar. olası istismar. <br />
          <br /> 3.1.9 Şirket, kredi şüphesi durumunda bir hesabı bloke etme hakkını saklı tutar
          kart dolandırıcılığı veya kredi kartının kötüye kullanılması. Bu özellikle çalıntı kredi
          kartlarının kullanımı için geçerlidir. veya diğer dolandırıcılık faaliyetleri. Game90
          kredi kartlarının kötüye kullanılmasından sorumlu değildir veya çalıntı kredi kartları.
          <br />
          <br /> 3.1.10 Game90 bonusları müşteri hesaplarına aktarabilir. Bu bonuslar bir Şirketin
          ve müşterilerin gönüllü hizmetleri bunlar üzerinde hiçbir temel yasal hakka sahip
          değildir. Bonus teknik veya manuel olarak aktarılabilir. <br />
          <br /> 3.1.11 Game90 tüm ödeme işlemlerini manuel, teknik ve elektronik önlemler. <br />
        </p>
        <h6 className="mt-3"> 3.2 Güvenlik </h6>
        <p className="mt-3">
          <br /> 3.2.1 Kullanıcının hesap bakiyesi nakit veya eşdeğeri ile karşılanır. Şirket
          Kullanıcı hesabındaki kredinin tamamının ödenebileceğini garanti eder. Tüm mevduatlar ve
          bir hesaptan para çekme işlemleri çevrimiçi olarak gerçek zamanlı olarak (Malta) yapılır
          ve VeriSign. <br />
          <br /> 3.2.2 Bir kullanıcı hesabına erişim yalnızca bir e-posta adresi ve müşterinin
          kişisel kişisel şifresi. <br />
          <br />
          3.2.3 Müşteriler, kullanıcı adlarını ve şifrelerini gizli tutmaktan tamamen sorumludur.
          Müşterilerin bu verileri saklaması durumunda Şirket&apos;in hiçbir yükümlülüğü yoktur.
          yanlış yere koyulmuş, unutulmuş veya kaybolmuş. Müşteriler oturum açma bilgilerini harici
          bir ortama kaydederse hesaplarına farklı bilgisayarlardan erişebilmek veya güvenliğini
          sağlamak için cihaz hesap bilgileri, bunu risk kendilerine ait olmak üzere yaparlar.
          <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 id="4" className="mt-75">
          4. Game90 web sitesinin kullanımına ilişkin kısıtlamalar
        </h5>
        <p className="mt-3">
          4.1 Game90&apos;ın 18 yaşın altındaki kişilerden bahis kabul etme yetkisi yoktur. Kayıt
          yaptıran müşteriler, yasal minimum yaş sınırı olan 18&apos;e ulaştıklarını beyan ederler.
          ve zihinsel olarak kendi eylemlerinin sorumluluğunu alabilecek durumdalar ve Kumar
          bağımlılığıyla ilgili sorunlar ve/veya kumar bağımlılığı riski. Game90&apos;ın bir
          özelliği var Reşit olmayanlar (veya Şirketin reşit olmadığından şüphelendiği kişiler)
          tarafından yapılan bahisleri geçersiz kılma yükümlülüğü. Müşteriler, reşit olmayanların
          kumar oynamasının cezai bir suç olduğunu kabul eder. <br />
          <br /> 4.2 Tüm dolandırıcılık, suç teşkil eden veya şüpheli faaliyetler, istisnasız
          olarak, İlgili kurum ve kuruluşlara raporlanır. <br />
          <br /> 4.3 Şirket, kişi grupları tarafından oynanan tüm bahisleri geçersiz kılma hakkını
          saklı tutar. Komplo veya dolandırıcılık niyetiyle hareket ettiğinden veya manipüle
          edildiğinden şüpheleniliyorsa bahisler. Bu grup, kişileri, akrabaları, kuruluşları,
          bahisçileri ve bunların üyelerini içerebilir. çalışanlar/temsilciler. <br />
          <br /> 4.4 Çalışanlar, yöneticiler, çalışanlar, danışmanlar, temsilciler veya ortaklar
          Şirketin, şubelerinin veya bağlı şirketlerinin ve bunların bayilerinin, tedarikçiler veya
          taşeronlar Game90&apos;ın doğrudan ve dolaylı kullanımının dışında tutulur Web sitesi veya
          mobil uygulamalar. Bunun istisnası, aşağıdakiler için oluşturulmuş test hesaplarıdır:
          teknik izleme ve sunulan hizmet üzerinde hiçbir etkisi yoktur. Bu kısıtlama bu kişilerin
          yakınları için de geçerlidir. Bu bağlamda “göreceli” terimi, bir eşe, hayat arkadaşına,
          ebeveyne, çocuğa veya kardeşe. <br />
          <br /> 4.5 Game90 web sitesinin kullanımına yalnızca aşağıdaki yerlerde izin verilir:
          böyle bir hizmete katılım yasal olarak düzenlenmiştir ve yasaldır. Müşteriler şunları
          temin eder: Şirket, bu tür oyunların bulunduğu yerlerden web sitesine veya hizmetlerine
          erişeceklerini şans veya bahis oynamak yasaktır. Game90, teknik önlemleri izler ve
          kullanır. Bu tür erişimleri mümkün olduğunca engelleyin. Ayrıca müşteriler bunu
          yapmayacaklarını garanti ederler. yasaklayan bir devletin vatandaşı olmaları durumunda web
          sitesine erişim veya giriş yapmaları Vatandaşların bulundukları yer ne olursa olsun şans
          oyunlarına katılmaları engelleniyor. Müşteriler kullanımını yasaklayabilecek yerel
          yasaları inceleme konusundaki tek sorumluluğun kendilerine ait olduğunu kabul ederler.
          Game90 hizmetlerinden. Kaydolmadan önce bir hukuk danışmanına danışmanız tavsiye edilir.
          Hizmetlerin kullanımının hiçbir şekilde bu yasalara aykırı olmamasını sağlamak. Şirket
          müşterilerin herhangi bir yerel veya ulusal yasayı ihlal etmesinden sorumlu değildir.
          <br />
          <br /> 4.6 Önceki paragrafa bakılmaksızın, ABD ve Litvanya vatandaşları ve ABD veya
          Litvanya&apos;da ikamet eden herhangi bir kişinin oturum açması ve web sitesini kullanmak.
          Şirket, yapılan veya yapıldığına inanılan tüm bahisleri geçersiz kılacaktır. veya
          müşterinin daimi ikametgahı olmadığı sürece Amerika Birleşik Devletleri veya
          Litvanya&apos;dan Game90s&apos;ın yetkisi.
          <br />
          <br /> 4.7 Game90, kontrol etmek için teknik, dijital veya diğer önlemleri kullanma
          hakkını saklı tutar. hizmetin devamını değerlendirmek amacıyla müşteri tarafından iletilen
          verilerin doğruluğunun tespiti mevcut iş ilişkisi. <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 id="5" className="mt-75">
          5. Web sitesi
        </h5>
        <h6 className="mt-3"> 5.1 Spor Bahisleri </h6>
        <p className="mt-3">
          5.1.1 Bahis oynandığında bahis tutarı müşterinin hesabından borçlandırılır.
          Almanya&apos;da ikamet eden müşterilerin kullanıcı hesaplarından, hisse artı bir miktar
          borçlandırılacaktır. bahis vergisi bahisi. Müşteriler, verilen bahisteki bahsi kontrol
          etmekle yükümlüdür gönderilmeden önce kaydırın. Bahisler gönderildikten sonra iptal
          edilemez veya değiştirilemez. Şirket, sebep göstermeksizin bahisleri kısmen veya tamamen
          reddetme hakkına sahiptir.
          <br />
          <br /> 5.1.2 Müşterilerin birden fazla bahis oynaması halinde, bunlar bahis sırasına göre
          işleme alınır. Game90s&apos;ın merkezi bilgisayarından alınırlar. <br />
          <br />
          5.1.3 Müşterilerin aynı bahisleri tekrar tekrar oynaması durumunda çoklu bahisler tekli
          bahis olarak kabul edilebilir ve oranlar, yani oyunda, sonuçta ve bahiste aynı olan
          bahisler. Şirket aynı zamanda İki veya daha fazla kişi arasında gizli anlaşma olduğuna
          dair belirtiler olması durumunda bahisleri geçersiz kılma hakkını saklı tutar. müşteriler
          veya bahis davranışının tamamı şüpheli görünüyorsa. Bir bahis ilan edilirse geçersiz, 1.0
          oranla değerlendirilir, bu durumda oyuncu sadece bahisini alır manipülasyonun hileli bir
          girişim olarak değerlendirilmediği sürece, bahisler ayrıca dondurulmuştur (ayrıca bkz.
          Madde 5.3.1.1). Müşterinin bahis davranışı şunu gösteriyorsa Game90&apos;ın organize veya
          profesyonel davranış sergilemesi durumunda, Game90&apos;ın uygun önlemleri alma hakkı
          vardır. bahis davranışını kontrol etmek ve gerekirse kısıtlamak veya engellemek. Bu da
          örneğin kazanma şansına ilişkin basit bahisler veya oynanan bahisler için geçerlidir
          içeriden edinilen bilgiye dayanmaktadır. Bu bahis davranışından şüpheleniliyorsa, Game90
          bahis miktarını sınırlayacaktır. oyuncular bahis koyar veya oyuncuyu hariç tutar. <br />
          <br /> 5.1.4 Bahislerin kabulü ve ödemeleri farklı koşullarla sınırlandırılabilir
          sınırlar. Buna özellikle bahis limitleri, bahis kuponu limitleri, maksimum bahis limitleri
          dahildir. kazançlar ve müşterilerin kişisel bahis limiti. Bu sınırlar değişebilir ve bahis
          kuponunda veya kullanıcı hesabında listelenir. Ancak Game90 aşağıdaki hakkını saklı tutar:
          bahis miktarını sınırlamak veya kazanma şansını değiştirmek veya sınırlamak ve ayrıca
          kazançların ödenmesi. Manipülasyon şüphesi varsa yapılan anlaşmalar (ayrıca diğer birkaç
          müşteri) veya diğer tutarsızlıklar nedeniyle, Game90 daha sonra sınırlandırabilir,
          değiştirebilir veya bu bahisleri iptal edin. Bahsedilen durumlarda oyuncu hesabı bloke
          edilecek ve eğer Gerekli incelemeler yapılmak üzere yetkililere iletildi. Bir ödeme
          Muhtemelen kullanılmamış depozito tutarı da dahil olmak üzere fonlar, soruşturma bitene
          kadar alıkonuluyor yetkililerin ödemeyi haklı çıkaracak bir sonuca vardıkları görüldü.
          Diğer durumlarda, para donduruldu. <br />
          <br /> 5.1.5 Resmi başlangıç saatinden sonra yanlışlıkla kabul edilen bahisler, aksi
          belirtilmediği sürece geçersizdir. <br />
          <br /> 5.1.6 Canlı bahisler (§ 21 GlüStV 2021 uyarınca) aktif bir etkinlik sırasında
          oynanabilen bahislerdir. Canlı bahislerde bahis oranları düzenli olarak canlı karşılaşmaya
          göre ayarlandığından, güvenlik nedeniyle gecikmeli olarak bahis oynanır. Bu aşamada
          oranların değişmesi durumunda bahis kuponu reddedilebilir ve bunun yerine müşteriye farklı
          oranlarla sunulabilir. Değiştirilen kotaların beklentilerini karşılaması müşterilerin
          sorumluluğundadır.
        </p>
        <h6 className="mt-3"> 5.2 Geçerli bahisler </h6>
        <p>
          5.2.1 Bir bahis veya oynanan bahis, bahis kuponu numarasıyla onaylandığı takdirde kabul
          edilmiş sayılır. Bahis daha sonra Bahislerim altında görüntülenir (bakınız bahis kuralları
          ve istisnalar, madde 5.1.4. ve 5.1.5.). <br />
          <br /> 5.2.2 Müşteriler, bahis oynayarak bahis ve spor bahisleri koyma kurallarını kabul
          eder ve bunları, süreci ve çevrimiçi kumarın genel risklerini anladıklarını onaylarlar.
          <br />
          <br /> 5.2.3 Herhangi bir nedenle bahisin oynanamamasından veya oynanamamasından Şirket
          sorumlu değildir. Bu nedenler aynı zamanda bilgisayarların arızalanması veya arızalanması,
          telekomünikasyon hizmetlerinin veya İnternet bağlantılarının arızalanmasını da içerir.
          Game90, paragraf 5.2.1 uyarınca gönderilen ancak tam olarak onaylanmayan bahisleri
          tanımaz. <br />
          <br /> 5.2.4 Yalnızca madde 5.2.1 uyarınca kabul edilen ve dolayısıyla oyuncunun hesabında
          da görüntülenen bahisler geçerli kabul edilir. Game90, bahis kuponu web sitesine veya
          uygulamalara vb. yerleştirildiğinde görüntülenen, ancak <br /> maddesi uyarınca yapılan ve
          kabul edilen bahisle eşleşmeyen temsiller için hiçbir garanti veya sorumluluk kabul etmez.
          <br />
          5.2.1.. Bu tür hatalar, örneğin İnternet hizmetindeki (İnternet bağlantıları)
          dalgalanmalardan veya müşteriler açısından uç cihazların bakımının yetersiz olmasından da
          kaynaklanabilir ve bu nedenle geçerli değildir. Müşterilerin, uç cihazlarda yanlış beyan
          edildiğinden şüphelenilen kârlara ilişkin hiçbir yasal hakkı yoktur. <br />
        </p>
        <h6 className="mt-3"> 5.3 Kârlar </h6>
        <p>
          5.3.1 Bu sonuçlar resmi olarak onaylandıktan sonra tüm kazançlar otomatik olarak oyuncunun
          hesabına aktarılacaktır. Bu, çeşitli koşullar nedeniyle uzun zaman alabilir. Game90 hemen
          rezervasyon yaptırmaya çalışır. <br />
          <br />
          5.3.1.1 Game90 web sitesindeki yazılımı manipüle etmek ve/veya bir avantaj elde etmek için
          geliştirilen AI sistemleri veya yazılımlarının (AI = yapay zeka), özellikle makinelerin,
          bilgisayarların, yazılımların veya diğer otomatik sistemlerin veya programların veya
          elektroniklerin kullanımı bahis kesinlikle yasaktır. Bu tür sistemlerin izinsiz
          kullanımından kaynaklanan karlar geçerliliğini yitirir ve müşteri hesabının bloke
          edilmesiyle sonuçlanır. Yatırılan tutarların iadesine derhal itiraz edilir ve tüm tutarlar
          (para yatırma ve kazançlar vb.) dondurulur. Game90 ayrıca bu durumlarda yasal yollara
          başvurmayı da değerlendirecektir. <br />
          <br /> 5.3.1.2 Game90, web sitesinin müşteriler tarafından kullanımını izlemek için uygun
          teknik, dijital, elektronik veya diğer önlemleri kullanma hakkını saklı tutar. <br />
          <br /> 5.3.2 Kazançlar, ödemenin başlatıldığı aynı banka hesabına (veya eCüzdan vb.)
          aktarılır. Bu, kapalı döngü politikası olarak bilinir ve kara para aklamanın ve terörün
          finansmanının önlenmesine yönelik Avrupa yasal gerekliliklerine karşılık gelir. Game90,
          her bir işlemin tüm ayrıntılarını onaylamak için gerekli tüm adımları atma hakkını saklı
          tutar.Bu makul bir süre içerisinde yapılacaktır. <br />
          <br />
          5.3.3 Şirket, kaybolan, geciken, okunamayan, eksik, hasar görmüş veya yanlış
          yönlendirilmiş belgelerden, sorgulardan, kar iddialarından veya müşterilerden gelen
          iletişimlerden sorumlu değildir. Şirkete veya ek posta ücretinin ödenmesi gereken
          mesajlara. Malta&apos;daki Game90 ofisine ulaşan her türlü soru, talep veya yazışma,
          alındıktan sonra Şirketin malı olacak ve müşteriye iade edilmeyecektir. Bu nedenle bu
          belgelerin yalnızca güvenilir kopyalarının gönderilmesi önerilir. <br />
          <br /> 5.3.4 Müşteri ve hafta başına maksimum kar 100.000 Euro&apos;dur (yüz bin). Bahis
          yaparken - müşteriler tarafından aynı veya benzer bahis kuponlarının sunulması durumunda,
          toplam yalnızca 100.000 € (yüz bin) tutarında kar elde edilebilir. Birden fazla hesap açan
          ve bu hesapları aynı veya benzer bahisler oynamak için kullanan müşteri haneleri (aynı ev
          adresi, internet bağlantısı veya aile üyeleri), Game90&apos;ın saklı tuttuğu toplam
          100.000 € (yüz bin) tutarında bir kar elde edebilir. dolandırıcılık şüphesi durumunda bu
          birden fazla hesabı bloke etme ve ödemeyi tamamen dondurma hakkı.
          <br />
          <br /> 5.3.5 Müşteri tarafından Auszahlen düğmesine basılarak talep edilmesi halinde
          kredi, seçilen ve doğrulanan ödeme yöntemi aracılığıyla ödenecektir. Müşteriler, aşağıdaki
          durumlar haricinde herhangi bir zamanda kredi bakiyesinin tamamının veya bir kısmının
          banka hesaplarına ödenmesini ayarlayabilir: 5.3.5.1 Kullanıcı hesabındaki bakiye,
          bonuslardan veya bonuslardan elde edilen kazançlardan veya kalan bakiyeden oluşup
          oluşmadığına bakılmaksızın depozitolardan, geçerli ciro koşullarına uygun olarak henüz
          bahis için kullanılmamış; <br />
          <br />
          5.3.5.2 Müşteriden gelen ilk ödeme talebi olması ve gerekli koşulların henüz
          karşılanmaması; <br />
          <br />
          5.3.5.3 Müşterinin kişisel verilerinin doğrulanması, verilerin yanlış olduğunu veya
          müşterinin birden fazla oyuncu hesabına sahip olduğunu gösteriyorsa (bu durumlarda oyuncu
          hesabı bloke edilecek ve bu oyuncu hesabındaki tüm oyun faaliyetleri ve sonuçları iptal
          edilecektir) ; <br />
          <br />
          5.3.6 Kredi, aşağıdaki durumlar haricinde müşteriye ücretsiz olarak ödenir: <br />
          <br />
          5.3.6.1 Müşteriler anlaşılır ve anlaşılır bir açıklama ile henüz kullanılmamış bir tutarın
          ödenmesini talep ederler. Bu durumda Game90 beyana ikna olursa talep edilen tutarın
          %8&apos;i (fakat en az 10 Euro) tutarında işlem ücreti kesilecektir; kullanıcı hesabının
          ve kullanıcının ömür boyu tamamen engellenmesiyle birlikte; <br />
          <br />
          5.3.6.2 Müşteriler 20 €&apos;dan daha az bir tutarın ödenmesini talep etmektedir. Bu
          durumda 5 € tutarında işlem ücreti tahsil edilecektir. (Kalan toplam tutarın 20 €&apos;dan
          az olması durumunda bu durum hesabın kapatılması durumunda geçerli değildir); <br />
          <br />
          5.3.6.3 Müşteriler, AB standart transferiyle ödenemeyen bir banka hesabına transfer
          başlatır. Bu tür uluslararası transferlerde tüm ücretler alıcıya aittir.
          <br />
          <br />
          5.3.7 Müşterilere ilk ödeme ancak müşterinin kimliği pasaport veya kimlik fotokopisi,
          adres kanıtı (elektrik, su, gaz veya sabit hat telefon faturaları veya alternatif olarak
          resmi kayıt) ile tamamen doğrulandıktan sonra yapılabilir. Sertifika) ve kullanılan ödeme
          yönteminin kanıtı. Tüm belgeler posta veya e-posta yoluyla da gönderilebilir. Ödeme
          yapılmadan önce, önceden yatırılan tutarın (veya tutarların) en az bir kez tamamen
          kullanılmış olması gerekir. <br />
          <br />
          5.3.8 Game90, aynı müşteriden gelen birden fazla ödemeyi tek bir toplamda birleştirme ve
          ayrıca belirli bir süre içindeki diğer ödemeleri birleştirme hakkını saklı tutar. Game90,
          müşterilere yapılan ödemeyle birlikte, birleşik ödeme ve içerdiği tutarlar hakkında onlara
          bilgi sağlar.
          <br />
          <br /> 5.3.9 Para çekme işlevi 5.3.9.1 Game90, belirli bahis teklifleri için gönüllü
          olarak, müşterilerin bahis kuponunun süresi dolmadan önce bahisleri Game90&apos;a geri
          satabilecekleri bir para çekme işlevi sunar. Bu para çekme teklifi Game90 açısından
          gönüllü bir ek seçenektir. Genel olarak, cihazın ekranında görünse bile para çekme
          işlevine yönelik herhangi bir yasal hak yoktur. İlgili süreç ancak Game90 tarafından bu
          para çekme işlevi için onaylandıktan sonra yasaldır. <br />
          <br />
          5.3.9.2 Game90, güvenlik nedeniyle devam eden bir etkinlik sırasında para çekme işlevini
          devre dışı bırakma hakkına sahiptir. Bu durum özellikle spor müsabakasıyla bağlantının
          teknik nedenlerden dolayı 30 saniyeden uzun süre kesintiye uğraması durumunda geçerlidir.
          Güvenlik nedeniyle bu bahis, sonunda normal bahis olarak sınıflandırılır ve artık canlı
          moda girmez. Bu bahis, ödeme fonksiyonu olan bir kombinasyon veya sistem bahisinin
          parçasıysa, ödeme fonksiyonu otomatik olarak kapatılır ve artık kullanılamaz. Bu gibi
          durumlarda müşterilerin yasal olarak erken ödeme hakkı da bulunmamaktadır.
        </p>
        <h6 className="mt-3"> 5.4 Anlaşmazlıklar </h6>
        <p>
          5.4.1 Bu Şartlar ve Koşullar tarafından düzenlenmeyen bir anlaşmazlığın ortaya çıkması
          durumunda Game90s destek ekibi (support@game90.bet), dahili şikayet prosedürümüzü
          kullanarak bunu adil ve eşitlikçi bir şekilde çözmeye çalışacaktır. Anlaşmazlıkların
          temeli her zaman Game90s Genel Hüküm ve Koşullarıdır ve web sitesinde veya uygulamalarda
          sunulan veya yayınlanan hüküm ve bilgilerin veya ele alınan konuların (örn. SSS)
          açıklamaları değildir. 5.4.2 Bahisler ve/veya kazançlarla ilgili şikayetler, bahis
          kararlaştırıldıktan sonraki 14 iş günü içerisinde e-posta veya faks yoluyla yazılı olarak
          alınmalıdır. Bu sürenin sonunda tüm bahisler onaylanmış sayılacaktır. <br />
          <br />
          5.4.3 Sizi tatmin edecek şekilde çözülmeyen herhangi bir anlaşmazlık, talep üzerine
          Bağımsız Bahis Karar Verme Hizmetine (IBAS) havale edilebilir. Lütfen bu Hüküm ve
          Koşulların 15. maddesine de dikkat edin. <br />
          <br />
          5.4.4 Oyuncular önerilen çözümleri kabul etmezlerse, anlaşmazlık ADR - Alternatif
          Uyuşmazlık Çözümü yoluyla çözülebilir (bkz. madde 16). <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 className="mt-75" id="6">
          6. Sorumluluk reddi beyanı ve Game90 web sitesinin kullanılabilirliği
        </h5>
        <p className="mt-3">
          <br /> 6.1 Şirket, Game90 web sitesinin ve uygulamalarının uygun uzmanlık ve özenle
          işletilmesine kendini adamıştır. Kanunen zorunlu kılınmadığı sürece Şirket, Game90 web
          sitesi ve hizmetleriyle ilgili olarak açık veya zımni herhangi bir garanti veya garanti
          sunmaz. <br />
          <br /> 6.2 Şirket, Game90 web sitesi ve hizmetlerinin müşterilerin gereksinimlerini tam
          olarak karşılayacağını, kesintisiz olarak kullanılabileceğini veya hızlı, güvenli ve
          hatasız çalışacağını garanti etmez. Şirket ayrıca kusurların düzeltileceğini veya Game90
          web sitesi ve uygulamalarının virüs veya hata içermediğini garanti etmez. Ayrıca Şirket,
          Şirket tarafından sağlanan verilerin tam işlevselliğini, doğruluğunu ve güvenilirliğini,
          web sitesinin kullanım başarısını veya web sitesinde müşterilere sunulan verilerin
          doğruluğunu garanti etmez. Çevrimiçi teklifteki hatalar veya başarısızlıklar derhal
          e-posta yoluyla support@game90.bet adresine bildirilmelidir. Game90 olası bir hatayı
          mümkün olan en kısa sürede düzeltmeye çalışacaktır. <br />
          <br />
          6.2.1 Müşterilerin Game90 hizmetini güncelliğini yitirmiş uygulamalarla, web
          tarayıcılarıyla veya diğer teknik açıdan güncelliğini kaybetmiş cihazlarla kullanması ve
          bunun sonucunda hatalar, görüntüleme hataları veya diğer yanlış verilerin iletilmesi
          durumunda Game90 hiçbir sorumluluk kabul etmez. <br />
          <br />
          6.2.2 Müşteriler, uç cihazların doğru şekilde çalışmasından sorumludur ve bağımsız olarak
          görüntüleme hatalarının hariç tutulmasını sağlamalıdır. Aksi yöndeki rezervasyonlar madde
          5.2.1 uyarınca onaylanırsa Game90, uygulamaların ve web sitesinin görüntülenmesindeki
          hatalardan sorumlu değildir. Örnek: Bir görselde oranlar 2,30 olarak gösteriliyor ancak
          gerçek oranlar 1,60 olarak onaylandı ve madde uyarınca oyuncu hesabında görüntülendi.
          5.2.1. Müşteriler daha yüksek kotayı talep edemez. <br />
          <br />
          6.3 Game90 web sitesine veya uygulamalarına erişim, onarım veya bakım çalışmalarını veya
          yeni tekliflerin sunulmasını sağlamak amacıyla zaman zaman kısıtlanabilir. Böyle bir
          durumda Şirket mümkün olan en kısa sürede çevrimiçi hizmeti yeniden sağlamaya
          çalışacaktır. <br />
          <br />
          6.3.1 Müşterilerin, erişimin kısıtlı olduğu zamanlarda ortaya çıkabilecek olası kar
          kayıplarına ilişkin herhangi bir yasal hakkı bulunmamaktadır. <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 className="mt-75" id="7">
          7. Sorumluluğun Sınırlandırılması
        </h5>
        <p className="mt-3">
          7.1 Müşteriler kaydolarak Game90 web sitesini ve Game90 tarafından oluşturulan ve
          onaylanan uygulamalarını, riskleri kendilerine ait olmak üzere ve gönüllü olarak
          kullandıklarını kabul ederler. <br />
          <br />
          7.2 Şirket, Game90 web sitesi aracılığıyla yüklenen veya aktarılan hiçbir veri ve içerik
          kaybından sorumlu değildir. Game90&apos;ın veya Game90&apos;a ait başka bir Şirketin,
          Game90 web sitesinde yapılan değişikliklerden veya Game90&apos;ın geçici veya kalıcı
          olarak devre dışı bırakılmasından kaynaklanan hiçbir tür zarardan sorumlu olmadığını kabul
          edersiniz. İnternet sitesi.
          <br />
          <br /> 7.3 Şirket, Game90 web sitesini herhangi bir zamanda tamamen veya kısmen durdurma
          hakkını saklı tutar. Bu hakkın kullanılması halinde, müşterilerin tazminat talepleri veya
          diğer talepler için hiçbir dayanağı olmayacaktır. <br />
          <br />
          7.4 Şirket, Game90 web sitesinin çalışması sırasındaki yazım hatalarından veya teknik ve
          insani hatalardan sorumlu değildir. Bir hata durumunda Şirket, etkilenen bahisleri
          geçersiz ilan etme veya hatayı düzeltme hakkını saklı tutar. Şirket tarafından yapılan bir
          düzeltmenin bahis şartlarını etkilemesi durumunda, müşterilere bahis düzeltilmiş
          sayılmadan önce değişen şartları kabul etme fırsatı verilecektir. <br />
          <br />
          7.5 Game90&apos;ın bu genel Hüküm ve Koşulları ihlal etmesi halinde, Game90 yalnızca
          bundan kaynaklanan doğrudan zararlardan sorumlu olacaktır. Bunun önkoşulu, müşterilerin
          ayrıca Şartlar ve Koşulların en son sürümünü daha önce tanımış olmaları ve daha eski bir
          sürüme başvurmalarına izin verilmemesidir. <br />
          <br /> 7.6 Önceki paragraf uyarınca herhangi bir tazminat, ilgili bahis kuponundaki
          maksimum kazanç miktarıyla sınırlıdır (ayrıca bkz. 5.2.1). <br />
          <br /> 7.7 Şirket, Game90 web sitesi veya içeriğinin neden olduğu iddia edilen dolaylı
          sonuçsal hasar veya kayıplardan hiçbir durumda sorumlu tutulamaz. Buna özellikle
          aşağıdakiler dahildir: çalışma veya iletimde gecikmeler veya kesintiler; Veri iletim
          hatlarının arızalanması; Web sitesinin, hizmetlerinin veya içeriğinin genel olarak
          müşteriler veya üçüncü kişiler tarafından kullanılması veya kötüye kullanılması; web
          sitesinde yanlış veya eksik bilgi; kaybı iş, kar kaybı, işin kesintiye uğraması, iş
          bilgilerinin kaybı veya mali, sonuç olarak ortaya çıkan zararlar da dahil olmak üzere
          diğer zararlar (ayrıca bkz. madde 6.2). <br />
          <br /> 7.8 Game90, kontrolü dışındaki koşullar nedeniyle bu Hüküm ve Koşulların ihlal
          edilmesinden sorumlu değildir. <br />
          <br /> 7.9 Şirket, herhangi bir zamanda web sitesini ve hizmetlerini tamamen veya kısmen
          durdurma hakkını saklı tutar. Böyle bir durumda müşteriler, müşteri hesabındaki kredi
          bakiyesi haricinde, ödemeye ilişkin her türlü yasal talepten feragat eder. <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 className="mt-75" id="8">
          8. Fikri mülkiyet hakları
        </h5>
        <p className="mt-3">
          Şirket, müşterilere Web Sitesinden indirilen bilgisayar programlarını (yazılım) kullanma
          ve buradan türetilen tüm içeriği yalnızca bu hizmetlerle bağlantılı olarak ve bu Koşullara
          uygun olarak yükleme konusunda münhasır olmayan, devredilemez ve lisanslanamaz bir hak
          verir. Koşullar. Buna telif hakları ve fikri mülkiyet hakları da dahildir. Müşteriler,
          yazılımı bir sabit sürücüye veya başka bir depolama aygıtına yükleyebilir ve hizmetin
          birincil kullanıcıları müşterilerin kendileri olduğu bir bilgisayarda kişisel kullanım
          için kullanılmasıyla bağlantılı olarak yedek kopyalar oluşturabilir. <br />
          <br />
          8.1 Müşteriler şunları yapamaz: <br />
          <br />
          8.1.1 Yazılımı bu amaçla kullanamaz, kopyalayamaz veya değiştiremez, bundan türetilmiş
          çalışmalar oluşturamaz veya yukarıda belirtilen eylemlerin yasal olduğu durumlar
          haricinde, yazılımı kısmen veya tamamen değişiklikler, transkripsiyonlar veya
          birleştirilmiş parçalarla dağıtamazsınız. izin verildi; <br />
          <br />
          8.1.2 yukarıda belirtilen eylemlere yasaların izin verdiği durumlar dışında, yazılımın
          kodunu çözmek, yeniden oluşturmak, parçalara ayırmak, kaynak koda dönüştürmek, tercüme
          etmek veya yazılımı veya herhangi bir parçasını başka şekilde dönüştürmek veya
          dönüştürmek; <br />
          <br />
          8.1.3 yazılımı üçüncü kişilere devretmek, ödünç vermek, kiralamak, devretmek, kiralamak
          veya başka şekilde lisanslamak partiler; <br />
          <br />
          8.1.4 yazılımdan telif haklarına, mülkiyete veya benzer bildirimlere yapılan atıfları
          kaldırın (veya bunların kopyaları);
          <br />
          <br /> 8.1.5 yazılımı bir bilgisayar ağı üzerinden veya başka türlü. <br />
          <br />
          8.2 Game90 markası, game90.bet web sitesi ve Şirket tarafından kullanılan diğer tüm
          markalar, hizmet markaları veya ticari isimler (ticari markalar) ve Game90 web sitesindeki
          tüm materyaller (özellikle tüm yazılım, metinler, süreçler, konseptler, resimler,
          grafikler, video ve ses) Şirkete aittir, fikri mülkiyet haklarını içerir ve telif hakkı
          yasasına tabidir. Burada adı geçen hiçbir ticari marka veya materyal üzerinde hiçbir
          hakkınız olmadığını ve Game90 web sitesini kullanmanızın size bu tür haklara ilişkin
          herhangi bir yetki vermediğini kabul edersiniz. Bu markaların Şirketin önceden izni
          olmadan kullanılması açıkça yasaktır. <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 className="mt-75" id="9">
          9. Bildirim gereksinimi
        </h5>
        <p className="mt-3">
          gereklilik Bir bahisle ilgili sonuç, karar veya başka bir eylemle ilgili bir anlaşmazlık
          olması durumunda, olaydan sonraki 14 iş günü içinde support@game90.bet adresine e-posta
          yoluyla veya yazılı olarak aşağıdaki adrese yazılı olarak şikayette bulunulmalıdır: the
          Şirket: Game90 Ltd., Melita Court, Level 1, Giuseppe Cali St c/w Abate Rigord St, XBX1420,
          TaXbiex, Malta. Şirketten gelen tüm yanıtlar müşterilere gönderilecektir. Kayıt sırasında
          verilen adres (bu Koşullarda aksi belirtilmedikçe ve Koşullar). <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 className="mt-75" id="10">
          10. Genel hükümler
        </h5>
        <p className="mt-3">
          10.1 Bu genel Şartlar ve Koşullar, şu anda geçerli olan versiyonlarıyla, Game90 web
          sitesinin ve hizmetlerinin kullanımına ilişkin olarak müşteri ile Şirket arasındaki iş
          ilişkisini düzenlemektedir. <br />
          <br />
          10.2 Müşteri, bu koşulları kabul etmekle bunlara muvafakat ettiğini mevcut haliyle açıkça
          beyan eder. Müşteriler ayrıca herhangi bir yan anlaşma yapılmadığını da beyan ederler.
          Ayrıca, söz konusu bilgilerin bu genel Hüküm ve Koşulların ayrılmaz bir parçası haline
          gelip gelmediğine bakılmaksızın, müşteriler belirsiz formülasyonlara güvenmemelidir.
          <br />
          <br />
          10.3 Bu Şartlar ve Koşullar kapsamında müşterilere tanınan haklar üçüncü şahıslara
          devredilemez. Şirket, müşterilerin haklarına zarar vermemek kaydıyla, işbu Sözleşme
          kapsamındaki hak ve yükümlülüklerini üçüncü kişilere devredebilir. 10.4 Bu Hüküm ve
          Koşullar, üçüncü bir tarafın kendisinden yasal hak talebinde bulunabileceğini açıkça
          belirtmiyorsa, bu Hüküm ve Koşullar ve sonraki tüm sözleşmelerden kaynaklanan hukuki
          ilişki yalnızca müşteri ile Şirket arasında mevcut olacaktır. Bu koşullar altında üçüncü
          şahısların tüm yasal talepleri hariçtir. 10.5 Bu şartların herhangi bir kısmının herhangi
          bir nedenle geçersiz veya uygulanamaz bulunması halinde, bu hüküm diğerlerinden ayrı
          olarak geçerli olacak ve geri kalan hükümlerin geçerliliğini veya uygulanabilirliğini
          etkilemeyecektir. 10.6 Şirketin bu şartların herhangi bir kısmından feragat etmesi
          yalnızca belirli bir olay için geçerli olacak ve bu şartları değiştirmeyecek veya
          taleplerden genel bir feragat teşkil etmeyecektir. 10.7 Bu genel Hüküm ve Koşullar hiçbir
          şekilde müşteri ile Şirket arasında bir temsilcilik, ortaklık veya başka türde bir ortak
          girişim teşkil etmez. 10.8 Bu Hüküm ve Koşullar ile bu Hükümlerle ilgili diğer belgeler
          arasında herhangi bir çelişki olması durumunda, bu Genel Hüküm ve Koşullar geçerli
          olacaktır. <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 className="mt-75" id="11">
          11. Bahis koyma kuralları / spor kuralları
        </h5>
        <p className="mt-3">
          Bahisler / Spor Kuralları 11.1 Bahis koyma kuralları Bu genel Şartlar ve Koşullar, diğer
          şeylerin yanı sıra, web sitesinde veya uygulamalarda sunulan hizmetleri, oyun kurallarını
          ve bahislerin tarafımızca işlenmesini düzenleyen bahis ve spor kurallarını içerir. şirket.
          Müşteriler, bu Şartlar ve Koşulları kabul ederek, bahis kuralları ve düzenlemelerinin
          güncel versiyonunun okunduğunu, anlaşıldığını ve kabul edildiğini beyan eder.{' '}
        </p>
        <h6 className="mt-3">
          11.2 §7 GlüStV&apos;ye göre oyunla ilgili bilgiler, maliyetler, bahis oranları ve
          kazançlar
        </h6>
        <div>
          Tüm oyunla ilgili bilgiler, örneğin:
          <ol className="mt-3">
            <li key={90} className="mt-3">
              Katılım maliyetleri
            </li>
            <li key={91} className="mt-3">
              Ödül miktarı
            </li>
            <li key={92} className="mt-3">
              Kârın sunumu
            </li>
            <li key={93} className="mt-3">
              Ödeme yüzdesi
            </li>
            <li key={94} className="mt-3">
              Bahis yatırma için son tarih
            </li>
            <li key={95} className="mt-3">
              Kârın belirlenmesi
            </li>
            <li key={96} className="mt-3">
              Kazançların kredilendirilmesi
            </li>
            <li key={97}>Bahis operatörü</li>
            <li key={98} className="mt-3">
              Ticaret sicil numarası
            </li>
            <li key={99} className="mt-3">
              Oyuncuların nasıl şikayette bulunabileceği
            </li>
            <li key={100} className="mt-3">
              İzin tarihi,
            </li>
          </ol>
          mümkünse bahis oynanmadan önce ve sonra istenildiği zaman görüntülenebilir. Müşteri
          hesabında ve web sitesinde sanal bahis kuponu. Tüm bahis etkinliklerinin sonuçları, resmi
          değerlendirmenin ardından çevrimiçi portalda yayınlanır. Ortaya çıkabilecek her türlü soru
          veya şikayet için müşteriye, bahis şirketi için açıkça görülebilen bir iletişim seçeneği
          ve istediği zaman resmi bir iletişim noktası sağlanır. <br />
          <a href="#top">Başa dön</a>
        </div>
      </div>
      <div>
        <h5 className="mt-75" id="12">
          12. Bonus kuralı
        </h5>
        <p className="mt-3">
          12.1 Bonus düzenlemesi ayrıca listelenen bonus koşullarında açıklanmıştır. Game90, genel
          bonus kurallarına göre değerlendirilen belirli koşullar altında bonuslar verir. Bonus
          koşullarından bağımsız olarak bu Şartlar ve Koşullar geçerli olmaya devam edecektir.
          <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 className="mt-75" id="13">
          13. Sorumlu oyun
        </h5>
        <p className="mt-3">
          oyun 13.1 Tüm kullanıcılar, işlemlerin sorumlu bir şekilde yapılması gerektiğinin
          bilincinde olmalıdır. Game90&apos;ın yalnızca spor etkinliklerine bahis sunduğunu ve bu
          etkinlikleri etkileyemeyeceğini kullanıcıların anlaması önemlidir. Bahislerde ayrıca
          bahsin sonucunu belirleyen bir rastgele oluşturucu da yoktur. Bir bahisin hangi sonuca
          yapılacağına ve bunun olumlu bir sonuçla sonuçlanıp sonuçlanmayacağına karar vermek
          kullanıcılara kalmıştır. 13.2 Game90, sorunlu kumar davranışı konusunda farkındalığı
          teşvik ederek ve önleme, müdahale ve tedaviyi geliştirerek sorumlu bahisleri desteklemeye
          kendini adamıştır. 13.3 Game90s Sorumlu Oyun İlkeleri, sorunlu kumarın olumsuz etkilerini
          en aza indirme ve sorumlu oyun uygulamalarını teşvik etme taahhüdümüzü belgelemektedir.
          13.4 Game90, sorumlu oyun davranışıyla ilgilenmek için, kayıt olmadan bile her zaman tek
          tıklamayla erişilebilen kendi kalıcı sayfasını yayınladı. Maddesine göre tüm alt sayfalar
          hariç 5.3.1.1. Bu sayfa tamamen geçerlidir ve sorunlu oyun davranışlarıyla başa çıkmak
          için en son bilgileri ve yardımı içerir. 13.4.1 Game90, § 8. GlüStV 2021&apos;e göre bir
          engelleme sistemi kullanır. Buna OASIS adı verilir ve Darmstadt Bölge Konseyi tarafından
          temsil edilen Hessen federal eyaleti tarafından yönetilir. 13.4.2 Game90, müşterilerini
          kayıt olurken ve her para yatırma işleminde bu OASIS sistemindeki olası tıkanmalara karşı
          kontrol edecektir. 13.4.3 Listelenen ve engellenen oyuncular, durum engelleme dosyasında
          kayıtlı olmaları halinde Game90&apos;da başka herhangi bir faaliyet gerçekleştiremezler.
          13.4.4 OASIS üzerinden yapılan bloke en az 3 ay geçerlidir ve yalnızca yetkili makam
          tarafından tekrar kaldırılabilir. Bu, sağlam temellere dayanan bir açıklamayla yazılı
          olarak yapılmalıdır. Hiçbir istisna mümkün değildir (ayrıca bkz. 2.7.3 ve 2.7.7). 13.4.5
          Bu durumda, OASIS&apos;te saklanan tüm verilerden Hessen Eyaleti Darmstadt Bölge Konseyi
          sorumludur. <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 className="mt-75" id="14">
          14. Veri koruma
        </h5>
        <p className="mt-3">
          Kişisel müşteri verilerinin kullanımı ve yönetimine ilişkin ayrıntılı bilgi, veri koruma
          kılavuzumuzda ve bu Hüküm ve Koşulların 2.3 numaralı maddesinde bulunabilir. Müşteri bu
          şartları kabul ederek gizlilik politikasını okuduğunu, kabul ettiğini ve anladığını beyan
          eder. <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 className="mt-75" id="15">
          15. Genel
        </h5>
        <p className="mt-3">
          15.1 Bu Sözleşmenin yorumlanması, geçerliliği ve uygulanması Malta yasalarına tabi
          olacaktır. 15.2 Şartlar ve Koşullarımızın tüm versiyonlarının aynı ilkeleri yansıtması
          amaçlanmaktadır. Bu Hüküm ve Koşulların İngilizce olmayan versiyonu ile İngilizce
          versiyonu arasında pek nadir de olsa bir tutarsızlık olması durumunda, İngilizce versiyonu
          geçerli olacaktır. 15.3 Game90 Limited&apos;in yargı yeri Malta&apos;dır.
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 className="mt-75" id="16">
          16. ADR - Alternatif Uyuşmazlık Çözümü (ADR)
        </h5>
        <p className="mt-3">
          Uyuşmazlık Çözümü (ADR) 16.1 Game90, sunulan hizmetle kullanıcı deneyimini mümkün olduğu
          kadar keyifli hale getirmeye çalışmaktadır. Ancak bir oyuncunun kalite, hizmet veya
          müşteri desteğinden memnun olmadığı zamanlar olabilir. Bir oyuncu daha sonra
          support@game90.bet adresinden Game90 müşteri hizmetlerine bir e-posta göndererek şikayette
          bulunabilir. Şikayet yönetime iletilecek ve Game90 şikayetin alınmasından itibaren 48 saat
          içinde konuyla ilgilenmeye çalışacaktır. Şikayetin sonucu, tam olarak ulaştığı tarihten
          itibaren 10 gün içerisinde kullanıcıya bildirilecektir. Bazı durumlarda bu süre 10 gün
          daha uzatılabilir. 16.2 Kullanıcılar, şikayetin Game90s müşteri hizmetleri ekibi
          tarafından ele alınma veya çözümlenme şeklinden memnun değilse, müşteriler alternatif
          olarak hizmet sağlayıcının EADR bağlantısını tıklayarak anlaşmazlıklarını Alternatif
          Uyuşmazlık Çözümü (ADR) birimine iletebilirler: https:// /eadr.org/eadr-form/.
          Kullanıcılar Game90&apos;dan nihai kararı aldıktan sonra, kullanıcılar bu ADR&apos;ye
          erişme ve anlaşmazlıklarını oraya yönlendirme hakkına sahiptir. 16.2.1 İkinci adımda,
          kullanıcılar tüm ayrıntıları https://eadr.org/eadr-form/ adresindeki forma girerler ve
          Game90 Ltd.&apos;ye yönelik endişelerden sorumlu olan hizmet sağlayıcı EADR ile iletişime
          geçerler. 16.3 Kullanıcılar karardan memnun kalmazlarsa support.mga@org.mt veya
          https://www.mga.org.mt/support/Online-Gaming-support - Malta Oyun Otoritesi&apos;nin
          oyuncu destek sitesi ile iletişime geçebilirler. (MGA) - yardım için. Ancak bunun
          önkoşulu, ADR aracılığıyla (bu durumda EADR.org hizmet sağlayıcısı aracılığıyla) önceden
          iletişime geçilmesidir. <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
      <div>
        <h5 className="mt-75" id="17">
          17. Yürürlük Tarihi
        </h5>
        <p className="mt-3">
          Bu Şartlar ve Koşullar, 1 Ekim 2021&apos;de Orta Avrupa Standart Saatine göre gece
          yarısında (00:00) yürürlüğe girecektir. Sürüm: 4.3.9. <br />
          <a href="#top">Başa dön</a>
        </p>
      </div>
    </div>
  );
}
