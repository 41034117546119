export const EOddsTransformerRounding = {
  Middle: 'middle',
  Down: 'down',
  Up: 'up'
};

export const oddsLimits = {
  preMatchMax: 100.0,
  liveMax: 100,
  outRightsMax: 100.0,
  minOddsValueAllowedAfterOddsTransformation: 1.01
};

export const oddsInverseMultipliers = {
  beforeStart: {
    hours: 12,
    value: 1.0
  },
  nearToStart: {
    a: {
      markets: new Set([1, 7, 18]),
      value: 1.0
    },
    b: {
      markets: null,
      value: 1.0
    }
  },
  live: {
    value: 1.0
  }
};

export const oddsLadderStages = [
  {
    start: 1.0,
    end: 1.3,
    increment: 0.01,
    round: EOddsTransformerRounding.Down,
    tolerance: 25 / 100
  },
  {
    start: 1.3,
    end: 1.5,
    increment: 0.02,
    round: EOddsTransformerRounding.Down,
    tolerance: 25 / 100
  },
  {
    start: 1.5,
    end: 3.0,
    increment: 0.05,
    round: EOddsTransformerRounding.Down,
    tolerance: 25 / 100
  },
  {
    start: 3.0,
    end: 10.0,
    increment: 0.1,
    round: EOddsTransformerRounding.Down,
    tolerance: 25 / 100
  },
  {
    start: 10,
    end: 50,
    increment: 1,
    round: EOddsTransformerRounding.Down,
    tolerance: 25 / 100
  },
  {
    start: 50,
    end: 100,
    increment: 5,
    round: EOddsTransformerRounding.Down,
    tolerance: 25 / 100
  },
  {
    start: 100,
    end: 100000,
    increment: 10,
    round: EOddsTransformerRounding.Down,
    tolerance: 25 / 100
  }
];
