export default function TR() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <h4 className="text-danger mt-75 "> Gizlilik Politikası</h4>
      <div className="mt-3">
        Game90 kişisel bilgilerinizin korunmasını çok ciddiye alır. Bu Gizlilik Politikası, veri
        işleme uygulamalarımızı ve kişisel bilgilerinizin kullanımına ilişkin tercihlerinizi
        açıklamaktadır. Game90, GDPR Madde 6 (1) (f) uyarınca verilerinizi toplamak konusunda meşru
        menfaate sahiptir. Kişisel verilerinizin işlenmesine ilişkin sorularınız ve istekleriniz
        için lütfen support@game90.bet adresinden veya doğrudan sorularınız için DPO@game90.bet
        adresinden bizimle iletişime geçin.
        <a id="top" />
        <hr className="mt-75" />
        <ol className="mt-3">
          <li key={1}>
            <a href="#stored-information"> Saklanan bilgiler</a>
          </li>
          <li anahtar={2}>
            <a href="#personal-information"> Kişisel bilgilerin kullanımı</a>
          </li>
          <li anahtar={3}>
            <a href="#marketing-preferences"> Pazarlama tercihleri</a>
          </li>
          <li anahtar={4}>
            <a href="#cookies"> Çerezlerin kullanımı</a>
          </li>
          <li anahtar={5}>
            <a href="#discloses"> Açıklamalar</a>
          </li>
          <li anahtar={6}>
            <a href="#other-websites"> Diğer web siteleri</a>
          </li>
          <li anahtar={7}>
            <a href="#right-access"> Erişim hakkı</a>
          </li>
          <li anahtar={8}>
            <a href="#data-transmission"> İnternet tabanlı veri iletimi</a>
          </li>
          <li anahtar={9}>
            <a href="#international-applicable"> Uluslararası yargı yetkisi ve geçerli yasa</a>
          </li>
        </ol>
      </div>

      <div className="mt-75">
        <h5 id="stored-information">1. Saklanan bilgiler </h5>
        <p className="mt-3">
          Game90s ödeme ve risk departmanları, her oyuncunun tamamen kişisel bilgilerini yöneten ve
          işleyen uygun departmanlardır.
          <br /> <br />
          Verilerin sorgulanması, örneğin &quot;Ciddi suçlardan elde edilen kazançların tespitine
          ilişkin Kanun&quot; (Federal Almanya Kara Para Aklama Kanunu GwG) veya Avusturya
          Cumhuriyeti Mali Piyasalar Kara Para Aklama Kanunu&apos;nda açıklanan yasal hükümlerden
          oluşur. .
          <br /> <br />
          Kaydınızla, verilerinizin Genel Veri Koruma Yönetmeliği çerçevesinde işlenmesini,
          saklanmasını ve ayrıca kullanılmasını gönüllü olarak kabul etmiş olursunuz. Gizlilik
          Politikası uyarınca tarafımıza gönüllü olarak ve işlenmek üzere sağladığınız kişisel
          bilgileriniz, kamuya açık olmayan ve şifrelenmiş medya ve/veya sunucularda saklanacaktır.
          Game90 ayrıca verileri ayrı ayrı şifreleme hakkını da saklı tutar.
          <br /> <br />
          Game90 ayrıca, herhangi bir olasılığa karşı doğrulama ve koruma amacıyla, ödeme hizmeti
          sağlayıcıları, finansal kurumlar ve diğer ilgili veri işleme kuruluşları dahil ancak
          bunlarla sınırlı olmamak üzere, kişisel verileri üçüncü taraflara (sözde işlemciler veya
          sipariş işlemcileri) aktarma hakkını saklı tutar. Game90&apos;ın gerekli görmesi halinde
          hizmetlerini kötüye kullanmak ve/veya kullanmak.
          <br /> <br />
          Ayrıca Game90, oyuncu hesabıyla ilgili herhangi bir usulsüzlük olması durumunda oyuncunun
          kişisel verilerini bu gibi durumlarda açıklama hakkını saklı tutar. Kişisel verilerin
          devlet kurumlarına ve devlet yetkililerine ifşa edilmesi yasa ve yönetmeliklere tabi
          olabilir ve Game90&apos;ın bu tür verileri talep edildiği şekilde uygun devlet
          yetkililerine iletmesini gerektirebilir. Verilerinizin devlet yetkililerine ve hükümete
          iletilmesi konusunda sizi bilgilendirme yükümlülüğü de yasal düzenlemeler tarafından
          yasaklanabilir.
          <br /> <br />
          game90.bet web sitesini ve bağlantılı uygulamaları ziyaret edenlerin kişisel bilgilerini
          çevrimiçi formlar aracılığıyla ve veriler e-postayla gönderildiğinde saklıyoruz. Ayrıca
          (güvenli ödeme işleyicilerimiz aracılığıyla), kullanılan ödeme yöntemlerinin ayrıntıları
          da dahil olmak üzere, yapılmış ve yapılmış tüm işlemlere ilişkin bilgileri de saklarız.
          Ayrıca web sitemize yapılan her ziyaretle ilgili ek bilgileri otomatik olarak topluyoruz.
          <br /> <br />
          Verilerinizin aktarımına ilişkin onayınız ve bu verileri kullanabileceğimize ilişkin
          ilgili izniniz olmadan, Game90&apos;a kayıt mümkün olmayacaktır. Yasal olarak öngörülen
          süreçler belirli alanlarda veri işlenmesini öngördüğünden, hizmetlerimizi sizin izniniz
          olmadan kullanmanız mümkün değildir. Onayın geri çekilmesi aynı zamanda oyuncu hesabınızın
          bloke edilmesini de gerektirir. En azından yeni bir onay verilene kadar. Daha önce
          işlenmiş veriler, onayın geri çekilmesinden etkilenmez.
        </p>
        <h6 className="fw-bolder mt-3"> Verilerin kaydedilmesi</h6>
        <p className="mt-3">
          Web sitemiz aracılığıyla veri toplanması, Game90&apos;ın Şartlar ve Koşulları
          2.2.1&apos;de listelendiği gibi kaydolurken, e-posta adreslerimize e-posta gönderirken ve
          sohbet sistemimiz aracılığıyla bizimle iletişim kurarken kişisel verilerinizin
          girilmesiyle gerçekleşir. veya iletişim formu.
          <br /> <br />
          Sayfalarımıza veya uygulamalarımıza yaptığınız her ziyaretinizde aşağıdaki veriler
          toplanır:
          <li key={10}> IP adresiniz ve dolayısıyla konumunuz </li>
          <li key={11}> Bizimle oturum açtığınız zaman </li>
          <li anahtar={12}>
            Bize zaten kaydolduğunuz sürece çerezlerimiz tarafından oluşturulan verilerinizin
            atanması, aksi takdirde hiçbir kişisel veri dahil edilmez
          </li>
          <li anahtar={13}>
            Reklam medyalarımızdan veya bağlı kuruluşlarımızdan biri aracılığıyla bize gelirseniz,
            tanınma ayrıntıları (reklam aracını oyuncu hesabınıza atayan kodlu numara - kişisel
            veriler veya isim ayrıntıları vb. iletilmez)
          </li>
          <li anahtar={14}>
            Kullanıcı aracısı, tarayıcınıza varsayılan olarak gönderilir ve işletim sisteminizi,
            kullandığınız tarayıcınızı ve güncel sürümünü bildirir.
          </li>
        </p>
        <h6 className="fw-bolder mt-15"> E-posta</h6>
        <p className="mt-3">
          Bize bir e-posta gönderirseniz, yukarıdaki verilere ek olarak, kullandığınız e-posta
          adresinizi ve e-postada sağladığınız verileri de alacağız. Belgeleri e-posta sistemimiz
          aracılığıyla gönderirken veriler ayrı bir güvenli depolama ortamında/sunucuda saklanır ve
          şifrelenerek şifrelenir.
        </p>
        <br /> <h6 className="fw-bolder mt-15"> İletişim formu</h6>
        <p className="mt-3">
          Web sitemizde size bir sohbet sisteminin yanı sıra, desteğimize yazılı olarak
          ulaşabileceğiniz bir iletişim formu da sunuyoruz. Bu form yalnızca sizin tarafınızdan
          forma girdiğiniz verileri iletir. Form doğrudan sunucularımıza bağlıdır ve üçüncü bir
          şirkete aktarılmayacak veya tespit edilmeyecektir. İletişim bilgileri olmadan gönderilen
          bir form tarafınızca tahsis edilemeyecek olup, Genel Veri Koruma Yönetmeliği uyarınca
          derhal imha edilecektir.
        </p>
        <h6 className="fw-bolder mt-15"> Sohbet sistemi</h6>
        <p className="mt-3">
          Sohbet sistemimizde IP adresinin yanı sıra o an sitemizde hangi alt sayfaları ziyaret
          ettiğinizi ve sitemizde ne kadar süre kaldığınızı da görebiliriz.
          <br /> <br />
          Through special technology and software we have in use, we are also able to see your
          user-agent. This indicates which operating system you are using and which browser you are
          using in which version. However, personal data will not be displayed or transmitted except
          if you provide us these details during the chat.
        </p>
        <br /> <h6 className="fw-bolder mt-15"> Deposit (PSP)</h6>
        <p className="mt-3">
          Oyuncu hesabınıza para yatırıyorsanız bu işlemi farklı şekillerde yapabilirsiniz. Game90
          banka hesabımıza yapılan normal banka havalesi haricinde, depozito için ödeme hizmeti
          sağlayıcıları (PSP) olarak adlandırılanları kullanıyoruz. Bunlar sizinle bizim aramızda
          bulunur ve hizmetleri aracılığıyla neredeyse ikinci sınıf hızlı bir ödeme yapılmasına
          olanak tanır. Bu PSP&apos;lerin katı yasal düzenlemelere tabi olan kendi gizlilik
          politikaları vardır ve Game90&apos;ın bu politikalar üzerinde hiçbir etkisi yoktur. Lütfen
          para yatırırken tercih ettiğiniz PSP&apos;nin gizlilik bildirimini önceden okuyun.
        </p>
        <h6 className="fw-bolder mt-15"> Açılış Sayfaları</h6>
        <p className="mt-3">
          Örneğin reklam bültenlerimizden biri veya bir bağlı kuruluş aracılığıyla şirketimizin bir
          reklam veya açıklayıcı sayfasına (web sitesinin alt sayfaları - sözde Açılış Sayfaları)
          ulaşırsanız, reklamda bastığınız düğme olabilir. E-posta özel bir kodla sağlanır. Bu
          durumda sizden oraya bir şey (ilgi anketi veya benzeri) girmeniz istense bile bu açılış
          sayfasında listelenmesi gerekir. Bu aynı zamanda e-posta adresiniz veya tam adınız gibi
          kişisel bilgileriniz için de geçerli olabilir. Bu açılış sayfaları kendi sunucularımızda
          bulunur ve özel reklamlar söz konusu olduğunda veriler ilgili oyuncu hesaplarına atanır.
          Anketler kişisel bilgi toplamaz.
        </p>
        <a href="#top">Başa dön</a>
      </div>
      <div className="mt-75">
        <h5 id="personal-information">2. Kişisel bilgilerin kullanımı </h5>
        <p className="mt-3">
          Bu web sitesi ve bağlantılı uygulamalar aracılığıyla toplanan bilgileri aşağıdaki
          amaçlarla kullanacağız:
          <ul>
            <li key={15}>Tam Oyuncu Hesabı Yönetimi </li>
            <li key={16}>Hizmetlerimizin tamamının sağlanması ve kişiselleştirilmesi; </li>
            <li key={17}>İstek ve dileklerin işlenmesi; </li>
            <li key={18}>Yasal olarak gerekli doğrulama süreçleri</li>
            <li key={19}>
              Pazar araştırması çalışmaları yürütmek (ayrıca açılış sayfalarına bakın);
            </li>
            <li anahtar={20}>
              Ürünlerimiz, yeniliklerimiz ve hizmetlerimizin yanı sıra promosyonlarımız hakkında
              sizi bilgilendirmek için (e-posta veya diğer iletişim araçları aracılığıyla) sizinle
              iletişime geçmek;
            </li>
            <li anahtar={21}>
              Bilgilerinizi, ilginizi çekebileceğini düşündüğümüz ürün veya hizmetleri sunan
              kuruluşlara açıklayın (bu tür bilgileri almayı kabul ettiğinizi varsayarsak). Bir
              değerlendirme ve kategori (profil oluşturma) sonrasında oluşturulan kişisel veriler
              buraya iletilmez. Kişisel bilgileriniz Game90 tarafından hiçbir şekilde otomatik
              süreçlerle işlenmez ve/veya oluşturulmaz. Game90&apos;da, Madde 22 GDPR&apos;de
              açıklandığı gibi özel otomatik profil oluşturma işlemi gerçekleştirilmemektedir.
            </li>
          </ul>
        </p>
        <h6 className="fw-bolder"> 2.1 Bülten</h6>
        <p className="mt-3">
          Bültenimizi almak istiyorsanız, geçerli bir e-posta adresinin yanı sıra belirtilen e-posta
          adresinin sahibi olduğunuzu ve bu bülteni almayı kabul ettiğinizi doğrulamamıza olanak
          tanıyan bilgilere ihtiyacımız var. Hiçbir ek veri toplanmaz veya yalnızca gönüllülük
          esasına göre toplanır. Bu verileri yalnızca istenen bilgileri göndermek için kullanırız ve
          üçüncü taraflara aktarmayız.
          <br /> <br />
          Bu nedenle, iletişim formuna girdiğiniz her türlü veriyi yalnızca Madde uyarınca izniniz
          doğrultusunda işleyeceğiz. 6 (1) (a) DSGVO. Verilerinizin ve e-posta adresinizin
          saklanmasına ve bunların haber bülteni göndermek için kullanılmasına ilişkin onayınızı
          istediğiniz zaman iptal edebilirsiniz, örn. bültendeki abonelikten çıkma bağlantısı
          aracılığıyla. Talebinizi almadan önce işlenen veriler hâlâ yasal olarak işlenebilir.
          <br /> <br />
          Haber bültenine kaydolurken sağlanan veriler, siz aboneliğinizi iptal edene ve söz konusu
          veriler silinene kadar haber bülteninin dağıtımı için kullanılacaktır. Başka amaçlarla
          sakladığımız veriler (örn. üye alanına ait e-posta adresleri) etkilenmeden kalır.
        </p>
        <h6 className="fw-bolder"> MailChimp</h6>
        <p className="mt-3">
          Bu web sitesi haber bültenleri göndermek için MailChimp hizmetlerini kullanır. Bu hizmet
          Rocket Science Group LLC, 675 Ponce De Leon Ave NE, Suite 5000, Atlanta, GA 30308, ABD
          tarafından sağlanmaktadır.
          <br />
          <br />
          MailChimp, haber bültenlerinin dağıtımını organize eden ve analiz eden bir hizmettir.
          Bültenimize abone olmak için veri sağlarsanız (örneğin e-posta adresiniz), bu veriler
          ABD&apos;deki MailChimp sunucularında saklanacaktır.
          <br />
          <br />
          MailChimp, AB-ABD Gizlilik Kalkanı kapsamında sertifikalıdır. Gizlilik Kalkanı, Amerika
          Birleşik Devletleri&apos;ndeki Avrupa gizlilik standartlarına uyumu sağlamak amacıyla
          Avrupa Birliği (AB) ile ABD arasında yapılan bir anlaşmadır.
          <br />
          <br />
          Haber bülteni kampanyalarımızı analiz etmek için MailChimp&apos;i kullanıyoruz. MailChimp
          tarafından gönderilen bir e-postayı açtığınızda, e-postanın içerdiği bir dosya (web
          işaretçisi adı verilir) Amerika Birleşik Devletleri&apos;ndeki MailChimps sunucularına
          bağlanır. Bu, bir haber bülteni mesajının açılıp açılmadığını ve hangi bağlantılara
          tıkladığınızı belirlememize olanak tanır. Ayrıca teknik bilgiler de toplanır (örn. alma
          zamanı, IP adresi, tarayıcı türü ve işletim sistemi). Bu bilgiler belirli bir alıcıya
          atanamaz. Yalnızca bülten kampanyalarımızın istatistiksel analizi için kullanılır. Bu
          analizlerin sonuçları gelecekteki haber bültenlerini ilgi alanlarınıza daha iyi uyarlamak
          için kullanılabilir.
          <br />
          <br />
          Haber bülteni kullanımınızın MailChimp tarafından analiz edilmesini istemiyorsanız haber
          bülteni aboneliğinizi iptal etmeniz gerekecektir. Bu amaçla gönderdiğimiz her haber
          bülteninde bir bağlantı veriyoruz. Ayrıca doğrudan web sitesi üzerinden haber bülteni
          aboneliğinizi iptal edebilirsiniz.
          <br />
          <br />
          Veri işleme Sanat&apos;a dayanmaktadır. 6 (1) (a) GDPR. Haber bültenine aboneliğinizi
          iptal ederek onayınızı istediğiniz zaman iptal edebilirsiniz. Talebinizi almadan önce
          işlenen veriler hâlâ yasal olarak işlenebilir.
          <br />
          <br />
          Haber bültenine kaydolurken sağlanan veriler, siz aboneliğinizi iptal edene kadar haber
          bültenini dağıtmak için kullanılacak ve söz konusu veriler bizim sunucularımızdan ve
          MailChimp&apos;in sunucularından silinecektir. Başka amaçlarla sakladığımız veriler (örn.
          üye alanına ait e-posta adresleri) etkilenmeden kalır.
          <br />
          <br />
          Ayrıntılar için https://mailchimp.com/legal/terms/ adresindeki MailChimp gizlilik
          politikasına bakın
        </p>
        <h6 className="fw-bolder"> 2.2 Eklentiler ve araçlar</h6>
        <h6 className="fw-bolder"> YouTube</h6>
        <p className="mt-3">
          Web sitemiz, Google tarafından işletilen YouTube&apos;un eklentilerini kullanır.
          Sayfaların operatörü YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066, ABD&apos;dir.
          <br />
          <br />
          YouTube eklentisi içeren sayfalarımızdan birini ziyaret ettiğinizde YouTube sunucularıyla
          bağlantı kurulur. Burada YouTube sunucusuna hangi sayfalarımızı ziyaret ettiğiniz
          konusunda bilgi verilir. YouTube hesabınızda oturum açtıysanız YouTube, gezinme
          davranışınızı doğrudan kişisel profilinizle ilişkilendirmenize olanak tanır. YouTube
          hesabınızdan çıkış yaparak bunu önleyebilirsiniz. YouTube, web sitemizi çekici hale
          getirmeye yardımcı olmak için kullanılır. Bu, Sanat uyarınca haklı bir menfaat teşkil
          etmektedir. 6 (1) (f) GDPR.
          <br />
          <br />
          Kullanıcı verilerinin işlenmesine ilişkin daha fazla bilgiyi
          https://www.google.de/intl/de/policies/privacy adresindeki YouTube&apos;un veri koruma
          beyanında bulabilirsiniz.
        </p>
        <h6 className="fw-bolder"> Google Web Yazı Tipleri </h6>
        <p className="mt-3">
          Yazı tiplerinin tek tip gösterimi için bu sayfada Google tarafından sağlanan web yazı
          tipleri kullanılmaktadır. Bir sayfayı açtığınızda, tarayıcınız metinleri ve yazı tiplerini
          doğru şekilde görüntülemek için gerekli web yazı tiplerini tarayıcınızın önbelleğine
          yükler.
          <br />
          <br />
          Bu amaçla tarayıcınızın Google sunucularına doğrudan bağlantı kurması gerekir. Böylece
          Google, web sayfamıza IP adresiniz aracılığıyla erişildiğini fark eder. Google Web yazı
          tiplerinin kullanımı, web sitemizin tek tip ve ilgi çekici bir sunumu amacıyla yapılır.
          Bu, Sanat uyarınca haklı bir menfaat teşkil etmektedir. 6 (1) (f) GDPR. Tarayıcınız web
          yazı tiplerini desteklemiyorsa bilgisayarınız tarafından standart bir yazı tipi
          kullanılır.
          <br />
          <br />
          Kullanıcı verilerinin işlenmesine ilişkin daha fazla bilgiyi
          https://developers.google.com/fonts/faq adresinde ve
          https://www.google.com/policies/privacy/ adresindeki Google&apos;ın gizlilik politikasında
          bulabilirsiniz.
        </p>
        <a href="#top">Başa dön</a>
      </div>
      <div className="mt-75">
        <h5 id="marketing-preferences">3. Pazarlama tercihleri </h5>
        <p className="mt-3">
          Kayıt olurken lütfen uygun kutuyu işaretleyerek bizden veya şirket grubumuzdaki diğer
          şirketlerden ürün veya hizmetler hakkında bilgi almak isteyip istemediğinizi de belirtin.
          Her reklam mesajının sonundaki talimatları izleyerek veya bize support@game90.bet adresine
          bir e-posta göndererek bu tür iletişimlerin alınmasını istediğiniz zaman iptal
          edebilirsiniz.
          <br /> <br />
          Diğer şirketlere hangi verileri ilettiğimizi ve ilettiğimizi bilmek istiyorsanız, bunu
          istediğiniz zaman bilme hakkına sahipsiniz. DPO@game90.bet adresinden veri koruma
          görevlimizle iletişime geçmeniz yeterli.
        </p>
        <a href="#top">Başa dön</a>
      </div>
      <div className="mt-75">
        <h5 id="açıklamalar">4. Çerezlerin Kullanımı </h5>
        <p className="mt-3">
          Çerez, bir web sunucusundan bir web tarayıcısına gönderilen ve sunucunun tarayıcıdan bilgi
          almasına olanak tanıyan küçük bir bilgi birimidir. Web sitemizi ziyaret ettiğinizde sizi
          tanımlamak ve kaydınızın ayrıntılarını (örneğin kayıtlı oyun ayarları vb.) daha hızlı ve
          etkili bir şekilde hatırlayabilmek için kendi çerezlerimizi kullanırız. Çerezlerin
          kullanımı aynı zamanda kayıtlı kullanıcıların işlem yapmasına ve hesapları hakkındaki
          bilgilere erişmesine de olanak tanır. Çoğu tarayıcı çerezleri devre dışı bırakmanıza izin
          verir. Bunu nasıl yapacağınızı öğrenmek istiyorsanız lütfen tarayıcınızın yardım menüsüne
          bakın. Ancak çerezleri devre dışı bıraktığınızda web sitemize yaptığınız ziyaretler önemli
          ölçüde kısıtlanacak veya kullanılamaz hale gelecektir.
        </p>
        <h6 className="fw-bolder">4.1.Analiz</h6>
        <p className="mt-3">
          Bu web sitesi bir web analiz hizmeti olan Google Analytics&apos;i kullanmaktadır. Google
          Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, ABD tarafından işletilmektedir.
          Google Analytics, çerez adı verilen bilgileri kullanır. Bunlar, bilgisayarınızda saklanan
          ve web sitesinin tarafınızca kullanımının analizine olanak sağlayan metin dosyalarıdır. Bu
          web sitesini kullanımınız hakkında çerez tarafından oluşturulan bilgiler genellikle
          Google&apos;ın ABD&apos;deki bir sunucusuna iletilir ve orada saklanır. Google Analytics
          çerezleri, Sanat&apos;a göre saklanır. 6 (1) (f) GDPR. Web sitesi operatörünün, hem web
          sitesini hem de reklamlarını optimize etmek için kullanıcı davranışını analiz etme
          konusunda meşru menfaati vardır.
        </p>
        <h6 className="fw-bolder">IP anonimleştirme</h6>
        <p className="mt-3">
          Bu web sitesinde IP anonimleştirme özelliğini etkinleştirdik. IP adresiniz, Amerika
          Birleşik Devletleri&apos;ne iletilmeden önce Google tarafından Avrupa Birliği veya Avrupa
          Ekonomik Alanı Anlaşmasının diğer tarafları dahilinde kısaltılacaktır. Yalnızca istisnai
          durumlarda tam IP adresi ABD&apos;deki bir Google sunucusuna gönderilir ve orada
          kısaltılır. Google, bu bilgileri, bu web sitesinin operatörü adına, web sitesini
          kullanımınızı değerlendirmek, web sitesi etkinliği hakkında raporlar derlemek ve web
          sitesi operatörüne web sitesi etkinliği ve İnternet kullanımına ilişkin diğer hizmetleri
          sağlamak için kullanacaktır. Google Analytics&apos;in bir parçası olarak tarayıcınız
          tarafından iletilen IP adresi, Google&apos;ın elinde bulunan diğer verilerle
          birleştirilmeyecektir.
        </p>
        <h6 className="fw-bolder">Tarayıcı eklentisi</h6>
        <p className="mt-3">
          Tarayıcınızda uygun ayarları seçerek bu çerezlerin saklanmasını önleyebilirsiniz. Ancak
          bunu yapmanızın, bu web sitesinin tüm işlevlerinden yararlanamayacağınız anlamına
          gelebileceğini belirtmek isteriz. Ayrıca, aşağıdaki bağlantıda bulunan tarayıcı
          eklentisini indirip yükleyerek, web sitesini kullanımınıza ilişkin çerezler tarafından
          oluşturulan verilerin (IP adresiniz dahil) Google&apos;a aktarılmasını ve bu verilerin
          Google tarafından işlenmesini de önleyebilirsiniz:
          https://tools.google.com/dlpage/gaoptout?hl=tr.
        </p>
        <h6 className="fw-bolder">Verilerin toplanmasına itiraz etme</h6>
        <p className="mt-3">
          Web sitesinin altbilgisindeki “İzlemeyi devre dışı bırak” bağlantısını tıklayarak
          verilerinizin Google Analytics tarafından toplanmasını engelleyebilirsiniz. Bu siteye
          gelecekteki ziyaretlerinizde verilerinizin toplanmasını önlemek için bir devre dışı
          bırakma çerezi ayarlanacaktır.
          <br />
          Google Analytics&apos;in kullanıcı verilerini nasıl işlediği hakkında daha fazla bilgi
          için Google&apos;ın gizlilik politikasına bakın:
          https://support.google.com/analytics/answer/6004245?hl=tr.
        </p>
        <h6 className="fw-bolder">Dış kaynaklı veri işleme</h6>
        <p className="mt-3">
          Veri işleme süreçlerimizde dış kaynak kullanımı için Google ile bir anlaşma imzaladık ve
          Google Analytics&apos;i kullanırken Alman veri koruma makamlarının katı gerekliliklerini
          tam olarak uyguladık.
        </p>
        <h6 className="fw-bolder">Google Analytics tarafından demografik veri toplama</h6>
        <p className="mt-3">
          Bu web sitesi Google Analytics&apos;in demografik özelliklerini kullanmaktadır. Bu, site
          ziyaretçilerinin yaşı, cinsiyeti ve ilgi alanlarına ilişkin ifadeler içeren raporların
          oluşturulmasına olanak tanır. Bu veriler Google&apos;ın ilgi alanına dayalı reklamlarından
          ve üçüncü taraf ziyaretçi verilerinden gelir. Toplanan bu veriler belirli bir kişiye
          atfedilemez. Google hesabınızdaki reklam ayarlarını değiştirerek bu özelliği istediğiniz
          zaman devre dışı bırakabilir veya Veri toplamanın reddedilmesi bölümünde açıklandığı gibi
          verilerinizin Google Analytics tarafından toplanmasını yasaklayabilirsiniz.
          <br />
          <br />
          Game90 kısmen bahis platformunda ve kendi hizmetlerinde web analiz hizmeti Google
          Analytics&apos;i kullanıyor. Google Analytics ayrıca web sitesini kullanımınızın analizini
          kolaylaştırmak için çerezleri kullanır. Bu platformu kullanımınıza ilişkin çerezler
          tarafından oluşturulan bilgiler (IP adresiniz dahil) Google&apos;a iletilecek ve Amerika
          Birleşik Devletleri&apos;ndeki sunucularda saklanacaktır.
          <br />
          <br />
          Google bu bilgileri Platform kullanımınızı değerlendirmek, Platform Operatörlerinin
          faaliyetlerine ilişkin raporlar derlemek ve İnternet ile ilgili diğer hizmetleri sağlamak
          için kullanır.
          <br />
          <br />
          Google ayrıca kanunen gerekli görülmesi halinde veya üçüncü şahısların bu verileri Google
          adına işlemesi halinde bu bilgileri üçüncü şahıslara aktaracaktır. Google, IP adresinizi
          hiçbir zaman diğer Google verileriyle ilişkilendirmeyecektir. Tarayıcı yazılımınızı uygun
          şekilde ayarlayarak çerezlerin yüklenmesini engelleyebilirsiniz; ancak bu durumda web
          sitesinin tüm fonksiyonlarını tam anlamıyla kullanamayabileceğinizi belirtmek isteriz. Web
          sitesini kullanarak hakkınızda toplanan verilerin Google tarafından yukarıda açıklanan
          şekilde ve yukarıda belirtilen amaç doğrultusunda işlenmesini kabul etmiş olursunuz.
        </p>
        <a href="#top">Başa dön</a>
      </div>
      <div className="mt-75">
        <h5 id="marketing-preferences">5. Açıklamalar</h5>
        <p className="mt-3">
          Kişisel bilgileri yalnızca grubumuzdaki diğer şirketlere, iş ortaklarına, devlet
          kurumlarına ve kolluk kuvvetlerine, işimizin gelecekteki sahiplerine ve verileri işlemek
          için kullandığımız hizmet sağlayıcılara (işleyiciler) açıklıyoruz.
          <br />
          <br />
          Verilerinizin aktarımını kısıtlama veya verilerinizin silinmesi konusunda ısrar etme
          hakkına sahipsiniz. Bu hak, Genel Veri Koruma Yönetmeliği&apos;nin (GDPR) 17. ve 18.
          Maddelerinde açıklanan hususlar bağlamında tarafınıza garanti edilmektedir. İstisnalar,
          GDPR Madde 17.3.b&apos;ye göre toplanması ve minimum yasal saklama zorunluluğuyla
          işlenmesi gereken verilerdir. Verilerinizin sınırlandırılması ve/veya kısıtlanması ve/veya
          reddedilmesi ve silinmesi hakkı bu yasal hükümlerden etkilenebilir veya
          sınırlandırılabilir.
          <br />
          <br />
          Ayrıca GDPR&apos;nin 15. Maddesi kapsamında Game90&apos;da saklanan veriler hakkında bilgi
          alma ve bunları talep etme hakkına da sahipsiniz. Game90, verileri kişisel olarak size
          okunabilir bir formatta iletecektir. Bilgiler, saklanan kişisel verilerinizi ve kullanım
          türünü içerir. Saklanan veriler, kısıtlama veya silme hakkında bilgi için lütfen
          DPO@game90.bet ile iletişime geçin.
        </p>
        <a href="#top">Başa dön</a>
      </div>
      <div className="mt-75">
        <h5 id="other-websites">6. Diğer web siteleri</h5>
        <p className="mt-3">
          Uygulamamız ve web sitemiz, üzerinde hiçbir etkimiz olmayan ve Game90 Gizlilik
          Politikasının kapsamına girmeyen ve bizimkinden farklı olabilecek üçüncü taraf web
          sitelerine ve uygulamalarına (örneğin, sosyal medyaya veya düzenleyicilere) bağlantılar
          içerebilir. Bağlantı verilen web sayfalarının işlevselliğinden veya meydana gelebilecek
          olası hatalardan yalnızca bunların operatörleri sorumludur. Game90, diğer operatörlerin
          web sitelerinin yasal hükümleri veya gizlilik politikaları konusunda hiçbir sorumluluk
          kabul etmez. İçerik paylaşmak için kullanabileceğiniz sosyal ağlar, hangi sosyal ağlara
          üye olduğunuza ve üye hesabınızı ve cihazınızı nasıl yapılandırdığınıza bağlıdır. Böyle
          bir bağlantının etkinleştirilmesinden sonra, bağlantının çalıştırılması sırasında üçüncü
          tarafa aktarılan kişisel verilerin işlenmesi üzerinde artık hiçbir etkimiz kalmaz. Bu
          nedenle bu tür kişisel verilerin üçüncü şahıslar tarafından işlenmesi konusunda herhangi
          bir sorumluluk kabul edemeyiz. Daha fazla bilgi için kullandığınız sosyal ağların veya web
          sitelerinin gizlilik politikasına bakın. Game90&apos;ın ayrıca, küçük simgeler
          aracılığıyla ödeme hizmeti sağlayıcılarına (örn. Visa, Skrill vb.) veya yetkililere
          yönlendiren sayfaların gizlilik politikası üzerinde hiçbir etkisi yoktur. Bu özel
          bağlantılar kısmen kanunen zorunludur ve Game90 ilgili sayfaların içeriğinden veya
          gizlilik politikasından sorumlu değildir.
        </p>
        <a href="#top">Başa dön</a>
      </div>
      <div className="mt-75">
        <h5 id="right-access">7. Erişim hakkı</h5>
        <p className="mt-3">
          Game90s Hüküm ve Koşullarının 2.2.1 bölümünde listelenen kişisel bilgilerinize erişme
          hakkına sahipsiniz. Bu veriler, kayıtlı oyuncu hesabınızda istediğiniz zaman kişisel
          olarak ve basit bir şekilde görüntülenebilir. Bizde saklanan kişisel bilgilerinizin bir
          kopyasını almak için lütfen aşağıdaki adresten bizimle iletişime geçin: <br /> Game90 Ltd.
          <br />
          Melita Mahkemesi
          <br />
          Giuseppe Cali St ve Abate Rigord St
          <br />
          XBX1420 TaXbiex
          <br />
          Malta
          <br />
          Alternatif olarak, bizde saklanan verilere genel bir bakış için e-posta isteğinizi
          DPO@game90.bet adresine gönderin. Genel Veri Koruma Yönetmeliği&apos;nin (GDPR) 15.
          maddesine göre bu hak tarafınıza 3 ayda bir ücretsiz olarak verilmektedir. Her ek talep,
          çabaya bağlı olarak makul bir yönetim ücretiyle karşılanacaktır. Belgeler sizin için
          okunabilir bir formatta tarafınıza sunulacaktır. Gizlilik nedeniyle, tarafınızdan yapılan
          talebin ayrıca ve kesin olarak doğrulanması gerekmektedir.
        </p>
        <a href="#top">Başa dön</a>
      </div>
      <div className="mt-75">
        <h5 id="data-transmission">8. İnternet tabanlı veri iletimi</h5>
        <p className="mt-3">
          İnternet küresel bir ortam olduğundan, kişisel bilgilerin toplanması ve işlenmesi zorunlu
          olarak verilerin uluslararası bazda iletilmesini gerektirir. Web sitemizi kullanarak ve
          bizimle elektronik ortamda iletişim kurarak kişisel bilgilerinizi bu şekilde işlediğimizi
          onaylıyor ve kabul ediyorsunuz. Verileri işlemcilerimizden birine aktarırken, verileri
          şifreli olarak ileteceğiz.
        </p>
        <a href="#top">Başa dön</a>
      </div>
      <div className="mt-75">
        <h5 id="uluslararası-uygulanabilir">9. Uluslararası yargı yetkisi ve geçerli hukuk</h5>
        <p className="mt-3">
          Siz ve Game90 arasında bu Gizlilik Politikasının konusu ile ilgili olarak ortaya çıkan
          herhangi bir anlaşmazlık, kanunlar ihtilafı ilkelerine atıfta bulunulmaksızın Malta
          kanunlarına uygun olarak yönetilecek ve yorumlanacaktır ve Malta&apos;nın yetkili
          mahkemelerinin münhasır yargı yetkisine tabi olacaktır. Malta. Mevcut Gizlilik
          Politikasının bir hükmünün yetkili bir mahkeme kararıyla yasa dışı, geçersiz veya
          uygulanamaz olması nedeniyle iptal edilmesi durumunda, bu durum, tam olarak yürürlükte
          kalacak ve buna göre uygulanacak olan geri kalan hükümlerinin geçerliliğini ve
          uygulanabilirliğini etkilemeyecektir.
        </p>
        <a href="#top">Başa dön</a>
      </div>
    </div>
  );
}
