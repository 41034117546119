export function calculateMatchCountByLeagues(data, category) {
  let sayi = 0;
  const tournamentsArray = [];

  if (data != null) {
    tournamentsArray.push(data[category]);
  }

  if (tournamentsArray != []) {
    for (let index = 0; index < tournamentsArray.length; index++) {
      const tournamentValues = Object.values(tournamentsArray[index]);
      tournamentValues.forEach((element) => {
        sayi += element;
      });
    }
  }

  return sayi > 0 ? sayi : '-';
}

export function calculateSportCount(sportsCountData, sportType) {
  let sayi = 0;
  const categoryList = [];
  const tournamentsArray = [];

  //belirtilen spor tipindeki categorilerin alınması
  if (
    sportsCountData != null &&
    sportsCountData != undefined &&
    sportsCountData[sportType] != null &&
    sportsCountData[sportType] != undefined
  ) {
    Object.keys(sportsCountData[sportType]).map((category) => {
      categoryList.push(category);
    });
  }

  //alınan kategorilerin altındaki turnuva bilgilerinin alınması
  if (categoryList != []) {
    categoryList.forEach((cat) => {
      tournamentsArray.push(sportsCountData[sportType][cat]);
    });
  }
  // alınan turnuva bilgilerinin altındaki maç sayılarının alınarak toplam rakama ulaşılması
  if (tournamentsArray != []) {
    for (let index = 0; index < tournamentsArray.length; index++) {
      const tournamentValues = Object.values(tournamentsArray[index]);
      tournamentValues.forEach((element) => {
        sayi += element;
      });
    }
  }
  return sayi > 0 ? sayi : '-';
}
