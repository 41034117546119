export default function DE() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <h4 className="text-danger mt-75 "> DATENSCHUTZBESTIMMUNGEN</h4>
      <div className="mt-3">
        Game90 nimmt den Schutz deiner persönlichen Daten sehr ernst. Diese Datenschutzrichtlinie
        beschreibt unsere Datenverarbeitungspraktiken sowie deine Auswahlmöglichkeiten hinsichtlich
        der Verwendung deiner personenbezogenen Informationen. Game90 hat gemäß Artikel 6 (1)(f)
        DSGVO ein legitimes berechtigtes und erforderliches Interesse an der Speicherung und
        Verarbeitung von personenbezogenen Daten. Für deine Fragen und Wünsche zum Umgang mit deinen
        persönlichen Daten stehen wir dir gerne unter DPO@game90.bet zur Verfügung.
        <a id="top" />
        <hr className="mt-75" />
        <ol className="mt-3">
          <li key={1}>
            <a href="#stored-information"> Gespeicherte Informationen</a>
          </li>
          <li key={2}>
            <a href="#personal-information"> Verwendung persönlicher Daten</a>
          </li>
          <li key={3}>
            <a href="#marketing-preferences"> Marketingpräferenzen</a>
          </li>
          <li key={4}>
            <a href="#cookies"> Verwendung von Cookies</a>
          </li>
          <li key={5}>
            <a href="#disclosures"> Offenlegungen</a>
          </li>
          <li key={6}>
            <a href="#other-websites"> Andere Websites</a>
          </li>
          <li key={7}>
            <a href="#right-access"> Zugriffsrecht</a>
          </li>
          <li key={8}>
            <a href="#data-transmission"> Internetbasierte Datenübertragung</a>
          </li>
          <li key={9}>
            <a href="#international-applicable">
              Internationale Rechtsprechung und anwendbares Recht
            </a>
          </li>
        </ol>
      </div>
      <div className="mt-75">
        <h5 id="stored-information">1. Gespeicherte Informationen </h5>
        <p className="mt-3">
          Die Zahlungs- und Risikoabteilungen von Game90 sind die entsprechenden Abteilungen, die
          die persönlichen Daten jedes Spielers verwalten und verarbeiten. Die Erhebung von Daten
          erfolgt unter anderem durch gesetzliche Vorschriften, die beispielsweise im Gesetz für das
          Aufspüren von Gewinnen aus schweren Straftaten (Geldwäschegesetz GwG der Bundesrepublik
          Deutschland) oder dem Finanzmarkt-Geldwäschegesetz der Republik Österreich beschrieben
          sind. Mit deiner Registrierung stimmst du freiwillig der Verarbeitung und Speicherung
          sowie der weiteren Verwendung deiner Daten im Rahmen der Datenschutz-Grundverordnung zu.
          Gemäß den Datenschutzbestimmungen werden deine uns freiwillig und zur Verarbeitung zur
          Verfügung gestellten Kundendaten auf nicht öffentlich zugänglichen und auf durch
          Verschlüsselungen gesicherten Datenträgern und / oder Servern gespeichert. Game90 behält
          sich auch das Recht vor, die Daten einzeln zu verschlüsseln.
          <br /> <br />
          Game90 behält sich weiterhin das Recht vor, die persönlichen Daten an Dritte (sog.
          Verarbeiter oder Auftragsverarbeiter) – einschließlich, jedoch nicht ausschließlich
          hierauf beschränkt, Zahlungsdienstleister, Finanzinstitute und andere relevante
          datenverarbeitende Instanzen – zum Zweck der Überprüfung und zum Schutz vor jeglichem
          Missbrauch zu übertragen und / oder deren Service zu nutzen, wenn Game90 dies für
          erforderlich hält.
          <br /> <br />
          Des Weiteren behält sich Game90 das Recht vor, die persönlichen Daten eines Spielers
          diesen Instanzen gegenüber offenzulegen, sofern Unregelmäßigkeiten im Zusammenhang mit
          seinem Spielerkonto vorliegen. Die Weitergabe der personenbezogenen Daten an Behörden und
          staatliche Instanzen kann durch gesetzliche Bestimmungen und Verordnungen geregelt sein
          und verpflichtet Game90, diese Daten an die entsprechenden staatlichen Behörden nach deren
          Aufforderung weiterzuleiten. Eine Informationspflicht an dich über das Weiterleiten deiner
          Daten an staatliche Behörden und Instanzen kann ebenfalls durch gesetzliche Vorschriften
          untersagt sein.
          <br /> <br />
          Wir speichern persönliche Informationen von Besuchern der Website game90.bet und der
          angeschlossenen Applikationen über Online-Formulare und immer dann, wenn Daten per E-Mail
          übersendet werden. Wir speichern ebenfalls (mittels unserer sicheren Zahlungsprozessoren)
          Informationen über alle Transaktionen, die unternommen werden und wurden, einschließlich
          der Einzelheiten über die verwendeten Zahlungsarten. Wir erfassen auch automatisch
          zusätzliche Informationen über jeden Besuch auf unserer Website.
          <br /> <br />
          Ohne die Bestätigung der Einwilligung zur Übertragung deiner Daten und der damit
          verbundenen Erlaubnis deinerseits, dass wir diese Daten verwenden dürfen, ist eine
          Registrierung bei Game90 nicht möglich. Da gesetzlich vorgeschriebene Prozesse die
          Datenverarbeitung in bestimmten Bereichen vorschreiben, ist also ein Nutzen unserer
          Services ohne eine Einwilligung nicht möglich. Der Entzug der Einwilligung zieht auch die
          Sperrung deines Spielerkontos nach sich. Mindestens solange, bis eine neue Einwilligung
          vorliegt. Bereits vorher verarbeitete Daten sind vom Entzug der Einwilligung nicht
          betroffen.
        </p>
        <h6 className="fw-bolder mt-3"> E-mail</h6>
        <p className="mt-3">
          Wenn du uns eine E-Mail schreibst, erhalten wir neben den oben angegebenen Daten
          zusätzlich deine genutzte E-Mail Adresse und die von dir in der E-Mail angegebenen Daten.
          Beim Einreichen von Unterlagen über unser E-Mail System, werden die Daten auf einem
          separatem gesicherten Speichermedium/Server gespeichert und kryptisch verschlüsselt.
        </p>
        <h6 className="fw-bolder mt-3"> Kontaktformular</h6>
        <p className="mt-3">
          Wir bieten dir auf unserer Website neben einem Chatsystem auch ein Kontaktformular an, mit
          dem du unseren Support schriftlich erreichen kannst. Dieses Formular überträgt nur die
          Daten, die du dort in das Formular eingibst. Das Formular ist mit unseren Servern direkt
          verbunden und wird nicht durch ein drittes Unternehmen transportiert oder gesichtet. Ein
          gesendetes Formular ohne Kontaktdaten kann nicht zugeordnet werden und wird gemäß der
          Datenschutz-Grundverordnung unverzüglich vernichtet.
        </p>
        <h6 className="fw-bolder mt-3"> Chatsystem</h6>
        <p className="mt-3">
          In unserem Chatsystem können wir neben der IP-Adresse auch einsehen, welche der
          Unterseiten auf unserer Website du im Augenblick besuchst und wie lange du dich bereits
          auf unserer Website aufhältst.Durch spezielle im Einsatz befindliche Technik und Software
          sind wir ebenso in der Lage, deinen User-Agent einsehen zu können. Dieser zeigt an,
          welches Betriebssystem du im Einsatz hast und welchen Browser in welcher Version du
          benutzt. Persönliche Daten werden dabei jedoch nicht angezeigt oder übermittelt.
        </p>
        <h6 className="fw-bolder mt-3"> Einzahlung (PSP) </h6>
        <p className="mt-3">
          Wenn du eine Einzahlung auf dein Spielerkonto tätigst, kann dies über verschiedene Wege
          geschehen. Mit Ausnahme der normalen Banküberweisung auf unser Game90-Bankkonto, nutzen
          wir bei der Einzahlung sogenannte Zahlungsdienstanbieter (PSP – Payment Service Provider).
          Diese sind zwischen dir und uns gelagert und ermöglichen durch ihren Service einen
          schnellen nahezu sekundenmäßigen Zahlungsverkehr. Diese PSP haben eigene spezielle und
          nach strengen gesetzlichen Verordnungen zu beachtende Datenschutzbestimmungen, auf die
          Game90 keinen Einfluß hat. Bitte lies dir bei deiner Einzahlung im Vorfeld die
          Datenschutzbestimmung des von dir bevorzugten PSP durch.
        </p>
        <h6 className="fw-bolder"> Landing Pages</h6>
        <p className="mt-3">
          Wenn du über eine unserer Werbemethoden auf eine Werbe- bzw. Erklärungsseite unseres
          Unternehmens gelangst (Unterseiten der Website - sogenannte Landig Pages) kann es sein,
          dass der Button, den du in der Werbe-E-Mail gedrückt hast mit einem speziellen Code
          versehen ist. Dieser wäre dann nötig, um auf dieser Landig Page gelistet zu werden,
          beispielsweise auch dann, wenn du dort aufgefordet wirst, etwas einzugeben
          (Interessenumfrage oder Ähnliches). Dies kann auch personenbezogene Daten, wie deine
          E-Mail-Adresse oder vollständigen Namen betreffen. Diese Landing Pages liegen auf eigenen
          Servern und Daten werden dort den jeweiligen Spielerkonten zugeordnet, wenn es um
          spezielle Werbung geht. Bei Umfragen werden keine personenbezogenen Daten erfasst.
        </p>
        <br /> <h6 className="fw-bolder mt-3"> Erfassung von Daten</h6>
        <p className="mt-3">
          Wenn du über eine unserer Werbemethoden auf eine Werbe- bzw. Erklärungsseite unseres
          Unternehmens gelangst (Unterseiten der Website - sogenannte Landig Pages) kann es sein,
          dass der Button, den du in der Werbe-E-Mail gedrückt hast mit einem speziellen Code
          versehen ist. Dieser wäre dann nötig, um auf dieser Landig Page gelistet zu werden,
          beispielsweise auch dann, wenn du dort aufgefordet wirst, etwas einzugeben
          (Interessenumfrage oder Ähnliches). Dies kann auch personenbezogene Daten, wie deine
          E-Mail-Adresse oder vollständigen Namen betreffen. Diese Landing Pages liegen auf eigenen
          Servern und Daten werden dort den jeweiligen Spielerkonten zugeordnet, wenn es um
          spezielle Werbung geht. Bei Umfragen werden keine personenbezogenen Daten erfasst.
          <br /> <br />
          Wenn du über eine unserer Werbemethoden auf eine Werbe- bzw. Erklärungsseite unseres
          Unternehmens gelangst (Unterseiten der Website - sogenannte Landig Pages) kann es sein,
          dass der Button, den du in der Werbe-E-Mail gedrückt hast mit einem speziellen Code
          versehen ist. Dieser wäre dann nötig, um auf dieser Landig Page gelistet zu werden,
          beispielsweise auch dann, wenn du dort aufgefordet wirst, etwas einzugeben
          (Interessenumfrage oder Ähnliches). Dies kann auch personenbezogene Daten, wie deine
          E-Mail-Adresse oder vollständigen Namen betreffen. Diese Landing Pages liegen auf eigenen
          Servern und Daten werden dort den jeweiligen Spielerkonten zugeordnet, wenn es um
          spezielle Werbung geht. Bei Umfragen werden keine personenbezogenen Daten erfasst.
        </p>
        <ul className="mt-3">
          <li key={10}>Deine IP-Adresse, und damit dein Standort. </li>
          <li key={11}>Die Uhrzeit, zu der du dich bei uns eingeloggt hast.</li>
          <li key={12}>
            Die Zuordnung deiner über unsere Cookies generierten Daten insofern du bei uns schon
            registriert bist, andernfalls sind keine personenbezogene Daten enthalten.
          </li>
          <li key={13}>
            Erkennungsdetails (codierte Nummer, welche den Werbeträger deinem Spielerkonto zuordnet
            – personenbezogene Daten oder Namensdetails etc. werden nicht übermittelt) wenn du über
            einen unserer Werbeträger zu uns kommst.
          </li>
          <li key={14}>
            Dein User-Agent, den dein Browser standardmäßig mitsendet und der dein Betriebssystem,
            deinen verwendeten Browser und dessen aktuelle Version mitteilt
          </li>
        </ul>
        <a href="#top">Zurück</a>
      </div>
      <div className="mt-75">
        <h5 id="personal-information mt-75">2. Verwendung persönlicher Daten </h5>
        Wir werden die über diese Website und angeschlossenen Applikationen gesammelten Daten für
        folgende Zwecke verwenden:
        <ul className="mt-3">
          <li key={15}>Vollständige Spielerkontoverwaltung; </li>
          <li key={16}>Bereitstellung und Personalisierung unserer kompletten Dienste; </li>
          <li key={17}>Bearbeitung von Anfragen und Wünschen; </li>
          <li key={18}>Gesetzlich vorgeschriebene Verifizierungsprozesse;</li>
          <li key={19}>Durchführung von Marktforschungsstudien (siehe auch Landing-Pages); </li>
          <li key={20}>
            Kontaktaufnahme mit dir (per E-Mail oder anderer Kommunikationswege), um dich über
            unsere Produkte, Neuerungen und Servicedienste als auch Werbeaktionen (Promotion) zu
            informieren;
          </li>
          <li key={21}>
            Weitergabe deiner Daten an Organisationen, die Produkte oder Dienste anbieten, von denen
            wir annehmen, dass sie für dich von Interesse sein könnten (vorausgesetzt, du hast
            zugestimmt, derartige Informationen zu erhalten). Personenbezogene Daten, welche nach
            einer Einschätzung und Kategorie (Profiling) entstanden sind, werden hierbei jedoch
            nicht übermittelt.
          </li>
        </ul>
        <br />
        Deine personenbezogenen Daten werden bei Game90 nicht ausschließlich durch automatisierte
        Prozesse verarbeitet und oder erstellt. Ein sogenanntes ausschließliches automatisiertes
        Profiling, wie in Artikel 22 DSGVO beschrieben, findet bei Game90 nicht statt.
        <h6 className="fw-bolder mt-3"> 2.1 Newsletter</h6>
        <p className="mt-3">
          Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von
          Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass
          Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters
          einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben.
          Diese Daten verwenden wir ausschließlich für den Versand der angeforderten Informationen
          und geben diese nicht an Dritte weiter.
          <br /> <br />
          Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt
          ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte
          Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand
          des Newsletters können Sie jederzeit widerrufen, etwa über den &quot;Austragen&quot;-Link
          im Newsletter. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt
          vom Widerruf unberührt.
          <br /> <br />
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns
          bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des
          Newsletters gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B.
          E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.
        </p>
        <h6 className="fw-bolder mt-3"> MailChimp</h6>
        <p className="mt-3">
          Diese Website nutzt die Dienste von MailChimp für den Versand von Newslettern. Anbieter
          ist die Rocket Science Group LLC, 675 Ponce De Leon Ave NE, Suite 5000, Atlanta, GA 30308,
          USA.
          <br /> <br />
          MailChimp ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und
          analysiert werden kann. Wenn Sie Daten zum Zwecke des Newsletterbezugs eingeben (z.B.
          E-Mail-Adresse), werden diese auf den Servern von MailChimp in den USA gespeichert.
          <br /> <br />
          MailChimp verfügt über eine Zertifizierung nach dem “EU-US-Privacy-Shield”. Der
          “Privacy-Shield” ist ein Übereinkommen zwischen der Europäischen Union (EU) und den USA,
          das die Einhaltung europäischer Datenschutzstandards in den USA gewährleisten soll.
          <br /> <br />
          Mit Hilfe von MailChimp können wir unsere Newsletterkampagnen analysieren. Wenn Sie eine
          mit MailChimp versandte E-Mail öffnen, verbindet sich eine in der E-Mail enthaltene Datei
          (sog. web-beacon) mit den Servern von MailChimp in den USA. So kann festgestellt werden,
          ob eine Newsletter-Nachricht geöffnet und welche Links ggf. angeklickt wurden. Außerdem
          werden technische Informationen erfasst (z.B. Zeitpunkt des Abrufs, IP-Adresse, Browsertyp
          und Betriebssystem). Diese Informationen können nicht dem jeweiligen Newsletter-Empfänger
          zugeordnet werden. Sie dienen ausschließlich der statistischen Analyse von
          Newsletterkampagnen. Die Ergebnisse dieser Analysen können genutzt werden, um künftige
          Newsletter besser an die Interessen der Empfänger anzupassen.
          <br /> <br />
          Wenn Sie keine Analyse durch MailChimp wollen, müssen Sie den Newsletter abbestellen.
          Hierfür stellen wir in jeder Newsletternachricht einen entsprechenden Link zur Verfügung.
          Des Weiteren können Sie den Newsletter auch direkt auf der Website abbestellen.
          <br /> <br />
          Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
          DSGVO). Sie können diese Einwilligung jederzeit widerrufen, indem Sie den Newsletter
          abbestellen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt
          vom Widerruf unberührt.
          <br /> <br />
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns
          bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des
          Newsletters sowohl von unseren Servern als auch von den Servern von MailChimp gelöscht.
          Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B. E-Mail-Adressen für den
          Mitgliederbereich) bleiben hiervon unberührt.
          <br /> <br />
          Näheres entnimmst du den Datenschutzbestimmungen von MailChimp unter:
          https://mailchimp.com/legal/terms/. <br />
        </p>
        <h6 className="fw-bolder mt-3"> 2.2 Plugins und Tools</h6>
        <h6 className="fw-bolder mt-3"> YouTube</h6>
        <p className="mt-3">
          OUnsere Website nutzt Plugins der von Google betriebenen Seite YouTube. Betreiber der
          Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine
          unserer mit einem YouTube-Plugin ausgestatteten Seiten besuchen, wird eine Verbindung zu
          den Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche
          unserer Seiten Sie besucht haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind,
          ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen.
          Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen. Die
          Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer
          Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
          DSGVO dar.
          <br />
          <br />
          Weitere Informationen zum Umgang mit Nutzerdaten findest du in der Datenschutzerklärung
          von YouTube unter: https://www.google.de/intl/de/policies/privacy.
        </p>
        <h6 className="fw-bolder"> Google Web Fonts </h6>
        <p className="mt-3">
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts,
          die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die
          benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
          <br /> <br />
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google
          aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere
          Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer
          einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
          berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
          <br /> <br />
          Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer
          genutzt. Weitere Informationen zu Google Web Fonts findest du unter
          https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google:
          https://www.google.de/intl/de/policies/privacy.
        </p>
        <a href="#top">Zurück</a>
      </div>
      <div className="mt-75">
        <h5 id="marketing-preferences">3. Marketingpräferenzen </h5>
        <p className="mt-3">
          Bitte gib bei deiner Registrierung zusätzlich an, ob du Informationen zu Produkten oder
          Dienstleistungen von uns oder anderen Unternehmen unserer Firmen-Gruppe erhalten möchtest,
          in dem du das entsprechende Kästchen ankreuzt. Du kannst den Empfang von solchen
          Mitteilungen jederzeit widerrufen, indem du den Anweisungen am Ende jeder Werbenachricht
          folgst oder uns eine E-Mail an support@game90.bet sendest.
          <br /> <br />
          Wenn du wissen möchtest, ob und welche Daten wir an andere Unternehmen weiterleiten, hast
          du das Recht, dies jederzeit zu erfahren. Kontaktiere dazu einfach unseren
          Datenschutzbeauftragten unter DPO@game90.bet.
        </p>
        <a href="#top">Zurück</a>
      </div>
      <div className="mt-75">
        <h5 id="disclosures">4. Verwendung von Cookies </h5>
        <p className="mt-3">
          Ein Cookie ist eine kleine Informationseinheit, die von einem Webserver an einen
          Webbrowser gesendet wird und dem Server ermöglicht, vom Browser Informationen zu beziehen.
          Wir verwenden eigene Cookies, um dich bei deinem Besuch unserer Website zu identifizieren
          und Details deiner Anmeldung schneller und effektiver wieder aufrufen zu können (z.B.
          gespeicherte Einstellungen der Spiele etc.). Die Verwendung von Cookies ermöglicht
          registrierten Benutzern auch, Transaktionen durchzuführen und Zugang zu Informationen über
          ihr Konto zu erhalten. Die meisten Browser ermöglichen dir, Cookies zu deaktivieren. Wenn
          du wissen möchtest, wie du dies tun kannst, beachte bitte das Hilfemenü deines Browsers.
          Allerdings werden deine Besuche unserer Website durch die Deaktivierung von Cookies
          deutlich eingeschränkt oder teilweise nicht möglich sein.
        </p>
        <h6 className="fw-bolder">4.1. Google Analytics</h6>
        <p className="mt-3">
          Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die
          Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Google Analytics
          verwendet sogenannte &quot;Cookies&quot;. Dies sind Textdateien, die auf deinem Computer
          gespeichert werden und die eine Nutzeranalyse der Website ermöglichen. Die durch den
          Cookie erzeugten Informationen über deine Benutzung dieser Website werden in der Regel an
          einen Server von Google in den USA übertragen und dort gespeichert. Die Speicherung von
          Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
          Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
          sowohl sein Webangebot als auch seine Werbung zu optimieren.
        </p>
        <h6 className="fw-bolder">IP-Anonymisierung</h6>
        <p className="mt-3">
          Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird deine
          IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung
          in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von
          Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website
          wird Google diese Informationen benutzen, um deine Nutzung der Website auszuwerten, um
          Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
          Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von deinem Browser
          übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
        </p>
        <h6 className="fw-bolder">Browser plugin</h6>
        <p className="mt-3">
          Du kannst die Speicherung der Cookies durch eine entsprechende Einstellung deiner
          Browser-Software verhindern; wir weisen jedoch darauf hin, dass du in diesem Fall
          gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich wirst nutzen
          können. Du kannst darüber hinaus die Erfassung der durch den Cookie erzeugten und auf
          deine Nutzung der Website bezogenen Daten (inkl. deiner IP-Adresse) an Google sowie die
          Verarbeitung dieser Daten durch Google verhindern, indem du das unter dem folgenden Link
          verfügbare Browser-Plugin herunterlädst und installierst:
          https://tools.google.com/dlpage/gaoptout?hl=de.
        </p>
        <h6 className="fw-bolder">Widerspruch gegen Datenerfassung</h6>
        <p className="mt-3">
          Du kannst die Erfassung deiner Daten durch Google Analytics verhindern, indem du im Footer
          auf den Link „Tracking deaktivieren“ klickst. Es wird ein Opt-Out-Cookie gesetzt, der die
          Erfassung deiner Daten bei zukünftigen Besuchen dieser Website verhindert.Mehr
          Informationen zum Umgang mit Nutzerdaten bei Google Analytics findest du in der
          <br />
          Datenschutzerklärung von Google:
          https://support.google.com/analytics/answer/6004245?hl=de.
        </p>
        <h6 className="fw-bolder">Auftragsdatenverarbeitung</h6>
        <p className="mt-3">
          Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen
          die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google
          Analytics vollständig um.
        </p>
        <h6 className="fw-bolder">Demografische Merkmale bei Google Analytics</h6>
        <p className="mt-3">
          Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch
          können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der
          Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google
          sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person
          zugeordnet werden. Du kannst diese Funktion jederzeit über die Anzeigeneinstellungen in
          deinem Google-Konto deaktivieren oder die Erfassung deiner Daten durch Google Analytics
          wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen
        </p>
        <a href="#top">Zurück</a>
      </div>
      <div className="mt-75">
        <h5 id="marketing-preferences">5. Offenlegungen</h5>
        <p className="mt-3">
          Wir geben persönliche Daten lediglich an andere Unternehmen innerhalb unserer
          Unternehmensgruppe, Geschäftspartner, Behörden und Strafverfolgungsbehörden, künftige
          Eigentümer unseres Geschäfts und Dienstleister weiter, die wir mit der Datenverarbeitung
          beauftragen (Auftragsverarbeiter).
          <br /> <br />
          Du hast das Recht, die Weitergabe deiner Daten einzuschränken oder auch auf die Löschung
          deiner Daten zu bestehen. Dieses Recht wird dir Im Rahmen der in Artikel 17 und 18 der
          Datenschutz-Grundverordnung (DSGVO) beschriebenen Punkte zugesichert. Ausnahmen bilden
          Daten, die nach Art.17.3.b der DSGVO erhoben und mit einer gesetzlichen
          Mindestspeicherpflicht verarbeitet werden müssen. Das Recht der Beschränkung und oder
          Einschränkung und oder Verweigerung als auch Löschung deiner Daten kann durch diese
          gesetzlichen Bestimmungen beeinflusst oder begrenzt sein.
          <br /> <br />
          Du hast zudem das Recht nach Art.15 der DSGVO eine Auskunft über die bei Game90
          gespeicherten Daten zu erhalten und diese auch anzufordern. Game90 wird die Daten in einem
          für dich lesbaren Format an dich persönlich übermitteln. Die Auskunft beinhaltet deine
          gespeicherten personenbezogenen Daten und die Art der Verwendung. Für eine Auskunft über
          gespeicherte Daten, eine Einschränkung oder Löschung wende dich bitte an DPO@game90.bet.
        </p>
        <a href="#top">Zurück</a>
      </div>
      <div className="mt-75">
        <h5 id="other-websites">6. Andere Websites</h5>
        <p className="mt-3">
          Unsere App und unsere Website enthalten möglicherweise Links zu Websites und Apps Dritter
          (z. B. zu sozialen Medien oder Regulierungsbehörden), auf die wir keinen Einfluss haben
          und auf die sich die Game90-Datenschutzerklärung nicht erstreckt und die von unseren
          abweichen können. Für die Funktionsfähigkeit oder eventuell auftretende Fehler auf den
          verlinkten Websites sind ausschließlich deren Betreiber verantwortlich. Game90 übernimmt
          keine Haftung für die gesetzlichen Bestimmungen oder Datenschutzrichtlinien von Websites
          anderer Betreiber. Die sozialen Netzwerke, über die du Inhalte austauschen kannst, hängen
          davon ab, in welchen sozialen Netzwerken du Mitglied bist und wie du dein Mitgliedskonto
          und dein Gerät konfiguriert hast. Nach der Aktivierung eines solchen Links haben wir
          keinen Einfluss mehr auf die Verarbeitung von personenbezogenen Daten, die beim Folgen des
          Links an den Dritten übermittelt werden. Deshalb können wir für die Verarbeitung solcher
          personenbezogenen Daten durch Dritte keine Verantwortung übernehmen. Weitere Informationen
          findest du in den Datenschutzrichtlinien der von dir verwendeten sozialen Netzwerke oder
          Websites. Game90 hat auch keinen Einfluss auf die Datenschutzerklärung der Seiten, die
          über kleine Icons zu Zahlungsdienstleistern (z. B. Visa, Skrill etc.) oder Behörden (MGA
          etc.) führen. Diese speziellen Links sind teilweise gesetzlich vorgeschrieben und Game90
          haftet nicht für den Inhalt oder die Datenschutzbestimmungen der jeweiligen Seiten.
        </p>
        <a href="#top">Zurück</a>
      </div>
      <div className="mt-75">
        <h5 id="right-access">7. Zugriffsrecht</h5>
        <p className="mt-3">
          Du hast das Recht, auf deine persönlichen Daten zuzugreifen, wie unter 2.2.1 der
          Allgemeinen Geschäftsbedingungen (AGB) von Game90 aufgeführt. Diese Daten sind für dich
          persönlich und einfach auch jederzeit in deinem angemeldeten Spielerkonto einsehbar. Um
          eine Kopie deiner personenbezogenen Daten, welche bei uns hinterlegt sind, zu erhalten,
          schreibe uns bitte an die folgende Adresse: Game90 Ltd., Melita Court, Giuseppe Cali St
          c/w Abate Rigord St, XBX1420 Ta&aposXbiex, Malta, oder sende deine E-Mail-Anfrage an
          DPO@game90.bet für eine Übersicht der bei uns hinterlegten Daten. Dieses Recht steht dir
          nach Artikel 15 der Datenschutz-Grundverordnung (DSGVO) einmal pro Halbjahr kostenlos zu.
          Jede zusätzliche Anforderung wird mit einer angemessenen Verwaltungsgebühr je nach Aufwand
          belegt. Die Unterlagen werden dir in einem für dich lesbaren Format zur Verfügung
          gestellt. Aus Datenschutzgründen muss die von dir gestellte Anfrage separat und
          zweifelsfrei verifiziert werden.
        </p>
        <a href="#top">Zurück</a>
      </div>
      <div className="mt-75">
        <h5 id="data-transmission">8. Internetbasierte Datenübertragung</h5>
        <p className="mt-3">
          Da das Internet eine globale Umgebung ist, beinhaltet die Zusammenstellung und
          Verarbeitung von persönlichen Daten notwendigerweise die Übertragung von Daten auf
          internationaler Basis. Durch die Verwendung unserer Website und die elektronische
          Kommunikation mit uns erkennst du an und erklärst dich damit einverstanden, dass wir deine
          persönlichen Daten auf diese Weise verarbeiten. Bei der Übertragung von Daten unsererseits
          zu einem unserer Verarbeiter werden wir die Daten kryptisch verschlüsselt übermitteln.
        </p>
        <a href="#top">Zurück</a>
      </div>
      <div className="mt-75">
        <h5 id="international-applicable">
          9. Internationale Rechtsprechung und anwendbares Recht
        </h5>
        <p className="mt-3">
          Jegliche Streitigkeiten zwischen dir und Game90, die sich aus oder in Bezug auf den
          Gegenstand dieser Datenschutzerklärung ergeben, unterliegen maltesischem Recht ohne
          Bezugnahme auf die Kollisionsnormen und unterliegen der ausschließlichen Zuständigkeit der
          zuständigen Gerichte Malta. <br />
          <br />
          Wenn eine Bestimmung der vorliegenden Datenschutzrichtlinie durch eine Entscheidung eines
          zuständigen Gerichts als rechtswidrig, ungültig oder nicht durchsetzbar zurückgenommen
          wird, berührt dies nicht die Gültigkeit und Durchsetzbarkeit der übrigen Bestimmungen, die
          in voller Kraft bleiben und dementsprechend angewendet werden
        </p>
        <a href="#top">Zurück</a>
      </div>
    </div>
  );
}
