import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TitleHistoryBack from 'components/general/TitleHistoryBack';

export default function ProfileSettings() {
  const { t } = useTranslation('translations', { useSuspense: false });
  // const [stake, setStake] = useState(10);

  return (
    <Container>
      <TitleHistoryBack title={t('consenthistory')} subTitle={t('profile')} />
      <>
        <Row>
          <Col xs={4} className="float-start fw-bold text-muted">
            <div className="mt-1"> {t('privacynotice')}</div>
          </Col>
          <Col
            xs={8}
            className="float-start pb-2"
            style={{ borderBottomColor: '#EDECEC', borderBottomWidth: '1' }}>
            <hr className="text-end me-0"></hr>
          </Col>
        </Row>

        <></>
        <Row>
          <Col>
            <div className="mt-3"></div>
            <h8 className="float-start text-muted">{t('datapublish')}</h8>
            <h8 className="float-end">Jul 18, 2019, 12:00:00 AM</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt-1"></div>
            <h8 className="float-start text-muted">{t('dateaccept')}</h8>
            <h8 className="float-end">Dec 5, 2021, 2:38:29 PM</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt-1"></div>
            <h8 className="float-start text-muted">{t('status')}</h8>
            <h8 className="float-end text-success">{t('accept')}</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt-1"></div>
            <h8 className="float-start text-muted">{t('versiontype')}</h8>
            <h8 className="float-end">MAJOR</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt-1"></div>
            <h8 className="float-start text-muted">{t('version')}</h8>
            <h8 className="float-end">1.0</h8>
          </Col>
        </Row>

        <div className="mt-3"></div>
        <Row>
          <Col xs={7} className="float-start fw-bold text-muted">
            <div className="mt-1 float-start"> {t('generalterms')}</div>
          </Col>
          <Col
            xs={5}
            className="float-start pb-2"
            style={{ borderBottomColor: '#EDECEC', borderBottomWidth: '1' }}>
            <hr className="text-end me-0"></hr>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex flex-row justify-content-between mt-2">
            <div className="mt-1"></div>
            <h8 className="float-start text-muted">{t('datapublish')}</h8>
            <h8 className="float-end ">Nov 29, 2021, 10:40:04 AM</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt-1"></div>
            <h8 className="float-start text-muted">{t('dateaccept')}</h8>
            <h8 className="float-end ">Dec 5, 2021, 2:38:29 PM</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt-1"></div>
            <h8 className="float-start text-muted">{t('status')}</h8>
            <h8 className="float-end text-success">{t('accept')}</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt-1"></div>
            <h8 className="float-start text-muted">{t('versiontype')}</h8>
            <h8 className="float-end">MAJOR</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt-1"></div>
            <h8 className="float-start text-muted">{t('version')}</h8>
            <h8 className="float-end">4.3.9</h8>
          </Col>
        </Row>
      </>
    </Container>
  );
}
