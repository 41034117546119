import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { checkIfLiveEvent, sportIdToSportType } from 'toolkit/utils';
import {
  fetchFullEventReq,
  fetchTopMatchesReq,
  fetchClearFavoritesReq,
  fetchLiveMatchesReq,
  fetchSportCountsWithHoursReq,
  fetchSportCountsReq,
  fetchCompetitorsNameByLanguageReq,
  fetchEventsByCategoryReq,
  fetchEventsByCategoryWithHoursReq,
  fetchEventsBySportReq,
  fetchNextLiveMatchesReq
} from './services';
import { getBetSlipMatches } from '../betSlip';
import { eventTimeToSeconds } from 'toolkit/utils';
import { EnumMatchStatusName } from 'toolkit/Enums';
import { sports } from 'toolkit/constants';

const initialState = {
  events: {}, // live or event entire objects
  counts: {},
  timeCounts: {},
  clearFavorites: [],
  liveMatches: [], // Live matches event ids only
  nextLiveMatches: [],
  nextLiveMatchesMeta: {},
  topMatches: [],
  filteredEventIds: [],
  producerStatus: {
    live: false,
    preMatch: false
  },
  selectedMatch: null,
  selectedSport: null,
  selectedClearFavoritesSport: null,
  selectedLiveSportDesktop: null,
  desktopSearchParams: {
    mode: 'topMatches',
    Sport: null,
    Category: null,
    Tournament: null,
    dateFilter: '0'
  },
  selectedLeague: null,
  categoryNames: {},
  tournamentNames: {},
  selectedLocale: 'en',
  locales: {
    tr: {},
    de: {},
    en: {},
    nl: {},
    bg: {},
    fr: {}
  }
};

export const fetchFullEvent = createAsyncThunk('match/fetchFullEvent', async (providerId) => {
  const response = await fetchFullEventReq({ gameNumber: providerId });
  return response;
});

export const fetchTopMatches = createAsyncThunk('match/fetchTopMatches', async (params) => {
  const response = await fetchTopMatchesReq(params);
  return response;
});

export const fetchClearFavorites = createAsyncThunk('match/fetchClearFavorites', async (params) => {
  const response = await fetchClearFavoritesReq(params);
  return response;
});

export const fetchLiveMatches = createAsyncThunk('match/fetchLiveMatches', async (params) => {
  const response = await fetchLiveMatchesReq(params);
  return response;
});

export const fetchNextLiveMatches = createAsyncThunk(
  'match/fetchNextLiveMatches',
  async (params) => {
    const response = await fetchNextLiveMatchesReq(params);
    return response;
  }
);

export const fetchSportCountsWithHours = createAsyncThunk(
  'match/fetchSportCountsWithHours',
  async (params) => {
    const response = await fetchSportCountsWithHoursReq(params);
    return response;
  }
);

export const fetchSportCounts = createAsyncThunk('match/fetchSportCounts', async (params) => {
  const response = await fetchSportCountsReq(params);
  return response;
});

export const fetchEventsByCategoryWithHours = createAsyncThunk(
  'match/fetchEventsByCategoryWithHours',
  async (data) => {
    const response = await fetchEventsByCategoryWithHoursReq(data);
    return response;
  }
);

export const fetchEventsByCategory = createAsyncThunk(
  'match/fetchEventsByCategory',
  async (data) => {
    const response = await fetchEventsByCategoryReq(data);
    return response;
  }
);

export const fetchEventsBySport = createAsyncThunk('match/fetchEventsBySport', async (data) => {
  const response = await fetchEventsBySportReq(data);
  return response;
});

export const fetchCompetitorsNameByLanguage = createAsyncThunk(
  'match/fetchCompetitorsNameByLanguage',
  async (language) => {
    const lang = language.id.split('-')[0];
    const response = await fetchCompetitorsNameByLanguageReq(lang);
    return {
      language,
      data: response
    };
  }
);

const addMatchesToState = (state, events, order, counts) => {
  events.forEach((event) => {
    setMatch(
      state,
      {
        ...event,
        markets: event.markets.filter((m) => m.outcomes.length > 0)
      },
      order
    );
    if (counts) {
      setCount(counts, event);
    }
  });
};

const setMatch = (events, event, order) => {
  if (!event) return;
  if ((events[event.providerId]?.lastUpdateOrder ?? -1) > order) {
    return;
  }
  if (checkIfLiveEvent(event)) {
    const existingEvent = events[event.providerId];
    events[event.providerId] = {
      ...event,
      timeTextInternal: event.timeText
        ? eventTimeToSeconds(event.timeText)
        : existingEvent?.timeTextInternal ?? 0,
      timeTextStoppageInternal: event.timeTextStoppage
        ? eventTimeToSeconds(event.timeTextStoppage)
        : existingEvent?.timeTextStoppageInternal ?? 0,
      lastUpdateOrder: order,
      LastUpdateTime: Date.now()
    };
  } else {
    events[event.providerId] = { ...event, lastUpdateOrder: order };
  }
};

const setCount = (counts, event, isDelete = false) => {
  if (!event) return;
  const { sport, categoryId, leagueId } = event;
  const sportName = sportIdToSportType(String(sport));
  if (!counts[sportName]) {
    counts[sportName] = {};
  }
  if (!counts[sportName][categoryId]) {
    counts[sportName][categoryId] = {};
  }
  if (counts[sportName][categoryId][leagueId] === undefined) {
    counts[sportName][categoryId][leagueId] = 0;
  }
  if (!isDelete) {
    counts[sportName][categoryId][leagueId] += 1;
  } else {
    counts[sportName][categoryId][leagueId] -= 1;
  }
};

const setTournamentName = (tournamentNames, event) => {
  const { leagueId, league } = event;
  if (leagueId && league) {
    tournamentNames[leagueId] = league;
  }
};

export const matchSlice = createSlice({
  name: 'match',
  initialState,
  reducers: {
    setSelectedMatch: (state, action) => {
      state.selectedMatch = action.payload;
    },
    setProducerStatus: (state, action) => {
      const { producers } = action.payload;
      state.producerStatus = producers;
    },
    setSelectedLocale: (state, action) => {
      state.selectedLocale = action.payload;
    },
    setLocales: (state, action) => {
      console.log(action.payload);
    },
    setSelectedSport: (state, action) => {
      state.selectedSport = action.payload;
    },
    setSelectedClearFavoritesSport: (state, action) => {
      state.selectedClearFavoritesSport = action.payload;
    },
    setCategoryNames: (state, action) => {
      state.categoryNames = action.payload;
    },
    addMatch: (state, action) => {
      const { events = [], order } = action.payload;

      // We control the order of the each event inside the addMatchToState function before actually adding them to the state
      addMatchesToState(state.events, events, order, state.counts);

      events.forEach((event) => {
        // Old update msg we should skip it, we might have get the update msg before the add msg
        if ((events[event.providerId]?.lastUpdateOrder ?? -1) > order) {
          return;
        }

        if (checkIfLiveEvent(event)) {
          if (state.liveMatches.indexOf(event.providerId) === -1) {
            state.liveMatches.push(event.providerId);
          }
          state.nextLiveMatches = state.nextLiveMatches.filter((m) => m !== event.providerId);
        }

        setTournamentName(state.tournamentNames, event);
      });
    },
    updateMatch: (state, action) => {
      const { events = [], order } = action.payload;
      events.forEach((event) => {
        if ((events[event.providerId]?.lastUpdateOrder ?? -1) > order) {
          return;
        }

        if (checkIfLiveEvent(event)) {
          if (state.liveMatches.indexOf(event.providerId) === -1) {
            state.liveMatches.push(event.providerId);
          }

          state.nextLiveMatches = state.nextLiveMatches.filter((m) => m !== event.providerId);
        }

        setMatch(state.events, event, order);
        setTournamentName(state.tournamentNames, event);
      });
    },
    deleteMatch: (state, action) => {
      const { events = [] } = action.payload;
      events.forEach((event) => {
        if (state.liveMatches.indexOf(event)) {
          state.liveMatches = state.liveMatches.filter((l) => l !== event);
        }
        setCount(state.counts, state.events[event], true);
        delete state.events[event];
      });
    },
    setSelectedLiveDesktopSport: (state, action) => {
      state.selectedLiveSportDesktop = action.payload;
    },
    setDesktopSearchParams: (state, action) => {
      state.desktopSearchParams = {
        ...state.desktopSearchParams,
        ...action.payload
      };
    },
    setSelectedLeague: (state, action) => {
      state.selectedLeague = action.payload;
    },
    setNextLiveMatches: (state) => {
      state.nextLiveMatches = [];
      state.nextLiveMatchesMeta = {};
    },
    updateLiveFootballEventsTime: (state) => {
      const updateTime = (event) => {
        const matchStatusId = event.matchStatusId;

        // We cannot increment time for events that are not in play
        if (
          matchStatusId !== EnumMatchStatusName.FIRST_HALF &&
          matchStatusId !== EnumMatchStatusName.SECOND_HALF &&
          matchStatusId !== EnumMatchStatusName.FIRST_EXTRA &&
          matchStatusId !== EnumMatchStatusName.SECOND_EXTRA
        ) {
          return;
        }

        const deltaUpdateTimeInSeconds = (Date.now() - event.LastUpdateTime) / 1000;

        if (deltaUpdateTimeInSeconds < 10 || deltaUpdateTimeInSeconds > 120) {
          return;
        }

        const matchTimeTextLimitsInMinutes = {
          [EnumMatchStatusName.FIRST_HALF]: 45,
          [EnumMatchStatusName.SECOND_HALF]: 90,
          [EnumMatchStatusName.FIRST_EXTRA]: 105,
          [EnumMatchStatusName.SECOND_EXTRA]: 120
        };

        const matchTimeLimitInSeconds = (matchTimeTextLimitsInMinutes[matchStatusId] ?? 150) * 60;

        const hasValidStoppageTime = event.timeTextInternal >= matchTimeLimitInSeconds;

        //Event in extra time
        if (hasValidStoppageTime) {
          event.timeTextStoppageInternal += deltaUpdateTimeInSeconds;
          event.LastUpdateTime = Date.now();
          return;
        }

        event.timeTextInternal += deltaUpdateTimeInSeconds;

        event.LastUpdateTime = Date.now();

        if (event.timeTextInternal > matchTimeLimitInSeconds) {
          const exceedAmount = event.timeTextInternal - matchTimeLimitInSeconds;
          event.timeTextInternal = matchTimeLimitInSeconds;
          event.timeTextStoppageInternal += exceedAmount;

          return;
        }
      };

      state.liveMatches.forEach((liveEventId) => {
        const liveEvent = state.events[liveEventId];
        if (!liveEvent || liveEvent.sport !== sports.Football) {
          return;
        }

        updateTime(liveEvent);
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFullEvent.fulfilled, (state, action) => {
        addMatchesToState(state.events, [action.payload]);
      })
      .addCase(fetchTopMatches.fulfilled, (state, action) => {
        const topMatches = action.payload;
        state.topMatches = topMatches
          .sort((a, b) => {
            const dateA = new Date(a.gameDate) ?? 0;
            const dateB = new Date(b.gameDate) ?? 0;
            return dateA - dateB;
          })
          .map((c) => c.providerId);
        addMatchesToState(state.events, topMatches);
        topMatches.forEach((event) => {
          setTournamentName(state.tournamentNames, event);
        });
      })
      .addCase(fetchClearFavorites.fulfilled, (state, action) => {
        const clearFavoritesMatches = action.payload;
        state.clearFavorites = clearFavoritesMatches.map((c) => c.providerId);
        addMatchesToState(state.events, clearFavoritesMatches);
        clearFavoritesMatches.forEach((event) => {
          setTournamentName(state.tournamentNames, event);
        });
      })
      .addCase(fetchLiveMatches.fulfilled, (state, action) => {
        const liveMatches = action.payload;
        state.liveMatches = liveMatches.map((c) => c.providerId);
        addMatchesToState(state.events, liveMatches);
        liveMatches.forEach((event) => {
          setTournamentName(state.tournamentNames, event);
        });
      })
      .addCase(fetchSportCountsWithHours.fulfilled, (state, action) => {
        const {
          categoryNames,
          counts
          // topMatches
        } = action.payload;
        state.counts = { ...counts };
        state.categoryNames = { ...categoryNames };
      })
      .addCase(fetchSportCounts.fulfilled, (state, action) => {
        const { counts } = action.payload;
        state.timeCounts = { ...counts };
      })
      .addCase(fetchCompetitorsNameByLanguage.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(fetchEventsByCategoryWithHours.fulfilled, (state, action) => {
        const { betEvents } = action.payload;
        const objToEventsArr = Object.values(betEvents).flat();
        addMatchesToState(state.events, objToEventsArr, 0);
        objToEventsArr.forEach((event) => {
          setTournamentName(state.tournamentNames, event);
        });
        // state.filteredEventIds = objToEventsArr.map((c) => c.providerId);
      })
      .addCase(fetchEventsByCategory.fulfilled, (state, action) => {
        const { betEvents } = action.payload;
        const objToEventsArr = Object.values(betEvents).flat();
        addMatchesToState(state.events, objToEventsArr, 0);
        objToEventsArr.forEach((event) => {
          setTournamentName(state.tournamentNames, event);
        });
        state.filteredEventIds = objToEventsArr.map((c) => c.providerId);
      })
      .addCase(fetchEventsBySport.fulfilled, (state, action) => {
        const { betEvents } = action.payload;
        const objToEventsArr = Object.values(betEvents)
          .map((c) => Object.values(c))
          .flat()
          .flat();
        addMatchesToState(state.events, objToEventsArr, 0);
        objToEventsArr.forEach((event) => {
          setTournamentName(state.tournamentNames, event);
        });
        state.filteredEventIds = objToEventsArr.map((c) => c.providerId);
      })
      .addCase(getBetSlipMatches.fulfilled, (state, action) => {
        const events = action.payload;
        if (events && events.length > 0) {
          addMatchesToState(state.events, events, 1);
          events.forEach((event) => {
            setTournamentName(state.tournamentNames, event);
          });
        }
      })
      .addCase(fetchNextLiveMatches.fulfilled, (state, action) => {
        const { betEvents, ...meta } = action.payload;
        state.nextLiveMatchesMeta = meta;
        state.nextLiveMatches = [
          ...state.nextLiveMatches,
          ...betEvents
            .filter((c) => c && !checkIfLiveEvent(c))
            .sort((a, b) => {
              const dateA = new Date(a.gameDate) ?? 0;
              const dateB = new Date(b.gameDate) ?? 0;
              return dateA - dateB;
            })
            .map((c) => c.providerId)
        ];
        state.nextLiveMatches = [...new Set(state.nextLiveMatches)];
        addMatchesToState(state.events, betEvents);
        betEvents.forEach((event) => {
          setTournamentName(state.tournamentNames, event);
        });
      });
  }
});

export const {
  setSelectedMatch,
  setProducerStatus,
  setLocales,
  setSelectedSport,
  setSelectedClearFavoritesSport,
  setCategoryNames,
  addMatch,
  updateMatch,
  deleteMatch,
  setSelectedLiveDesktopSport,
  setDesktopSearchParams,
  setNextLiveMatches,
  setSelectedLeague,
  updateLiveFootballEventsTime
} = matchSlice.actions;

export default matchSlice.reducer;
