import { Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import Default from 'components/breakpoints/Default';
import Desktop from 'components/breakpoints/Desktop';
import ScrollButton from './_components/ScrollButton';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { useSelector } from 'react-redux';

const FooterMenu = () => {
  const { t } = useTranslation('translations', { useSuspense: false });
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  if (location.pathname === '/slot-game' || location.pathname === '/casino') return <Fragment />;

  return (
    <>
      {location.pathname === '/' && (
        <Default>
          <div className="container-fluid bg-primary text-start mt-auto">
            {location.pathname == '/bet-slip' ? null : (
              <footer className="pb-7 border-top w-100 ">
                {user && (
                  <Row>
                    <NavLink
                      end
                      to="/"
                      className="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
                      <img
                        width="185"
                        height="45"
                        className={'ms-n2'}
                        src={`${AWS_BUCKET_URL}/Icons/game90.betlogobeyaz.png`}
                      />
                    </NavLink>
                  </Row>
                )}
                <Row className="pb-3">
                  <Col xs={4}>
                    <h5 className="text-white"> {t('pages.footer.section')}</h5>
                    <hr className="my-2 border-bottom border-top-0 border-white opacity-100" />
                    <ul className="nav flex-column">
                      <li className="nav-item mb-2">
                        <NavLink to="/" className="nav-link p-0 text-white">
                          {t('pages.footer.sportsBetting')}
                        </NavLink>
                      </li>
                      <li className="nav-item mb-2">
                        <NavLink to="/live" className="nav-link p-0 text-white">
                          {t('pages.footer.liveBetting')}
                        </NavLink>
                      </li>
                      <li className="nav-item mb-2">
                        <NavLink to="/" className="nav-link p-0 text-white">
                          {t('pages.footer.longTermBets')}
                        </NavLink>
                      </li>
                      <li className="nav-item mb-2">
                        <NavLink to="/results" className="nav-link p-0 text-white">
                          {t('pages.footer.results')}
                        </NavLink>
                      </li>
                      <li className="nav-item mb-2">
                        <NavLink to="#" className="nav-link p-0 text-white">
                          {t('pages.footer.app')}
                        </NavLink>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={4}>
                    <h5 className="text-white">{t('pages.footer.information')}</h5>
                    <hr className="my-2 border-bottom border-top-0 border-white opacity-100" />
                    <ul className="nav flex-column">
                      <li className="nav-item mb-2">
                        <NavLink to="/" className="nav-link p-0 text-white">
                          {t('pages.footer.help')}
                        </NavLink>
                      </li>
                      <li className="nav-item mb-2">
                        <NavLink to="/" className="nav-link p-0 text-white">
                          {t('pages.footer.sportRules')}
                        </NavLink>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={4}>
                    <h5 className="text-white">{t('contact')}</h5>
                    <hr className="my-2 border-bottom border-top-0 border-white opacity-100" />
                    <ul className="nav flex-column">
                      <li className="nav-item mb-2">
                        <NavLink to="/" className="nav-link p-0 text-white">
                          {t('pages.footer.aboutUs')}
                        </NavLink>
                      </li>
                      <li className="nav-item mb-2">
                        <NavLink to="/" className="nav-link p-0 text-white">
                          {t('pages.footer.support')}
                        </NavLink>
                      </li>
                      <li className="nav-item mb-2">
                        <NavLink to="/" className="nav-link p-0 text-white">
                          {t('pages.footer.gtc')}
                        </NavLink>
                      </li>
                      <li className="nav-item mb-2">
                        <NavLink to="/" className="nav-link p-0 text-white">
                          {t('pages.footer.privacyPolicy')}
                        </NavLink>
                      </li>
                      <li className="nav-item mb-2">
                        <NavLink to="/" className="nav-link p-0 text-white">
                          {t('pages.footer.legalNote')}
                        </NavLink>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </footer>
            )}
          </div>
        </Default>
      )}
      <Desktop>
        <div className="container-fluid bg-header text-start mt-auto">
          <footer className="py-5 w-100">
            <Row>
              <Row>
                <Col xs={11} />
                <Col xs={1}>
                  <ScrollButton />
                </Col>
              </Row>
              <Col xs={5}>
                {user && (
                  <NavLink
                    to="/"
                    className="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
                    <img
                      width="185"
                      height="45"
                      className={'ms-n2'}
                      src={`${AWS_BUCKET_URL}/Icons/game90.betlogobeyaz.png`}
                    />
                  </NavLink>
                )}
                <p className="text-white">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quaerat voluptatum autem
                  similique rem aliquid dolore magnam animi blanditiis nemo, quia exercitationem?
                  Odit officia delectus ipsum iure, laudantium nostrum reiciendis illum?
                </p>
                <p className="text-white">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur quia placeat
                  similique iusto neque eligendi delectus sed, sunt magnam ducimus earum. Cum
                  corrupti incidunt pariatur, enim nemo in atque velit?
                </p>
                <p className="text-white">© 2023</p>
              </Col>
              <Col xs={2}>
                <h5 className="text-white"> {t('pages.footer.section')}</h5>
                <hr className="my-2 border-bottom border-top-0 border-white opacity-100" />
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <NavLink to="/" className="nav-link p-0 text-white">
                      {t('pages.footer.sportsBetting')}
                    </NavLink>
                  </li>
                  <li className="nav-item mb-2">
                    <NavLink to="/live" className="nav-link p-0 text-white">
                      {t('pages.footer.liveBetting')}
                    </NavLink>
                  </li>
                  <li className="nav-item mb-2">
                    <NavLink to="/" className="nav-link p-0 text-white">
                      {t('pages.footer.longTermBets')}
                    </NavLink>
                  </li>
                  <li className="nav-item mb-2">
                    <NavLink to="/results" className="nav-link p-0 text-white">
                      {t('pages.footer.results')}
                    </NavLink>
                  </li>
                  <li className="nav-item mb-2">
                    <NavLink to="#" className="nav-link p-0 text-white">
                      {t('pages.footer.app')}
                    </NavLink>
                  </li>
                </ul>
              </Col>
              <Col xs={2}>
                <h5 className="text-white">{t('pages.footer.information')}</h5>
                <hr className="my-2 border-bottom border-top-0 border-white opacity-100" />
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <NavLink end to="/" className="nav-link p-0 text-white">
                      {t('pages.footer.help')}
                    </NavLink>
                  </li>
                  <li className="nav-item mb-2">
                    <NavLink to="/" className="nav-link p-0 text-white">
                      {t('pages.footer.sportRules')}
                    </NavLink>
                  </li>
                </ul>
              </Col>
              <Col xs={2}>
                <h5 className="text-white">{t('contact')}</h5>
                <hr className="my-2 border-bottom border-top-0 border-white opacity-100" />
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <NavLink to="/" className="nav-link p-0 text-white">
                      {t('pages.footer.aboutUs')}
                    </NavLink>
                  </li>
                  <li className="nav-item mb-2">
                    <NavLink to="/" className="nav-link p-0 text-white">
                      {t('pages.footer.support')}
                    </NavLink>
                  </li>
                  <li className="nav-item mb-2">
                    <NavLink to="/" className="nav-link p-0 text-white">
                      {t('pages.footer.gtc')}
                    </NavLink>
                  </li>
                  <li className="nav-item mb-2">
                    <NavLink to="/" className="nav-link p-0 text-white">
                      {t('pages.footer.privacyPolicy')}
                    </NavLink>
                  </li>
                  <li className="nav-item mb-2">
                    <NavLink to="/" className="nav-link p-0 text-white">
                      {t('pages.footer.legalNote')}
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </footer>
        </div>
      </Desktop>
    </>
  );
};

export default FooterMenu;
