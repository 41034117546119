import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AWS_BUCKET_URL } from '../../toolkit/constants';
import TitleHistoryBack from 'components/general/TitleHistoryBack';

export default function Bank() {
  const { t } = useTranslation('translations', { useSuspense: false });

  return (
    <Container>
      <TitleHistoryBack location="/profile" title={t('bank')} subTitle={t('profile')} />
      <Row>
        <Col xs={1}>
          <img className="float-start" src={`${AWS_BUCKET_URL}/Icons/123.png`}></img>
        </Col>
        <Col xs={4}>
          <Link to="/transactions" className="float-start">
            {t('myfinance')}
          </Link>
        </Col>
      </Row>
      <div className="mt-2"></div>
      <hr className="m-0"></hr>
      <div className="mt-2"></div>
      <Row>
        <Col xs={1}>
          <img className="float-start" src={`${AWS_BUCKET_URL}/Icons/124.png`}></img>
        </Col>
        <Col xs={3}>
          <Link to="/payout" className="float-start ">
            {t('payout')}
          </Link>
        </Col>
      </Row>
      <div className="mt-2"></div>
      <hr className="m-0"></hr>
      <></>
      <></>
    </Container>
  );
}
