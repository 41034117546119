import { tmmInstance } from 'libraries/axios';

export const postPlaceBetReq = (body) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/Bet/PlaceBetRequest', body)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const postAutoBetReq = (body) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/Bet/RequestAutoBet', body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const postCashoutBetSlipReq = (body) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/Bet/CashOutBetSlip', body)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getBetSlipMatchesReq = (body) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/BetEvent/GetSlipEvents', body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};
