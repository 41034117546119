import {
  oddsLadderStages,
  oddsInverseMultipliers,
  oddsLimits,
  EOddsTransformerRounding
} from './oddsConstants';

const oddsTransformer = () => {
  const findLadderForOdds = (odds) => {
    let low = 0,
      high = oddsLadderStages.length - 1,
      mid = 0;

    while (low <= high) {
      mid = (low + high) / 2;

      if (odds < oddsLadderStages[mid].start) {
        high = mid - 1;
      } else if (odds >= oddsLadderStages[mid].end) {
        low = mid + 1;
      } else {
        return oddsLadderStages[mid];
      }
    }

    return null;
  };

  const getInverseOddsMultiplier = (now, isLive, marketNumericId, sportEventStartTime) => {
    const hoursToStartOfEvent = (now.valueOf() - sportEventStartTime.valueOf()) / 1000 / 60 / 60;

    if (hoursToStartOfEvent > oddsInverseMultipliers.beforeStart.hours) {
      return oddsInverseMultipliers.beforeStart.value;
    }
    if (isLive) {
      return oddsInverseMultipliers.live.value;
    }

    if (oddsInverseMultipliers.nearToStart.a.markets.has(marketNumericId)) {
      return oddsInverseMultipliers.nearToStart.a.value;
    }

    return oddsInverseMultipliers.nearToStart.b.value;
  };

  const transform = (now, isLive, isOutRights, marketNumericId, sportEventStartTime, odds) => {
    const oddsInverseMultiplier = getInverseOddsMultiplier(
      now,
      isLive,
      marketNumericId,
      sportEventStartTime
    );

    const inverseMultipliedOdds = odds / oddsInverseMultiplier;
    if (inverseMultipliedOdds < 1.0001) {
      return 1.0;
    }
    const maxOdds = isLive
      ? oddsLimits.liveMax
      : isOutRights
      ? oddsLimits.outRightsMax
      : oddsLimits.preMatchMax;

    if (inverseMultipliedOdds >= maxOdds) {
      return maxOdds;
    }

    const ladder = findLadderForOdds(inverseMultipliedOdds);
    if (ladder === null) {
      return inverseMultipliedOdds >= oddsLimits.minOddsValueAllowedAfterOddsTransformation
        ? inverseMultipliedOdds
        : oddsLimits.minOddsValueAllowedAfterOddsTransformation;
    }

    const offsetForRounding = ladder.increment * ladder.tolerance;
    const diffN = (inverseMultipliedOdds + offsetForRounding - ladder.start) / ladder.increment;
    const smoothedN =
      ladder.round === EOddsTransformerRounding.Middle
        ? Math.round(diffN)
        : ladder.round === EOddsTransformerRounding.Down
        ? Math.floor(diffN)
        : Math.ceil(diffN);

    const ladderOdds = ladder.start + smoothedN * ladder.increment;
    var ladderOdds3d = Math.round(1000 * ladderOdds) / 1000;

    return Math.max(ladderOdds3d, 1.0);
  };

  return Object.freeze({
    transform
  });
};

export default oddsTransformer;
