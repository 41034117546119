import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form, FloatingLabel, Alert, Col } from 'react-bootstrap';
import TitleHistoryBack from 'components/general/TitleHistoryBack';
import FetchButton from 'components/general/FetchButton';
import { errorToaster, showToaster } from 'mixin/general';
import { changePasswordReq } from 'stores/auth/services';
import { AWS_BUCKET_URL } from '../../toolkit/constants';
import { useMediaQuery } from 'react-responsive';
import LeftSidebar from 'views/home/_desktop/left_sidebar';
import RightSidebar from 'views/home/_desktop/right_Sidebar';
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {
  const { t } = useTranslation('translations');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: ''
  });
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleFormDataChange = (key, value) => {
    setFormData((oldValue) => ({ ...oldValue, [key]: value }));
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (!form.checkValidity()) throw new Error(t('pages.changePassword.errorFillForm'));
    if (formData.newPassword !== formData.newPasswordRepeat)
      throw new Error(t('pages.changePassword.errorUnmatchedPassword'));
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      handleSubmit(e);
      setIsSubmitting(true);
      const response = await changePasswordReq(formData);
      if (!response) {
        throw new Error('Login error');
      }
      showToaster(t('pages.changePassword.successMessageChangePassword'), 'success');
    } catch (error) {
      errorToaster(error);
    }
    setIsSubmitting(false);
  };

  const getData = () => {
    return (
      <Form noValidate validated={validated} onSubmit={handleLogin}>
        <Form.Floating className="mb-3 fs-7">
          <FloatingLabel
            controlId="oldPassword"
            label={t('pages.changePassword.form.oldPassword.label')}>
            <Form.Control
              type="password"
              value={formData.oldPassword}
              onInput={(event) => handleFormDataChange('oldPassword', event.target.value)}
              placeholder={t('pages.changePassword.form.oldPassword.placeholder')}
              required
            />
          </FloatingLabel>
        </Form.Floating>
        <Form.Floating className="mb-3 fs-7">
          <FloatingLabel
            controlId="newPassword"
            label={t('pages.changePassword.form.newPassword.label')}>
            <Form.Control
              type="password"
              minLength={8}
              value={formData.newPassword}
              onInput={(event) => handleFormDataChange('newPassword', event.target.value)}
              placeholder={t('pages.changePassword.form.newPassword.placeholder')}
              required
            />
          </FloatingLabel>
        </Form.Floating>
        <Form.Floating className="mb-3 fs-7">
          <FloatingLabel
            controlId="confirmPassword"
            label={t('pages.changePassword.form.confirmPassword.label')}>
            <Form.Control
              type="password"
              minLength={8}
              value={formData.newPasswordRepeat}
              onInput={(event) => handleFormDataChange('newPasswordRepeat', event.target.value)}
              placeholder={t('pages.changePassword.form.confirmPassword.placeholder')}
              required
            />
          </FloatingLabel>
        </Form.Floating>
        <Alert variant={'danger'} className="d-flex align-items-center text-start fs-7">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/133.png`} />
          <span className="ms-2">{t('pages.changePassword.infoPasswordRequirements')}</span>
        </Alert>
        <FetchButton className="w-100" type="submit" variant="success" fetching={isSubmitting}>
          {t('pages.changePassword.form.buttonSubmit')}
        </FetchButton>
      </Form>
    );
  };
  if (isDesktop) {
    return (
      <div className="container-fluid py-2">
        <div className="row flex-nowrap">
          <Col xs={2} className="filter-sidebar" onClick={() => navigate('/sport')}>
            <LeftSidebar />
          </Col>
          <Col xs={7} className="desktop-main">
            {getData()}
          </Col>
          <Col xs={3}>
            <RightSidebar />
          </Col>
        </div>
      </div>
    );
  } else {
    return (
      <Container>
        <TitleHistoryBack subTitle={t('myprofile')} />
        {getData()}
      </Container>
    );
  }
}
