import './match_tracker_theme.css';

const MatchTracker = (props) => {
  const { matchId, language, isDesktop } = props;

  return matchId != '0' && matchId != '' ? (
    <div>
      {/* {loading && <Loading message="" />}
      <div
        ref={matchTrackerRef}
        className={`matchtracker sr-widget sr-widget-1 ${loading ? 'd-none' : ''}`}></div> */}
      <iframe
        src={`https://trackerv2.yextof.com/preview.php?55&id=${matchId}&token=f28030ff1a1528e1fcc991975d76b8f0&lang=${language}`}
        width="100%"
        height={400}
        className={isDesktop ? 'match-tracker-desktop' : ''}
        scrolling={isDesktop ? 'yes' : 'no'}
        id="matchTrackerFrame"></iframe>
    </div>
  ) : null;
};

export default MatchTracker;
