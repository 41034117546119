import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from 'language/en.json';
import de from 'language/de.json';
import tr from 'language/tr.json';
import nl from 'language/nl.json';
import bg from 'language/bg.json';
import fr from 'language/fr.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translations: en
      },
      de: {
        translations: de
      },
      tr: {
        translations: tr
      },
      nl: {
        translations: nl
      },
      bg: {
        translations: bg
      },
      fr: {
        translations: fr
      }
    },
    ns: ['translations'],
    nsSeparator: '~',
    defaultNS: 'translations',
    debug: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
