import { Container, Row, Col, Button } from 'react-bootstrap';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function Calendar(props) {
  const { onCalendarSubmit, initialDate } = props;
  const [startDate, setStartDate] = useState(new Date(initialDate.startDate));
  const [endDate, setEndDate] = useState(new Date(initialDate.endDate));
  const { t } = useTranslation('translations', { useSuspense: false });
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <Container fluid className="bettinghistory">
      <Row className="text-center my-3">
        <Col xs={6}>
          <span className="fw-bold fs-8">{t('pages.bettinghistory.calendar.from')}</span>
          <div className="text-muted fs-8">
            {startDate
              ? dayjs(startDate).format('D MMM YYYY')
              : t('pages.bettinghistory.calendar.emptyDate')}
          </div>
        </Col>
        <Col xs={6}>
          <span className="fw-bold fs-8">{t('pages.bettinghistory.calendar.to')}</span>
          <div className="text-muted fs-8">
            {endDate
              ? dayjs(endDate).format('D MMM YYYY')
              : t('pages.bettinghistory.calendar.emptyDate')}
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-center mb-3">
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
        />
      </div>
      <Button
        className="w-100 mb-3 bg-danger text-white border-0 rounded px-2 py-2 fw-bold m-2 "
        type="button"
        onClick={() => onCalendarSubmit(startDate, endDate)}>
        {t('pages.bettinghistory.calendar.buttonLabel')}
      </Button>
    </Container>
  );
}
