import { Tabs, Tab } from 'react-bootstrap';
export default function EN() {
  return (
    <Tabs justify className="mb-4 py-2 text-start me-1 ms-1">
      <Tab eventKey="overview" className="py-2 text-start me-1 ms-1" title="Overview">
        <h2 className="text-danger">OVERVIEW</h2>
        <p className="mt-3">
          &quot;Follow your instinct&quot;. Our company motto characterizes the attribute of bettors
          exactly: The feeling to be right on the money. Follow your instincts and profit from our
          extensive betting offers, high betting limits and our odds, which are available for long
          periods and are updated in a matter of seconds. You want to know more about Game90? Here
          we have an overview for you. Additional information - relevant to the game. <br />
          <br />
          Additional information - relevant to the game.
        </p>
        <p className="mt-75">
          1. All costs caused by participation
          <br />
          <br /> Participation in a sports bet on our site does not entail any additional costs
          other than the stake and the betting tax.
        </p>
        <p className="mt-75">
          2. the amount of all profits,
          <br />
          <br /> The maximum profit can be calculated as: stake x betting odds.
        </p>
        <p className="mt-75">
          3. when and where all prizes will be published,
          <br />
          <br /> Winnings are published in the player account under &quot;My bets&quot; and all game
          results can be reviewed at game90.bet/results
        </p>
        <p className="mt-75">
          4. the percentage of payouts for winnings from the stake (the payout percentage),
          <br />
          <br /> Sports betting does not have a fixed payout ratio, as it is a result-oriented
          event.
        </p>
        <p className="mt-75">
          5. Information on the probability of profit and loss,
          <br />
          <br /> In contrast to casino or Slot games, the actual probabilities of the outcome of a
          sporting event are not known. The betting provider offers individual odds with implicit
          probabilities for these events. The possible winnings will be displayed to the customer
          before the bet is placed and will also be shown on the betting slip. When specifying the
          quota, Game90 Ltd. Quota key between 103% and 117% is used. An odds key of e.g. 105% means
          that if a player bets on all possible events with the same amount on a bet, the player
          statistically loses (1-100 / 105)% = 4.76% of the bet.
        </p>
        <p className="mt-75">
          6. the closing date for participation,
          <br />
          <br /> The acceptance deadline is the timestamp when the bet is placed.
        </p>
        <p className="mt-75">
          7. the method by which the winner is determined, in particular the information about the
          random mechanism on which the generation of the random game results is based,
          <br />
          <br /> The evaluation of a game tip is based on the outcome of sporting events. We obtain
          these results from established service providers such as Sportsradar, Perform Group and
          Betgenius, as well as from the official results services of the respective associations.
          There is no random mechanism in sports betting like it is in slot machines and casino
          games.
        </p>
        <p className="mt-75">
          8. How prizes will be shared between the winners,
          <br />
          <br /> The winnings will not be split between different players. There is a betting slip
          that depicts the betting contract between the betting customer and the betting operator.
          In the event of a win, the bettor receives the stated profit. A so-called totalizator, as
          is common in horse betting, is not included in the Game90 Ltd. betting offer.
        </p>
        <p className="mt-75">
          9. the deadline by which winners must claim their winnings,
          <br />
          <br /> The winnings are automatically credited to the player&apos;s account after the bet
          has occurred or the sporting events have ended
        </p>
        <p className="mt-75">
          10. the name of the license holder and his contact details (address, e-mail, telephone),
          <br />
          <br /> Game90 Ltd. represented by Mr Simon Sullivan with the following company address:
          1st Floor of Melita Court, Giuseppe Cali St, c / w Abate Rigord St, XBX1420 Ta&apos;Xbiex,
          Malta. The company can be reached at the following email address: info@game90.bet
        </p>
        <p className="mt-75">
          11. the commercial register number (if available),
          <br />
          <br /> Game90 Ltd. is a company registered in Malta with company registration number
          C50436.
        </p>
        <p className="mt-75">
          12. how the player can submit its complaints,
          <br />
          <br /> The player has the possibility to contact us directly via the live chat,
          support@game90.bet. If the player is not satisfied with the way the complaint has been
          handled or resolved by the Game90 customer service team, customers can alternatively
          submit their disputes to the Alternative Dispute Resolution (ADR) unit by clicking the
          EADR link here of the service provider: https://eadr.org/eadr-form/. Once users have
          received the final decision from Game90, users have the free right to access this ADR and
          refer their disputes there.
        </p>
        <p className="mt-75">
          13. the date of the permit issued.
          <br />
          <br /> International license: Malta Gaming Authority (MGA) License number:
          MGA/B2C/191/2010 License issued on: 18.12.2011
          <br />
          <br /> German license: Officially approved sports betting operator under the supervision
          of the Joint Gaming Authority of the Federal States (GGL). The Game90 Ltd. the official
          permit to organize sports betting on the internet and in stationary areas was granted (for
          the first time) by decision dated November 19, 2020; this was extended on December 22,
          2022 (GGL whitelist).
        </p>
      </Tab>
      <Tab
        eventKey="companyInformation"
        className="py-2 text-start me-1 ms-1"
        title="Company Information">
        <h2 className="text-danger">COMPANY INFORMATION</h2>
        <p className="mt-3">
          Game90 was established in 2010 in Malta, has been registered as a company there, and has
          been officially licensed as a betting provider since 2011. The founders of Game90 have
          long-standing betting expertise and innovative ideas — in 2005 they developed a completely
          new format with the LiveBet 0:0 concept. LiveBet 0:0, also frequently known as a rest of
          the match bet, is a ﬁxed element of any betting provider&apos;s range these days. In this
          fast-growing market Game90 became accepted by its own odds management and own innovative
          bet types. An easy and smooth procedure, updates of the odds within seconds and a secure
          platform have been ensured by a large team of developers and a high number of odds
          specialists working in the background. In total, more than 200 members of staff throughout
          Europe and over 3,000 match observers worldwide work together in partnership to ensure the
          quality of the odds and the platform.
        </p>
        <div>
          <h5 className="text-danger mt-75"> Your benefits at Game90 </h5>
          <ul>
            <li key={1} className="mt-3">
              <b>Faster betting:</b> We specialise in football and the most popular bet types – so
              you can place bets more easily and quickly with us.
            </li>
            <li key={2} className="mt-3">
              <b>Stable odds:</b> Thanks to monitoring by our specialists, our odds are signiﬁcantly
              more stable and noticeably faster available again. This means you can place a new bet
              immediately after an event such as a goal took place.
            </li>
            <li key={3} className="mt-3">
              <b>Highest potential winnings:</b> Highest potential winnings: We offer uniquely high
              stake limits and potential winnings of up to EUR 100,000 per betting slip.
            </li>
            <li key={4} className="mt-3">
              <b>No blocking of gamers:</b> No blocking of gamers: We do not limit or exclude gamers
              with high winnings from gaming.
            </li>
            <li key={5} className="mt-3">
              <b>Varied betting service:</b> Varied betting service: You have the choice — place
              bets daily on up to 350 international matches.
            </li>
            <li key={6} className="mt-3">
              <b>Secure platform:</b> game90.bet conforms to the highest security standards. Its own
              programming ensures its continuous further development — to keep your data secure.
            </li>
            <li key={7} className="mt-3">
              <b>Experience and know-how:</b> Game90 was founded on years of experience. With us,
              you are in good hands.
            </li>
          </ul>
          <br />
        </div>
        <h5 className="text-danger mt-75">Our company philosophy</h5>
        <p>
          Our vision is to make bets as simple as possible while giving our customers more time to
          enjoy betting and the match itself. We aim to establish Game90 as a brand which stands out
          from the competition by focusing on stable, fast odds, new bet types and being easy to use
          – because these are the qualities that make Game90 unique. We want to be a reliable
          partner and innovative pioneer for our customers in the ﬁeld of sports bets and thus
          continuously develop our services further.
        </p>
        <h5 className="text-danger mt-75">
          Our values — this is important to us when dealing with our customers
        </h5>
        <ul>
          <li key={8} className="mt-3">
            <b>Fairness:</b> Whether customer or business partner – we deal fairly with everyone.
          </li>
          <li key={9} className="mt-3">
            <b>Competence:</b> We know what we&apos;re doing – because we have top experts working
            for us.
          </li>
          <li key={10} className="mt-3">
            <b>Honesty:</b> We give honest replies – even though this might be uncomfortable.
          </li>
          <li key={11} className="mt-3">
            <b>Commitment: </b> We put all our energy into our customers and partners so they are
            being satisfied with us.
          </li>
          <li key={12} className="mt-3">
            <b>Reliability:</b>You can trust what we say – we keep to agreement
          </li>
          <li key={13} className="mt-3">
            <b>Respect</b> We respect the sensitivity of our customers‘ and partners‘ data and
            handle it securely.
          </li>
        </ul>
        <h5 className="text-danger mt-75">Our licence</h5>
        <p className="mt-3">
          Game90 is registered in Malta with commercial register number C50436 at the following
          business address: Game90 Ltd., 1st Floor of Melita Court, Giuseppe Cali St, c/w Abate
          Rigord St, XBX1420 Ta&apos;Xbiex, Malta. Game90 Ltd. is subject to the strict guidelines
          of the Malta Gaming Authority (MGA) and is officially licensed by this authority as a
          betting provider for remote gaming (MGA/B2C/191/2010). You can ﬁnd more information about
          MGA&apos;s licensing process in English at www.mga.org.mt
        </p>
        <h5 className="text-danger mt-75">German license</h5>
        <p className="mt-3">
          Officially approved sports betting operator under the supervision of the Joint Gaming
          Authority of the Federal States (GGL). The Game90 Ltd. the official permit to organize
          sports betting on the internet and in stationary areas was granted (for the first time) by
          decision dated November 19, 2020; this was extended on December 22, 2022 (GGL whitelist).
        </p>
      </Tab>
      <Tab eventKey="dataProtection" className="py-2 text-start me-1 ms-1" title="Data Protection">
        <h2 className="text-danger">DATA PROTECTION</h2>
        <p className="mt-3">
          It is important to us that your data is secure in our hands. Thanks to our network of
          developers, we ensure that your sensitive information is always managed to the highest
          safety standards.
        </p>
        <h5 className="text-danger mt-75">What information do we gather?</h5>
        <p className="mt-3">
          We collect the personal data of visitors to this website that are either using online
          forms or sending data by e-mail. We also gather information (via our secure payment
          processing system) about the transactions made by you, including the data of the credit
          cards used. We collect additional data automatically when you call up our website.
        </p>
        <h5 className="text-danger mt-75">Why do we need this information and how do we use it?</h5>
        <p>Your data that we collect via this website is used for the following purposes:</p>
        <ul>
          <li key={14} className="mt-3">
            For the provision and customisation of our services,
          </li>
          <li key={15} className="mt-3">
            For processing your questions and requests,
          </li>
          <li key={16} className="mt-3">
            For performing market research studies,
          </li>
          <li key={17} className="mt-3">
            For getting in touch with you (by e-mail) about what we offer and for information about
            our products and services.
          </li>
        </ul>
        <p className="mt-3">
          With your express agreement, we also pass your data on to third parties who offer products
          or services which we believe may be of interest to you. You can agree to this during
          registration by ticking the relevant checkbox. You can withdraw your agreement at any time
          in your account settings or by sending an e-mail to dpo@game90.bet. In addition, each
          e-mail not relating to the settlement of bets on game90.bet contains a link at the bottom
          with which you can remove your address from the distribution list. Our data processing
          practice and your options regarding the use of your personal data are regulated in our
          Privacy Policy. If you would like to have information about your personal data, or have
          questions about this policy, please contact dpo@game90.bet.With your express agreement, we
          also pass your data on to third parties who offer products or services which we believe
          may be of interest to you. You can agree to this during registration by ticking the
          relevant checkbox. You can withdraw your agreement at any time in your account settings or
          by sending an e-mail to dpo@game90.bet. In addition, each e-mail not relating to the
          settlement of bets on game90.bet contains a link at the bottom with which you can remove
          your address from the distribution list. Our data processing practice and your options
          regarding the use of your personal data are regulated in our Privacy Policy. If you would
          like to have information about your personal data, or have questions about this policy,
          please contact dpo@game90.bet.
        </p>
        <h5 className="text-danger mt-75">PCI standard – We observe PCI standard for payments.</h5>
        <p className="mt-3">
          Game90 Ltd. only works with payment service providers that operate in accordance with the
          Payment Card Industry Data Security Standards (PCI). This ensures that the strict PCI
          requirements are met when storing and processing data.
        </p>
      </Tab>
      <Tab eventKey="prevention" className="py-2 text-start me-1 ms-1" title="Prevention">
        <h2 className="text-danger ">PREVENTION</h2>
        <p className="mt-3">
          Only those who bet responsibly and consciously will enjoy it in the long run. Gambling can
          be entertaining and profitable. However, there is always a portion of luck involved in
          betting success. For this reason, you should not take the risk too high and not gamble a
          disproportionate amount of money. We want you to enjoy betting and to be able to assess
          your gambling behavior correctly. Therefore, we provide some information on prevention and
          where to get help. Game90 can also help you regulate your gambling behavior at any time.
          If you have questions or problems, we can help you to find the right kind of assistance.
          Gambling can be addictive. Always play responsibly.
        </p>
        <p className="mt-3">
          For minors, participation in gambling of any kind, including sports betting, is strictly
          prohibited. The player protection officer of Game90 Ltd. is Steffen Schröder, who can be
          reached at any time by e-mail under rgaming@game90.bet or by phone under +356 2276 9103.
          We are obliged by law to cancel bets placed by minors and any winnings that may have been
          made
        </p>
        <h5 className="text-danger mt-75">How you set yourself limits</h5>
        <ul>
          <li key={18} className="mt-3">
            Determine your maximum total stake for the next week or the next month in advance and do
            not increase this amount subsequently.
          </li>
          <li key={19} className="mt-3">
            Do not pay more money into your gaming account than this amount speciﬁed at the start.
            This also applies in case of lost games.
          </li>
          <li key={20} className="mt-3">
            Decide beforehand that you will stop when you reach a certain amount of winnings.
          </li>
          <li key={21} className="mt-3">
            If necessary, reduce your limit to ensure that you cannot bet more money than you
            intended.
          </li>
          <li key={22} className="mt-3">
            You should only play if you are fully rested and concentrated.
          </li>
          <li key={23} className="mt-3">
            Take regular breaks in which you do something else.
          </li>
          <li key={24} className="mt-3">
            Never play under the inﬂuence of drugs, strong medication or excessive amounts of
            alcohol.
          </li>
          <li key={25} className="mt-3">
            Do not play if you are feeling particularly unwell or depressed.
          </li>
        </ul>
        <p className="mt-3">
          We give all players the opportunity to set their own limits or to block their own accounts
          for a certain period of time. You can change your own limits in your account settings by
          sending us a message via the support form. Please note that an increase of the limit(s)
          only comes into effect seven days after receipt of your request. If a limit is reduced,
          the new limit is valid with immediate effect. Consequently, Game90 does not accept any
          bets that exceed the limits set by the customer. To block your account, please contact our
          support. You can block your account for a limited period or permanently.
        </p>
        <h5 className="text-danger mt-75">+18</h5>
        <p className="mt-3">
          Minors are forbidden by law to open an account or participate in betting at game90.bet. In
          order to be able to place bets at Game90, you need to conﬁrm on registration that you are
          at least 18 years old, and give your date of birth. For legal reasons, this must be
          veriﬁed with a copy of your legal photo ID when you ﬁrst request a payout. Game90 Ltd.
          also reserves the right to request the proof of age of an user independently of a payout
          and to close the account in question until the proof has been provided. All user accounts
          will be investigated on a regular time base to avoid fraud and under aged behavior.
        </p>
        <h5 className="text-danger mt-75">Protect your children</h5>
        <p className="mt-3">
          Are you uncertain whether your children might be able to access this site? If you share
          your computer with minors, you should ensure that they are unable to access your username,
          passwords and payment details. You can ﬁnd useful information about child-safe protection
          for computers and data and about ﬁlter solutions at www.betterinternetforkids.eu. For the
          protection of minors, we also recommend visiting the following sites for information:
          www.netnanny.com and www.cyberpatrol.com
        </p>
        <h5 className="text-danger mt-75">Selftest</h5>
        <p className="mt-3">
          If you are not sure whether you have a gambling addiction or not, you can test your
          gambling behaviour on the following website: www.gamblersanonymous.org
        </p>
        <h5 className="text-danger mt-75">Who can I contact and where do I ﬁnd help?</h5>
        <p className="mt-3">
          Game90 offers you immediate help and support in case of gambling addiction or for the
          prevention of gambling addiction. You can contact our player protection officer Steffen
          Schröder at any time, who is trained accordingly and can help you with any problems. You
          can reach him by e-mail under rgaming@game90.bet
        </p>
        <p className="mt-3">
          Of course you can also get confidential advice and help from our Game90 prevention expert
          Steffen Schröder at rgaming@game90.bet
        </p>
        <p className="mt-3">
          If you do not want to speak directly to our Game90 team, you can also contact an official
          authority with confidence:
        </p>
        <p className="mt-3">
          You also may contact the free helpline on 0808 8020 133 ,for confidential and specialist
          support.
        </p>
      </Tab>
    </Tabs>
  );
}
