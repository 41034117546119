import Desktop from 'components/breakpoints/Desktop.js';
import Default from 'components/breakpoints/Default.js';
import StartDesktop from 'views/home/HomeDesktop.js';
import SportMobile from './SportMobile';

function Sport() {
  return (
    <>
      <Desktop>
        <StartDesktop />
      </Desktop>
      <Default>
        <SportMobile />
      </Default>
    </>
  );
}
export default Sport;
