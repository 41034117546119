import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BetSlipList from 'views/betslip/bet_slip';

export default function RightSidebar() {
  const betSlipBetsLength = useSelector((state) => state.betSlip.bets.length);
  const { t } = useTranslation('translations', { useSuspense: false });

  return (
    <div className="betslip-sidebar">
      <div className="card border-card">
        <div className="card-header text-color-green bg-list-dark d-flex justify-content-center align-item-center border-card">
          {t(`pages.desktop.bettingSlip.bettingSlip`)}
          {betSlipBetsLength > 0 ? (
            <span className="betslip-title-badge">{betSlipBetsLength}</span>
          ) : (
            ''
          )}
        </div>
        {betSlipBetsLength > 0 ? (
          <BetSlipList type={'desktop'} />
        ) : (
          <div className="card-body">
            <div className="py-4">
              <h6 className="mb-1 text-white d-flex justify-content-center align-item-center">
                {t(`pages.desktop.bettingSlip.noBetsSelected`)}
              </h6>
              <small className="d-flex text-dark-light justify-content-center align-item-center">
                {t(`pages.desktop.bettingSlip.pleasePlaceBet`)}
              </small>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
