export function ConvertDisplayNumber(value) {
  if (typeof value === 'string') {
    const newValue = Number(value).toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return newValue;
  }
  const newValue = Number(value).toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return newValue;
}

export function ConvertNumberDisplayNumber(value) {
  const newValue = value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return newValue;
}

//export function parseWithCommaStringToNumber(value) {
//  return Number(value.replace(/,/g, ''));
//}
