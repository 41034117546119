import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { saveBetSlip, getBetSlipMatches } from 'stores/betSlip';
import { getDeviceType } from 'toolkit/utils';

const useReloadHelper = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let betSlipMatchesReloaded = false;

    dispatch(getBetSlipMatches())
      .unwrap()
      .then(() => {
        betSlipMatchesReloaded = true;
      });

    const handleVisibilityChange = () => {
      const screenState = document.visibilityState;
      if (screenState === 'hidden') {
        if (!betSlipMatchesReloaded) {
          return;
        }

        dispatch(saveBetSlip());
      }

      if (screenState === 'visible') {
        if (getDeviceType() !== 'desktop') {
          //console.log('GetDeviceType');
          // location.reload();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export default useReloadHelper;
