import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Button, Collapse, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { postCashoutBetSlipReq } from 'stores/betSlip/services.js';
import BetMatchCard from 'components/general/bet_match_card.js';
import { errorToaster, showToaster } from 'mixin/general';
import { gameDay, gameTime } from 'toolkit/utils';
import { EnumBetslipStatus } from 'toolkit/Enums';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { ConvertDisplayNumber } from 'toolkit/NumberUtil';
import FetchButton from 'components/general/FetchButton';
import TitleHistoryBack from './TitleHistoryBack.js';
import BettingHistoryTitle from './betting_history_title.js';
import { useMediaQuery } from 'react-responsive';
import { getSingleBetSlipReq } from 'stores/auth/services.js';

function BettingHistoryDetail() {
  const navigate = useNavigate();
  const { slipId } = useParams();
  const { t } = useTranslation('translations', { useSuspense: false });
  const userLimits = useSelector((state) => state.auth.userLimits);
  const [showCashoutForm, setShowCashoutForm] = useState(false);
  const [showBetDetails, setShowBetDetails] = useState(false);
  const [isCashoutInvalid, setIsCashoutInvalid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState();
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });
  const [isCashoutHandle, setIsCashoutHandle] = useState(false);
  const getBettingHistoryParams = () => ({
    betSlipsystemId: slipId
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, [isCashoutHandle]);

  const getData = async () => {
    const response = await getSingleBetSlipReq(getBettingHistoryParams());
    setData(response.data);
    setCashout(response.data?.cashOut);
  };

  const maxWinning = data?.maxWinning.replace(',', '');
  const cashoutValue = Number(data?.cashOut?.replace(',', '')).toFixed(2);
  const [cashout, setCashout] = useState();

  const handleCashoutFormSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      if (isCashoutInvalid || cashout === '') {
        showToaster('Invalid Cashout', 'error', 5000);
        setIsSubmitting(false);
        return;
      }
      const body = {
        systemSlipId: slipId,
        proposedCashOutAmount: cashoutValue,
        isFullCashOut: cashoutValue === cashout
      };
      if (!body.isFullCashOut) {
        body.cashOutAmount = Number(cashout);
      }
      const response = await postCashoutBetSlipReq(body);
      if (response.data && response.data.success) {
        showToaster(t('pages.bettingHistoryDetail.successMessageCashout'), 'success');
        navigate('/mybets');
      } else {
        if (
          response.data.messages[0].extraParam1 === null ||
          response.data.messages[0].extraParam1 === undefined ||
          response.data.messages[0].extraParam1 === ''
        ) {
          showToaster(response.data.messages[0].text, 'error', 5000);
        } else if (response.data.messages[0].extraParam1 === '0') {
          showToaster(t('pages.bettingHistoryDetail.errorZeroCashout'), 'error', 5000);
        } else {
          showToaster(
            t('pages.bettingHistoryDetail.errorCashout', {
              value1: cashoutValue,
              value: response.data.messages[0].extraParam1
            }),
            'error',
            5000
          );
        }
        setIsCashoutHandle(!isCashoutHandle);
      }
    } catch (error) {
      setIsCashoutHandle(!isCashoutHandle);
      showToaster(error, 'error', 5000);
    }
    setIsSubmitting(false);
  };

  const handleCashoutInputChange = (e) => {
    if (data?.accountType === 5100) return;
    const cashOutInputValue = Number(e.target.value);
    setCashout(e.target.value);
    setIsCashoutInvalid(
      cashOutInputValue > cashoutValue || cashOutInputValue < 0 || isNaN(cashOutInputValue)
    );
  };

  if (!data) {
    return null;
  }

  return (
    <Container fluid className="bettinghistory">
      <Modal show={isSubmitting} onHide={() => setIsSubmitting(true)} />
      <TitleHistoryBack title={t('mybets')} subTitle={t('bettingSlipDetails')} />
      <div className={`fs-8 mt-n2 ${isDesktop ? 'text-white' : 'text-muted'}`}>
        <div className="mb-1">
          <BettingHistoryTitle
            betSlipStatus={data?.betSlipStatus}
            count={data?.matches.length}
            betSlipType={data?.betSlipType}
            combinations={data?.combinations}
            numberOfBets={data?.numberOfBets}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mb-1">
          <div>
            {data?.accountType === 5100
              ? `${t('bonus')} ${t('pages.bettingHistoryDetail.details.stake')}`
              : t('pages.bettingHistoryDetail.details.stake')}
          </div>
          <div>
            {ConvertDisplayNumber(data?.totalStake)}
            &nbsp;EUR
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-1">
          <div>
            {t(
              `pages.bettingHistoryDetail.details.${
                data?.betSlipStatus == EnumBetslipStatus.LOST ? 'maxwinnings' : 'winnings'
              }`
            )}
          </div>
          <div>
            <span>
              {data?.betSlipStatus == EnumBetslipStatus.LOST
                ? ConvertDisplayNumber(maxWinning)
                : ConvertDisplayNumber(data?.winning) || '0.00'}
              &nbsp;EUR
            </span>
            <button
              className={`btn btn-sm pt-0 pe-0 pb-1 shadow-none ${
                isDesktop ? 'text-white' : 'text-muted'
              }`}
              onClick={() => setShowBetDetails(!showBetDetails)}>
              {showBetDetails ? (
                <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/135.png`} />
              ) : (
                <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/134.png`} />
              )}
            </button>
          </div>
        </div>
        <Collapse in={showBetDetails}>
          <div className="fw-bold">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div>{t('pages.bettingHistoryDetail.details.maxwinnings')}&#58;</div>
              <div>{ConvertDisplayNumber(maxWinning)}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div>{t('pages.bettingHistoryDetail.details.bettingslip')}&#58;</div>
              <div>{data?.systemSlipId}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div>{t('pages.bettingHistoryDetail.details.betTime')}&#58;</div>
              <div>
                {gameDay(data?.placeDate)}, {gameTime(data?.placeDate)}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div>{t('pages.bettingHistoryDetail.details.evaluationTime')}&#58;</div>
              <div>
                {data?.betSlipStatus == EnumBetslipStatus.WON &&
                  gameDay(data?.evaluationDate) + ', ' + gameTime(data?.evaluationDate)}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2 ps-4 fw-normal small">
              <div>{t('pages.bettingHistoryDetail.details.stakePerBet')}&#58;</div>
              <div>
                {ConvertDisplayNumber(data?.stakePerBet)} EUR (TAX {ConvertDisplayNumber(data?.tax)}{' '}
                EUR)
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between ps-4 fw-normal small">
              <div>{t('pages.bettingHistoryDetail.details.numberOfBets')}&#58;</div>
              <div>{data?.matches.length}</div>
            </div>
          </div>
        </Collapse>
      </div>
      {userLimits.isCashout && cashoutValue > 0 && (
        <div className="mt-3 px-2">
          <Button
            onClick={() => setShowCashoutForm(!showCashoutForm)}
            variant="success"
            className="w-100 d-flex align-items-center justify-content-center">
            {t('pages.bettingHistoryDetail.cashout')} {cashoutValue}
            <span className="ms-2">
              {showCashoutForm ? (
                <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/138.png`} />
              ) : (
                <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/137.png`} />
              )}
            </span>
          </Button>
          <Collapse in={showCashoutForm}>
            <Form onSubmit={handleCashoutFormSubmit}>
              <div className="row">
                <div className="col mt-3">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="number"
                      value={cashout}
                      placeholder={t('pages.bettingHistoryDetail.cashoutPlaceholder')}
                      onChange={handleCashoutInputChange}
                      isInvalid={isCashoutInvalid}
                      disabled={data?.accountType === 5100}
                    />
                    {isCashoutInvalid ? (
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {t('pages.bettingHistoryDetail.errorText')}
                      </Form.Control.Feedback>
                    ) : (
                      <Form.Text className={isDesktop ? 'text-white' : 'text-muted'}>
                        {t('pages.bettingHistoryDetail.infoText')} {cashoutValue}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
                <div className="col-auto mt-3">
                  <FetchButton className="w-100" type="submit" fetching={isSubmitting}>
                    {t(
                      `pages.bettingHistoryDetail.${
                        Number(cashout) === Number(cashoutValue) ? 'full' : 'partial'
                      }Cashout`
                    )}
                  </FetchButton>
                </div>
              </div>
            </Form>
          </Collapse>
        </div>
      )}
      {data?.matches.map((matches, index) => (
        <div key={index}>
          <div className="mt-2"></div>
          <div className="">
            <BetMatchCard key={'BetMatchCard' + index} match={matches}></BetMatchCard>
          </div>
        </div>
      ))}
    </Container>
  );
}
export default BettingHistoryDetail;
