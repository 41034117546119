export default function EN() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <h5 className="text-danger mt-75">WOULD YOU LIKE TO CONTACT US?</h5>
      <p>
        Here you will find our contact details. You can contact us by using our Support form, by
        post or by e-mail.
      </p>
      <h5 className="text-danger mt-3">Address</h5>
      <p>
        Game90 Ltd. 1 st Floor Melita Court Giuseppe Cali Street C/W Abate Rigord Street XBX 1420
        Ta’Xbiex Malta E-mail: support@game90.bet Responsible for this website for the German Media
        law: Simon Sullivan
      </p>
      <h5 className="text-danger mt-3">Licence</h5>
      <p>
        German license: <br />
        Officially approved sports betting operator under the supervision of the Joint Gaming
        Authority of the Federal States (GGL). The Game90 Ltd. the official permit to organize
        sports betting on the internet and in stationary areas was granted (for the first time) by
        decision dated November 19, 2020; this was extended on December 22, 2022 (GGL whitelist).
      </p>
    </div>
  );
}
