export default function DE() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <h5 className="text-danger mt-75">IMPRESSUM</h5>
      <p>
        Hier findest du alle Angaben, um mit uns in Kontakt zu treten, sowie die wichtigsten
        Informationen zu unserer MGA-Lizenz.
      </p>
      <h5 className="text-danger mt-3">Addresse</h5>
      <p>
        Game90 Ltd. 1 st Floor Melita Court Giuseppe Cali Street C/W Abate Rigord Street XBX 1420
        Ta’Xbiex Malta E-mail: support@game90.bet
      </p>
      <h5 className="text-danger mt-3">Lizenz</h5>
      <p>
        Internationale Lizenz: <br />
        Malta Gaming Authority (MGA) Lizenznummer: MGA/B2C/191/2010 Lizenz erteilt am: 18.12.2011
      </p>
      <p>
        Deutsche Lizenz: <br />
        Behördlich zugelassener Sportwettveranstalter unter Aufsicht der Gemeinsame
        Glücksspielbehörde der Länder (GGL). Der Game90 Ltd. wurde mit Bescheid vom 19.11.2020
        (erstmalig) die behördliche Erlaubnis zur Veranstaltung von Sportwetten im Internet und
        stationären Bereich erteilt, diese wurde am 22.12.2022 verlängert (GGL Whitelist).
      </p>
    </div>
  );
}
