import Desktop from 'components/breakpoints/Desktop.js';
import Default from 'components/breakpoints/Default.js';
import HomeDesktop from './HomeDesktop.js';
import HomeMobile from './HomeMobile.js';

function Home() {
  return (
    <>
      <Desktop>
        <HomeDesktop />
      </Desktop>
      <Default>
        <HomeMobile />
      </Default>
    </>
  );
}
export default Home;
