import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { gameDay, gameTime } from 'toolkit/utils';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { ConvertDisplayNumber } from 'toolkit/NumberUtil';
import { EnumBetslipStatus } from 'toolkit/Enums';
import { useMediaQuery } from 'react-responsive';

function BetBottom(props) {
  const { t } = useTranslation('translations', { useSuspense: false });

  const day = gameDay(props.bettingHistoryItem.placeDate);
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });
  const time = gameTime(props.bettingHistoryItem.placeDate);

  const winningData = (function () {
    const winning = Number(props.bettingHistoryItem.winning.replace(',', ''));
    const maxwinning = Number(props.bettingHistoryItem.maxWinning.replace(',', ''));

    if (props.bettingHistoryItem.betSlipStatus == EnumBetslipStatus.OPEN) {
      return {
        label: t('maxwinnings'),
        value: ConvertDisplayNumber(maxwinning)
      };
    }
    return {
      label: t('winnings'),
      value: ConvertDisplayNumber(winning)
    };
  })();

  return (
    <>
      <Row className="justify-content-between align-items-center mb-n1">
        <Col xs={3} className={`fs-9 ${isDesktop ? 'text-white' : 'text-secondary'}`}>
          <span>{day}</span>
          <br></br>
          <span className="fw-bolder">{time}</span>
        </Col>
        <Col xs={3} className={`fs-9 text-center ${isDesktop ? 'text-white' : 'text-secondary'}`}>
          <span>
            {props.bettingHistoryItem.accountType === 5100 ? t('bonusBalance') : t('stake')}
          </span>
          <br></br>
          <span className="fw-bolder">
            {ConvertDisplayNumber(props.bettingHistoryItem.totalStake)} {' EUR'}
          </span>
        </Col>
        <Col xs={3} className={`fs-9 text-center ${isDesktop ? 'text-white' : 'text-secondary'}`}>
          <span>{winningData.label}</span>
          <br></br>
          <span className="fw-bolder">
            {winningData.value} {' EUR'}
          </span>
        </Col>
        <Col xs={2} className={`fs-9 ${isDesktop ? 'text-white' : 'text-secondary'}`}>
          <Link to={`/bettinghistorydetail/${props.bettingHistoryItem.systemSlipId}`}>
            {isDesktop ? (
              <img
                width="22"
                height="22"
                className="icon-white"
                src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`}
              />
            ) : (
              <img width={22} height={22} src={`${AWS_BUCKET_URL}/Icons/147.png`} alt="next" />
            )}
          </Link>
        </Col>
      </Row>
    </>
  );
}
export default BetBottom;
