import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import { BsChevronDown, BsChevronRight, BsPlus, BsDash } from 'react-icons/bs';
import { calculateMatchCountByLeagues } from 'components/base/constants';
import { categoryNameHelper, sportTypeToSportId } from 'toolkit/utils';
import { setDesktopSearchParams } from 'stores/match';
import Tournament from './tournament';

const Category = ({ category, sport }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations');
  const desktopSearchParams = useSelector((state) => state.match.desktopSearchParams);
  const categoryNames = useSelector((state) => state.match.categoryNames);
  const count = useSelector((state) => {
    if (desktopSearchParams.dateFilter > 0) {
      return state.match.timeCounts[sport];
    }
    return state.match.counts[sport];
  });
  const [open, setOpen] = useState(false);
  const isActive = desktopSearchParams.Category === category;
  const categoryText = categoryNameHelper(category, categoryNames, t(`categories.${category}`));

  function handleCategoryClick() {
    setOpen(!open);
  }

  function handlePlusClick(e) {
    e.stopPropagation();

    if (desktopSearchParams.Category == category) {
      dispatch(
        setDesktopSearchParams({
          Sport: sportTypeToSportId(sport),
          Category: null,
          Tournament: null
        })
      );
    } else {
      dispatch(
        setDesktopSearchParams({
          mode: null,
          Sport: sportTypeToSportId(sport),
          Category: category,
          Tournament: null
        })
      );
    }
  }

  function getTournaments() {
    const categoryCountsData = count[category];

    if (categoryCountsData) {
      return Object.keys(categoryCountsData).map((tournamentId) => (
        <Tournament
          tournament={tournamentId}
          category={category}
          sport={sport}
          count={categoryCountsData[tournamentId]}
          key={tournamentId}
        />
      ));
    }
    return [];
  }

  return (
    <>
      <button
        className={`btn category-button ${isActive ? 'active' : ''}`}
        onClick={handleCategoryClick}>
        <span className="category-expand">{open ? <BsChevronDown /> : <BsChevronRight />}</span>
        <span className="category-text">{categoryText}</span>
        <span className="category-count">{calculateMatchCountByLeagues(count, category)}</span>
        <span className="category-plus" onClick={handlePlusClick}>
          {isActive ? <BsDash size={32} /> : <BsPlus size={32} />}
        </span>
      </button>
      <Collapse in={open}>
        <div>
          <div className="d-flex flex-column">{getTournaments()}</div>
        </div>
      </Collapse>
    </>
  );
};

export default Category;
