import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { startLiveCasinoSessionReq } from 'stores/auth/services';
import { getDeviceType, getLanguageCode } from 'toolkit/utils';

function BlackBox(props) {
  const { to, icon, iconType, text1, text2 } = props;
  const { t } = useTranslation('translations', { useSuspense: false });
  const user = useSelector((state) => state.auth.user);
  const openCasionFrame = to === '/casino' && getDeviceType() === 'iphone';

  return (
    <div className="card p-1 header-bg-color">
      <Link
        className="d-flex flex-column text-white"
        to={to}
        // onClick={(e) => {
        //   if (openCasionFrame) {
        //     e.stopPropagation();
        //     StartSession(user);
        //   }
        // }}
      >
        <div className="text-end">
          {iconType === 'flag' ? (
            <img width="20" height="13" src={icon} alt={text1 || text2}></img>
          ) : (
            <img
              className="icon-color"
              width="22"
              height="22"
              src={icon}
              alt={text1 || text2}></img>
          )}
        </div>
        <div className={'text-start mt-n1 fs-8'}>{t(text1) || <span>&nbsp;</span>}</div>
        <div className="text-start mt-n1 fs-8">{t(text2)}</div>
      </Link>
    </div>
  );
}

function StartSession(user) {
  if (user === null) return;
  const newWindow = window.open(
    '',
    'Game',
    'height=' +
      screen.availHeight +
      ',width=' +
      screen.availWidth +
      ',toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,fullscreen=yes'
  );
  startLiveCasinoSessionReq({ customerId: user.id, language: getLanguageCode().split('-')[0] })
    .then((r) => {
      if (newWindow !== null) {
        newWindow.location.href = r.data;
        if (window.focus) {
          newWindow.focus();
          newWindow.moveTo(0, 0);
        }
      }
    })
    .catch((e) => {
      console.log(e.message);
    });
}

export default BlackBox;
