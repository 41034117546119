import { toast } from 'react-toastify';

export const showToaster = (message, type = 'error', autoClose = 3000) => {
  toast(message, {
    position: 'top-right',
    autoClose,
    type: type,
    theme: 'colored',
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
};

export const errorToaster = (error) => {
  if (Array.isArray(error)) {
    error.forEach((errorMessage) => {
      showToaster(errorMessage);
    });
  } else {
    showToaster(error.message);
  }
};
