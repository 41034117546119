import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AWS_BUCKET_URL } from '../../toolkit/constants';
import TitleHistoryBack from 'components/general/TitleHistoryBack';

export default function Bank() {
  const { t } = useTranslation('translations', { useSuspense: false });

  return (
    <Container>
      <TitleHistoryBack title={t('payout')} subTitle={t('bank')} />
      <div className="mt-2"></div>
      <div className="mt-30"></div>
      <div className="justify-content-center align-item-center">
        <img
          style={{ height: '30%', width: '30%', marginTop: 20 }}
          src={`${AWS_BUCKET_URL}/Icons/115.png`}></img>
        <div className="mt-2"></div>
        <div className="fw-bolf mt-4">Payout Not Possible</div>

        <div className="mt-3"></div>
        <Row>
          <div className="text-center w-75 mb-6">
            <div className="fs-2">
              Please upload your{' '}
              <span className="text-danger fw-bold">
                <Link to="/uploaddocument">documents</Link>
              </span>{' '}
              and contact our{' '}
              <span className="text-danger fw-bold">
                <Link to="/contact" className="text-success">
                  support
                </Link>
              </span>
            </div>
          </div>
        </Row>
      </div>
    </Container>
  );
}
