import React from 'react';
import { Row, Button, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TitleHistoryBack from 'components/general/TitleHistoryBack';

export default function Contact() {
  const { t } = useTranslation('translations');

  return (
    <Container fluid>
      <TitleHistoryBack title={t('myprofile')} subTitle={t('contact')} />
      <Row>
        <Col>
          <h2 className="float-start mt-3">{t('contact')}</h2>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <span>{t('contactsubtitle1')}</span>
          <span>{t('contactsubtitle2')}</span>
        </Col>
      </Row>
      <Row className="ps-4 pe-4 mt-2">
        <Button variant="success">support@norobet.de</Button>{' '}
      </Row>
    </Container>
  );
}
