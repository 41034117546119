import { Container, Accordion, Row, Col } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
export default function EN() {
  return (
    <Tabs justify className="mb-4 py-2 text-start me-1 ms-1">
      <Tab eventKey="faq" title="FAQ">
        <h2 className="text-danger">FAQ</h2>
        <p key={1}>
          We have collected the most frequentially asked questions and answered them for you. If
          your question is not answered here, please contact our support. We will get in contact
          with you as soon as possible.
        </p>
        <Container className="py-2 text-start me-1 ms-1">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" key={0}>
              <Accordion.Header>FAQ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Row>
                    <Col className="text-danger" xs={4}>
                      By e-mail to the following address: <hr />
                    </Col>
                    <Col className="text-danger" xs={4}>
                      By fax to the following number: <hr />
                    </Col>
                    <Col className="text-danger" xs={4}>
                      Use our chat: <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>support@game90.bet</Col> <Col xs={4}>+356 2276 9002</Col>
                    <Col xs={4}></Col>
                  </Row>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" key={1}>
              <Accordion.Header>How long does it take till my bets are settled?</Accordion.Header>
              <Accordion.Body>
                Your bet will be settled as soon as the event you bet on is decided.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" key={2}>
              <Accordion.Header>How do I find out if I have won?</Accordion.Header>
              <Accordion.Body>
                You can find a status overview for all bets placed by you under &apos;My bets&apos;.
                The total winnings from the settled bets are credited to your account.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" key={3}>
              <Accordion.Header>What is the currency used for my betting account?</Accordion.Header>
              <Accordion.Body>
                Your betting account is in euros (EUR). If you make deposits in other currencies,
                these will be converted to euros (EUR) at the current exchange rate.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" key={4}>
              <Accordion.Header>What are the limits on stakes and winnings?</Accordion.Header>
              <Accordion.Body>
                The minimum stake per betting slip is EUR 1. The maximum payout per betting slip is
                EUR 100,000. You can find more information under &apos;Limits&apos;.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" key={5}>
              <Accordion.Header>
                How much time elapses between my payout request and the money being paid into my
                account?
              </Accordion.Header>
              <Accordion.Body>
                The first time you request a payout, we require a copy of your valid photo
                identification. As soon as we have this document, we make the transfer. Further
                payout requests are processed immediately.
                <br />
                Please find out from your bank about the transfer time for national or international
                payment transactio
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" key={6}>
              <Accordion.Header>How do I reduce and increase the limits?</Accordion.Header>
              <Accordion.Body>
                An account holder can ask for Game90 account settings to be changed. Please send an
                e-mail to support@game90.bet:
                <br />
                <br />
                (a) a limit to the stake the account holder wishes to place on bets in a certain
                time; <br />
                (b) an upper limit for the duration of the session; <br />
                (c) a limit on the maximum pending bets in EUR. <br />
                <br />
                An account holder who has set a limit, as above in (a), (b) and (c), can change or
                revoke it by sending an e-mail to support@game90.bet. An increase to the limit(s)
                comes into effect one day (24 hours) after receipt of the e-mail. A reduction of the
                limit(s) comes into effect immediately on receipt of the e-mail.Game90 accepts no
                bets from a gamer who exceeds the customer-defined limits, or from customers who
                have blocked their gaming account.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" key={7}>
              <Accordion.Header>How do I place a bet using game90.bet?</Accordion.Header>
              <Accordion.Body>
                To place a bet on game90.bet you need a Game90 customer card. You can get one in a
                Game90 shop near you. If you activated your card for online betting at a betting
                terminal, you can sign in on game90.bet using your given access data. There you can
                place a bet provided you dispose of enough credit.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Tab>
      <Tab eventKey="betTypes" title="Bet Types">
        <h2 className="text-danger">BET TYPES</h2>
        <p key={2}>
          Game90 offers you only the most attractive bet types, ranging from single bets to system
          bets. Find out in detail about the options for placing a bet with Game90.
        </p>
        <Container className="py-2 text-start me-1 ms-1">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Rest of Match</Accordion.Header>
              <Accordion.Body>
                With the bet type Rest of Match, you predict which team will score more goals from
                the time you placeyour bet to the end of the match. At the point in time you place
                your bet, the current score is reset to 0:0 just for your bet.For valuating your
                bets, only goals which are scored after you have placed your bet count. <br />
                <br /> <b>Possible Bets: </b>
                <li className="mt-3" key={1}>
                  <b>Bet 1:</b> The home team will score more goals than the away team from the
                  point in time youplace your bet until the end of the match.
                </li>
                <li key={2}>
                  <b> Bet 0:</b> Both teams will score an equal number of goals from the time you
                  place your bet.
                </li>
                <li key={3}>
                  <b>Bet 2:</b> The away team will score more goals than the home team from the
                  point in time you place yourbet until the end of the match.
                </li>
                <br />
                <b>Example 1 </b> <br /> At the point in time you place your bet, the score is 0:1.
                At the end of the match, the score is 1:1. You win if you selected bet 1 (home
                team),because the home team scored more goals than the away team after you placed
                your bet. <br /> <br /> <b>Example 2</b>
                <br /> At the point in time you place your bet, the score is 0:1. At the end of the
                match, the score is 1:2. You win if you selected bet 0 (draw), because bothteams
                scored an equal number of goals after you placed your bet.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Winner</Accordion.Header>
              <Accordion.Body>
                With the bet type Winner and Win bet you say in advance how the game will turn out.
                Depending on the type of sport, the Winner bet and Win bet refer to the outcome of
                the entire game (e.g. football) or the entire match (e.g. tennis). <br /> <br />
                <b>Possible Bets:</b>
                <li className="mt-3" key={4}>
                  <b>Bet 1:</b> The home team or player 1 wins.
                </li>
                <li key={5}>
                  <b>Bet 0:</b> The game ends in a draw.
                </li>
                <li key={6}>
                  <b>Bet 2:</b> The away team or player 2 wins.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Fantasy</Accordion.Header>
              <Accordion.Body>
                The bet type Fantasy is for betting on Fantasy games. A bookmaker is selecting two
                teams for Fantasy matches who actually do not play against each other but against
                two other football teams. <br />
                <br />
                The LiveBet 0:0 principle applies to Fantasy bets. In a Fantasy bet, you predict
                which one of the two teams will score more goals in the Fantasy match from the time
                you place the bet until the end of both real matches. At the point in time of
                placing your bet, the current score of the Fantasy match is ignored and is reset to
                0:0 just for your bet.For valuating your bets, only goals count which the Fantasy
                match teams scored in the real matches after you have placed your bet. Goals scored
                by the opposing team in the real matches have no effect on the valuation of the
                Fantasy bet.In Fantasy bets, there is no home advantage. Fantasy matches are
                assigned to the Fantasy league. Fantasy matches are highlighted in orange in the
                odds section.
                <br />
                <br />
                <b>Possible Bets:</b>
                <li className="mt-3" key={7}>
                  <b>Bet 1:</b>The home team or player 1 wins.
                </li>
                <li key={8}>
                  <b>Bet 0:</b> The game ends in a draw.
                </li>
                <li key={9}>
                  <b>Bet 2:</b> The away team or player 2 wins.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Fantasy Over/Under</Accordion.Header>
              <Accordion.Body>
                With the bet type Fantasy Over/Under, you predict whether the total number of goals
                scored in a Fantasy match will be over or under the goal line given in column G.All
                the goals scored in a Fantasy match are counted in settling the bet (including goals
                scored before the bet was placed). Goals scored by the opposing team in the real
                matches have no effect on the valuation of the Fantasy Over/Under bet.
                <br />
                <br />
                <b> Example:</b> Goal line given is 2.5.If two or fewer goals are scored in the
                Fantasy match, the bet placed on the Under wins. If three or more goals are scored
                in the Fantasy match, the bet placed on the Over wins.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>First Half</Accordion.Header>
              <Accordion.Body>
                The same principle applies to the settlement of the First Half bet type as to the
                settlement of the Rest of Match bet type. With the bet type First Half, you predict
                which team will score more goals from the time you place your bet to the end of the
                first half. At the point in time you place your bet, the current score is reset to
                0:0 just for your bet. For valuating your bets, only goals count which are scored in
                the first half after you have placed your bet.
                <br />
                <br />
                <b>Possible Bets:</b>
                <li className="mt-3" key={10}>
                  <b>Bet 1:</b>The home team will score more goals than the away team from the point
                  in time you place your bet until the end of the first half.
                </li>
                <li key={11}>
                  <b>Bet 0:</b> Both teams will score an equal number of goals from the time you
                  place your bet until the end of the first half.
                </li>
                <li key={12}>
                  <b>Bet 2:</b> The away team will score more goals than the home team from the
                  point in time you place your bet until the end of the first half.
                </li>
                <br />
                <b>Example 1:</b> At the point in time you place your bet, the score is 0:1. At the
                end of the first half, the score is 1:1. You win if you selected bet 1 (home team),
                because the home team scored more goals than the away team from the time you placed
                your bet until the end of the first half.
                <br />
                <br />
                <b>Example 2:</b> At the point in time you place your bet, the score is 0:1. At the
                end of the first half, the score is 1:2. You win if you selected bet 0 (draw),
                because both teams scored an equal number of goals from the time you placed your bet
                until the end of the first half.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Next Goal</Accordion.Header>
              <Accordion.Body>
                With the bet type Next Goal you predict which team will score the next goal.
                <br />
                <br />
                <b>Possible Bets:</b>
                <li className="mt-3" key={13}>
                  <b>Bet 1:</b>The home team will score the next goal.
                </li>
                <li key={14}>
                  <b>Bet 0:</b> Neither team will score another goal before the end of the match.
                </li>
                <li key={15}>
                  <b>Bet 2:</b> The away team will score the next goal.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Handicap</Accordion.Header>
              <Accordion.Body>
                With the bet type Handicap, a team gets a fictive advantage in the form of one or
                more goals. With a Handicap bet, you are predicting which team will score more
                goals, taking the handicap into consideration. Depending on the type of sport, the
                handicap is oriented towards goals, points, or set wins.
                <br />
                <br />
                <b>Possible Bets:</b>
                <li className="mt-3" key={16}>
                  <b>Bet 1:</b>The home team will score more goals than the visitors from the time
                  the bet is placed until the end of the game, taking the handicap into
                  consideration.
                </li>
                <li key={17}>
                  <b>Bet 0:</b> Both teams will score the same number of goals from the time the bet
                  is placed until the end of the game, taking the handicap into consideration.
                </li>
                <li key={18}>
                  <b>Bet 2:</b> The away team will score more goals than the home team from the time
                  the bet is placed until the end of the game, taking the handicap into
                  consideration.
                </li>
                <br />
                <br />
                <b>Additional types of handicap bets</b> <br />
                <li key={19}>
                  Handicap Extra Time: The Handicap Extra Time bet refers to the handicap on the end
                  result of the game, including extra time and, if applicable, penalty shoot-outs.
                </li>
                <li key={20}>
                  Handicap Sets: The Handicap Sets bet is offered, for example, for the sport of
                  tennis. The handicap refers to the sets won.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Double Chance</Accordion.Header>
              <Accordion.Body>
                With the bet type Double Chance you are betting on two different outcomes at the
                same time with one bet.
                <br />
                <br />
                <b>Possible Bets </b>
                <li className="mt-3" key={21}>
                  <b>Bet 1X:</b>The home team wins or the game ends with a draw.
                </li>
                <li key={22}>
                  <b>Bet 12:</b>The home or the away team wins.
                </li>
                <li key={23}>
                  <b>Bet X2:</b>The away team wins or the game ends with a draw.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Corners Over/Under</Accordion.Header>
              <Accordion.Body>
                With the bet type Corners Over/Under, you are wagering on whether the total number
                of corner kicks in a match will be over or under a specified corners number (C). All
                corner kicks in a match are counted when assessing the outcome of this bet.
                <br />
                <br />
                <b>Example:</b>
                <br />
                Specified number of corner kicks is 12.5. If there are twelve or fewer corner kicks
                in the match, the bet placed on the Under wins. If there are thirteen or more corner
                kicks in the match, the bet placed on the Over wins.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>Both Teams To Score</Accordion.Header>
              <Accordion.Body>
                When you place a Both Teams To Score bet, you are wagering that both teams will
                score at least one goal or not.
                <br />
                <br />
                <b>Possible Bets </b>
                <li className="mt-3" key={24}>
                  <b>Bet Yes:</b>Both teams will score at least one goal.
                </li>
                <li key={25}>
                  <b>Bet No:</b>One or both teams will not score any goals.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>To Qualify</Accordion.Header>
              <Accordion.Body>
                With the bet type To Qualify you say in advance which team will reach the next round
                of a competition.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>Half-time/Final Score</Accordion.Header>
              <Accordion.Body>
                When betting on Half-time/Final Score you predict with only one bet how the first
                half will go and how the game will turn out. Overall, in this bet type are 9 bets to
                choose from. A bet consists of two numbers. The first number in the bet indicates
                who will win the first half. The second number in the bet indicates who will win the
                game.
                <br />
                <br />
                <b>Possible Bets </b>{' '}
                <li className="mt-3" key={26}>
                  1 = Home team
                </li>
                <li key={27}>1 = Home team</li> <li key={28}> 2 = Away Team </li>
                <b>Example 1:</b> Bet 1/2: The home team wins the first half and the away team wins
                the game. <br />
                <b>Example 2:</b> Bet 0/1: The first half ends in a draw and the home team wins the
                game.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>Result</Accordion.Header>
              <Accordion.Body>
                With a Result bet you predict the exact result of a game.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>Winner 1st Set</Accordion.Header>
              <Accordion.Body>
                With the bet type Winner 1st Set you say in advance which player will win the first
                set.
                <br />
                <br />
                <b>Possible Bets </b>
                <li className="mt-3" key={29}>
                  <b>Bet 1:</b>Player 1 wins the first set.
                </li>
                <li key={30}>
                  <b>Bet 2:</b>Player 2 wins the first set.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>Games Over/Under</Accordion.Header>
              <Accordion.Body>
                With the bet type Games Over/Under you say in advance whether the total number of
                games of all sets in an encounter will be over or under the previously given game
                line (GT). The Games Over/Under bet is offered both for the entire match and for the
                first set.
                <br />
                <br />
                <b>Example:</b> Game line given is 30,5. If there are 30 or fewer games in an
                encounter the bet will win with the tip Under. If there are 31 or more games in an
                encounter the bet will win with the tip Over.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
              <Accordion.Header>Single Bet</Accordion.Header>
              <Accordion.Body>
                With a Single bet, you predict the result of a single event. If you place several
                bets within the framework of a single football match, they are entered as single
                bets on the betting slip and settled independently of each other. The bet is won if
                you predict the result correctly.
                <br />
                <br />
                Any winnings due are calculated as follows: Winnings = betting stake x odds
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
              <Accordion.Header>First 180</Accordion.Header>
              <Accordion.Body>
                With the First 180 bet you predict which player will score a 180 (180 points with
                three throws) first.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
              <Accordion.Header>Most 180s</Accordion.Header>
              <Accordion.Body>
                With the Most 180s bet, you predict which player will score the most 180s (180
                points with three throws) in a game.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="18">
              <Accordion.Header>180 Player 1 Over/Under</Accordion.Header>
              <Accordion.Body>
                With the 180 Player 1 Over/Under bet, you predict whether the total number of 180s
                (180 points with three throws) scored in a game by player 1 will be over or under
                the score shown in column P.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="19">
              <Accordion.Header>180 Player 2 Over/Under</Accordion.Header>
              <Accordion.Body>
                With the 180 Player 2 Over/Under bet, you predict whether the total number of 180s
                (180 points with three throws) scored in a game by player 2 will be over or under
                the score shown in column P.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="20">
              <Accordion.Header>Long Term Bet</Accordion.Header>
              <Accordion.Body>
                In a Long-term bet, you are betting on an event in a tournament or a season. The
                result will as a rule only be known after some time.
                <br />
                <br />
                <b>Examples of Long-term bets</b> <br />
                <br />- Who will be the world champion?
                <br />- Who will advance? <br />- Who will make it to the finals?
                <br />- Who will finish the top of this group?
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="21">
              <Accordion.Header>Multiple Bet</Accordion.Header>
              <Accordion.Body>
                With a Multiple bet, you predict the result of several different sporting events.
                Your bets are combined into one bet on the betting slip. You can combine up to 10
                events in one bet. The bet is won if you predict all the results of your Multiple
                bet correctly. The entire bet is deemed as lost if you predict at least one event
                incorrectly.
                <br />
                <br />
                Any winnings due are calculated as follows:
                <br />
                Winnings = betting stake x (odds A x odds B x odds C x odds ...)
                <br />
                <br />
                <b>Note</b>
                <br />
                If you place several bets within the framework of a single football match, they are
                not entered as a Multiple bet on the betting slip, but as Single bets, and are
                settled independently of each other.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="22">
              <Accordion.Header>System Bet</Accordion.Header>
              <Accordion.Body>
                With a System bet, you place several Multiple bets on one betting slip at the same
                time. You predict the results of several different sporting events and define the
                minimum number of results that must be correctly predicted to gain winnings for you
                (e.g. 2 out of 4). Various Multiple bets are now formed covering the events you have
                bet on (see the System bets table).
                <br />
                <br />
                A System bet may include one or more incorrect predictions and still win.
                <br />
                <br />
                <b>Example</b>
                <br />
                You bet on four events and select the 2 out of 4 system. The following six two-fold
                Multiple bets are now formed from these four events: AB, AC, AD, BC, BD, CD. You win
                the bet if you predict at least two events correctly.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="23">
              <Accordion.Header>Banker System Bet</Accordion.Header>
              <Accordion.Body>
                The Banker System bet is an extension of the System bet. If you are sure that an
                event will happen, you can mark it as banker in your System bet. You can also mark
                several events as banker.
                <br />
                <br />
                <b>Example of a Banker System bet</b> <br />
                You bet on five events (A, B, C, D, E) and mark one event (e.g. E) as banker. You
                select the 2 out of 4 system for the other four events (A, B, C, D). The following
                six two-fold Multiple bets are now formed: AB, AC, AD, BC, BD, CD. The event marked
                as banker, E, is now additionally worked into each combination: ABE, ACE, ADE, BCE,
                BDE, CDE. You win the bet, if the event you have marked as banker, E, and at least 2
                out of 4 other events (A, B, C, D) have been correctly predicted.
                <br />
                <br />
                If you have not predicted the event marked as banker correctly, the whole bet
                (irrespective of other correctly predicted events) is deemed as having been lost.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="24">
              <Accordion.Header>Over/Under</Accordion.Header>
              <Accordion.Body>
                With the bet type Over/Under, you predict whether the total number of goals scored
                in a match will be over or under the goal line given in column G. All the goals
                scored in a match are counted in settling the bet (including those goals scored
                before the bet was placed).
                <br />
                <br />
                The Over/Under bet is available covering either the entire match or just the first
                half.
                <br />
                <br />
                Depending on the sport, Over/Under bets are for the total number of goals (goal
                line), points (point line) or games (game line).
                <br />
                <br />
                <b> Example </b>
                <br />
                Goal line given: 2.5. <br />
                If two or fewer goals are scored in the match, the bet placed on the Under wins.
                <br />
                If three or more goals are scored in the match, the bet placed on the Over wins.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Tab>
    </Tabs>
  );
}
