import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLanguageCode } from 'toolkit/utils';
import LiveMatchContainer from './_desktop/live_match_container';
import MatchDetailsDesktop from './_desktop/match_details';
import MatchTracker from 'components/match_tracker/match_tracker';
import 'views/betslip/forza-betslip.scss';
import { fetchLiveMatches } from 'stores/match';
import LeftSidebar from 'views/home/_desktop/left_sidebar';
import { useNavigate } from 'react-router-dom';
import RightSidebar from 'views/home/_desktop/right_Sidebar';

const Live = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations', { useSuspense: false });
  const [matchTrackerData, setMatchTrackerData] = useState(null);
  const [matchDetailId, setMatchDetailId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchLiveMatches());
  }, []);

  const handleMatchTrackerSet = (data) => {
    if (!matchTrackerData || data.providerId !== matchTrackerData.providerId) {
      setMatchTrackerData(data);
    } else {
      setMatchTrackerData(null);
    }
  };

  const handleMatchDetailIdSet = (data) => {
    setMatchDetailId(data);
  };
  const mainContent = () => {
    if (matchDetailId) {
      return (
        <MatchDetailsDesktop
          matchDetailId={matchDetailId}
          onMatchDetailIdSet={handleMatchDetailIdSet}
        />
      );
    }
    return (
      <LiveMatchContainer
        onMatchTrackerSet={handleMatchTrackerSet}
        onMatchDetailIdSet={handleMatchDetailIdSet}
      />
    );
  };

  return (
    <div className="container-fluid py-2">
      <div className="row flex-nowrap">
        <Col xs={2} className="filter-sidebar" onClick={() => navigate('/sport')}>
          <LeftSidebar />
        </Col>
        <Col xs={7} className="desktop-main">
          {mainContent()}
        </Col>
        <Col xs={3} className="betslip-sidebar">
          <RightSidebar />
          {matchTrackerData ? (
            <div className="mt-3">
              <MatchTracker
                matchId={matchTrackerData.radarId}
                sportId={matchTrackerData.sport}
                language={getLanguageCode().split('-')[0]}
              />
            </div>
          ) : null}
        </Col>
      </div>
    </div>
  );
};

export default Live;
