import { Row, Col } from 'react-bootstrap';
export default function TR() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <div>
        <h5 className="text-danger fw-bolder mt-75"> İLETİŞİM</h5>
        <span className="fw-bolder mt-3">Bizimle iletişime geçmek ister misiniz?</span> <br />
        Bu bölümde bizimle nasıl iletişime geçebileceğiniz anlatılmaktadır.
        <p className="mt-75 text-danger fw-bolder">Destek aracılığıyla bizimle iletişime geçin</p>
        <p>
          Her gün 10:00 - 22:00 (CET) saatleri arasında aklınıza takılan her türlü soruyu
          yanıtlamaya hazırız.
        </p>
      </div>
      <Row>
        <Row>
          <Col className="text-danger" xs={4}>
            Aşağıdaki adrese e-posta ile: <hr />
          </Col>
          <Col className="text-danger" xs={4}>
            Aşağıdaki numaraya faks ile: <hr />
          </Col>
          <Col className="text-danger" xs={4}>
            Sohbetimizi kullanın: <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>support@game90.bet</Col> <Col xs={4}>+356 2276 9002</Col> <Col xs={4}></Col>
        </Row>
      </Row>
      <div className="mt-75">
        <h5 className="text-danger">Adresimiz</h5>
        <span>
          Game90 Ltd.
          <br />
          Melita Court 1.Katı
          <br />
          Giuseppe Cali St, Abate Rigord St ile birlikte
          <br />
          XBX1420 TaXbiex
          <br />
          Malta
          <br />
          <br />
          E-posta: <br />
          support@game90.bet
          <br />
          <br />
          Lisans: Game90 Ltd. (kayıt no. C50436), Malta tarafından uzaktan oyun lisansı almıştır.
          <br />
          Oyun Otoritesi(MGA/B2C/191/2010).
        </span>
      </div>
    </div>
  );
}
