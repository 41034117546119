import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import Default from 'components/breakpoints/Default';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import useOddTransformer from '../toolkit/useHooks/useOddsTransformer';
import { checkIfLiveEvent } from '../toolkit/utils';

const Footer = () => {
  const { t } = useTranslation('translations', { useSuspense: false });
  const user = useSelector((state) => state.auth.user);
  const betSlip = useSelector((state) => state.betSlip);
  const location = useLocation();
  const oddsTransformer = useOddTransformer();
  if (location.pathname === '/slot-game' || location.pathname === '/casino') return <Fragment />;
  return (
    <Default>
      <footer className="mobile-footer text-center container-fluid">
        <div className="hstack gap-1">
          <Col>
            <div className="footer-slot">
              <NavLink
                end
                to="/"
                className={({ isActive }) =>
                  'py-2 d-block text-dark' +
                  (isActive || location.pathname === '/' || location.pathname === '/start'
                    ? ' active'
                    : '')
                }>
                <img width="25" height={22} src={`${AWS_BUCKET_URL}/Icons/home.svg`} />
                <span className="d-block text-truncate pt-1 fs-6">{t('start')}</span>
              </NavLink>
            </div>
          </Col>
          {/* icon sport */}
          <Col>
            <div className="footer-slot">
              <NavLink end to="/sport" className="py-2 d-block text-dark">
                <span>
                  <img width="25" height={22} src={`${AWS_BUCKET_URL}/Icons/sports.svg`} />
                </span>
                <span className="d-block text-truncate pt-1 fs-6">{t('sport')}</span>
              </NavLink>
            </div>
          </Col>
          {betSlip.bets.length > 0 ? (
            <Col>
              <NavLink
                end
                to="/bet-slip"
                className="position-relative d-flex flex-column justify-content-center align-items-center">
                <div className="py-3 circle-overflow">
                  <span className="circle">
                    <span>{betSlip.bets.length}</span>
                    {betSlip.totalOdds ? (
                      <span className={'small text-truncate fw-bold fs-8'}>
                        {betSlip.totalOdds}
                      </span>
                    ) : null}
                  </span>
                </div>
                <span className={'mw-100 mt-5 mb-2 text-dark fw-bold fs-6'}>
                  {Number(betSlip.maxWinning).toLocaleString('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </span>
              </NavLink>
            </Col>
          ) : null}
          {/* icon live */}
          <Col>
            <div className="footer-slot">
              <NavLink end to="/live" className="py-2 d-block text-dark">
                <span>
                  <img width="25" height={22} src={`${AWS_BUCKET_URL}/Icons/live.svg`} />
                </span>
                <span className="d-block text-truncate pt-1 fs-6">{t('live')}</span>
              </NavLink>
            </div>
          </Col>
          {user === null ? (
            <Col>
              <div className="footer-slot">
                <NavLink end to="/login" className="py-2 d-block text-dark">
                  <span>
                    <img width="25" height={22} src={`${AWS_BUCKET_URL}/Icons/login.svg`} />
                  </span>
                  <span className="d-block text-truncate pt-1 fs-6">{t('loginTitle')}</span>
                </NavLink>
              </div>
            </Col>
          ) : (
            <Col>
              <div className="footer-slot">
                <NavLink end to="/mybets" className="py-2 d-block text-dark">
                  <span>
                    <img
                      width="25"
                      height={22}
                      src={`${AWS_BUCKET_URL}/Icons/my-bets-inactive.svg`}
                    />
                  </span>
                  <span className="d-block text-truncate pt-1 fs-6">{t('mybets')}</span>
                </NavLink>
              </div>
            </Col>
          )}
        </div>
      </footer>
    </Default>
  );
};

export default Footer;
