import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { sportTypeToSportId } from 'toolkit/utils';
import { setDesktopSearchParams } from 'stores/match';
import { TopLeagues } from 'toolkit/constants.js';
import Category from './category';

import './index.scss';

const Sport = ({ sport }) => {
  const dispatch = useDispatch();
  const desktopSearchParams = useSelector((state) => state.match.desktopSearchParams);
  const count = useSelector((state) => {
    if (desktopSearchParams.dateFilter > 0) {
      return state.match.timeCounts[sport];
    }
    return state.match.counts[sport];
  });
  const sportCount = count
    ? Object.values(count).reduce(
        (tempCount, tournament) => Object.values(tournament).reduce((c, t) => t + c, 0) + tempCount,
        0
      )
    : null;
  const [open, setOpen] = useState(false);
  const sportId = sportTypeToSportId(sport);
  const isActive = desktopSearchParams.Sport === sportId;

  function handleSportClick() {
    setOpen(!open);
  }

  function handlePlusClick(e) {
    e.stopPropagation();

    if (isActive) {
      dispatch(
        setDesktopSearchParams({
          mode: 'topMatches',
          Sport: null,
          Category: null,
          Tournament: null
        })
      );
    } else {
      dispatch(
        setDesktopSearchParams({
          mode: null,
          Sport: sportTypeToSportId(sport),
          Category: null,
          Tournament: null
        })
      );
    }
  }

  function categoryItems() {
    const azLeagues = [];
    let topLeagues = [];
    const leagueArray = [];

    Object.keys(count).forEach((category) => {
      const categoryItem = (
        <Category
          key={category}
          category={category}
          sport={sport}
          data={TopLeagues.indexOf(category)}
        />
      );
      if (TopLeagues.includes(category)) {
        topLeagues.push(categoryItem);
      } else {
        azLeagues.push(categoryItem);
      }
    });

    topLeagues = topLeagues.sort((t1, t2) => t1.props.data - t2.props.data);
    leagueArray.push(topLeagues);
    leagueArray.push(azLeagues);
    return leagueArray;
  }

  return (
    <>
      <button className={`btn sport-button ${isActive ? 'active' : ''}`} onClick={handleSportClick}>
        <span className="sport-text">{sport}</span>
        <span className="sport-count">{sportCount}</span>
        <span className="sport-plus" onClick={handlePlusClick}>
          <BsPlus size={32} />
        </span>
      </button>
      <Collapse in={open}>
        <div>
          <div className="d-flex flex-column gap-1">{categoryItems()}</div>
        </div>
      </Collapse>
    </>
  );
};

export default Sport;
