export default function BettingRules() {
  return (
    <div className="py-2 text-start px-75">
      <div>
        <a id="top" />
        <h5 className="text-danger mt-75">Header</h5>
        <span>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim vitae dicta minima
          consectetur aut reprehenderit repellat ex nesciunt sequi fugiat pariatur, animi velit eius
          autem tempora a inventore recusandae vel.
        </span>
      </div>
      <hr className="fw-bolder" />
      <div>
        <h6 className="fw-bolder">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita, dignissimos veritatis
          repudiandae sequi culpa corporis architecto quae ullam recusandae, provident distinctio
          officiis cumque enim. Facilis blanditiis temporibus provident ex aliquam!
        </h6>
        <p className="mt-2">
          1. Das Wettereignis, auf dessen Eintritt gewettet werden kann, wird vom Wettveranstalter
          bestimmt. Der Wettveranstalter ist jederzeit berechtigt, Wetten ohne Angabe von Gründen zu
          verweigern, die Höhe der Wetteinsätze vor Annahme der Wetten zu begrenzen und/oder
          Quotenänderungen vor Wettabschluss vorzunehmen.
        </p>
        <p className="mt-3">
          2. Der Wettvertrag kommt durch Annahme eines Wettangebotes zustande. Maßgeblich für die
          Gültigkeit der Wette sind die beim Wettveranstalter, zu der jeweiligen Wettscheinnummer,
          gespeicherten Daten. Für die Auslegung des Vertragsinhaltes sind die Aufzeichnungen des
          Wettveranstalters maßgebend. Der Wettkunde akzeptiert mit der Bestätigung des Wettscheines
          dessen Richtigkeit. Spätere Beanstandungen werden nicht berücksichtigt. Der
          Wettveranstalter ist berechtigt, von sich aus Schreib-, Rechen- oder Quotenfehler
          jederzeit - auch nach Vertragsabschluss - zu berichtigen.
        </p>
        <p className="mt-3">
          Es besteht für den Wettveranstalter keine Verpflichtung Zusatzangaben (z.B.
          Reservemannschaft (R), Altersbeschränkungen (U 23), Frauenmannschaft „(F),
          verkürzte/verlängerte Spielzeit, als Live-Ereignis geplant, neutraler Platz) zu machen.
          Sollten Zusatzangaben trotzdem gemacht werden, erfolgen diese ohne Gewähr und haben keinen
          Einfluss auf die Bewertung der Wette.
        </p>
        <p className="mt-3">
          Die Angabe des auf dem Wettschein angegebenen max. Gewinns ist bei Mehrwegwetten nicht
          verbindlich.
        </p>
        <p className="mt-3">
          Der Wettveranstalter ist berechtigt, bei Verdacht auf Fälschung oder Manipulation von
          Wetten (auch nachträglich) jeglichen Wetteinsatz zu stornieren - mit der Folge, dass die
          für die fraglichen Wetten eingesetzten Beträge auf das Wettkonto zurücküberwiesen und der
          Kunde von der Platzierung weiterer Wetten ausgeschlossen, das Wettkonto gesperrt und das
          Restguthaben in Übereinstimmung mit den allgemeinen Geschäftsbedingungen eingefroren wird.
          Fälschungen und Manipulationen oder der Verdacht auf Fälschung und Manipulation oder der
          Versuch der Fälschungen und Manipulation werden vom Wettveranstalter an die entsprechenden
          Behörden gemeldet und können zudem ein Strafverfahren wegen Betruges nach sich ziehen. Der
          Verdacht auf Manipulation bzw. Wettbetrug ist insbesondere dann gegeben, wenn zum Beispiel
          eines oder mehrere der nachfolgenden Kriterien erfüllt ist:
          <br />
          <br /> &emsp;a. Wetteinsätze auf Wettereignisse mit ungewöhnlichem Ausgang <br />
          <br /> &emsp;b. Wetteinsätze mit ungewöhnlich hohen Auszahlungsergebnissen <br />
          <br /> &emsp;c. Kombinationswetten auf ungewöhnliche Spiele/Ligen <br />
          <br /> &emsp;d. Gestaffelte Wetten mit gleichen oder auffällig abgewandelten Kombinationen{' '}
          <br />
          <br /> &emsp;e. Wetten, die auf eine Wettgemeinschaft schließen lassen und in zeitlichem
          und inhaltlichem Zusammenhang stehen
          <br />
          <br /> &emsp;f. Wetteinsätze, die auf technische oder elektronische Unterstützung bei der
          Abgabe oder Eingabe schließen lassen
        </p>
        <p className="mt-3">3. Gültigkeit der Wetten. </p>
        <p>
          In nachstehenden Fällen ist eine Einzelwette ungültig und der Einsatz wird zurückgezahlt,
          bzw. ist die Wette Teil einer Kombinationswette oder Systemwette, wird sie als gewonnen
          mit der Quote 1,0 gewertet. <br />
          <br />
          <br />
          <br /> Des Weiteren ist ein durchgeführter Cashout ungültig und der Wettschein erhält
          wieder seinen ursprünglichen Status:
          <br />
          <br /> &emsp; a. Wenn das Wettereignis einen falschen Teilnehmer oder ein vertauschtes
          Heimrecht enthält.
          <br />
          <br /> &emsp; b. Wenn ein Wettereignis abgesagt oder um mehr als 48 Stunden verschoben
          wird.
          <br />
          <br /> &emsp; c. Wenn der Wettabschluss nach dem tatsächlichen Beginn, des der Wette,
          zugrundeliegenden Ereignisses stattfindet. Dies gilt allerdings nicht für jene Wetten, die
          aufgrund ihrer Art vom Unternehmen laufend auch nach Beginn des Ereignisses angeboten
          werden, z.B. Langzeitwetten auf Sieger oder Livewetten, die eben auch noch nach Beginn
          eines Ereignisses abgeschlossen werden können.
          <br />
          <br /> &emsp; d. Wenn ein Wettereignis unter-/abgebrochen wird und nicht innerhalb von 6
          Stunden nach dem Zeitpunkt der Unterbrechung/des Abbruchs fortgeführt wird. Die Ausnahme
          hierzu bilden Wetten auf Tennismatches, die unterbrochen und zu einem beliebigen, späteren
          Zeitpunkt fortgeführt werden, sowie Wetten, deren Inhalt bereits vor dem Spielabbruch
          entschieden wurde (Über/ Unter, 1. Halbzeit, Nächstes Tor, etc.).
          <br />
          <br /> &emsp;e. Wenn ein falscher Spielstand auf dem Wettschein ausgewiesen ist.
          <br />
          <br /> &emsp; f. Wenn Quoten offensichtlich vertauscht oder fehlerhaft sind. <br />
          <br /> &emsp; Bei Spielen mit Heimrecht (Ligaspiel, Pokalspiel, internationaler
          Wettbewerb, etc.) ist die an erster Stelle auf dem Wettschein genannte Mannschaft immer
          die Heimmannschaft. Trägt die Mannschaft mit Heimrecht das Spiel – aus welchen Gründen
          auch immer – auf einer anderen Sportanlage aus, behalten alle Wetten ihre Gültigkeit, es
          sei denn, das Heimrecht wurde vom zuständigen Verband gedreht. In diesem Fall werden alle
          Wetten auf dieses Spiel als gewonnen mit der Quote 1,0 gewertet.
        </p>
        <p className="mt-3">
          4. Für die Bewertung des Wettausgangs gelten insbesondere folgende Regelungen:
        </p>
        <p>
          <br />
          <br /> &emsp; a. Maßgeblich sind einzig und allein die unmittelbar nach Beendigung des
          Wettereignisses vom zuständigen Verband bekanntgegebenen Ergebnisse. Nachträgliche
          Änderungen des Klassements (z.B. Entscheidungen „am grünen Tisch“) sind für die Bewertung
          der Wetten ohne Belang.
          <br />
          <br /> &emsp; b. Es ist das Ergebnis nach regulärer Spielzeit (+ eventueller
          Nachspielzeit) maßgebend. Etwaige Verlängerungen oder Elfmeterschießen usw. haben daher
          keinen Einfluss auf den Wettvertrag, außer die Vertragsteile haben davon Abweichendes
          durch Vermerk in den Aufzeichnungen des Wetthalters vereinbart (z.B. Turniersieger, Wer
          kommt weiter, inkl. Verlängerung, etc.). Ausnahme: Bei den Sportarten Basketball, American
          Football und Baseball ist das Ergebnis inkl. einer möglichen Verlängerung maßgebend.
          <br />
          <br /> &emsp; c. Bei der Entscheidung über die Über/Unter-Wetten bei einem
          Elfmeterschießen werden alle Tore aus diesem Elfmeterschießen gezählt.
          <br />
          <br /> &emsp; d. Für Langzeitwetten gelten folgende Regeln: <br />
          <br /> &emsp;&emsp; i. Totes Rennen: Belegen mehrere Teilnehmer am Ende einer
          Veranstaltung den gleichen Platz, werden alle Wetten, die auf diese Teilnehmer platziert
          wurden, durch die Anzahl der Plätze geteilt. <br />
          <br /> &emsp;&emsp; ii. Reduzierung der Quoten durch vermindertes Teilnehmerfeld: Wenn
          sich ein oder mehrere Teilnehmer nach der Wettplatzierung, jedoch vor Beginn der
          Veranstaltung, aus dieser zurückziehen, werden diese Wetten als gewonnen mit der Quote 1,0
          gewertet. <br />
          <br /> &emsp;&emsp; iii. Im Einzelfall kann es dann zu einer Verminderung der gewetteten
          Quoten auf die anderen Teilnehmer kommen. <br />
          <br /> &emsp;&emsp; iv. Bei Wetten auf Torschützen eines Spieles werden diese Wetten als
          gewonnen mit der Quote 1,0 gewertet, falls der betreffende Spieler nicht in der
          Startformation steht. <br />
          <br /> &emsp;&emsp; Beschwerden in Bezug auf die Entscheidung oder die Gutschrift der
          Gewinne jeglicher Art müssen schriftlich, bis spätestens 14 Werktage nachdem die
          Ergebnisse feststehen, an den Wetthalter (per Brief, Fax oder E-Mail) gerichtet werden.
        </p>
        <p className="mt-3">
          5. Ein Rücktrittsrecht des Wettkunden nach Abschluss des Wettvertrages ist ausgeschlossen.
        </p>
        <p className="mt-3">6. Cashout-Funktion</p>
        <p>
          <br />
          <br /> &emsp; a. Der Wettveranstalter bietet zu bestimmten Wettangeboten eine
          Cashout-Funktion an, mit welcher Kunden Wettscheine vor deren Ablauf an den
          Wettveranstalter verkaufen können.
          <br />
          <br /> &emsp; b. Diese Cashout- Funktion ist eine freiwillige Zusatzoption seitens des
          Wettveranstalters. <br />
          <br /> &emsp; c. Generell besteht kein Rechtsanspruch auf die Cashout-Funktion.
          <br />
          <br /> &emsp; d. Insbesondere ist Cashout nicht möglich, wenn aus welchen Gründen auch
          immer die Wettabgabe eines der gewetteten Events oder deren Tippart zum Zeitpunkt des
          Cashoutversuchs gesperrt ist. <br />
          <br /> &emsp; e. die Cashout-Funktion unterliegt den Wettregeln <br />
          <br /> &emsp;Diese Wettregeln gelten ab dem 01.10.2021 (Version 4.0.1).
        </p>
        <a href="#top">Back to top</a>
      </div>
    </div>
  );
}
