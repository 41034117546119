import { useTranslation } from 'react-i18next';
import { getSportTypeToIconForBetSlip, convertToTime } from 'toolkit/utils';
import { useMediaQuery } from 'react-responsive';

function ResultCard(props) {
  const { t } = useTranslation('translations', { useSuspense: false });

  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });

  return (
    <div
      className={`px-2 py-2 rounded mb-2 ${
        isDesktop ? 'bg-list-dark border-card' : ' border-gray-300 '
      } `}>
      <div className="d-flex justify-content-between mb-n2">
        <div>
          <div className="text-center ms-n2">
            <span className="px-2 py-1 text-white bg-secondary fs-8">{props.day}</span>
            <span className="px-2 py-1 bg-dark text-white rounded-end-5 fs-8">
              {convertToTime(props.time)}
            </span>
          </div>
        </div>
        <div className="ms-auto">
          <div className={isDesktop ? 'pt-1 pb-2 icon-white' : 'pt-1 pb-2'}>
            {getSportTypeToIconForBetSlip(props?.sportType) == null
              ? t(`sports.${props.sportType}`)
              : getSportTypeToIconForBetSlip(props?.sportType)}
            {/* - {t(`tournaments.${props.match.tournamentId}`)} */}
          </div>
        </div>
      </div>
      <div className="text-left px-2 pt-1 ">
        <div className="d-flex justify-content-between mb-1 fw-bold fs-6">
          <span className={isDesktop ? 'text-white' : 'text-dark'}>{props.team1}</span>
          <span className={isDesktop ? 'text-white' : 'text-dark'}>{props.scoreteam1}</span>
        </div>
        <div className="d-flex justify-content-between fs-6">
          <span className={isDesktop ? 'text-white' : 'text-dark'}>{props.team2}</span>
          <span className={isDesktop ? 'text-white' : ''}>{props.scoreteam2}</span>
        </div>
      </div>
    </div>
  );
}
export default ResultCard;
