import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BsPlus, BsDash } from 'react-icons/bs';
import { sportTypeToSportId, tournamentNameHelper } from 'toolkit/utils';
import { setDesktopSearchParams } from 'stores/match';

const Tournament = ({ tournament, count, category, sport }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const desktopSearchParams = useSelector((state) => state.match.desktopSearchParams);
  const tournamentNames = useSelector((state) => state.match.tournamentNames);
  const isActive = desktopSearchParams.Tournament === tournament;

  function handlePlusClick(e) {
    e.stopPropagation();
    if (desktopSearchParams.Tournament == tournament) {
      dispatch(
        setDesktopSearchParams({
          Tournament: null
        })
      );
    } else {
      dispatch(
        setDesktopSearchParams({
          mode: null,
          Sport: sportTypeToSportId(sport),
          Category: category,
          Tournament: tournament
        })
      );
    }
  }

  return (
    <button className={`btn tournament-button ${isActive ? 'active' : ''}`}>
      <span className="tournament-text text-truncate">
        {tournamentNameHelper(tournament, tournamentNames, t)} &nbsp;
      </span>
      <span className="tournament-count">{count}</span>
      <span className="tournament-plus" onClick={handlePlusClick}>
        {isActive ? <BsDash size={32} /> : <BsPlus size={32} />}
      </span>
    </button>
  );
};

export default Tournament;
