export default function DE() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <a id="top" />
      <h3 className="text-danger text-left">GESCHÄFTSBEDINGUNGEN</h3>
      <h6>Allgemeine Geschäftsbedingungen</h6>
      <ol>
        <li key={1}>
          <a href={`#${1}`}> Grundlagen</a>
        </li>
        <li key={2}>
          <a href={`#${2}`}> Ihr Konto</a>
        </li>
        <li key={3}>
          <a href={`#${3}`}> Zahlungen und Sicherheit</a>
        </li>
        <li key={4}>
          <a href={`#${4}`}> Nutzungsbeschränkung der Game90-Website</a>
        </li>
        <li key={5}>
          <a href={`#${5}`}> Die Website</a>
        </li>
        <li key={6}>
          <a href={`#${6}`}> Haftungsausschluss und Verfügbarkeit der Game90-Website</a>
        </li>
        <li key={7}>
          <a href={`#${7}`}> Haftungsbeschränkung</a>
        </li>
        <li key={8}>
          <a href={`#${8}`}> Rechte an geistigem Eigentum</a>
        </li>
        <li key={9}>
          <a href={`#${9}`}> Meldepflicht</a>
        </li>
        <li key={10}>
          <a href={`#${10}`}> Allgemeine Bestimmungen</a>
        </li>
        <li key={11}>
          <a href={`#${11}`}>
            Regeln für die Platzierung von Wetten / Sportregeln / Spielrelevante Informationen,
            Kosten, Wettquoten und Gewinne
          </a>
        </li>
        <li key={12}>
          <a href={`#${12}`}> Bonusregelung</a>
        </li>
        <li key={13}>
          <a href={`#${13}`}> Verantwortliches Spielen</a>
        </li>
        <li key={14}>
          <a href={`#${14}`}> Datenschutzrichtlinie</a>
        </li>
        <li key={15}>
          <a href={`#${15}`}> Allgemeines</a>
        </li>
        <li key={16}>
          <a href={`#${16}`}> ADR - Alternative Streitbeilegung</a>
        </li>
        <li key={17}>
          <a href={`#${17}`}> Datum des Inkrafttretens</a>
        </li>
      </ol>
      <br />
      <br />
      <div>
        <h5 id="1" className="mt-75">
          1. Grundlagen
        </h5>
        <h6 className="mt-3"> 1.1 Begriffsbestimmungen </h6>
        <div className="mt-3">
          1.1.1 Die Begriffe „wir“, „das Unternehmen“ oder „uns“ beziehen sich auf game90.bet oder
          Game90 Ltd., ein Unternehmen mit der registrierten Firmennummer C50436 in Melita Court,
          Level 1, Guiseppe Cali St c/w Abate Rigord St, XBX 1420, Ta’Xbiex, Malta. <br />
          <br />
          1.1.2 Der Begriff „Website“ bezieht sich auf game90.bet. Vollständiger und alleiniger
          Besitzer und Betreiber der Website ist Game90. <br />
          <br />
          1.1.3 Der Begriff &quot;herunterladbare Anwendung&quot; (kurz App) bezieht sich auf jede
          Version der mobilen App und auch auf die zukünftig genehmigten Anwendungen
          (App-Versionen), die von Game90 erstellt wurden. Vollständiger und alleiniger Eigentümer
          und Anbieter dieser mobilen Apps ist Game90. <br />
          <br />
          1.1.4 Die hier vorliegenden Bedingungen beziehen sich auch auf alle herunterladbaren
          Applikationen für Informations- und Kommunikationsendgeräte. Diesbezüglich können die
          Allgemeinen Geschäftsbedingungen von der Website auch auf die App in Bezug auf alle
          Informations- und Kommunikationsendgeräte übertragen werden. Im Folgenden sind alle
          Applikations- und Kommunikationsgeräte mit den Worten „Website“ oder „Game90“
          eingeschlossen. .
          <br />
          <br />
          1.1.5 Der Begriff „Dienst“ bezieht sich auf die Möglichkeit zur Teilnahme an allen Wetten,
          die von Game90 angeboten werden. <br />
          <br />
          1.1.6 Der Begriff „Software“ bezieht sich auf alle Computerprogramme, die von der Website
          oder aus einem Applikations-Shop (App-Store, Google-Play-Store etc.) heruntergeladen
          werden können. <br />
          <br />
          1.1.7 Der Begriff „Game90-Dienst“ kann sich auf die Website, die Software und den
          Wettdienst beziehen. <br />
          <br />
          1.1.8 Die Begriffe „Sie“ oder „Ihr“ oder „Kunden“ bezeichnen jede Person, die die
          Dienstleistungen von Game90 in Übereinstimmung mit diesen allgemeinen Geschäftsbedingungen
          in Anspruch nimmt. <br />
          <br />
          1.1.9 Der Begriff „Konto“ bezieht sich auf das Spielerkonto der Kunden auf game90.bet.
          <br />
          <br />
          1.1.10 Die seit dem 25. Mai 2019 europaweit geltende Datenschutz-Grundverordnung wird in
          diesen Geschäftsbedingungen als DSGVO bezeichnet. <br />
          <br />
          1.1.11 Game90 verwendet seit dem 01. März 2020 in seinem Dienst befindliche
          Spielerblockierungssystem OASIS des Regierungspräsidiums Darmstadt. Dieses
          Online-Abfrage-Spielerstatus-System wird als OASIS-System oder nur OASIS in diesen
          Geschäftsbedingungen bezeichnet und bezieht sich vollständig auf dieses Sperrsystem und
          ist für alle Kunden verpflichtend.
          <br />
          <br />
          1.1.12 Durch die Regulierungen der Europäischen Union hat Game90 die Option einer
          Streitbeilegungsstelle eingeräumt. Diese wird allgemein als ADR (Alternative Dispute
          Resolution) abgekürzt und in diesen Geschäftsbedingungen als ADR bezeichnet. <br />
          <br />
          Diese Begriffsbestimmungen gelten gleichermaßen für die Datenschutzrichtlinien und die
          Regeln für Wetten und Sportwetten von Game90. <br /> <br />
          <br /> <h6> 1.2 Umfang dieser Allgemeinen Geschäftsbedingungen </h6>
          <br />
          1.2.1 Diese allgemeinen Geschäftsbedingungen gelten für die Nutzung aller von Game90
          angebotenen Online-Dienstleistungen die über die Game90-Website, deren Services als auch
          angebotenen Software angeboten werden. Dies schließt auch für die Zukunft erweiterbare
          Dienstleistungen ein. Um auf game90.bet ein Konto zu eröffnen, müssen Kunden sich mit
          diesen Bedingungen einverstanden erklären. Mit der Registrierung auf der Website, der
          Eröffnung eines Kontos oder der Nutzung der Game90-Website erkennen die Kunden diese
          Allgemeinen Geschäftsbedingungen als verbindlich an.
          <br /> <br />
          1.2.2 Game90 ist von der maltesischen Lotterie- und Glücksspielbehörde zugelassen, unter
          der Lizenznummer MGA/B2C/191/2010, ausgestellt am 18. Dezember 2011, seinen Service wie
          Sportwetten etc. online anzubieten. Die gleiche Behörde reguliert den Online-Wettdienst
          von Game90. Game90 Ltd. wurde mit Bescheid vom 19. November 2020 unter dem Aktenzeichen
          Az. III 34-73c 38/01-35-2019 vom Regierungspräsidium Darmstadt eine Konzession zur
          Veranstaltung von Sportwetten im Internet und stationären Betrieb in Deutschland erteilt.
          <br />
          <br />
          Die Services der Game90 Ltd. können in anderen Staaten mit abweichender Gesetzgebung
          anders betrachtet werden. Das Unternehmen enthält sich Darstellungen zur Rechtmäßigkeit
          seines Online-Wettdienstes unter anderer Gerichtsbarkeit. Diese Vereinbarung unterliegt
          den Gesetzen von Malta und ist in Übereinstimmung mit diesen auszulegen. Gerichtsstand ist
          Malta. <br /> <br />
          <h6> 1.3 Die Verbindlichkeit dieser Allgemeinen Geschäftsbedingungen </h6>
          <br />
          1.3.1 Diese allgemeinen Geschäftsbedingungen stellen eine rechtliche bindende Vereinbarung
          zwischen den Kunden und dem Unternehmen Game90 Ltd. dar und regeln die Nutzung der
          Game90-Website. Bitte lesen Sie diese allgemeinen Geschäftsbedingungen sorgfältig. Das
          Unternehmen behält sich das Recht vor, jederzeit und ohne Ankündigung Änderungen an diesen
          Geschäftsbedingungen vorzunehmen. Das Unternehmen wird sicherstellen, dass Kunden über
          derartige Änderungen informiert werden, indem beim nächsten Anmeldevorgang auf der Website
          darum gebeten wird, die überarbeiteten allgemeinen Geschäftsbedingungen anzunehmen. Ohne
          diese Zustimmung ist die Nutzung der Game90 Dienste nicht möglich.
          <br /> <br />
          1.3.2 Es obliegt der Verantwortung der Kunden, durch die regelmäßige Überprüfung der
          allgemeinen Geschäftsbedingungen sicherzustellen, dass den Geschäftsbedingungen zugestimmt
          wurde, welche unter anderem die Platzierung der Wetteinsätze regeln. Es wird ferner
          empfohlen, diese allgemeinen Geschäftsbedingungen jedes Mal nachzulesen, wenn die Website
          verwendet wird. Für alle Serviceleistungen, die vor einer Änderung dieser Bedingungen und
          deren Annahme durch Kunden genutzt wurden (aber noch nicht entschieden sind), gelten die
          bisherigen AGB. <br />
          <br /> <h6> 1.4 Wettbestimmungen – Wett- und Sportregeln global </h6>
          <br />
          In Verbindung mit diesen Allgemeinen Geschäftsbedingungen gelten des Weiteren die globalen
          Wettbestimmungen (Version 4.0.1)
          <br /> <br /> <a href="#top">Zurück</a>
        </div>
      </div>
      <div>
        <h5 id="2" className="mt-75">
          2. Ihr Konto
        </h5>
        <h6 className="mt-3">2.1 Eröffnung eines Kontos</h6>
        <p className="mt-3">
          2.1.1 Um über die Game90-Website einen Service nutzen zu können, müssen Kunden sich
          registrieren und ein eigenes Nutzer-Konto erstellen. Das Mindestalter für die Anmeldung
          beträgt 18 Jahre (21 Jahre für Spieler aus Estland). Ohne Registrierung ist kein
          Serviceabschluss möglich.
          <br />
          <br /> 2.1.2 Bei der Registrierung müssen Kunden die erforderlichen abgefragten Daten
          bereitstellen. Kunden sind dafür verantwortlich, dass alle zur Verfügung gestellten
          Angaben richtig und aktuell bleiben. Ohne die Angabe der erforderlichen personenbezogenen
          Daten hat Game90 das Recht und die Pflicht ein Kundenkonto zu schließen und eventuell
          bereits eingezahlte Gelder einzufrieren. Game90 behält sich auch das Recht vor, alle
          Serviceleistungen für ungültig zu erklären, wenn von Kunden falsche oder irreführende
          Angaben gemacht worden sind. <br />
          <br />
          2.1.3 Jede Einzelperson darf nur ein Nutzerkonto eröffnen. Game90 behält sich das Recht
          vor, nach eigenem Ermessen weitere Konten zu schließen und eventuelle Guthaben
          zurückzuhalten oder mehrere wie ein einziges Konto zu betrachten und entsprechend zu
          behandeln. <br />
          <br />
          2.1.4 Game90 behält sich ebenso das Recht vor, seine Kunden durch Drittunternehmen prüfen
          zu lassen, welche dazu berechtigt sind, eine Kopie der Informationen zu erhalten, die
          gemäß unserer Datenschutzbestimmungen von Kunden bei der Registrierung bereitgestellt
          werden. <br />
          <br />
          2.1.5 Game90 weist darauf hin, dass es illegal ist, unrechtmäßig erworbenes Geld
          einzuzahlen.
          <br />
          <br />
          2.1.6 Alle Transaktionen werden sowohl technisch als auch manuell überprüft, um Geldwäsche
          und illegalen Transaktionen vorzubeugen. Verdächtige Transaktionen werden der Financial
          Intelligence Analysis Unit (FIAU) in Malta oder der FIU (Financial Intelligence Unit) in
          Deutschland mitgeteilt. Die FIU´s sind Aufsichtsbehörden, die die verdächtigen
          Transaktionen aufzeichnen, überprüfen und gegebenenfalls an die zuständigen Behörden (FATF
          – Financial Action Task Force bzw. in Deutschland dem BKA und Zoll) übergeben. <br />
          <br /> Das Kunden-Konto und die darin enthaltenen Beträge sind ausschließlich für
          Spieleinsätze zu verwenden. Zu den reinen Wetteinsätzen werden Konten deutsch ansässiger
          Kunden nach § 18 Rennwett- und Lotteriegesetz vom 25.06.2021 mit einer Konzessionsabgabe
          („Wettsteuer“) belastet. <br />
          <br />
          2.1.8 Game90 behält sich das Recht vor, zu verschiedenen Zeiten und an verschiedenen
          Schnittpunkten der Zusammenarbeit mit den Kunden digitale, technische, elektronische als
          auch manuelle Kontrollen durchzuführen, um die Sicherheit für Kunden und Unternehmen zu
          gewährleisten. <br />
          <br />
          2.1.9 Game90 ist kein Finanzunternehmen und darf auch nicht als solches missbraucht
          werden. Länger liegende Geldbeträge auf einem Nutzerkonto, welche durch Einzahlungen die
          höher als durchschnittlich pro Kunden üblich sind entstanden und welche seit mindestens 90
          Tagen nicht bespielt wurden, können gemäß Punkt 2.1.6 von Game90 an die Behörden gemeldet
          werden. <br />
          <br />
          Game90 bietet keine Nutzerkonten auf Kreditbasis an und wird auch nie ein Nutzerkonto mit
          kreditiertem Geld zur Verfügung stellen. Dies ist nicht vergleichbar mit eventuellen
          Werbeaktionen in denen ein Bonus im Vorfeld zur Verfügung steht. Boni sind an Bestimmungen
          geknüpft, die nicht mit einem Kredit gleichsetzbar sind.
        </p>
        <h6 className="mt-3">2.2 Registrierung</h6>
        <p className="mt-3">
          2.2.1 Die Registrierung erfolgt online durch Eingabe der erforderlichen personenbezogenen
          Daten, wie Name, Adresse, E-Mail-Kontakt, Geburtsdatum, Benutzername und Passwort. Das
          Passwort kann jederzeit durch die Kunden auf der Website selbst geändert werden. Ohne die
          erforderlichen Angaben kann der Account nicht erstellt werden.
          <br />
          <br /> 2.2.2 Ohne die separate Bestätigung der Einwilligung zur Abgabe der persönlichen
          Daten der Datenschutz-Grundverordnung nach Artikel 7 kann kein Spielerkonto eröffnet
          werden. Die Einwilligung ist auch allein deshalb separat erforderlich, weil gesetzliche
          Vorgaben zur Verifizierung erfüllt werden müssen. Game90 hat nach Artikel 6(1)f der DSGVO
          das legitime und erforderliche Recht, die Daten zur Verarbeitung zu erfragen.
          <br />
          <br /> 2.2.3 Game90 behält sich vor, nicht genutzte Kundenkonten ohne Guthaben nach Ablauf
          der Frist von drei Monaten automatisch zu löschen. In diesem Fall ist eine erneute
          Registrierung jederzeit möglich.
          <br />
          <br />
          2.2.4 Die Registrierung beinhaltet automatisch die Eröffnung eines persönlichen virtuellen
          Spielerkontos. Die Eröffnung dieses Spielerkontos ist kostenlos und geht mit keiner
          Verpflichtung für die Kunden einher.
          <br />
          <br />
          2.2.5 Alle Geldtransaktionen (insbesondere Einzahlungen, Auszahlungen, Bonusgutschriften
          und Einsätze) werden ausschließlich über das persönliche Spielerkonto durchgeführt.
          Forderungen und Beanstandungen zu Guthaben, Auszahlungen oder anderen Transaktionen, die
          auf dem Spielerkonto verbucht werden, müssen innerhalb von 14 Werktagen per E-Mail oder
          schriftlich bei Game90 eingehen. Nach Ablauf dieser Frist werden alle verbuchten
          Transaktionen als vom Kunden genehmigt betrachtet.
          <br />
          <br />
          2.2.6 Game90 behält sich das Recht vor, die Registrierung ohne jegliche Begründung
          abzulehnen.
        </p>
        <h6 className="mt-3">2.3. Datenschutz</h6>
        <p className="mt-3">
          2.3.1 Gemäß der Datenschutz-Grundverordnung Artikel 12 steht jedem Nutzer frei, in
          halbjährlichen Abständen eine Bestandsanzeige seiner Daten bei Game90 anzufordern. Diese
          werden dann entsprechend geltendem Recht in einem lesbaren Format dem Kunden zur Verfügung
          gestellt. Eine Beantragung der Auskunft löst eine separate Verifizierung, die entsprechend
          Punkt 5.3.7.ff dieser AGB ausgeführt wird, aus. Nähere Informationen dazu sind in unserer
          Datenschutzbestimmung ebenfalls dargestellt.
          <br />
          <br />
          2.3.2 Kunden haben das Recht Ihre Daten auf dem aktuellsten Stand zu halten. Hierfür
          können die Kunden auch eine Vervollständigung ihrer personenbezogenen Daten nach Artikel
          16 Datenschutz-Grundverordnung verlangen.
          <br />
          <br />
          2.3.3 Daten von Kunden werden gemäß gesetzlicher Bestimmungen gespeichert. Bei Verlangen
          auf Löschung dieser Daten setzt diese Frist ab dem Datum der beantragten Löschung ein und
          die Kunden werden entsprechend darüber informiert.
          <br />
          <br />
          2.3.4 Personenbezogene Daten werden bei Game90 auf speziell gesicherten Speichermedien
          oder Servern mit spezieller Sicherung zusätzlich kryptisch verändert abgespeichert. Auf
          Anfrage stellt Game90 diese in einem lesbaren Format zur Verfügung.
          <br />
          <br />
          2.3.5 Ein automatisiertes Einstufen, das sogenannte Profiling, also eine Einschätzung des
          Kundenverhaltens nur auf automatisierten Prozessen basierend, findet bei Game90 nicht
          ausschließlich statt. Profilings werden zusätzlich auch durch manuelle Sichtungen und
          Einschätzungen vorgenommen und folgen keiner programmierten Logik.
          <br />
          <br />
          2.3.6 Eine Unterteilung in rassische, ethnische, religiöse, politische oder
          weltanschauliche Daten oder die Verarbeitung von genetischen oder nach sexuellen
          Interessen orientierten Daten findet bei der personenbezogenen Datenverarbeitung durch
          Game90 nicht statt.
          <br />
          <br />
          2.3.7 Alle Kunden haben das Recht ihre Einwilligung für das Verarbeiten von ihren
          personenbezogenen Daten gemäß Artikel 7 (3) zu widerrufen oder nach Artikel 18
          einzuschränken. Alle bis dahin verarbeiteten Daten bleiben von diesem Widerruf unberührt.
          Game90 wird auf den Widerruf hin alle Auftragsverarbeiter der personenbezogenen Daten über
          den Widerruf informieren.
          <br />
          <br />
          2.3.8 Game90 Limited hat einen Datenschutzbeauftragten (DPO – Data Protection Officer) für
          das Unternehmen bestimmt. Dieser ist per E-Mail unter DPO@game90.bet für Fragen zum Thema
          Datenschutz erreichbar..
        </p>
        <h6 className="mt-3">2.4 Nutzung des Kontos</h6>
        <p className="mt-3">
          2.4.1 Jedes Konto ist ausschließlich zum persönlichen Gebrauch bestimmt und darf nicht für
          berufliche, geschäftliche oder kommerzielle Zwecke verwendet werden.
          <br />
          <br />
          2.4.2 Kunden sind verpflichtet, sicherzustellen, dass kein Dritter, insbesondere keine
          Person unter 18 Jahren Zugriff auf das Nutzerkonto oder auf die Passwörter hat (weder
          absichtlich noch unabsichtlich) oder deren Identität für den Zugriff auf die
          Game90-Website und deren Dienste verwendet. Einsätze, die durch Dritte erfolgen, denen der
          Benutzername oder das Passwort bekannt geworden ist, werden behandelt, als wären sie durch
          den Inhaber des Nutzerkontos selbst erfolgt. Die Kunden haften für alle Transaktionen, die
          durch Dritte in ihrem Namen erfolgen. Game90 haftet nicht für die unbefugte Nutzung der
          Kundenkonten. Unabhängig davon, ob Dritte mit oder ohne Zustimmung des Kunden auf den
          Game90- Dienst zugreifen, erfolgt keine Erstattung der daraus resultierenden Verluste.
          <br />
          <br />
          2.4.3 Game90 kann ein Kundenkonto bei Verdacht auf Datenmissbrauch aus Sicherheitsgründen
          vorübergehend schließen und Kunden dazu auffordern, das Passwort umgehend zu ändern. Dies
          gilt auch wenn Game90 Kenntnis von nach Punkt 2.4.2 vorhandenen Umständen erhält.
          <br />
          <br />
          2.4.4 Die Kunden sichern zu, dass sie die Game90-Dienste und Website nicht während eines
          Aufenthaltes in einem Land nutzen in dem die Teilnahme an Online-Wetten oder
          Online-Spielen gegen geltende Gesetze oder Vorschriften des Ortes oder des Landes
          verstoßen. Game90 behält sich das Recht vor, die Nutzung der Game90-Dienste jederzeit für
          diese Orte oder Länder einzuschränken.
          <br />
          <br />
          2.4.5 Game90 empfiehlt seinen Kunden den aktuellen Kontostand nach jeder Anmeldung zu
          prüfen. Kunden müssen Game90 unmittelbar schriftlich benachrichtigen, falls Korrekturen
          erforderlich sind. Werden derartige Forderungen erhoben, müssen Kunden nicht nur ihren
          aktuellen Kontostand vorlegen, sondern auch alle Transaktionen seit der letzten
          Saldenanpassung, damit in Übereinstimmung mit Punkt 3.1.7 eine Prüfung durchgeführt werden
          kann.
          <br />
          <br />
          2.4.6 Jede Partei kann das Nutzerkonto umgehend, vorübergehend oder dauerhaft schließen,
          wenn die andere Partei ihre Verpflichtungen unter diesen Bedingungen nicht erfüllt. Die
          Auszahlung von etwaigen Restbeträgen richtet sich nach den Bestimmungen dieser allgemeinen
          Geschäftsbedingungen. Die vorübergehende oder dauerhafte Schließung eines Nutzerkontos
          entbindet die Parteien nicht von ihren gesetzlichen Rechten und Pflichten.
          <br />
          <br />
          2.4.7 Game90 behält sich für seinen angebotenen Service vor, alle Einsätze jederzeit ohne
          Angabe von Gründen abzulehnen. Allerdings bemüht sich Game90, die Kunden über die Gründe
          für diese Entscheidung in Kenntnis zu setzen.
          <br />
          <br />
          2.4.8 Game90 ist kein Finanzinstitut und darf nicht als solches behandelt werden; auf
          Kontoguthaben werden keine Zinsen ausgezahlt und gemäß Punkt 2.1.9 hat Game90 das Recht,
          als auch die Pflicht, die entsprechenden zuständigen Behörden (FIAU, ZOLL bzw. BKA etc.)
          über diesen Umstand zu informieren.
          <br />
          <br />
          2.4.9 Das Guthaben kann gegen alle Beträge verrechnet werden, die Kunden dem Unternehmen
          schulden.
          <br />
          <br />
          2.4.10 Kunden akzeptieren, dass sie durch ihren Wetteinsatz Geld verlieren können und
          übernehmen die volle Verantwortung für derartige Verluste.
          <br />
          <br />
          2.4.11 Alle Kontobewegungen können jederzeit online in Echtzeit angezeigt werden.
          <br />
          <br />
          2.4.12 Wenn Sie Fragen zu Ihrem Konto haben, setzen Sie sich bitte mit unserem
          Kundendienst unter support@game90.bet in Verbindung.
          <br />
          <br />
          2.4.13 Das Unternehmen behält sich das Recht vor, jede seiner Dienstleistungen und seine
          Website jederzeit und ohne Ankündigung zu ändern und Dienstleistungen hinzuzufügen oder zu
          entfernen.
          <br />
          <br />
          2.4.14 Das Nutzen von Applikationen für die Game90-Dienste, welche ausschließlich durch
          Game90 erstellt wurden, ist dem Spieler frei überlassen und der Nutzer handelt hier auf
          eigene Gefahr und stellt Game90 von der Haftung frei. (siehe auch 6.2 und Pkt. 7 –
          Haftungsbeschränkung).
          <br />
          <br />
          2.4.15 Der Game90 Kundendienst und jeder weitere Mitarbeiter der Game90 bietet einen
          professionellen und höflichen Service bei jedem Kunden-Kontakt. Jegliche unhöfliche oder
          andere Art von Belästigung gegenüber Game90-Mitarbeitern kann aufgezeichnet und eine
          direkte und sofortige Schließung des Nutzerkontos zur Folge haben. Game90 behält sich dann
          auch die Option frei, gegebenenfalls rechtliche Schritte in solchen Fällen einzuleiten.
        </p>
        <h6 className="mt-3">2.5 Schließen Ihres Kontos </h6>
        <p className="mt-3">
          2.5.1 Das Unternehmen als auch die Kunden können ein Konto jederzeit und ohne Angabe von
          Gründen schließen. Game90 wird sich bemühen, die Kunden über diese Entscheidung zu
          informieren. Um eine Löschung ihres Kontos zu beantragen, senden Kunden einfach eine
          schriftliche Mitteilung per E-Mail, Fax oder per Post an die genannte Unternehmensadresse.
          2.5.2 Jedes zum Zeitpunkt der Schließung auf dem Konto bestehende Guthaben, außer in
          Zweifels- oder Betrugsfällen, wird nach Ermessen des Unternehmens dem aktenkundigen
          Bankkonto gutgeschrieben. Gewinne, die nach der Schließung eines Kontos entstanden sind,
          werden dem Kundenkonto gutgeschrieben. Auszahlungen werden entsprechend Punkt 3.1.3
          behandelt. Einbehaltenes Geld wird entsprechend den gesetzlichen Bestimmungen behandelt
          und gegebenenfalls den Behörden übergeben.
          <br />
          <br />
          2.5.3 Ungeachtet der vorstehenden Bestimmungen (§2.4) ist das Unternehmen berechtigt, ein
          neues Konto mit sofortiger Wirkung zu sperren oder zu löschen, wenn
          <br />
          <br />
          2.5.3.1 die Kunden gegen diese allgemeinen Geschäftsbedingungen (AGB) oder die Regeln für
          Einsätze für Sportwetten verstoßen;
          <br />
          <br />
          2.5.3.2 es berechtigten Grund zu der Annahme oder Hinweise darauf gibt, dass die
          Einzahlungen der Kunden mit illegalen oder betrügerischen Handlungen verbunden sind. In
          solch einem Fall übernimmt das Unternehmen keine Haftung für Zahlungen, die von Kunden
          vorgenommen wurden und den gesetzlich festgelegten Rahmen überschreiten. Das Unternehmen
          übermittelt (ohne Verletzung seiner Verpflichtungen, die aus diesen allgemeinen
          Geschäftsbedingungen oder Datenschutzbestimmungen hervorgehen) den zuständigen Behörden
          auf deren Anfrage alle Informationen oder Dokumente, die mit diesen Kunden in Verbindung
          stehen (vgl. 2.1.4 und 2.1.6);
          <br />
          <br />
          2.5.3.3 das Unternehmen vermutet, dass eine unbefugte Person mit falschen oder gefälschten
          Identitätsdaten ein Konto eröffnen will oder hat;
          <br />
          <br />
          2.5.3.4 die gesetzlichen Vorschriften diese Sperrung oder Schließung des Kontos verlangen;
          <br />
          <br />
          2.5.3.5 Kunden einer Nutzung ihrer personenbezogenen und zum Aufrechterhalten des
          Geschäftsbetriebes erforderlichen Daten nach Artikel 7 der Datenschutz-Grundverordnung
          widersprechen;
          <br />
          <br />
          2.5.3.6 es ein Sicherheitsproblem oder andere Probleme gibt, die das Unternehmen als
          wichtig erachtet, oder eine Rufschädigung des Unternehmens zu befürchten ist;
          <br />
          <br />
          2.5.3.7 In der Vergangenheit eine Rückbuchung erfolgte und/oder erfolgreich angefordert
          wurde und der damalige Betrag nicht vollständig einmal bespielt wurde;
          <br />
          <br />
          2.5.3.8 Dem Unternehmen bekannt ist oder bekannt wird, dass Kunden bereits ein früheres
          Konto besessen hatten, welches wegen Spielsuchtprävention geschlossen wurde und/oder noch
          geschlossen ist oder sich Kunden auffällig zum Thema Spielsucht äußern;
          <br />
          <br />
          2.5.3.9 Nutzer eine Staatsbürgerschaft oder einer Nationalität angehören, dessen Staat das
          Online-Glücksspiel generell auch außerhalb seiner eigenen Justizbarkeit (z.B. USA oder
          Litauen) weltweit verbietet;
          <br />
          <br />
          2.5.3.10 Wenn Kunden zuvor ein Konto besessen haben, das wegen eines Verstoßes gegen diese
          oder frühere Bedingungen geschlossen wurde, oder dem Unternehmen bekannt wird, dass der
          Kontoinhaber dieselbe Person oder Partner oder Verwandter der Person ist, deren Konto
          gegen diese oder frühere Bedingungen verstößt oder Bedingungen verstoßen haben und die
          Nutzerkonten geschlossen wurden.
          <br />
          <br />
          2.5.4 Den Kunden ist die Übertragung, der Kauf oder der Verkauf eines Nutzerkontos an oder
          von anderen Spielern untersagt. Game90 hat dann sofort das Recht diese Konten sofort zu
          schließen. (siehe auch Punkt 2.5.3.1)
          <br />
          <br />
          2.5.5 Die Kunden können und dürfen keine Überweisungen zwischen Nutzerkonten tätigen.
          <br />
          <br />
          2.5.6 Ein Konto wird als inaktives Konto betrachtet, wenn sich Kunden über einen Zeitraum
          von mehr als drei Monaten nicht angemeldet haben.
          <br />
          <br />
          2.5.6.1 Kunden werden 30 Tage vor Eintreten ihrer Inaktivität per E-Mail darüber
          informiert.
          <br />
          <br />
          2.5.6.2 Für inaktive Konten, welche länger als 360 Tage nicht genutzt wurden (angemeldet)
          wird eine Verwaltungsgebühr von 5,00 Euro (fünf) je weiteren inaktiven Monat erhoben, ohne
          jedoch negative Salden auf dem Spielerkonto zu erzeugen. Sollte das Spielerkonto keinen
          Geldbetrag aufweisen oder der Saldo nach Abzug von Gebühren auf null fallen, wird das
          Konto nach Ablauf der Informationsfrist (Punkt 2.5.7.1) gesperrt.
          <br />
          <br />
          2.5.6.3 Wenn Kunden sich über einen Zeitraum von 60 (sechzig) Monaten auf Ihrem Konto
          nicht angemeldet haben, und ihr Konto bereits geschlossen ist, verfallen eventuelle
          Geldbeträge zu Gunsten der Game90 Ltd..
          <br />
          <br />
          2.5.6.4 Vor Eintreten des Geldverfalles (60 Monate inaktiv) wird Game90 Ltd. seine Kunden
          auf allen möglichen Kommunikationswegen (E-Mail, Telefon oder Post) kontaktieren und auf
          den bevorstehenden Verfall der Gelder hinweisen. Kunden sollten die gesetzten Fristen in
          den Schreiben beachten, da nach Ablauf der Fristen ein Anspruch auf die Auszahlung des
          Geldes nicht mehr besteht.
        </p>
        <h6 className="mt-3">2.6 Verwaltung des Game90-Kontos </h6>
        <p className="mt-3">
          Game90 behält sich vor, nach eigenem Ermessen und jederzeit:
          <br />
          <br />
          2.6.1 die Eröffnung eines Kontos abzulehnen und/oder ein bestehendes Konto ohne Angabe von
          Gründen zu schließen;
          <br />
          <br />
          2.6.2 die Hinterlegung von Einlagen ohne Angabe von Gründen abzulehnen;
          <br />
          <br />
          2.6.3 Dokumente anzufordern, um
          <br />
          <br />
          2.6.3.1 die vollständige und zweifelsfreie Identität der Kontoinhaber festzustellen oder
          zu bestätigen.
          <br />
          <br />
          2.6.3.2 die Herkunft der vom Spieler eingezahlten Gelder zu jedem Zeitpunkt zu erkunden
          oder durch eine von Game90 bestimmte Dritte Partei ermitteln zu lassen.
          <br />
          <br />
          2.6.3.3 die Befugnis zu prüfen, eine bestimmte Zahlungsmethode zu benutzen und/oder
          <br />
          <br />
          2.6.3.4 andere Daten und Informationen zu prüfen, die von Kontoinhabern zur Verfügung
          gestellt wurden. Dies kann zu jedem beliebigen Zeitpunkt getätigt werden und Game90 behält
          sich das Recht vor, ein Spielerkonto auch während laufender Überprüfungen von einer
          möglichen Nutzung auszuschließen oder zeitweilig bis zur Vervollständigung der Prüfung
          ruhen zu lassen. <br />
          <br /> 2.6.4 ohne vorherige Ankündigung <br />
          <br /> 2.6.4.1 Daten eines Kontoinhabers an andere juristische Personen (dritte Partei),
          die letztendlich von Game90 verwaltet oder kontrolliert oder beauftragt werden, in ein
          beliebiges Land zu übertragen. Dabei garantiert Game90, dass die besagten Daten jederzeit
          in Übereinstimmung mit der geltenden Gesetzgebung und Datenschutzgesetzen und/oder
          ähnlichen Regelungen übertragen und verwaltet werden.
          <br />
          <br />
          2.6.4.2 die Rechte und Pflichten der Kontoinhaber an andere juristische Personen in ein
          beliebiges Land zu übertragen und/oder zu lizenzieren, sofern diese juristischen Personen
          letztendlich von Game90 verwaltet oder kontrolliert oder beauftragt werden und Game90 die
          Einhaltung der Verpflichtungen garantiert. <br />
          <br />
          2.6.5 in Übereinstimmung mit allgemein anerkannten Richtlinien für Cash-Management das
          Guthaben von Kontoinhabern einzubehalten und zu verwalten. Dies kann die Beteiligung von
          im Namen und/oder zugunsten der Kontoinhaber mit der Verwaltung des Kontoguthabens
          beauftragten Finanzinstituten und/oder Anbietern von Zahlungsdiensten einschließen;
          <br />
          <br />
          2.6.6 das Guthaben des Game90-Kontos verfallen zu lassen und/oder zu sperren und/oder sich
          weigern, eine Forderung zu befriedigen, in dem Fall, dass direkt oder indirekt
          <br />
          <br />
          2.6.6.1 die Game90-Regelungen verletzt wurden und/oder
          <br />
          <br />
          2.6.6.2 wenn zum Beispiel unbefugte Aktivitäten in Verbindung mit einem Wett- oder
          Spielereignis und/oder des Betriebes des Game90-Kontos oder allen anderen Game90-Services
          stattgefunden haben (wie zum Beispiel, jedoch nicht beschränkt auf die Verletzung von
          Gesetzen oder anderen Vorschriften, Verletzung der Rechte Dritter oder Betrug);
          <br />
          <br />
          2.6.7 die Teilnahme von Kontoinhabern an Spielen, Werbeaktivitäten, Wettbewerben oder
          anderen Dienstleistungen auszusetzen und/oder zu unterbinden, wann immer Game90 der
          Meinung ist, dass es berechtigte Bedenken gibt, dass ein Game90-Konto für illegale,
          betrügerische oder unehrliche Praktiken missbraucht wird, wurde oder werden könnte. Dies
          schließt auch das Vorbeugen von Spielsucht mit ein.
          <br />
          <br />
          2.6.8 ein Spielerkonto zu sperren oder zu limitieren, wenn Nutzer als exponierte Personen
          (PEP) identifiziert werden. Dies betrifft auch Prominente die in einer der angebotenen
          Sportarten (als aktive oder inaktive Amateur- oder Profi-Sportler) tätig sind oder waren
          oder mediale Prominente aus Funk und Fernsehen sowie sozialen Medien (MEP). Wenn ein
          Spieler auf einer Sanktionsliste steht, wird das Spielerkonto umgehend geschlossen und der
          zuständigen Behörde gemeldet.
          <br />
          <br />
          2.6.9 die Teilnahme von Kontoinhabern an den Dienstleistungen auszusetzen und/oder zu
          unterbinden und/oder das Guthaben auf dem Game90-Konto zu sperren, wenn Kontoinhaber des
          Betrugs überführt wurden oder Game90 feststellt, dass Kontoinhaber ein System
          (einschließlich Maschinen, Roboter, Computer, Software und jedes andere automatisierte
          System) angewandt oder eingesetzt haben, das dazu entwickelt wurde oder dazu fähig ist,
          die Kundenanwendungen und/oder die Software von Game90 zu manipulieren, zu beschädigen
          oder sich einen finanziellen Vorteil zu verschaffen.
        </p>
        <h6 className="mt-3">2.7 Spielerschutz </h6>
        <p className="mt-3">
          2.7.1 Nach Anklicken des permanent vorhandenen grünen Banners mit der Aufschrift „18+
          Glücksspiel kann süchtig machen.” erreichen Kunden die Game90 Präventionsseite, auf der
          man sich über Glücksspielsucht informieren und auch Hilfe beantragen kann.
          <br />
          <br />
          2.7.2 Kunden haben die Möglichkeit, sich selbst für künftige Einsätze sperren zu lassen,
          indem sie die Sperrung ihres Spielerkontos schriftlich, per E-Mail oder per Post
          beantragen oder über die Einstellungen auf der Website einrichten.
          <br />
          <br />
          2.7.3 Game90 nutzt das offizielle Spielerblockiersystem OASIS. Durch die Eingabe des
          Selbstausschlusses eines Spielers werden diese Informationen von der Landesbehörde erfasst
          und überwacht und ein Spieler für ganz Deutschland gesperrt (auch wenn er zu einem anderen
          Anbieter geht). Spieler können sich jederzeit eine zeitlich unbegrenzte Spielsperre mit
          einer Mindestdauer von 3 Monaten einrichten. Eine Ausnahme bildet der sogenannte
          Panik-Button, der für 24 Stunden eine Pause auslöst (siehe 2.7.7).
          <br />
          <br />
          Die Aufhebung einer Sperre ist nur auf schriftlichen Antrag der gesperrten Person möglich.
          Dies gilt auch dann, wenn bei Beantragung der Sperre für deren Laufzeit eine bestimmte
          Frist genannt wurde. Der Antrag kann frühestens nach Ablauf der Frist gestellt werden.
          Wird kein Aufhebungsantrag gestellt, endet die Sperre nicht! Der Antrag auf Aufhebung der
          Spielersperre ist bei der für die Führung der Sperrdatei zuständigen Behörde, dem
          Regierungspräsidium Darmstadt, zu stellen. Es genügt die Weiterleitung des Antrags durch
          einen Veranstalter oder Vermittler. Veranstalter und Vermittler von Glücksspielen können
          Spielersperren nicht aufheben!
          <br />
          <br />
          Während der Registrierung als auch später bei Einzahlungen nutzt Game90 das offizielle
          OASIS-System zum Prüfen auf eventuelle Spielersperren. Sollte ein Spieler bereits gesperrt
          sein, wird das Spielerkonto eingefroren oder garnicht erst erstellt.
          <br />
          <br />
          2.7.4 Kunden haben die Möglichkeit im privaten Bereich Sicherheit und Limits der Website,
          eigene Limits in ihrem Nutzerkonto festzulegen.
          <br />
          <br />
          2.7.5 Limits können als Einzahlungslimit (maximale Einzahlung/1.000 Eur pro
          Kalendermonat/anbieterübergreifend), als Wettlimit (maximaler Wettbetrag) und als
          Verlustlimit (maximaler Verlust) auf Tage, Wochen oder Monate selbstständig eingerichtet
          oder auf Wunsch auch durch den Kundendienst gesetzt werden.
          <br />
          <br />
          2.7.6 Verringerungen von Risiko-Limits werden unmittelbar aktiv. Erhöhungen werden nach
          einer gesetzlichen Frist von 7 Tagen aktiv. Diese Einstellungen sind gesetzlich
          vorgeschrieben und können nicht über Ausnahmen verändert werden.
          <br />
          <br />
          2.7.7 Desweiteren hat der Spieler die Möglichkeit, mittels des Panik-Button, der jederzeit
          auf der Website ersichtlich ist, sich für 24 Stunden zu sperren.
          <br />
          <br />
          2.7.8 Die Spieler werden über die Summe der Einsätze, Gewinne und Verluste der jeweils
          vorangegangenen 30 Tage informiert. Diese Information wird nach jeder Identifizierung und
          Authentifizierung erfolgen, sowie vor Beginn eines Spiels, wenn seit der letzten
          Information mehr als 24 Stunden vergangen sind. Eine Spielteilnahme darf erst nach
          ausdrücklich erklärter Kenntnisnahme der Information durch den Spieler erfolgen.
          <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="3" className="mt-75">
          3. Zahlungen und Sicherheit
        </h5>
        <h6 className="mt-3">3.1 Zahlungen</h6>
        <p className="mt-3">
          3.1.1 Das Konto für den Wetteinsatz wird ausschließlich in Euro (EUR, €) geführt. Andere
          Währungen dürfen nicht verwendet werden. Es werden keine Kreditlinien zur Verfügung
          gestellt. Eine Voraussetzung für einen Spieleinsatz ist ein Guthaben gleich oder höher als
          der jeweilige Einsatz.
          <br />
          <br />
          3.1.2 Folgende Einzahlungsmöglichkeiten stehen zur Verfügung:
          <br />
          <br />
          - Paysafecard (sofort)
          <br />
          <br />
          - Kreditkarte (Mastercard und Visa, sofort) insofern diese Methoden für Online-Spiele in
          diesem Rechtsbereich erlaubt sind
          <br />
          <br />
          - SOFORT Banking (sofort)
          <br />
          <br />
          - GIROPAY (sofort)
          <br />
          <br />
          - Skrill (sofort)
          <br />
          <br />
          - Banküberweisung (innerhalb von bis zu fünf Werktagen)
          <br />
          <br />
          Einzahlungen erfolgen unter der Voraussetzung, dass die Rechnungsadresse mit der bei der
          Registrierung angegebenen Adresse und Identität übereinstimmt. Andere Zahlungsmethoden
          können nach Ermessen von Game90 akzeptiert werden solange sie den gesetzlichen
          Bestimmungen, insbesondere den zur Geldwäsche voll umfänglich genügen. Kunden werden über
          sämtliche Kosten informiert, die durch die Verwendung von alternativen Zahlungsmethoden
          anfallen. Einzahlungen können jederzeit (gratis) per Banküberweisung (siehe Informationen
          zum Bankkonto) oder Kreditkarte erfolgen. Nach Eingang der Einzahlung auf dem Bankkonto
          des Unternehmens oder der elektronischen Bestätigung über die Kreditkartenzahlung wird der
          Einzahlungsbetrag dem Konto gutgeschrieben. Pro Kundenkonto darf jeweils nur eine
          Kreditkarte oder andere Zahlungsmethode für Einzahlungen und/oder Auszahlungen verwendet
          werden. Ein häufiger oder ständiger Wechsel in den Zahlungsmethoden ist nicht erlaubt.
          <br />
          <br />
          3.1.3 Folgende Mindest- und Höchstbeträge sind bei Einzahlungen möglich:
          <br />
          <br />
          3.1.3.1 Einzahlung Minimum: 10 Euro je Zahlung
          <br />
          <br />
          3.1.3.2 Einzahlung Maximum: 1.000 Euro je Zahlung (anbieterübergreifend pro Monat)
          <br />
          <br />
          Das Einzahlungsmaximum kann im Einzelfall, unter Genehmigung der Behörden, erhöht werden.
          <br />
          <br />
          3.1.4 Game90 zahlt keine Guthaben von Spielerkonten aus, solange die zweifelsfreien
          Identitäten, das Alter, der aktuelle Wohnort und die genutzten Zahlungsmethoden von
          Nutzern nicht eindeutig festgestellt und bestätigt worden sind. Game90 behält sich auch
          das Recht vor, alle Identifikationsdetails von Spielern eindeutig mit Hilfe einer Dritten
          Partei identifizieren zu lassen. Sofern durchführbar, überweist Game90 auf Anforderung des
          registrierten und vollständig verifizierten Spielers und in dessen Namen ein Spielerkonto
          eingerichtet wurde, das Guthaben des Spielerkontos auf das verifizierte Spielerbankkonto
          (bzw. eWallet oder Kreditkarte).
          <br />
          <br />
          3.1.5 Gegenüber staatlichen Behörden sind Kunden eigenständig dafür verantwortlich die
          erreichten Gewinne aus Game90 Diensten zu erklären, zu dokumentieren und gegebenenfalls
          auch die Gebühren zu entrichten.
          <br />
          <br />
          3.1.6 Die folgenden Auszahlungsmethoden stehen zur Verfügung:
          <br />
          <br />
          - Banküberweisung (innerhalb von bis zu fünf Werktagen)
          <br />
          <br />
          Andere Zahlungsmethoden für Ein- und Auszahlungen können in der Zukunft nach Ermessen von
          Game90 hinzugefügt werden. Jede Auszahlungsaufforderung wird vor der Zahlung nochmals von
          den Mitarbeitern des Unternehmens manuell oder technisch überprüft. Sobald die Auszahlung
          bearbeitet und genehmigt wurde, werden Kunden mittels einer E-Mail-Mitteilung
          benachrichtigt.
          <br />
          <br />
          3.1.7 Kunden sind verpflichtet, alle noch ausstehenden Salden vollständig zu begleichen.
          Wenn irrtümlich einem Konto Geld gutgeschrieben oder belastet wird, müssen Kunden Game90
          unverzüglich benachrichtigen. Alle irrtümlich einem Konto gutgeschriebenen Beträge müssen
          unverzüglich zurückgezahlt werden. Alle Beträge, die irrtümlich einem Konto belastet
          wurden, werden ebenso unverzüglich zurückerstattet. Geldmittel, die einem Konto irrtümlich
          gutgeschrieben wurden, dürfen von Kunden nicht für Wetteinsätze verwendet werden. Game90
          behält sich das Recht vor, alle Transaktionen für null und nichtig zu erklären, die mit
          solchen Mitteln finanziert wurden. Jede Partei verpflichtet sich, die andere Partei von
          Forderungen in Bezug auf irrtümlich gutgeschriebene oder belastete Beträge freizustellen.
          <br />
          <br />
          3.1.8 Kunden verpflichten sich, keine Kreditkartenrücklastschriften vorzunehmen und auch
          keine anderweitig erfolgten Zahlungen rückgängig zu machen. Alle Kosten und Schäden, die
          sich aus derartigen Rücklastschriften ergeben, werden den Kunden in Rechnung gestellt. Bis
          zur vollständigen Klärung der Sachlage wird das betroffene Spielerkonto umgehend
          geschlossen und anstehende Auszahlungen storniert. Game90 hält die Option in solchen
          Fällen rechtliche Schritte vorzunehmen und Behörden über einen möglichen Missbrauch zu
          informieren.
          <br />
          <br />
          3.1.9 Das Unternehmen behält sich das Recht vor, ein Konto bei bloßem Verdacht auf
          Kreditkartenbetrug oder Kreditkartenmissbrauch zu sperren. Dies gilt insbesondere für die
          Verwendung von gestohlenen Kreditkarten oder andere betrügerische Handlungen. Game90
          haftet nicht für den Missbrauch von Kreditkarten oder gestohlenen Kreditkarten.
          <br />
          <br />
          3.1.10 Game90 kann Bonusse auf Kundenkonten gutschreiben. Diese Bonusse sind eine
          freiwillige Leistung des Unternehmens und Kunden haben keinen grundsätzlichen
          Rechtsanspruch darauf. Bonus kann technisch oder manuell gutgeschrieben werden.
          <br />
          <br />
          3.1.11 Game90 überwacht den gesamten Zahlungsverkehr durch manuelle, technische und
          elektronische Maßnahmen.
        </p>
        <h6 className="mt-3">3.2 Sicherheit </h6>
        <p className="mt-3">
          <br /> 3.2.1 Der Kontostand des Nutzers ist mit Bargeld oder gleichwertigen Mitteln
          abgedeckt. Das Unternehmen garantiert, dass das Guthaben auf einem Nutzerkonto in voller
          Höhe ausgezahlt werden kann. Alle Ein- und Auszahlungen eines Kontos erfolgen online in
          Echtzeit (Malta) und sind über VeriSign gesichert. <br />
          <br /> 3.2.2 Der Zugriff auf ein Nutzerkonto ist nur mit einer E-Mail-Adresse und dem
          individuellen persönlichen Passwort eines Kunden möglich. <br />
          <br />
          3.2.3 Die Kunden sind selbst allein für die Geheimhaltung ihrer Benutzernamen und
          Passwörter verantwortlich. Das Unternehmen ist nicht verpflichtet, diese Daten für den
          Fall zu speichern, dass Kunden diese verlegt, vergessen oder verloren haben. Wenn Kunden
          ihre Anmeldedaten auf einem externen Gerät speichern, um von verschiedenen Computern aus
          auf ihr Konto zugreifen zu können oder die Kontodaten zu sichern, geschieht dies auf deren
          eigene Gefahr. <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="4" className="mt-75">
          4. Nutzungsbeschränkung der Game90-Website
        </h5>
        <p className="mt-3">
          <br />
          <br />
          Game90 ist nicht befugt, Wetten von Personen unter 18 Jahren anzunehmen. Die Kunden
          erklären bei ihrer Anmeldung, dass sie das gesetzliche Mindestalter von 18 Jahre erfüllt
          haben und geistig in der Lage sind, die Verantwortung für ihr eigenes Handeln zu
          übernehmen und dass es keine Spielsuchtprobleme und/oder das Risiko der Abhängigkeit zur
          Spielsucht gibt. Game90 hat die Pflicht, Wetteinsätze von Minderjährigen (oder jenen, von
          denen das Unternehmen vermutet, Minderjährige zu sein) für nichtig zu erklären. Kunden
          erkennen an, dass Glücksspiel von Minderjährigen eine Straftat darstellt.
          <br />
          <br />
          4.2 Alle betrügerischen, kriminellen oder verdächtigen Aktivitäten werden ausnahmslos den
          zuständigen Behörden und Agenturen gemeldet.
          <br />
          <br />
          4.3 Das Unternehmen behält sich das Recht vor, alle Wetteinsätze für ungültig zu erklären,
          die von Personengruppen platziert wurden, die den Verdacht erregen, mit konspirativer oder
          betrügerischer Absicht gehandelt oder Wetten manipuliert zu haben. Diese Gruppe kann
          Personen, Verwandte, Organisationen, Buchmacher und deren Mitarbeiter/Vertreter umfassen.
          <br />
          <br />
          4.4 Die Mitarbeiter, leitende Angestellte, Angestellte, Berater, Vertreter oder Partner
          des Unternehmens, seiner Zweigstellen oder verbundener Unternehmen und ihre jeweiligen
          Händler, Lieferanten oder Zulieferer sind von der direkten und indirekten Verwendung der
          Game90-Website oder der mobilen Applikationen ausgeschlossen. Ausnahmen bilden Testkonten,
          die zur technischen Überwachung eingerichtet worden sind und von denen kein Einfluss auf
          den angebotenen Service hervorgeht. Diese Einschränkung gilt auch für die Angehörigen
          dieser Personen. In diesem Zusammenhang bezieht sich der Begriff „Angehörige“ auf
          Ehegatten, Lebenspartner, Eltern, Kinder oder Geschwister.
          <br />
          <br />
          4.5 Die Nutzung der Game90-Website ist nur von Orten aus zulässig, an denen die Teilnahme
          an einem derartigen Dienst gesetzlich geregelt und legal ist. Kunden sichern dem
          Unternehmen zu, dass sie von Orten auf die Website oder ihre Dienste zugreifen, in denen
          derartige Glücksspiele oder Wetteinsätze nicht verboten sind. Game90 überwacht und
          verhindert mit technischen Maßnahmen solche Zugriffe bestmöglich. Darüber hinaus
          versichern die Kunden, nicht auf die Website zuzugreifen oder sich darauf anzumelden, wenn
          sie Angehörige eines Staates sind, der seinen Bürgern die Teilnahme an Glücksspielen
          unabhängig vom Standort untersagt. Die Kunden erkennen ihre alleinige Verantwortung für
          die Überprüfung lokaler Gesetze an, welche die Verwendung des Game90-Dienstes untersagen
          können. Es ist ratsam, vor der Registrierung einen Rechtsbeistand zu konsultieren, um
          sicherzustellen, dass die Verwendung des Dienstes in keiner Weise im Widerspruch zu diesen
          Gesetzen steht. Das Unternehmen übernimmt keine Verantwortung für einen Verstoß von Kunden
          gegen lokale oder nationale Gesetze.
          <br />
          <br />
          4.6 Ungeachtet des vorstehenden Absatzes ist den Bürgern der USA und Litauen und allen
          anderen Personen mit Wohnsitz in den USA oder Litauen die Anmeldung und Nutzung der
          Website untersagt. Das Unternehmen erklärt alle Wetteinsätze für ungültig, die aus den USA
          oder Litauen heraus getätigt wurden oder bei denen davon ausgegangen wird, dass sie aus
          den USA oder aus Litauen getätigt wurden, es sei denn die Kunden haben einen festen
          Hauptwohnsitz in der Gerichtsbarkeit von Game90.
          <br />
          <br />
          4.7 Game90 behält sich das Recht vor durch technische, digitale oder andere Maßnahmen die
          übermittelten Daten seitens der Kunden auf deren Echtheit zu prüfen, um die Weiterführung
          der bestehenden Geschäftsbeziehung zu beurteilen. <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="5" className="mt-75">
          5. Die Website
        </h5>
        <h6 className="mt-3">5.1 Sportwetten </h6>
        <p className="mt-3">
          5.1.1 Durch die Platzierung einer Wette wird das Konto der Kunden mit dem Betrag des
          Wetteinsatzes belastet. Das Nutzerkonto von Kunden mit Wohnsitz in Deutschland wird mit
          dem Wetteinsatz zuzüglich einer „Wettsteuer“ des Wetteinsatzes belastet. Kunden sind
          verpflichtet, die Wette auf dem ausgestellten Wettschein zu prüfen, bevor diese abgesendet
          werden. Nachdem die Wetten versendet wurden, können sie weder storniert noch geändert
          werden. Das Unternehmen ist berechtigt, Wetteinsätze ohne Angabe von Gründen in vollem
          Umfang oder teilweise abzulehnen.
          <br />
          <br />
          5.1.2 Wenn Kunden mehrere Wetten platzieren, werden diese in der Reihenfolge ihres
          Eingangs auf dem Zentralrechner von Game90 bearbeitet.
          <br />
          <br />
          5.1.3 Mehrfachwetten können als einzelner Wetteinsatz gelten, wenn Kunden wiederholt die
          gleichen Einsätze und Quoten platzieren, das bedeutet Wetten, die im Spiel, Ergebnis und
          Einsatz identisch sind. Das Unternehmen behält sich auch das Recht vor, Wetten für
          ungültig zu erklären, wenn Hinweise auf Absprachen zwischen zwei oder mehreren Kunden
          vorliegen oder das gesamte Wettverhalten verdächtig erscheint. Wenn eine Wette für
          ungültig erklärt wird, wird diese mit der Quote 1,0 bewertet, in diesem Fall erhält der
          Spieler lediglich seinen Wetteinsatz zurück, es sei denn die Manipulation kann als
          betrügerischen Versuch gewertet werden, dann werden die Wetteinsätze ebenfalls eingefroren
          (siehe auch Punkt 5.3.1.1).
          <br />
          <br />
          Wenn das Wettverhalten des Kunden auf ein organisiertes oder professionelles Verhalten
          hindeutet, hat Game90 das Recht, das Spielverhalten durch geeignete Maßnahmen zu
          kontrollieren und gegebenenfalls einzuschränken oder zu blockieren. Dies gilt zum Beispiel
          auch für das einfache Wetten auf Gewinnchancen oder Wetten, die durch &quot;Insiderwissen
          &quot;platziert werden. Wenn dieses Wettverhalten vermutet wird, begrenzt Game90 die
          Wetten des Spielers oder schließt den Spieler aus.
          <br />
          <br />
          5.1.4 Die Annahme von Wetten und ihre Auszahlungen können auf verschiedene Limits
          beschränkt sein. Dazu gehören insbesondere Einsatzlimits, Wettschein-Limits, maximale
          Gewinne und das persönliche Einsatzlimit der Kunden. Diese Limits unterliegen Änderungen
          und werden auf dem Wettschein oder dem Nutzerkonto aufgelistet. Das Unternehmen Game90
          behält sich jedoch das Recht vor, den eingesetzten Betrag zu begrenzen oder die
          Gewinnchancen als auch die Gewinnauszahlung zu ändern oder zu limitieren. Bei Verdacht auf
          Manipulation, getätigten Absprachen (auch mit mehreren anderen Kunden) oder anderweitige
          Unstimmigkeiten kann Game90 diese Wetten im Nachgang limitieren, ändern oder stornieren.
          Das Spielerkonto wird in den genannten Fällen gesperrt und gegebenenfalls zur weiteren
          Ermittlung an die Behörden weitergeleitet. Eine Auszahlung von Geldern, auch eines
          eventuell noch nicht genutzten Einzahlungsbetrages, wird widersprochen, bis die
          Ermittlungen der Behörden zu einem Ergebnis gelangt sind, welches eine Auszahlung
          rechtfertigt. In anderen Fällen wird das Geld eingefroren.
          <br />
          <br />
          5.1.5 Wetten, die versehentlich nach der offiziellen Startzeit angenommen wurden, werden
          für ungültig erklärt, sofern dies nicht anders angegeben ist.
          <br />
          <br />
          5.1.6 Live-Wetten (gem. § 21 gem.§21 GlüStV 2021) sind Wetten, die während eines aktiven
          Ereignisses platziert werden können. Da bei Live-Wetten die Wett-Quoten dem Live-Ereignis
          regelmäßig angepasst werden, wird aus Sicherheitsgründen eine Wette mit zeitlicher
          Verzögerung platziert. Sollte sich während dieser Phase die Quote ändern, kann der
          Wettschein abgelehnt und stattdessen mit veränderter Quote den Kunden angeboten werden. Es
          obliegt den Kunden selbst darauf zu achten, dass die veränderten Quoten auch deren
          Erwartungen entsprechen.
        </p>
        <h6 className="mt-3">5.2 Gültige Wetten </h6>
        <p className="mt-3">
          5.2.1 Eine Wette bzw. platzierte Wette gilt als angenommen, wenn diese mit einer
          Wettschein-Nummer bestätigt wurde. Die Wette wird dann unter „Meine Wetten“ angezeigt
          (siehe Wettregeln und Ausnahmen Punkt 5.1.4. und 5.1.5.).
          <br />
          <br />
          5.2.2 Durch das Abgeben eines Wetteinsatzes erkennen Kunden die Regeln für die Platzierung
          von Wetten und Sportwetten an und bestätigen, dass sie diese, das Verfahren und die
          allgemeinen Risiken von Online-Glücksspielen verstanden haben.
          <br />
          <br />
          5.2.3 Das Unternehmen übernimmt keine Verantwortung dafür, falls eine Wette, gleichgültig
          aus welchen Gründen, nicht platziert werden kann oder konnte. Diese Gründe umfassen auch
          Fehlfunktionen oder Ausfälle von Computern, Ausfälle von Telekommunikationsdiensten oder
          Internet-Verbindungen. Game90 erkennt keine Wetteinsätze an, die gesendet, jedoch nicht
          entsprechend Absatz 5.2.1 vollständig bestätigt wurden.
          <br />
          <br />
          5.2.4 Als gültig gelten nur Wetten, die gemäß Punkt 5.2.1 angenommen werden und daher auch
          im Konto des Spielers angezeigt werden. Game90 übernimmt keine Gewährleistung oder Haftung
          für Darstellungen, die beim Platzieren des Wettscheines auf der Website oder in
          Anwendungen usw. angezeigt werden, jedoch nicht mit der gemäß Punkt 5.2.1 gebuchten und
          akzeptierten Wette übereinstimmen. Solche Fehler können unter anderem auch beispielsweise
          durch Schwankungen des Internet-Dienstes (Internetverbindungen) oder mangelnde Wartung von
          Endgeräten seitens der Kunden verursacht werden und haben daher keine Gültigkeit. Kunden
          haben kein gesetzliches Recht auf Gewinne, die im Verdacht der falschen Darstellung auf
          Endgeräten stehen.
        </p>
        <h6 className="mt-3"> 5.3 Gewinne </h6>
        <p>
          5.3.1 Alle Gewinne werden einem Spieler-Konto automatisch gutgeschrieben, sobald diese
          Ergebnisse offiziell bestätigt wurden. Dies kann durch verschiedene Umstände auch längere
          Zeit in Anspruch nehmen. Game90 ist bemüht zeitnah zu buchen.
          <br />
          <br />
          5.3.1.1 Die Verwendung von AI-Systemen oder –Software (AI = künstliche Intelligenz), vor
          allem Maschinen, Computer, Software oder andere automatisierte Systeme bzw. Programme oder
          Elektronik, die entwickelt wurden, die Software auf der Game90-Website zu manipulieren
          und/oder sich einen Vorteil bei Wettplatzierungen zu verschaffen, ist strikt verboten.
          Gewinne, die sich aus der unberechtigten Nutzung solcher Systeme ergeben, verfallen und
          ziehen die Sperrung des Kundenkontos nach sich. Auch eine Rückerstattung der eingezahlten
          Beträge wird hiermit sofort widersprochen und alle Beträge (Einzahlungen und Gewinne etc.)
          werden eingefroren. Game90 wird in diesen Fällen auch rechtliche Schritte in Erwägung
          ziehen.
          <br />
          <br />
          5.3.1.2 Game90 behält sich das Recht vor durch geeignete technische, digitale,
          elektronische oder andere Maßnahmen, die Nutzung der Website seitens der Kunden zu
          überwachen.
          <br />
          <br />
          5.3.2 Gewinne werden bei einer Auszahlung auf das gleiche Bankkonto (oder eWallet etc.)
          gutgeschrieben, von dem die Einzahlung veranlasst wurde. Dies wird als
          „closed-loop-policy“ (geschlossener Kreislauf) bezeichnet und entspricht den europäischen
          Gesetzesvorgaben zur Vermeidung von Geldwäsche und Terrorismusfinanzierung. Das
          Unternehmen Game90 behält sich das Recht vor, alle notwendigen Maßnahmen zu ergreifen, um
          sämtliche Details jeder Transaktion zu bestätigen. Dies erfolgt innerhalb eines
          angemessenen Zeitrahmens.
          <br />
          <br />
          5.3.3 Das Unternehmen haftet nicht für verlorene, verspätete, unleserliche,
          unvollständige, beschädigte oder fehlgeleitete Dokumente, Anfragen, Gewinnansprüche oder
          Mitteilungen der Kunden an das Unternehmen oder Nachrichten, für die ein Nachporto zu
          entrichten ist. Jegliche Anfragen, Gewinnansprüche oder Korrespondenz, die das Game90-Büro
          auf Malta erhält, werden mit dem Empfang Eigentum des Unternehmens und werden nicht an den
          Kunden zurückgesendet. Daher wird empfohlen nur glaubhafte Kopien dieser Dokumente zu
          versenden.
          <br />
          <br />
          5.3.4 Der max. Gewinn pro Kunde und Woche beträgt 100 000 Euro (Hunderttausend). Bei
          Wetten - werden von Kunden identische oder ähnliche Wettscheine abgegeben, so kann nur ein
          Gesamtgewinn in Höhe von 100 000 € (Hunderttausend) erzielt werden. Kundenhaushalte
          (gleiche Wohnadresse, Internetverbindung oder auch Familienmitglieder) die mehr als ein
          Konto eröffnet und diese Konten dazu verwendet haben, identische oder ähnliche
          Wetteinsätze zu tätigen, können ebenfalls nur einen Gesamtgewinn in Höhe von 100 000 €
          (Hunderttausend) erzielen, wobei Game90 sich das Recht behält diese Mehrfachkonten bei
          Betrugsverdacht zu sperren und die Auszahlung komplett einzufrieren.
          <br />
          <br />
          5.3.5 Das Guthaben wird über die gewählte und verifizierte Einzahlungsmethode wieder
          ausgezahlt, wenn dies von Kunden durch Klicken der Schaltfläche „Auszahlen“ angefordert
          wird. Die Kunden können die vollständige oder teilweise Auszahlung eines Guthabens auf
          ihre Bankkonten jederzeit veranlassen, es sei denn:
          <br />
          <br />
          5.3.5.1 das Guthaben auf dem Nutzerkonto, unabhängig ob aus Bonussen oder Gewinnen aus
          Bonussen oder verbliebenes Restguthaben von Einzahlungen bestehend, wurde noch nicht
          entsprechend der geltenden Umsatzbedingungen zum Wetten eingesetzt;
          <br />
          <br />
          5.3.5.2 es handelt sich um die erste Zahlungsanfrage eines Kunden und die erforderlichen
          Bedingungen sind noch nicht erfüllt;
          <br />
          <br />
          5.3.5.3 aus der Überprüfung der personenbezogenen Kundendaten geht hervor, dass die Daten
          nicht der Wahrheit entsprechen oder der Kunde mehr als ein Spielerkonto hat (in diesen
          Fällen wird das Spielerkonto gesperrt und alle Spielaktivitäten und Resultate auf diesem
          Spielerkonto werden storniert);
          <br />
          <br />
          5.3.6 Das Guthaben wird an die Kunden kostenfrei ausgezahlt, es sei denn:
          <br />
          <br />
          5.3.6.1 Kunden verlangen, mit einer nachvollziehbaren und verständlichen Erklärung, die
          Zahlung eines Betrages, der noch nicht eingesetzt worden ist. In diesem Fall wird, wenn
          Game90 von der Erklärung überzeugt ist, eine Bearbeitungsgebühr von 8 % (mind. jedoch 10
          Euro) des angeforderten Betrages abgezogen; einhergehend mit der vollständigen Sperrung
          des Nutzerkontos und des Nutzers auf Lebenszeit;
          <br />
          <br />
          5.3.6.2 Kunden verlangen die Zahlung eines Betrages von weniger als 20 €. In diesem Fall
          wird eine Bearbeitungsgebühr von 5 € erhoben. (Dies gilt nicht im Falle einer
          Kontoauflösung, wenn der gesamte Restbetrag weniger als 20 € beträgt.);
          <br />
          <br />
          5.3.6.3 Kunden veranlassen eine Überweisung auf ein Bankkonto, an das nicht mit einer
          EU-Standardüberweisung gezahlt werden kann. Für solche internationalen Überweisungen gehen
          alle Gebühren zu Lasten des Empfängers.
          <br />
          <br />
          5.3.7 Die erstmalige Auszahlung an Kunden kann erst nach der vollständigen Überprüfung der
          Identität der Kunden mit einer Kopie des Passes oder des Ausweises, des Adressennachweises
          (Rechnungen für Strom, Wasser, Gas oder Festnetztelefon oder alternativ eine offizielle
          amtliche Meldebescheinigung) und eines Nachweises der verwendeten Zahlungsmethode
          erfolgen. Alle Dokumente können auch per Post oder E-Mail übermittelt werden. Vor einer
          Auszahlung muss der zuvor eingezahlte Betrag (oder Beträge) mindestens einmal vollständig
          bespielt worden sein.
          <br />
          <br />
          5.3.8 Game90 behält sich das Recht vor, mehrere Auszahlungen desselben Kunden als eine
          Summe zusammenzufassen und weitere Auszahlungen in einem bestimmten Zeitraum ebenfalls
          zusammenzufassen. Mit der Auszahlung an die Kunden erhalten diese von Game90 die
          Information über die zusammengefasste Zahlung und die darin enthaltenen aufgeschlüsselten
          Beträge.
          <br />
          <br />
          5.3.9 Cashout-Funktion
          <br />
          <br />
          5.3.9.1 Game90 bietet freiwillig zu bestimmten Wettangeboten eine Cashout-Funktion an, in
          der Kunden Wetten vor Ablauf des Wettscheines an Game90 zurückverkaufen können. Dieses
          Cashout-Angebot ist eine freiwillige Zusatzoption seitens Game90. Generell besteht kein
          Rechtsanspruch auf die Cashout-Funktion, auch dann nicht, wenn diese im Display des
          Gerätes auftaucht. Erst mit Bestätigung durch Game90 für diese Cashout-Funktion ist der
          dazugehörige Vorgang rechtmäßig.
          <br />
          <br />
          5.3.9.2 Game90 hat das Recht, die Cashout-Funktion auch während einer laufenden
          Veranstaltung aus Sicherheitsgründen zu deaktivieren. Dies ist insbesondere dann der Fall,
          wenn aus technischen Gründen die Verbindung zum Sportereignis länger als 30 Sekunden
          unterbrochen wurde. Aus Sicherheitsgründen wird diese Wette am Ende als normale Wette
          eingestuft und fällt nicht mehr in den Live-Modus. Wenn diese Wette Teil einer
          Kombinations- oder Systemwette mit einer Auszahlungsfunktion ist, wird die
          Auszahlungsfunktion automatisch ausgeschaltet und kann nicht mehr verwendet werden. Kunden
          haben in solchen Fällen auch keinen Rechtsanspruch auf vorzeitige Auszahlung.
        </p>
        <h6 className="mt-3"> 5.4 Streitfälle </h6>
        <p>
          5.4.1 Sollte ein Streitfall auftreten, der nicht von diesen Geschäftsbedingungen geregelt
          wird, bemüht sich das Support-Team von Game90 (support@game90.bet), diesen auf faire und
          gerechte Weise über unser internes Beschwerdeverfahren zu lösen.
          <br />
          <br />
          Als Grundlage für Streitfälle gelten immer die AGB von Game90 und nicht die auf der
          Webseite oder in Applikationen dargestellten oder publizierten Begriffserklärungen und
          Informationen oder behandelte Themen (bspw. FAQ).
          <br />
          <br />
          5.4.2 Beschwerden in Bezug auf Wetteinsätze und/oder Gewinne müssen innerhalb von 14
          Werktagen schriftlich per E-Mail oder Fax eingehen, nachdem die Wette entschieden wurde.
          Nach Ablauf dieser Frist werden alle Wetten als genehmigt betrachtet.
          <br />
          <br />
          5.4.3 Jeder Streitfall, der nicht zur Zufriedenheit gelöst worden ist, kann auf Anfrage an
          den unabhängigen Dienst für Schiedsverfahren bei Wetten (Independent Betting Adjudication
          Service, IBAS) weitergeleitet werden. Bitte auch Punkt 15 dieser AGB beachten.
          <br />
          <br />
          5.4.4 Wenn Spieler den vorgeschlagenen Lösungen nicht zustimmen, kann der Streitfall über
          die ADR – Alternative Streitbeilegung (siehe Punkt 16) geregelt werden.
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="6" className="mt-75">
          6. Haftungsausschluss und Verfügbarkeit der Game90-Website
        </h5>
        <p className="mt-3">
          <br /> 6.1 Das Unternehmen widmet sich mit angemessener Sachkenntnis und Sorgfalt dem
          Betrieb der Game90-Website und Applikationen. Soweit nicht gesetzlich vorgeschrieben,
          bietet das Unternehmen weder ausdrücklich noch stillschweigend eine Garantie oder
          Gewährleistung in Bezug auf die Game90-Website und ihre Dienste.
          <br />
          <br />
          6.2 Das Unternehmen übernimmt keine Gewährleistung dafür, dass die Game90-Website und
          -Dienste die Anforderungen der Kunden vollständig erfüllen, ohne Unterbrechung verfügbar
          sind oder zeitnah, sicher und fehlerfrei funktionieren. Das Unternehmen übernimmt
          ebenfalls keine Garantie für die Behebung von Mängeln oder dafür, dass die Game90-Website
          und Applikationen frei von Viren oder Fehler sind. Darüber hinaus übernimmt das
          Unternehmen keine Gewährleistung für die volle Funktionalität, Genauigkeit und
          Zuverlässigkeit der Daten, die vom Unternehmen zur Verfügung gestellt wurden, den Erfolg
          der Nutzung der Website oder die Genauigkeit der auf der Website den Kunden dargebotenen
          Daten. Störungen oder ein Ausfall des Online-Angebots sollten umgehend per E-Mail an
          support@game90.bet gemeldet werden. Game90 wird versuchen, einen eventuellen Fehler so
          schnell wie möglich zu beheben.
          <br />
          <br />
          6.2.1 Game90 übernimmt keine Haftung dafür, wenn Kunden mit veralteten Applikationen,
          Webbrowsern oder anderen technisch überholten Geräten den Service der Game90 nutzen und
          dabei Übertragungsfehler, Anzeigefehler oder andere falsche Daten übermittelt werden.
          <br />
          <br />
          6.2.2 Für korrekt funktionierende Endgeräte sind die Kunden selbst verantwortlich und
          müssen eigenständig sicherstellen, dass Anzeigefehler ausgeschlossen sind. Game90 haftet
          nicht für Fehler in den Darstellungen bei Apps und Website, wenn nach Punkt 5.2.1
          anderslautende Buchungen bestätigt wurden. Beispiel: Ein Bild zeigt eine Quote von 2,30,
          die tatsächliche Quote wurde jedoch mit 1,60 bestätigt und gemäß Punkt 5.2.1 im
          Spielerkonto angezeigt. Die Kunden können die höhere Quote nicht beanspruchen.
          <br />
          <br />
          6.3 Der Zugang auf die Game90-Website oder die Applikationen kann gelegentlich
          eingeschränkt werden, um Reparaturen oder Wartungsarbeiten oder die Einführung neuer
          Angebote zu ermöglichen. Sollte dieser Fall auftreten, versucht das Unternehmen, den
          Online-Dienst so schnell wie möglich wiederherzustellen.
          <br />
          <br />
          6.3.1 Es besteht kein Rechtsanspruch für Kunden auf mögliche entgangene Gewinne, die in
          Zeiten mit eingeschränktem Zugang hätten platziert werden können. <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="7" className="mt-75">
          7. Haftungsbeschränkung
        </h5>
        <p className="mt-3">
          7.1 Mit ihrer Registrierung akzeptieren Kunden, dass sie die Game90-Website und deren von
          Game90 erstellten und genehmigten Applikationen auf eigene Gefahr und freiwillig
          eigenständig nutzen.
          <br />
          <br />
          7.2 Für jedweden Verlust beim Hochladen oder über die Game90-Website zu übertragenen Daten
          und Inhalten ist das Unternehmen nicht haftbar. Sie erkennen an, dass weder Game90 noch
          ein anderes zu Game90 gehörendes Unternehmen für Schäden jedweder Art haftbar zu machen
          ist, die aus Änderungen an der Game90-Website oder aus der vorübergehenden oder
          endgültigen Deaktivierung der Game90-Website resultieren.
          <br />
          <br />
          7.3 Das Unternehmen behält sich das Recht vor, jederzeit die Game90-Website vollständig
          oder auch teilweise einzustellen. Wenn dieses Recht ausgeübt wird, haben Kunden keine
          Grundlage für Schadensersatzforderungen oder sonstige Ansprüche.
          <br />
          <br />
          7.4 Das Unternehmen ist während des Betriebes der Game90-Website nicht verantwortlich für
          Tippfehler oder technische und menschliche Fehler. Im Falle eines Fehlers behält sich das
          Unternehmen vor, entweder die betroffenen Wetten für ungültig zu erklären oder den Fehler
          zu korrigieren. Wenn eine vom Unternehmen vorgenommene Korrektur die Bedingungen eines
          Wetteinsatzes beeinträchtigt, erhalten Kunden die Möglichkeit, die geänderten Bedingungen
          zu akzeptieren, bevor die Wette als korrigiert betrachtet wird.
          <br />
          <br />
          7.5 Wenn Game90 diese allgemeinen Geschäftsbedingungen verletzt, haftet Game90 lediglich
          für einen eventuellen dadurch unmittelbar verursachten Schaden. Voraussetzung dafür ist,
          dass die Kunden die aktuellste Version der AGB vorher ebenfalls anerkannt haben und sich
          nicht auf eine ältere Version beziehen dürfen.
          <br />
          <br />
          7.6 Jeglicher Schadensersatz gemäß dem vorstehenden Absatz beschränkt sich auf den Betrag
          des max. Gewinns des jeweiligen Wettscheins (siehe auch 5.2.1).
          <br />
          <br />
          7.7 Auf keinen Fall haftet das Unternehmen für indirekte Folgeschäden oder Verluste, die
          angeblich durch die Game90-Website oder ihren Inhalt verursacht werden. Dazu gehören
          insbesondere: Verzögerungen oder Unterbrechungen im Betrieb oder bei der Übertragung;
          Ausfall von Datenübertragungsleitungen; Nutzung oder Missbrauch der Website, ihrer Dienste
          oder Inhalte im Allgemeinen durch Kunden oder Dritte; ungenaue oder unvollständige
          Informationen auf der Website; Verlust von Geschäften, entgangener Gewinn,
          Betriebsunterbrechung, Verlust von Geschäftsinformationen oder andere, auch finanzielle,
          Folgeschäden (vgl. auch Punkt 6.2).
          <br />
          <br />
          7.8 Game90 haftet nicht für Verletzungen dieser Bedingungen durch Umstände, die sich
          außerhalb seiner Kontrolle befinden.
          <br />
          <br />
          7.9 Das Unternehmen behält sich das Recht vor, die Website und ihre Dienste jederzeit
          vollständig oder auch teilweise einzustellen. In einem solchen Fall verzichten die Kunden
          auf jegliche Rechtsansprüche auf Auszahlung, mit Ausnahme des Guthabens auf dem
          Kunden-Konto. <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="8" className="mt-75">
          8. Rechte an geistigem Eigentum
        </h5>
        <p className="mt-3">
          Das Unternehmen gewährt Kunden ein nicht exklusives, nicht übertragbares und nicht
          lizenzierbares Recht, die von der Website heruntergeladenen Computerprogramme (die
          „Software“) zu nutzen und alle daraus abgeleiteten Inhalte nur in Verbindung mit diesen
          Diensten und im Einklang mit diesen Bedingungen zu installieren. Dies umfasst auch
          Urheberrechte und Rechte an geistigem Eigentum. Kunden können die Software auf einer
          Festplatte oder anderen Speichergeräten installieren und Sicherheits-Kopien in Verbindung
          mit der Nutzung des Dienstes für den persönlichen Gebrauch auf einem Computer erstellen,
          dessen Hauptnutzer die Kunden selbst sind.
          <br />
          <br />
          8.1 Kunden dürfen nicht:
          <br />
          <br />
          8.1.1 die Software dazu verwenden, kopieren oder ändern, noch davon abgeleitete Werke
          schaffen oder die Software ganz oder teilweise mit Änderungen, Transkriptionen oder
          zusammengeführte Teile von dieser vertreiben, ausgenommen in dem Umfang, in dem die
          vorgenannten Handlungen gesetzlich erlaubt sind;
          <br />
          <br />
          8.1.2 die Software dekodieren, nachkonstruieren, auseinanderbauen, dekompilieren,
          übersetzen oder die Software oder irgendeinen Teil davon anderweitig umwandeln oder
          konvertieren, ausgenommen in dem Umfang, in dem die vorgenannten Handlungen gesetzlich
          erlaubt sind;
          <br />
          <br />
          8.1.3 die Software transferieren, verleihen, leasen, übertragen, vermieten oder
          anderweitig an Dritte lizenzieren;
          <br />
          <br />
          8.1.4 Verweise auf Urheberrechte, Eigentum oder ähnliche Hinweise aus der Software (oder
          Kopien davon) entfernen;
          <br />
          <br />
          8.1.5 die Software über ein Computer-Netzwerk oder auf andere Weise Dritten zugänglich
          machen.
          <br />
          <br />
          8.2 Die „Game90“-Marke, die Website game90.bet und alle anderen Marken,
          Dienstleistungsmarken oder Handelsnamen (Warenzeichen), die vom Unternehmen verwendet
          werden, und alle Materialien der Game90-Website (insbesondere sämtliche Software, Texte,
          Verfahren, Konzepte, Bilder, Grafiken, Video und Audio) befinden sich im Eigentum des
          Unternehmens, umfassen die Rechte an geistigem Eigentum und unterliegen dem Urheberrecht.
          Sie bestätigen, dass sie keine Rechte an den Handelsmarken oder Materialien haben, auf die
          hier Bezug genommen wird, und durch die Nutzung der Game90 Website entstehen keine
          Ansprüche auf diese Rechte. Die Verwendung dieser Marken ohne die vorherige Zustimmung des
          Unternehmens ist ausdrücklich untersagt. <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="9" className="mt-75">
          9. Meldepflicht
        </h5>
        <p className="mt-3">
          Im Fall von Meinungsverschiedenheiten in Bezug auf ein Ergebnis, eine Entscheidung oder
          eine andere Handlung, die sich auf eine Wette bezieht, muss eine Beschwerde schriftlich
          innerhalb von 14 Werktagen nach dem Vorfall erfolgen und per E-Mail an support@game90.bet
          oder schriftlich über die folgende Adresse an das Unternehmen gerichtet werden: Game90
          Ltd., Melita Court, Level 1, Giuseppe Cali St c/w Abate Rigord St, XBX1420, Ta&aposXbiex,
          Malta. Alle Antworten des Unternehmens werden an die Adresse der Kunden gesendet, die bei
          der Registrierung angegeben wurde (sofern in diesen Geschäftsbedingungen nicht anders
          festgelegt). <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="10" className="mt-75">
          10. Allgemeine Bestimmungen
        </h5>
        <p className="mt-3">
          10.1 Diese allgemeinen Geschäftsbedingungen regeln in ihrer jeweils gültigen Fassung die
          Geschäftsbeziehung zwischen den Kunden und dem Unternehmen in Bezug auf die Nutzung der
          Game90-Website und -Dienste.
          <br />
          <br />
          10.2 Mit der Annahme dieser Bedingungen erklären die Kunden ausdrücklich ihre Zustimmung
          zu diesen in ihrer aktuellen Form. Die Kunden erklären ebenfalls, dass keine Nebenabreden
          getroffen wurden. Ferner dürfen Kunden sich nicht auf missverständliche Formulierungen
          verlassen, unabhängig davon, ob solche Informationen zu einem integralen Bestandteil
          dieser allgemeinen Geschäftsbedingungen geworden sind oder nicht.
          <br />
          <br />
          10.3 Die den Kunden gewährten Rechte aus diesen Bedingungen sind nicht auf Dritte
          übertragbar. Das Unternehmen kann seine hierin gewährten Rechte und Pflichten einem
          Dritten übertragen, vorausgesetzt, die Rechte der Kunden werden dadurch nicht
          beeinträchtigt.
          <br />
          <br />
          10.4 Wenn diese Bedingungen nicht ausdrücklich festlegen, dass ein Dritter einen
          Rechtsanspruch aus diesen ableiten kann, besteht die Rechtsbeziehung aus diesen
          Geschäftsbedingungen und allen nachfolgenden Verträgen ausschließlich zwischen den Kunden
          und dem Unternehmen. Alle rechtlichen Ansprüche Dritter unter diesen Bedingungen sind
          ausgeschlossen.
          <br />
          <br />
          10.5 Sollte ein Teil dieser Bedingungen sich als ungültig erweisen oder aus irgendeinem
          Grund nicht durchsetzbar sein, gilt diese Bestimmung getrennt von den übrigen und hat
          keine Auswirkungen auf die Gültigkeit und Durchsetzbarkeit der übrigen Bestimmungen.
          <br />
          <br />
          10.6 Der Verzicht des Unternehmens auf einen Teil dieser Bedingungen gilt nur für einen
          bestimmten Vorfall und bedeutet weder eine Änderung dieser Bedingung noch stellt er einen
          allgemeinen Forderungsverzicht dar.
          <br />
          <br />
          10.7 Diese allgemeinen Geschäftsbedingungen bilden auf keinen Fall eine Vertretung,
          Partnerschaft oder eine andere Art von gemeinsamer Unternehmung zwischen dem Kunden und
          dem Unternehmen.
          <br />
          <br />
          10.8 Im Falle eines Widerspruchs zwischen diesen Geschäftsbedingungen und anderen
          Dokumenten mit Bezug auf diese Bedingungen haben diese allgemeinen Geschäftsbedingungen
          Vorrang. <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="11" className="mt-75">
          Regeln für die Platzierung von Wetten / Sportregeln / Spielrelevante Informationen,
          Kosten, Wettquoten und Gewinne
        </h5>
        <h6 className="mt-3"> 11.1 Regeln für die Platzierung von Wetten </h6>
        <p className="mt-3">
          Diese Allgemeinen Geschäftsbedingungen umfassen die Wett- und Sportregeln, die unter
          anderem die Dienste, die auf der Website oder in den Applikationen angeboten werden, die
          Spielregeln und die Bearbeitung der Wetteinsätze durch das Unternehmen regulieren. Mit der
          Annahme dieser Bedingungen erklären die Kunden, dass die Wettregeln und Vorschriften in
          der jeweils aktuellen Version gelesen, verstanden und angenommen wurden.
        </p>
        <h6 className="mt-3">
          11.2 Spielrelevante Informationen, Kosten, Wettquoten und Gewinne nach §7 GlüStV
        </h6>
        <div>
          Alle Spielrelevanten Informationen wie:
          <ol className="mt-3">
            <li key={90} className="mt-3">
              Teilnahme-Kosten
            </li>
            <li key={91} className="mt-3">
              Gewinnsumme
            </li>
            <li key={92} className="mt-3">
              Darstellung der Gewinne
            </li>
            <li key={93} className="mt-3">
              Auszahlungsquote
            </li>
            <li key={94} className="mt-3">
              Annahmeschluss einer Wette
            </li>
            <li key={95} className="mt-3">
              Gewinnermittlung
            </li>
            <li key={96} className="mt-3">
              Gutschrift der Wettgewinne
            </li>
            <li key={97}>Wettveranstalter</li>
            <li key={98} className="mt-3">
              Handelsregisternummer
            </li>
            <li key={99} className="mt-3">
              Wie kann der Spieler Beschwerden vorbringen
            </li>
            <li key={100} className="mt-3">
              Datum der ausgestellten Erlaubnis
            </li>
          </ol>
          sind jederzeit, falls möglich, vor und nach der Wettabgabe auf dem virtuellen Wettschein,
          im Kundenkonto und auf der Website einzusehen. Die Ergebnisse aller Wettereignisse werden
          nach offizieller Bewertung auf dem Onlineportal veröffentlicht. Für gegebenenfalls
          entstehende Rückfragen oder Beschwerden werden dem Kunden jederzeit und gut ersichtlich
          sowohl eine Kontaktmöglichkeit des Wetthalters als auch einer behördlichen Anlaufstelle
          zur Verfügung gestellt.
          <br />
          <a href="#top">Zurück</a>
        </div>
      </div>
      <div>
        <h5 id="12" className="mt-75">
          12. Bonusregelung
        </h5>
        <p className="mt-3">
          12.1 Die Bonusregelung wird in den separat aufgeführten Bonusbedingungen erläutert. Game90
          gibt unter bestimmten Bedingungen Boni aus, die gemäß den allgemeinen Bonusregeln
          behandelt werden. Unabhängig von den Bonusbedingungen gelten diese Geschäftsbedingungen
          weiterhin.
          <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="13" className="mt-75">
          13. Verantwortliches Spielen
        </h5>
        <p className="mt-3">
          13.1 All Allen Nutzern sollte bewusst sein, dass verantwortungsvoll mit den Einsätzen
          umgegangen werden muss. Es ist wichtig, dass Nutzer verstehen, dass Game90 lediglich
          Wetten auf Sportereignisse anbietet, diese Ereignisse jedoch nicht beeinflussen kann. Auch
          gibt es beim Wetten keinen Zufallsgenerator, der einen Wettausgang bestimmt. Es obliegt
          den Nutzern, auf welchen Ausgang gewettet wird und ob dadurch ein positives Ergebnis
          zustande kommt.
          <br /> <br />
          13.2 Game90 engagiert sich unterstützend für verantwortliches Wetten durch die Förderung
          des Bewusstseins für problematisches Spielverhalten und die Verbesserung von Prävention,
          Intervention und Behandlung.
          <br /> <br />
          13.3 Die Grundsätze von Game90 zum verantwortungsvollen Spielen dokumentieren unser
          Engagement für die Minimierung der negativen Auswirkungen problematischen Spielverhaltens
          und die Förderung von verantwortungsvollen Spielpraktiken.
          <br /> <br />
          13.4 Für den Umgang mit verantwortlichem Spielverhalten hat Game90 eine eigene ständige
          Seite veröffentlicht, welche auch ohne Registrierung mit einem Klick immer erreichbar ist.
          Mit Ausnahme aller Unterseiten nach Punkt 5.3.1.1. hat diese Seite volle Gültigkeit und
          enthält die aktuellsten Informationen und Hilfen zum Umgang bei problematischen
          Spielverhalten.
          <br /> <br />
          13.4.1 Game90 verwendet ein Sperrsystem nach § 8. GlüStV 2021 Es heißt &quot;OASIS&quot;
          und wird vom Bundesland Hessen verwaltet, das vom Regierungspräsidium Darmstadt vertreten
          wird.
          <br /> <br />
          13.4.2 Game90 wird seine Kunden bei der Registrierung und bei jeder Einzahlung auf
          mögliche Blockierungen in diesem OASIS-System überprüfen.
          <br /> <br />
          13.4.3 Gelistete und gesperrte Spieler können bei Game90 keine weiteren Aktivitäten
          ausführen, wenn sie in der Statusblockdatei registriert sind.
          <br /> <br />
          13.4.4 Eine Sperre über OASIS ist mindestens 3 Monate lang gültig und kann nur bei oder
          von zuständigen Behörde wieder aufgehoben werden. Dies muss schriftlich mit einer
          fundierten Erklärung erfolgen. Hier können keine Ausnahmen gemacht werden (siehe auch
          2.7.3 und 2.7.7).
          <br /> <br />
          13.4.5 Für alle in OASIS gespeicherten Daten ist in diesem Fall das Regierungspräsidium
          Darmstadt aus dem Land Hessen verantwortlich. <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="14" className="mt-75">
          14. Datenschutzrichtlinie
        </h5>
        <p className="mt-3">
          In unserer Datenschutzrichtlinie und unter Punkt 2.3 dieser AGB finden Sie ausführliche
          Informationen über den Einsatz und die Verwaltung von persönlichen Kundendaten. Mit
          Annahme dieser Geschäftsbedingungen erklären Kunden, dass die Datenschutzrichtlinie
          gelesen, angenommen und verstanden wurde.
          <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="15" className="mt-75">
          15. Allgemeines
        </h5>
        <p className="mt-3">
          15.1 Die Auslegung, Gültigkeit und Erfüllung dieser Vereinbarung unterliegt den Gesetzen
          von Malta.
          <br /> <br />
          15.2 Alle Versionen unserer Geschäftsbedingungen sollen die gleichen Grundsätze
          widerspiegeln. Im unwahrscheinlichen Fall einer Diskrepanz zwischen einer nicht-englischen
          Version und der englischen Fassung dieser Allgemeinen Geschäftsbedingungen gilt vorrangig
          die englische Version.
          <br /> <br />
          15.3 Gerichtsstand für die Game90 Limited ist Malta. <br /> <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h5 id="16" className="mt-75">
          16. ADR - Alternative Streitbeilegung
        </h5>
        <p className="mt-3">
          16.1 Game90 bemüht sich, die Benutzererfahrung mit dem angebotenen Service so angenehm wie
          möglich zu gestalten. Es kann jedoch vorkommen, dass ein Spieler mit der Qualität und dem
          Service oder der Kundenbetreuung unzufrieden ist. Ein Spieler kann dann eine Beschwerde
          einreichen, indem er eine E-Mail an den Game90-Kundendienst unter support@game90.bet
          sendet. Die Beschwerde wird an das Management weitergeleitet und Game90 wird sich bemühen,
          sie innerhalb von 48 Stunden nach Eingang zu bearbeiten. Das Ergebnis der Beschwerde wird
          dem Benutzer innerhalb von 10 Tagen ab dem Datum des vollständigen Eingangs mitgeteilt. In
          bestimmten Fällen kann dieser Zeitraum um weitere 10 Tage verlängert werden.
          <br />
          <br />
          16.2 Wenn Benutzer mit der Art und Weise, wie die Beschwerde vom Game90-Kundendienstteam
          bearbeitet oder gelöst wurde, nicht zufrieden sind, können die Kunden ihre Streitigkeiten
          alternativ der ADR-Stelle (Alternative Dispute Resolution) einreichen, indem sie hier auf
          den EADR-Link des Dienstanbieters klicken: https://eadr.org/eadr-form/. Sobald Benutzer
          die endgültige Entscheidung von Game90 erhalten haben, haben Benutzer das freie Recht, auf
          diese ADR zuzugreifen und ihre Streitigkeiten dort weiterzuleiten.
          <br />
          <br />
          16.2.1 In einem zweiten Schritt geben Benutzer alle Details in das Formular unter
          https://eadr.org/eadr-form/ ein und werden vom Dienstanbieter EADR kontaktiert, der für
          die Bedenken gegen Game90 Ltd. verantwortlich ist.
          <br />
          <br />
          16.3 Wenn Benutzer mit dem Urteil nicht zufrieden sind, können sie sich danach auch an
          support.mga@org.mt wenden oder auf https://www.mga.org.mt/support/Online-Gaming-support -
          die Spieler-Support-Seite von der Malta Gaming Authority (MGA) nutzen um Unterstützung zu
          erhalten. Voraussetzung dazu ist aber der vorherige Kontakt über die ADR – in diesem Falle
          also über den Serviceanbieter EADR.org.
          <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
      <div>
        <h6 id="17"> 17. Datum des Inkrafttretens</h6>
        <p>
          Diese allgemeinen Geschäftsbedingungen treten am 01. Oktober 2021 um Mitternacht (00:00
          Uhr) mitteleuropäischer Standardzeit in Kraft. Version: 4.3.9. <br />
          <a href="#top">Zurück</a>
        </p>
      </div>
    </div>
  );
}
