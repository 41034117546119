export default function EN() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <h4 className="text-danger mt-75 "> Privacy Policy</h4>
      <div className="mt-3">
        Game90 takes the protection of your personal information very seriously. This Privacy Policy
        describes our data processing practices and your choices regarding the use of your personal
        information. Game90 has the legitimate interest according to Article 6 (1) (f) of the GDPR
        to collect your data. For your questions and wishes regarding the handling of your personal
        data, please contact us at support@game90.bet or for direct queries under DPO@game90.bet.
        <a id="top" />
        <hr className="mt-75" />
        <ol className="mt-3">
          <li key={1}>
            <a href="#stored-information"> Stored information</a>
          </li>
          <li key={2}>
            <a href="#personal-information"> Use of personal information</a>
          </li>
          <li key={3}>
            <a href="#marketing-preferences"> Marketing preferences</a>
          </li>
          <li key={4}>
            <a href="#cookies"> Use of cookies</a>
          </li>
          <li key={5}>
            <a href="#disclosures"> Disclosures</a>
          </li>
          <li key={6}>
            <a href="#other-websites"> Other websites</a>
          </li>
          <li key={7}>
            <a href="#right-access"> Right of access</a>
          </li>
          <li key={8}>
            <a href="#data-transmission"> Internet-based data transmission</a>
          </li>
          <li key={9}>
            <a href="#international-applicable"> International jurisdiction and applicable law</a>
          </li>
        </ol>
      </div>
      <div className="mt-75">
        <h5 id="stored-information">1. Stored information </h5>
        <p className="mt-3">
          Game90s payment and risk departments are the appropriate departments that manage and
          process each players fully personal information.
          <br /> <br />
          The enquiry of data consists of statutory provisions, described for example, in the “Law
          for the detection of profits from serious crimes” (Money Laundering Act GwG of the Federal
          Republic of Germany) or the Financial Market Money Laundering Act of the Republic of
          Austria.
          <br /> <br />
          With your registration, you voluntarily agree to the processing and storage as well as the
          further use of your data in the framework of the General Data Protection Regulation. In
          accordance with the Privacy Policy, your personal information provided to us voluntarily
          and for processing will be stored on non-public and encrypted media and / or servers.
          Game90 also reserves the right to encrypt the data individually.
          <br /> <br />
          Game90 further reserves the right to transfer the personal data to third parties
          (so-called processors or order processors) including, but not limited to, payment service
          providers, financial institutions and other relevant data-processing entities for purposes
          of verification and protection against any misuse and / or to use their service if Game90
          deems it necessary.
          <br /> <br />
          Furthermore, Game90 reserves the right to disclose a players personal data to such
          instances, if there are any irregularities related to their player account. Disclosure of
          personal data to government agencies and state authorities may be governed by law and
          regulation and requires Game90 to forward such data to the appropriate governmental
          authorities as requested. An obligation to inform you about the forwarding of your data to
          state authorities and government can also be prohibited by legal regulations.
          <br /> <br />
          We store personal information of visitors to the website game90.bet and the connected
          applications via online forms and whenever data is sent by e-mail. We also store (through
          our secure payment processors) information about all transactions that have been and are
          made, including details of the payment methods used. We also automatically collect
          additional information about each visit to our website.
          <br /> <br />
          Without the confirmation of consent to the transfer of your data and the associated
          permission on your part that we may use this data, registration with Game90 will not be
          possible. Since legally prescribed processes prescribe data processing in certain areas,
          it is not possible to use our services without your consent. The withdrawal of a consent
          also entails the blocking of your player account. At least until a new consent is given.
          Previously processed data are not affected by the withdrawal of consent.
        </p>{' '}
        <h6 className="fw-bolder"> Recording of data</h6>
        <p className="mt-3">
          The collection of data via our website takes place by entering your personal data when
          registering, as listed under 2.2.1 of Game90´s Terms and Conditions, sending e-mails to
          our e-mail addresses and when communicating with us via our chat system or contact form.
          <br /> <br />
          The following data is collected during each of your visits to our pages or applications:
          <li key={10}> Your IP address, and thus your location </li>
          <li key={11}> The time when you logged in with us </li>
          <li key={12}>
            The assignment of your data generated by our cookies insofar as you have already
            registered with us, otherwise no personal data is included
          </li>
          <li key={13}>
            Recognition details (coded number which assigns the advertising medium to your player
            account - personal data or name details, etc. are not transmitted) if you come to us via
            one of our advertising media or affiliates
          </li>
          <li key={14}>
            The user agent, your browser sent by default and tells your operating system, your
            browser used and its current version.
          </li>
          <br /> <h6 className="fw-bolder"> E-mail</h6>
          <p className="mt-3">
            If you send us an e-mail, in addition to the above data, we will additionally receive
            your used e-mail address and the data you provided in the e-mail. When submitting
            documents via our e-mail system, the data is stored on a separate secure storage medium
            / server and cryptically encrypted.{' '}
          </p>
          <br /> <h6 className="fw-bolder"> Contact form</h6>
          <p className="mt-3">
            We offer you on our website in addition to a chat system a contact form, with which you
            can reach our support in writing. This form only transmits the data on your part, which
            you enter there in the form. The form is directly connected to our servers and will not
            be transported or spotted by a third company. A submitted form without contact data
            could not be assigned by you and will be destroyed immediately in accordance with the
            General Data Protection Regulation.
          </p>{' '}
          <h6 className="fw-bolder"> Chat system</h6> <p className="mt-3"></p>
          In our chat system, besides the IP address, we can also see which of the subpages on our
          website you are visiting at the moment and how long you have been staying on our website.
          <br /> <br />
          Through special technology and software we have in use, we are also able to see your
          user-agent. This indicates which operating system you are using and which browser you are
          using in which version. However, personal data will not be displayed or transmitted except
          if you provide us these details during the chat.
        </p>{' '}
        <h6 className="fw-bolder"> Deposit (PSP)</h6>
        <p className="mt-3">
          If you make a deposit on your player account, this can be done in different ways. With the
          exception of the normal bank transfer to our Game90 bank account, we use so-called payment
          service providers (PSP) for the deposit. These are located between you and us and allow a
          fast almost second-rate payment through their service. These PSPs have their own privacy
          policies, which are subject to strict legal regulations and Game90 has no influence on it.
          Please read the privacy statement of your preferred PSP in advance when making your
          deposit.
        </p>
        <h6 className="fw-bolder"> Landing Pages</h6>
        <p className="mt-3">
          If you reach, for example through one of our advertising newsletter or an affiliate, on an
          advertising or explanatory page of our company (subpages of the website - so-called
          Landing Pages), it may be that the button that you pressed in the advertising e-mail is
          provided with a special code, This would then be necessary to be listed on this landing
          page, even if you are asked to enter something (interest survey or similar) there. This
          may also apply to personal information, such as your e-mail address or full name. These
          landing pages are located on our own servers and data is assigned to the respective player
          accounts when it comes to special advertisements. Surveys do not collect personal
          information.{' '}
        </p>
        <a href="#top">Back to top</a>
      </div>
      <div className="mt-75">
        <h5 id="personal-information">2. Use of personal information </h5>
        <p className="mt-3">
          We will use the information collected through this website and connected applications for
          the following purposes:
          <ul>
            <li key={15}>Full Player Account Management </li>
            <li key={16}>Provision and personalization of our complete services; </li>
            <li key={17}>Processing of requests and wishes; </li>
            <li key={18}>Legally required verification processes</li>
            <li key={19}>Conducting market research studies (see also landing pages);</li>
            <li key={20}>
              Contacting you (via e-mail or other means of communication) to inform you about our
              products, innovations and services, as well as promotions;
            </li>
            <li key={21}>
              Disclose your information to organizations that offer products or services that we
              believe might be of interest to you (assuming you have consented to receive such
              information). Personal data, which were created after an assessment and category
              (profiling), are not transmitted here Your personal information is not processed and /
              or generated through automated processes by Game90 at all. A so-called exclusive
              automated profiling, as described in Article 22 GDPR, does not take place at Game90.
            </li>
          </ul>
        </p>{' '}
        <h6 className="fw-bolder"> 2.1 Newsletter</h6>
        <p className="mt-3">
          If you would like to receive our newsletter, we require a valid email address as well as
          information that allows us to verify that you are the owner of the specified email address
          and that you agree to receive this newsletter. No additional data is collected or is only
          collected on a voluntary basis. We only use this data to send the requested information
          and do not pass it on to third parties.{' '}
        </p>
        <p className="mt-3">
          We will, therefore, process any data you enter onto the contact form only with your
          consent per Art. 6 (1) (a) DSGVO. You can revoke consent to the storage of your data and
          email address as well as their use for sending the newsletter at any time, e.g. through
          the unsubscribe link in the newsletter. The data processed before we receive your request
          may still be legally processed.
          <br /> <br />
          The data provided when registering for the newsletter will be used to distribute the
          newsletter until you cancel your subscription when said data will be deleted. Data we have
          stored for other purposes (e.g. email addresses for the members area) remain unaffected.
        </p>{' '}
        <h6 className="fw-bolder"> MailChimp</h6>{' '}
        <p className="mt-3">
          This website uses the services of MailChimp to send newsletters. This service is provided
          by Rocket Science Group LLC, 675 Ponce De Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA.
          <br />
          <br />
          MailChimp is a service which organizes and analyzes the distribution of newsletters. If
          you provide data (e.g. your email address) to subscribe to our newsletter, it will be
          stored on MailChimp servers in the USA.
          <br />
          <br />
          MailChimp is certified under the EU-US Privacy Shield. The Privacy Shield is an agreement
          between the European Union (EU) and the US to ensure compliance with European privacy
          standards in the United States.
          <br />
          <br />
          We use MailChimp to analyze our newsletter campaigns. When you open an email sent by
          MailChimp, a file included in the email (called a web beacon) connects to MailChimps
          servers in the United States. This allows us to determine if a newsletter message has been
          opened and which links you click on. In addition, technical information is collected (e.g.
          time of retrieval, IP address, browser type, and operating system). This information
          cannot be assigned to a specific recipient. It is used exclusively for the statistical
          analysis of our newsletter campaigns. The results of these analyses can be used to better
          tailor future newsletters to your interests.
          <br />
          <br />
          If you do not want your usage of the newsletter to be analyzed by MailChimp, you will have
          to unsubscribe from the newsletter. For this purpose, we provide a link in every
          newsletter we send. You can also unsubscribe from the newsletter directly on the website.
          <br />
          <br />
          Data processing is based on Art. 6 (1) (a) GDPR. You may revoke your consent at any time
          by unsubscribing to the newsletter. The data processed before we receive your request may
          still be legally processed.
          <br />
          <br />
          The data provided when registering for the newsletter will be used to distribute the
          newsletter until you cancel your subscription when said data will be deleted from our
          servers and those of MailChimp. Data we have stored for other purposes (e.g. email
          addresses for the members area) remains unaffected.
          <br />
          <br />
          For details, see the MailChimp privacy policy at https://mailchimp.com/legal/terms/ <br />
        </p>
        <h6 className="fw-bolder"> 2.2 Plugins and tools</h6>
        <h6 className="fw-bolder"> YouTube</h6>
        <p className="mt-3">
          Our website uses plugins from YouTube, which is operated by Google. The operator of the
          pages is YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.
          <br />
          <br />
          If you visit one of our pages featuring a YouTube plugin, a connection to the YouTube
          servers is established. Here the YouTube server is informed about which of our pages you
          have visited. If you are logged in to your YouTube account, YouTube allows you to
          associate your browsing behavior directly with your personal profile. You can prevent this
          by logging out of your YouTube account. YouTube is used to help make our website
          appealing. This constitutes a justified interest pursuant to Art. 6 (1) (f) GDPR.
          <br />
          <br />
          Further information about handling user data, can be found in the data protection
          declaration of YouTube under https://www.google.de/intl/de/policies/privacy.
          <h6 className="fw-bolder"> Google Web Fonts </h6>
          <br /> <br />
          For uniform representation of fonts, this page uses web fonts provided by Google. When you
          open a page, your browser loads the required web fonts into your browser cache to display
          texts and fonts correctly.
          <br />
          <br />
          For this purpose your browser has to establish a direct connection to Google servers.
          Google thus becomes aware that our web page was accessed via your IP address. The use of
          Google Web fonts is done in the interest of a uniform and attractive presentation of our
          website. This constitutes a justified interest pursuant to Art. 6 (1) (f) GDPR. If your
          browser does not support web fonts, a standard font is used by your computer.
          <br />
          <br />
          Further information about handling user data, can be found at
          https://developers.google.com/fonts/faq and in Googles privacy policy at
          https://www.google.com/policies/privacy/.{' '}
        </p>
        <a href="#top">Back to top</a>
      </div>
      <div className="mt-75">
        <h5 id="marketing-preferences">3. Marketing preferences </h5>
        <p className="mt-3">
          When registering, please also indicate whether you would like to receive information about
          products or services from us or other companies in our company group by ticking the
          appropriate box. You may revoke the receipt of such communications at any time by
          following the instructions at the end of each advertising message or by sending us an
          e-mail to support@game90.bet.
          <br /> <br />
          If you want to know if and what data we forward to other companies, you have the right to
          know at any time. Simply contact our data protection officer at DPO@game90.bet.{' '}
        </p>
        <a href="#top">Back to top</a>
      </div>
      <div className="mt-75">
        <h5 id="disclosures">4. Use of Cookies </h5>
        <p className="mt-3">
          A cookie is a small unit of information that is sent from a web server to a web browser
          and allows the server to obtain information from the browser. We use our own cookies to
          identify you when you visit our website and to be able to recall details of your
          registration more quickly and effectively (for example, saved game settings etc.). The use
          of cookies also allows registered users to conduct transactions and access information
          about their account. Most browsers allow you to disable cookies. If you would like to know
          how to do this, please refer to the help menu of your browser. However, by disabling
          cookies, your visits to our website will be significantly restricted or unusable.{' '}
        </p>
        <h6 className="fw-bolder">4.1. Analytics</h6>
        <p className="mt-3">
          This website uses Google Analytics, a web analytics service. It is operated by Google
          Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Google Analytics uses
          so-called cookies. These are text files that are stored on your computer and that allow an
          analysis of the use of the website by you. The information generated by the cookie about
          your use of this website is usually transmitted to a Google server in the USA and stored
          there. Google Analytics cookies are stored based on Art. 6 (1) (f) GDPR. The website
          operator has a legitimate interest in analyzing user behavior to optimize both its website
          and its advertising.{' '}
        </p>
        <h6 className="fw-bolder">IP anonymization</h6>
        <p className="mt-3">
          We have activated the IP anonymization feature on this website. Your IP address will be
          shortened by Google within the European Union or other parties to the Agreement on the
          European Economic Area prior to transmission to the United States. Only in exceptional
          cases is the full IP address sent to a Google server in the US and shortened there. Google
          will use this information on behalf of the operator of this website to evaluate your use
          of the website, to compile reports on website activity, and to provide other services
          regarding website activity and Internet usage for the website operator. The IP address
          transmitted by your browser as part of Google Analytics will not be merged with any other
          data held by Google.{' '}
        </p>
        <h6 className="fw-bolder">Browser plugin</h6>
        <p className="mt-3">
          You can prevent these cookies being stored by selecting the appropriate settings in your
          browser. However, we wish to point out that doing so may mean you will not be able to
          enjoy the full functionality of this website. You can also prevent the data generated by
          cookies about your use of the website (incl. your IP address) from being passed to Google,
          and the processing of these data by Google, by downloading and installing the browser
          plugin available at the following link: https://tools.google.com/dlpage/gaoptout?hl=en.{' '}
        </p>
        <h6 className="fw-bolder">Objecting to the collection of data</h6>
        <p className="mt-3">
          You can prevent the collection of your data by Google Analytics by clicking on the link
          “Disable tracking” in the website’s footer. An opt-out cookie will be set to prevent your
          data from being collected on future visits to this site.
          <br />
          For more information about how Google Analytics handles user data, see Googles privacy
          policy: https://support.google.com/analytics/answer/6004245?hl=en.{' '}
        </p>
        <h6 className="fw-bolder">Outsourced data processing</h6>
        <p className="mt-3"></p>
        We have entered into an agreement with Google for the outsourcing of our data processing and
        fully implement the strict requirements of the German data protection authorities when using
        Google Analytics.
        <h6 className="fw-bolder">Demographic data collection by Google Analytics</h6>
        <p className="mt-3">
          This website uses Google Analytics demographic features. This allows reports to be
          generated containing statements about the age, gender, and interests of site visitors.
          This data comes from interest-based advertising from Google and third-party visitor data.
          This collected data cannot be attributed to any specific individual person. You can
          disable this feature at any time by adjusting the ads settings in your Google account or
          you can forbid the collection of your data by Google Analytics as described in the section
          Refusal of data collection.
          <br />
          <br />
          In part, Game90 uses the web analytics service Google Analytics on the betting platform
          and its own services. Google Analytics also uses cookies to facilitate the analysis of
          your use of the website. The information generated by the cookies about your use of this
          platform (including your IP address) will be transmitted to and stored by Google on
          servers in the United States.
          <br />
          <br />
          Google uses this information to evaluate your use of the Platform, to compile reports on
          the activities of the Platform Operators and to provide other Internet related services.
          <br />
          <br />
          Google will also transfer this information to third parties if required by law or if third
          parties process this data on behalf of Google. Google will never associate your IP address
          with other Google data. You can prevent the installation of cookies by setting your
          browser software accordingly; however, we point out that in this case you may not be able
          to use all the functions of the website to the full extent. By using the website you agree
          to the processing of the data collected about you by Google in the manner described above
          and for the purpose stated above.{' '}
        </p>
        <a href="#top">Back to top</a>
      </div>
      <div className="mt-75">
        <h5 id="marketing-preferences">5. Disclosures</h5>
        <p className="mt-3">
          We only disclose personal information to other companies within our group of companies,
          business partners, government agencies and law enforcement agencies, future owners of our
          business, and service providers that we use to process data (processors).
          <br />
          <br />
          You have the right to restrict the transfer of your data or to insist on the deletion of
          your data. This right is guaranteed to you in the context of the points described in
          Articles 17 and 18 of the General Data Protection Regulation (GDPR). Exceptions are data
          that have to be collected according to Art.17.3.b of the GDPR and processed with a minimum
          legal storage requirement. The right of limitation and or restriction and or refusal as
          well as deletion of your data may be affected or limited by these legal provisions.
          <br />
          <br />
          You also have the right under Art.15 of the GDPR to obtain information about the data
          stored at Game90 and to request them. Game90 will personally transmit the data to you in a
          readable format. The information includes your stored personal data and the type of use.
          For information about stored data, a restriction or deletion please contact DPO@game90.bet{' '}
        </p>
        <a href="#top">Back to top</a>
      </div>
      <div className="mt-75">
        <h5 id="other-websites">6. Other websites</h5>
        <p className="mt-3">
          Our app and website may contain links to third-party websites and apps (for example, to
          social media or regulators) over which we have no influence and to which the Game90
          Privacy Policy does not extend and may be differ from ours. For the functionality or
          possible occurring errors on the linked web pages only their operators are responsible for
          it. Game90 assumes no liability for the statutory provisions or privacy policies of
          websites of other operators. The social networks available to you for sharing content
          depend on which social networks you are a member of and how you have configured your
          member account and device. After the activation of such a link, we no longer have the
          influence on the processing of any personal data transferred to the third party when the
          link is operated. Therefore, we cannot take any responsibility for the processing of such
          personal data by third parties. For more information, see the privacy policy of the social
          networks or websites you use. Game90 also has no influence on the privacy policy of the
          pages, which lead through small icons to payment service providers (e.g. Visa, Skrill
          etc.) or authorities . These special links are partly required by law and Game90 is not
          liable for the content or privacy policy of the respective pages.{' '}
        </p>
        <a href="#top">Back to top</a>
      </div>
      <div className="mt-75">
        <h5 id="right-access">7. Right of access</h5>
        <p className="mt-3">
          You have the right to access your personal information as listed under 2.2.1 of Game90s
          Terms and Conditions. This data can be viewed personally and simply at any time in your
          registered player account. To obtain a copy of your personal information stored with us,
          please contact us at the following address: <br /> Game90 Ltd.
          <br />
          Melita Court
          <br />
          Giuseppe Cali St c/w Abate Rigord St
          <br />
          XBX1420 TaXbiex
          <br />
          Malta
          <br />
          Alternatively send your e-mail request to DPO@game90.bet for an overview of the data
          stored with us. According to Article 15 of the General Data Protection Regulation (GDPR),
          this right is granted to you once every 3 months free of charge. Each additional request
          will be covered by a reasonable administration fee depending on the effort. The documents
          will be made available to you in a format that is readable for you. For privacy reasons,
          the request made by you must be verified separately and unequivocally.{' '}
        </p>
        <a href="#top">Back to top</a>
      </div>
      <div className="mt-75">
        <h5 id="data-transmission">8. Internet-based data transmission</h5>
        <p className="mt-3">
          Because the Internet is a global environment, the collection and processing of personal
          information necessarily involves the transmission of data on an international basis. By
          using our website and communicating with us electronically, you acknowledge and agree that
          we process your personal information in this way. When transferring data on our part to
          one of our processors, we will transmit the data cryptically encrypted.{' '}
        </p>
        <a href="#top">Back to top</a>
      </div>
      <div className="mt-75">
        <h5 id="international-applicable">9. International jurisdiction and applicable law</h5>
        <p className="mt-3">
          Any dispute between you and Game90 arising from or in relation to the subject matter of
          this Privacy Policy shall be governed and construed in accordance with Malta law without
          reference to its conflict of laws principles and shall be subjected to the exclusive
          jurisdiction of the competent courts of Malta. If a provision of the present Privacy
          Policy is canceled by a decision of a competent court as unlawful, invalid or
          unenforceable, this will not affect the validity and enforceability rest of its
          provisions, which will remain in full force and will be accordingly applied.{' '}
        </p>
        <a href="#top">Back to top</a>
      </div>
    </div>
  );
}
