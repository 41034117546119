import { Row, Col } from 'react-bootstrap';
export default function EN() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <div>
        <h5 className="text-danger fw-bolder mt-75"> CONTACT</h5>
        <span className="fw-bolder mt-3">Would you like to contact us?</span> <br />
        This section shows how you can contact us.
        <p className="mt-75 text-danger fw-bolder">Contact us via support</p>
        <p>
          We are ready to answer any questions you may have every day from 10:00 until 22:00 o clock
          (CET).
        </p>
      </div>
      <Row>
        <Row>
          <Col className="text-danger" xs={4}>
            By e-mail to the following address: <hr />
          </Col>
          <Col className="text-danger" xs={4}>
            By fax to the following number: <hr />
          </Col>
          <Col className="text-danger" xs={4}>
            Use our chat: <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>support@game90.bet</Col> <Col xs={4}>+356 2276 9002</Col> <Col xs={4}></Col>
        </Row>
      </Row>
      <div className="mt-75">
        <h5 className="text-danger">Our address</h5>
        <span>
          Game90 Ltd.
          <br />
          1st Floor of Melita Court
          <br />
          Giuseppe Cali St, c/w Abate Rigord St
          <br />
          XBX1420 TaXbiex
          <br />
          Malta
          <br />
          <br />
          E-mail: <br />
          support@game90.bet
          <br />
          <br />
          License: Game90 Ltd. (registration no. C50436) is licensed for remote gaming by the Malta
          <br />
          Gaming Authority(MGA/B2C/191/2010).
        </span>
      </div>
    </div>
  );
}
