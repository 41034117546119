import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { AWS_BUCKET_URL, TopSports, OtherSports } from 'toolkit/constants';
import BlackBox from 'components/general/black_box.js';
import { calculateSportCount } from 'components/base/constants';
import SportListItem from '../../components/SportListItem.js';

function SportMobile() {
  const { t } = useTranslation('translations');
  const counts = useSelector((state) => state.match.counts);
  const listItems = [
    {
      items: TopSports,
      title: t('popularsports')
    },
    {
      items: OtherSports,
      title: t('othersports')
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="py-2">
      <Container>
        <h6 className="text-uppercase text-muted mb-n1 fs-7">{t('sport')}</h6>
        <hr />
        <div className="row g-3 mb-3">
          <div className="col-4">
            <BlackBox
              to="/sport-details?time=Next 4 Hours&show=true"
              icon={`${AWS_BUCKET_URL}/Icons/sandwatchicon.svg`}
              text1={t('pages.sport.next')}
              text2={t('pages.sport.4Hours')}
            />
          </div>
          <div className="col-4">
            <BlackBox
              to="/sport-details?time=Today&show=true"
              icon={`${AWS_BUCKET_URL}/Icons/todayicon.svg`}
              text2={t('today')}
            />
          </div>
          <div className="col-4">
            <BlackBox
              to="/sport-details?time=Tomorrow&show=true"
              icon={`${AWS_BUCKET_URL}/Icons/todayicon.svg`}
              text2={t('tomorrow')}
            />
          </div>
          <div className="col-4">
            <BlackBox
              to="/sport-details?time=Upcoming Live Matches&show=true"
              icon={`${AWS_BUCKET_URL}/Icons/todayicon.svg`}
              text1={t('pages.sport.upcoming')}
              text2={t('pages.sport.liveMatches')}
            />
          </div>
          <div className="col-4">
            <BlackBox
              to="/sport-details?time=Weekend&show=true"
              icon={`${AWS_BUCKET_URL}/Icons/todayicon.svg`}
              text2={t('weekend')}
            />
          </div>
        </div>
        {listItems.map((listItem, index) => (
          <div key={index}>
            <h6 className="text-uppercase text-muted fs-7">{listItem.title}</h6>
            <div className="">
              {listItem.items
                .filter((sportType) => counts[sportType] && Object.keys(counts[sportType]).length)
                .map((sportType) => (
                  <SportListItem
                    key={sportType}
                    to={`/sport-details/${sportType}`}
                    text={t(`sportType.${sportType}`)}
                    count={calculateSportCount(counts, sportType)}
                    icon={sportType.toLowerCase()}
                  />
                ))}
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
}
export default SportMobile;
