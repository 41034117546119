import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormControl, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TitleHistoryBack from 'components/general/TitleHistoryBack';
import { errorToaster, showToaster } from 'mixin/general';
import FetchButton from 'components/general/FetchButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from 'stores/auth';
import { setTotal } from 'stores/betSlip';
import { useMediaQuery } from 'react-responsive';
import LeftSidebar from 'views/home/_desktop/left_sidebar';
import RightSidebar from 'views/home/_desktop/right_Sidebar';
import { useNavigate } from 'react-router-dom';

export default function ProfileSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, userSettings } = useSelector((state) => state.auth);
  const { t } = useTranslation('translations');
  // const [stake, setStake] = useState(10);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    defaultStake: 10,
    presetStake1: 10,
    presetStake2: 20,
    presetStake3: 30,
    presetStake4: 40,
    presetStake5: 50
  });
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });

  // const Increment = () => {
  //   setStake(stake + 1);
  // };

  // const Decrement = () => {
  //   setStake(stake - 1);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setTotal(formData.defaultStake));
  }, [formData.defaultStake]);

  useEffect(() => {
    if (userSettings && user) {
      const userData = { ...formData };
      Object.keys(userSettings).forEach((field) => {
        if (Object.keys(userData).indexOf(field) > -1 && userSettings[field]) {
          userData[field] = userSettings[field];
        }
      });
      if (user.id) {
        userData.id = user.id;
      }
      setFormData({ ...userData });
    }
  }, [userSettings, user]);

  const handleFormDataChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (!form.checkValidity()) throw new Error('Please fill all required fields');
  };

  const handleEditSettings = async (e) => {
    try {
      e.preventDefault();
      handleSubmit(e);
      setIsSubmitting(true);
      const response = await dispatch(updateSettings(formData)).unwrap();
      if (response.statusCode !== 200) {
        throw response.errors;
      }
      showToaster(t('pages.settings.successMessageSettings'), 'success');
    } catch (error) {
      errorToaster(error);
    }
    setIsSubmitting(false);
  };

  const getData = () => {
    return (
      <div>
        {' '}
        <Row className="mt-n3">
          <Col>
            <h3 className="float-start mt-3 text-color-green">{t('profilesettings')}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 className={`float-start opacity-50 ${isDesktop ? 'text-white' : 'text-muted '}`}>
              {t('general')}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 className={`float-start opacity-50 ${isDesktop ? 'text-white' : 'text-muted '}`}>
              {t('language')}
            </h6>
            <h6 className="float-end opacity-50 text-white">English</h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 className={`float-start opacity-50 ${isDesktop ? 'text-white' : 'text-muted '}`}>
              {t('oddsdisplay')}
            </h6>
            <h6 className="float-end text-white opacity-50 ">European Odds</h6>
          </Col>
        </Row>
        <hr className="m-0"></hr>
        <Row>
          <Col>
            <h6 className={`float-start opacity-50 ${isDesktop ? 'text-white' : 'text-muted '}`}>
              {t('sportsbook')}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 className={`float-start ${isDesktop ? 'text-white' : ''}`}>
              {t('ordermatchesbytime')}
            </h6>
            <Form.Check type="switch" id="custom-switch-show-balance" className="float-end" />
          </Col>
        </Row>
        <hr className="m-0"></hr>
        <>
          <Row>
            <Col>
              <h6 className={`float-start opacity-50 ${isDesktop ? 'text-white' : 'text-muted '}`}>
                {t('notifications')}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className={`float-start ${isDesktop ? 'text-white' : ''}`}>
                {t('notifications')}
              </h6>
              <div className="float-end">
                <Form.Check type="switch" id="custom-switch-show-balance" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="float-start">
                <h6 className="text-info">{t('resetallnotifications')}</h6>
              </div>
            </Col>
          </Row>
        </>
        <>
          <Row>
            <Col>
              <h6 className={`float-start opacity-50 ${isDesktop ? 'text-white' : 'text-muted '}`}>
                {t('oddschanges')}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className={`float-start ${isDesktop ? 'text-white' : ''}`}>
                {t('acceptoddschanges')}
              </h6>
              <div className="float-end">
                <Form.Check type="switch" id="custom-switch-show-balance" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="float-start">
                <h6 className="text-white opacity-50">{t('highestoddsarealwaysaccepted')}</h6>
              </div>
            </Col>
          </Row>
        </>
        <Row>
          <Col>
            <h6 className="float-start text-white opacity-50">{t('stakesettings')}</h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 className={`float-start ${isDesktop ? 'text-white' : ''}`}>
              {t('standartstake')} (EUR)
            </h6>
          </Col>
        </Row>
        <Row className="pe-3">
          <Col xs={6}>
            <FormControl
              placeholder={t('standartstake')}
              value={formData.defaultStake}
              onChange={(e) => handleFormDataChange('defaultStake', Number(e.target.value))}
            />
          </Col>
          <Col xs={3}>
            <Button
              variant="border border-left-green rounded text-color-green w-100 h-100 "
              onClick={() =>
                handleFormDataChange('defaultStake', Number(formData.defaultStake - 1))
              }>
              -
            </Button>
          </Col>
          <Col xs={3}>
            <Button
              variant="border border-left-green rounded text-color-green justify-content-center w-100 h-100"
              onClick={() =>
                handleFormDataChange('defaultStake', Number(formData.defaultStake + 1))
              }>
              +
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6
              className={`float-start opacity-50 mt-0 ${isDesktop ? 'text-white' : 'text-muted '}`}>
              Min 1,00, Max 4,0000,00
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 className={`float-start ${isDesktop ? 'text-white' : ''}`}>
              {t('numpadpresetstakes')}
            </h6>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="g-2">
            <FormControl
              value={formData.presetStake1}
              onChange={(e) => handleFormDataChange('presetStake1', Number(e.target.value))}
            />
          </Col>
          <Col className="g-2">
            <FormControl
              value={formData.presetStake2}
              onChange={(e) => handleFormDataChange('presetStake2', Number(e.target.value))}
            />
          </Col>
          <Col className="g-2">
            <FormControl
              value={formData.presetStake3}
              onChange={(e) => handleFormDataChange('presetStake3', Number(e.target.value))}
            />
          </Col>
          <Col className="g-2">
            <FormControl
              value={formData.presetStake4}
              className=" g-3"
              onChange={(e) => handleFormDataChange('presetStake4', Number(e.target.value))}
            />
          </Col>
          <Col className="g-2">
            <FormControl
              value={formData.presetStake5}
              onChange={(e) => handleFormDataChange('presetStake5', Number(e.target.value))}
            />
          </Col>
        </Row>
        <div>
          <FetchButton className="w-100" type="submit" variant="success" fetching={isSubmitting}>
            {t('save')}
          </FetchButton>
        </div>
      </div>
    );
  };

  if (isDesktop) {
    return (
      <div className="container-fluid py-2">
        <div className="row flex-nowrap">
          <Col xs={2} className="filter-sidebar" onClick={() => navigate('/sport')}>
            <LeftSidebar />
          </Col>
          <Col xs={7} className="desktop-main">
            <Form noValidate validated={validated} onSubmit={handleEditSettings}>
              {getData()}
            </Form>
          </Col>
          <Col xs={3}>
            <RightSidebar />
          </Col>
        </div>
      </div>
    );
  } else {
    return (
      <section className="py-2 text-start">
        <Container>
          <Form noValidate validated={validated} onSubmit={handleEditSettings}>
            <TitleHistoryBack title={t('profilesettings')} subTitle={t('myprofile')} />
            {getData()}
          </Form>
        </Container>
      </section>
    );
  }
}
