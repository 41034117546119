import { useEffect, useRef, useState } from 'react';

//type TTimerMode = 'Count_Up' | 'Count_Down';

// Counts up from 0 to infinity and calls onTimerTick every interval
const useTimer = (
  onTimerTick,
  onTimeOut,
  interval,
  stopOn = Number.MAX_SAFE_INTEGER,
  mode = 'Count_Up',
  enableOnMount = false
) => {
  const _interval = Math.max(interval, 100);

  const _timerRef = useRef(null);
  const _timeRef = useRef(0);

  const [enabled, setEnabled] = useState(enableOnMount);

  const _initialTimeRef = useRef(mode === 'Count_Up' ? 0 : stopOn);

  const stop = () => {
    _timeRef.current = 0;
    setEnabled(false);

    if (_timerRef.current) {
      clearInterval(_timerRef.current);
      _timerRef.current = null;
    }
  };

  const start = (initialTime) => {
    stop();

    setEnabled(true);
    _initialTimeRef.current = initialTime;
    _timeRef.current = initialTime;

    _timerRef.current = setInterval(() => {
      _timeRef.current += mode === 'Count_Up' ? _interval : -_interval;
      onTimerTick(_timeRef.current);

      if (
        (mode === 'Count_Up' && _timeRef.current >= stopOn) ||
        (mode === 'Count_Down' && _timeRef.current <= 0)
      ) {
        onTimeOut();
        stop();
      }
    }, _interval);
  };

  const reset = (initialTime) => {
    stop();
    start(initialTime ?? _initialTimeRef.current);
  };

  // Stop the timer when the component unmounts
  // Just to be safe
  useEffect(() => {
    if (enableOnMount) {
      start(_initialTimeRef.current);
    }

    return () => {
      stop();
    };
  }, [enableOnMount]);

  return Object.freeze({ stop, start, reset, enabled });
};

export default useTimer;
