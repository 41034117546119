import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { fetchUser } from 'stores/auth';
import { fetchSportCountsWithHours, updateLiveFootballEventsTime } from 'stores/match';
import routes from 'router/index';
import Footer from 'layout/Footer';
import Header from 'layout/Header';
import FooterMenu from 'layout/FooterMenu';
import useReloadHelper from './helper/useReloadHelper';
import { useMakeHubConnection } from 'helper/hubconnection';
import { showToaster } from 'mixin/general';
import { getNotificationsReq } from 'stores/auth/services';
//import './Blue.css';
import './ForzaApp.css';
import 'styles/index.scss';
import { setInterceptors } from 'libraries/axios';
import { getBetSlipMatches } from 'stores/betSlip';
import { useMediaQuery } from 'react-responsive';
import useTimer from 'toolkit/useHooks/useTimer';

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useMakeHubConnection();
  useReloadHelper();
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });

  const liveUpdateTimer = useTimer(
    () => {
      dispatch(updateLiveFootballEventsTime());
    },
    () => {},
    10000
  );

  const getData = async () => {
    try {
      setLoading(true);
      await dispatch(getBetSlipMatches()).unwrap();
      const promises = [
        dispatch(fetchSportCountsWithHours()).unwrap(),
        dispatch(fetchUser()).unwrap()
      ];
      await Promise.allSettled(promises);
    } catch (error) {
      // error
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
    fetchNotifications();
    liveUpdateTimer.reset();
  }, []);

  function typeEnumToString(enumValue) {
    switch (enumValue) {
      case 1:
        return 'info';
      case 2:
        return 'warning';
      case 3:
        return 'error';
      case 4:
        return 'success';
      default:
        return 'info';
    }
  }

  const fetchNotifications = async () => {
    try {
      const response = await getNotificationsReq();
      const data = await response.data;
      // get localization from storage
      let notificationIds = localStorage.getItem('notifications');
      let diff = [];
      const lang = localStorage.getItem('i18nextLng').split('-')[0];
      // get difference between notification ids and data and remove only different ids from localstorage
      if (notificationIds) {
        const ids = notificationIds.split(',');
        diff = ids.filter((x) => !data.map((y) => y.id).includes(x));
        if (diff.length > 0) {
          // remove differences from notificationIds
          notificationIds = ids.filter((x) => !diff.includes(x)).join(',');
        }
      }

      data.find((entityItem) => {
        if (notificationIds && notificationIds.includes(entityItem.id)) return;

        entityItem.texts.find((item) => {
          if (item.languageKey === lang) {
            showToaster(item.text, typeEnumToString(entityItem.type), false);
          }
        });
        notificationIds = notificationIds ? notificationIds + ',' + entityItem.id : entityItem.id;
      });
      // set localstorage
      localStorage.setItem('notifications', notificationIds);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  if (loading) return null;

  return (
    <div className="App">
      <Header />
      {/* content area */}
      <main className={isDesktop ? 'bg-dark-body' : ''}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
        </Routes>
      </main>
      <ToastContainer />
      <Footer />
      <FooterMenu />
    </div>
  );
}

export default withTranslation()(App);
