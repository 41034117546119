import { Link } from 'react-router-dom';
import { AWS_BUCKET_URL } from '../toolkit/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

function SportListItem(props) {
  const { to, text, count, itemName, icon } = props;
  const { t } = useTranslation('translations', { useSuspense: false });
  const categoryNames = useSelector((state) => state.match.categoryNames);

  let categoryText = text;

  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });

  const code = itemName?.split(':')[2];
  if (t(`categories.${itemName}`) === categoryText && categoryNames) {
    categoryText = categoryNames[itemName];
  }

  if (location.pathname != '/sport' && categoryText && location.pathname != '/results/true') {
    if (categoryText.includes(' ')) {
      categoryText = categoryText.replaceAll(' ', '');
    }
    categoryText = t(`categories.${categoryText}`);
  }

  return (
    <>
      <div className="row">
        <div className="col-1">
          {code ? (
            <div>
              <img
                width="20"
                height="13"
                src={`${AWS_BUCKET_URL}/ClientFlags/${code}.svg`}
                alt={code}
              />
            </div>
          ) : icon ? (
            <div>
              <img width="22" height="22" src={`${AWS_BUCKET_URL}/Icons/${icon}.svg`} alt={icon} />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="col-11">
          <Link to={to}>
            <div className="d-flex justify-content-between">
              <span className={`col-7 fs-6 ${isDesktop ? 'text-white' : 'text-dark'} `}>
                {categoryText}
              </span>
              <div className="text-color col-auto">
                {count == 0 ? <span></span> : <span className="me-2">{count}</span>}
                <span>
                  <img
                    width="20"
                    height="20"
                    className={isDesktop ? 'icon-white' : null}
                    src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`}
                  />
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <hr className="my-2" />
    </>
  );
}

export default SportListItem;
