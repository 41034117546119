export default function TR() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <h5 className="text-danger mt-75">BİZİMLE İLETİŞİME GEÇMEK İSTER MİSİNİZ?</h5>
      <p>
        Burada iletişim bilgilerimizi bulacaksınız. Destek formumuzu kullanarak, posta yoluyla veya
        e-posta yoluyla bizimle iletişime geçebilirsiniz.
      </p>
      <h5 className="text-danger mt-3">Adres</h5>
      <p>
        Game90 Ltd. 1. Kat Melita Court Giuseppe Cali Caddesi C/W Abate Rigord Caddesi XBX 1420
        Ta’Xbiex Malta E-posta: support@game90.bet Bu web sitesinin Alman Medya hukuku sorumlusu:
        Simon Sullivan
      </p>
      <h5 className="text-danger mt-3">Lisans</h5>
      <p>
        Almanca lisansı: <br />
        Federal Devletlerin Ortak Oyun Otoritesi&apos;nin (GGL) denetimi altında resmi olarak
        onaylanmış spor bahis operatörü. Game90 Ltd.&apos;ye internette ve sabit alanlarda spor
        bahisleri düzenleme resmi izni (ilk kez) 19 Kasım 2020 tarihli kararla verildi; bu, 22
        Aralık 2022&apos;de uzatıldı (GGL beyaz listesi).
      </p>
    </div>
  );
}
