import { Row, Col } from 'react-bootstrap';
export default function DE() {
  return (
    <div className="py-2 text-start me-1 ms-1">
      <div>
        <h5 className="text-danger fw-bolder mt-75"> KONTAKT</h5>
        <span className="fw-bolder mt-3">Du willst uns kontaktieren?</span> <br />
        Kein Problem. Schreib unseren Support über eine der folgenden Möglichkeiten an.
        <p className="mt-75 text-danger fw-bolder">Unser support</p>
        <p>Wir sind täglich von 10:00 Uhr bis 20:00 Uhr für euch erreichbar.</p>
      </div>
      <Row>
        <Row>
          <Col className="text-danger" xs={4}>
            Schreibt uns an diese E-Mail-Adresse: <hr />
          </Col>
          <Col className="text-danger" xs={4}>
            Schickt uns ein Fax an diese Nummer: <hr />
          </Col>
          <Col className="text-danger" xs={4}>
            Nutzt unseren Chat <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>support@game90.bet</Col> <Col xs={4}>+356 2276 9002</Col> <Col xs={4}></Col>
        </Row>
      </Row>
      <div className="mt-75">
        <h5 className="text-danger">Unsere Anschrift</h5>
        <span>
          Game90 Ltd.
          <br />
          1st Floor of Melita Court
          <br />
          Giuseppe Cali St, c/w Abate Rigord St
          <br />
          XBX1420 TaXbiex
          <br />
          Malta
          <br />
          <br />
          E-mail: <br />
          support@game90.bet
          <br />
          <br />
          Lizenz:
          <br />
          Game90 Ltd. (Handelsregisternummer C50436) ist durch die Malta Gaming Authority für Remote
          Gaming lizenziert (MGA/B2C/191/2010)
        </span>
      </div>
    </div>
  );
}
