import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventsBySport, fetchSportCounts } from 'stores/match';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { AWS_BUCKET_URL, sports as sportsObj } from 'toolkit/constants';
import {
  categoryNameHelper,
  checkIfLiveEvent,
  desktopDefaultMarkets,
  getSportTypeToIcon
} from 'toolkit/utils';
import MatchCard from 'components/general/match_card.js';
import eventsToGroup from 'toolkit/eventsToGroup';
import Loading from 'components/general/loading';
import { getSportTypeToWhiteIcon, getSportTypeToGreenIcon } from 'toolkit/utils';

export default function Today({ onMatchTrackerSet, onMatchDetailIdSet }) {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations');
  const swiperRef = useRef();
  const categoryNames = useSelector((state) => state.match.categoryNames);
  const [page, setPage] = useState(1);
  let showMoreButton = false;
  const events = useSelector(
    (state) => {
      showMoreButton = state.match.filteredEventIds.length > page * 10;
      return state.match.filteredEventIds.map((providerId) => state.match.events[providerId]);
    },
    (a, b) => a.length === b.length
  );
  const counts = useSelector((state) => {
    return state.match.timeCounts;
  });
  const [sport, setSport] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTimeCounts = () => {
    dispatch(fetchSportCounts({ dateFilter: '1' }));
  };

  const sliderSettings = {
    440: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    680: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 10
    }
  };

  const getSportEvents = async () => {
    try {
      setLoading(true);
      const params = {
        Sport: sport,
        dateFilter: '1'
      };
      await dispatch(fetchEventsBySport(params)).unwrap();
    } catch (error) {
      // error
    }
    setLoading(false);
  };

  useEffect(() => {
    getTimeCounts();
  }, []);

  useEffect(() => {
    const tempGroups = eventsToGroup(events);
    if (sport !== null) {
      setSelectedGroup(tempGroups[sport]);
    }
  }, [events]);

  useEffect(() => {
    getSportEvents();
  }, [sport]);

  const renderGroupMatches = () => {
    if (selectedGroup)
      return (
        <div className="desktop-league">
          {selectedGroup
            .filter((g) => g.matches.every((m) => !checkIfLiveEvent(m)))
            .map((leagueGroup, index) => (
              <div
                className="mb-3 mt-3 d-flex flex-column gap-2"
                key={leagueGroup.category + leagueGroup.league + index}>
                <div className="desktop-league-header h6 fw-bold">
                  <span className="me-2">
                    {getSportTypeToIcon(sport || 0)}
                    {/* <img width="16" height="16" src={`${AWS_BUCKET_URL}/Icons/football.svg`} /> */}
                  </span>
                  <span className="text-white">
                    {categoryNameHelper(
                      leagueGroup.category,
                      categoryNames,
                      t(`categories.${leagueGroup.category}`)
                    )}
                  </span>
                  <span className="me-2 ms-2 fw-bold text-truncate"> {leagueGroup.league}</span>
                  {index === 0 && desktopDefaultMarkets[Number(sport || 0)] && (
                    <div className="ms-auto bets-header-wrapper">
                      {desktopDefaultMarkets[Number(sport || 0)].map((m) => (
                        <span className="bet-group-header" key={m}>
                          {t('markets.' + m)}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                {leagueGroup.matches
                  .filter((l) => !checkIfLiveEvent(l))
                  .map((match) => (
                    <MatchCard
                      key={match.providerId}
                      providerId={match.providerId}
                      path={location.pathname}
                      desktop={true}
                      desktopMarkets={desktopDefaultMarkets[sport || 0]}
                      hideMarketLabel={true}
                      onMatchTrackerSet={onMatchTrackerSet}
                      onMatchDetailIdSet={onMatchDetailIdSet}
                    />
                  ))}
              </div>
            ))}
          <div className="flex-center">
            {showMoreButton && (
              <button
                className="btn text-color-green"
                onClick={() => {
                  setPage(page + 1);
                }}>
                {t('pages.desktop.show_more')}
              </button>
            )}
          </div>
        </div>
      );
  };

  return (
    <>
      <div className="row justify-content-between align-items-center pt-5">
        <div className="col-auto">
          <h3 className="text-color-green text-start">{t('pages.desktop.filterSidebar.today')}</h3>
        </div>
        <div className="col-1">
          <button
            className="card card-body bg-button-dark align-items-center gap-2 border-2"
            onClick={() => swiperRef.current?.slidePrev()}>
            <img
              className="icon-white"
              width="18"
              height="25"
              src={`${AWS_BUCKET_URL}/Icons/backicon-caret.svg`}
            />
          </button>
        </div>
        <div className="col-8">
          <Swiper
            slidesPerView={3}
            breakpoints={sliderSettings}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}>
            {Object.keys(counts).map((sportKey, index) => (
              <SwiperSlide key={index}>
                {() => {
                  const swiper = useSwiper();
                  return (
                    <div
                      className={`card card-body bg-button-dark  flex-center gap-2 p-2 border-2 ${
                        sport === sportsObj[sportKey] ? 'text-color-green' : 'text-white'
                      }`}
                      onClick={() => {
                        setSport(sportsObj[sportKey]);
                        swiper.slideTo(index);
                      }}>
                      {sport === sportsObj[sportKey]
                        ? getSportTypeToGreenIcon(sportKey)
                        : getSportTypeToWhiteIcon(sportKey)}
                      <h6 className="small mb-0 text-truncate">
                        {t(`sports.${sportsObj[sportKey]}`)}
                      </h6>
                    </div>
                  );
                }}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="col-1">
          <button
            className="card card-body bg-button-dark align-items-center gap-2 border-2"
            onClick={() => swiperRef.current?.slideNext()}>
            <img
              className="icon-white"
              width="18"
              height="25"
              src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`}
            />
          </button>
        </div>
      </div>
      <hr />
      <div className="desktop-league">
        {loading ? <Loading message={t('search.loadingMatches')} /> : renderGroupMatches()}
      </div>
    </>
  );
}
