import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { currencyFormatter, composeEventName } from 'toolkit/utils';

export const useCategoryLimitValidation = () => {
  const { t } = useTranslation('translations', { useSuspense: false });
  const { user } = useSelector((state) => state.auth);
  const betSlip = useSelector((state) => state.betSlip);
  const _currencyFormatter = currencyFormatter(betSlip.currencyCode, 2);
  const customerLimits = user.customerLimitDto;
  const limits = customerLimits;

  const fixedCategoryLimits = {
    A: 3000,
    B: 1000,
    C: 800,
    D: 500,
    E: 250
  };

  const customerOrProductLimits = {
    A: limits.categoryLimitA,
    B: limits.categoryLimitB,
    C: limits.categoryLimitC,
    D: limits.categoryLimitD,
    E: limits.categoryLimitE
  };

  const checkLimitForEvent = (totalStake, event) => {
    const isLive = event.eventStatus === 1 ? true : false;
    const category = event.limitCategory;
    const customerOrProductLimit = customerOrProductLimits[category];
    const customerOrProductFixedLimit = fixedCategoryLimits[category];

    const calculatedLimit = isLive
      ? Math.min(customerOrProductLimit, customerOrProductFixedLimit)
      : customerOrProductLimit;
    const hasLimit = totalStake <= calculatedLimit;

    if (!hasLimit) {
      return {
        text: t('categoryLimit', {
          game: composeEventName(event, t),
          limit: _currencyFormatter.format(calculatedLimit)
        }),
        type: 'error',
        valid: false
      };
    }
    return {
      text: '',
      type: 'none',
      valid: true
    };
  };

  const validateBetSlipCategoryLimit = (betSlipEvents, stake) => {
    if (!limits) {
      return {
        text: t('noLimits'),
        type: 'warning',
        valid: false
      };
    }
    const activeOutcomes = betSlip.bets
      .filter((o) => o.enabled)
      .map((b) => {
        return {
          ...b,
          match: b,
          enabled: b.enabled
        };
      });

    for (const outcome of activeOutcomes) {
      const event = betSlipEvents[outcome.providerId];
      if (!event) {
        continue;
      }
      const limitValidation = checkLimitForEvent(stake, event);
      if (!limitValidation.valid) {
        return limitValidation;
      }
    }

    return {
      text: '',
      type: 'none',
      valid: true
    };
  };

  return { validateBetSlipCategoryLimit };
};
