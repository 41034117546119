import { useState } from 'react';
import { Button, Card, Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
import { AWS_BUCKET_URL } from 'toolkit/constants';

export default function SlotItem({ provider, games }) {
  const [open, setOpen] = useState(true);

  const swiperOptions = {
    slidesPerView: 3,
    loop: true,
    spaceBetween: 10,
    pagination: {
      clickable: true
    },
    mousewheel: true,
    modules: [Mousewheel]
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Button
            className="w-100 flex-center gap-2 text-capitalize p-0"
            variant=""
            onClick={() => setOpen(!open)}
            aria-controls={`${provider}-text`}
            aria-expanded={open}>
            <span>{provider}</span>
            <span>
              <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/${open ? 135 : 134}.png`} />
            </span>
          </Button>
        </Card.Header>
        <Card.Body
          style={{ backgroundColor: '#171717', color: 'white' }}
          className={`${!open ? 'p-0 border-0' : ''}`}>
          <Collapse in={open}>
            <div id={`${provider}-text`}>
              <Swiper {...{ ...swiperOptions }}>
                {games
                  .reduce((accumulator, _, currentIndex, array) => {
                    if (currentIndex % 2 === 0) {
                      accumulator.push(array.slice(currentIndex, currentIndex + 2));
                    }
                    return accumulator;
                  }, [])
                  .map((gameArr, i) => (
                    <SwiperSlide key={i} className="h-auto">
                      <div
                        className="d-flex flex-column gap-3"
                        style={{ minHeight: '20rem', height: '20rem' }}>
                        {gameArr.map((game) => (
                          <Link
                            className="h-50 d-flex flex-column"
                            key={game.id_game}
                            to={{
                              pathname: '/slot-game',
                              hash: `#${game.id_game}-${game.isGameExtra}`
                            }}>
                            <img className="img-fluid w-100 rounded h-75" src={game.image} alt="" />
                            <span className="text-center fs-7" style={{ color: 'white' }}>
                              {game.display_name}
                            </span>
                          </Link>
                        ))}
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </Collapse>
        </Card.Body>
      </Card>
    </>
  );
}
