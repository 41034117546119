import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import eventsToGroup from 'toolkit/eventsToGroup';
import MatchCard from 'components/general/match_card.js';
import {
  categoryNameHelper,
  checkIfLiveEvent,
  desktopDefaultMarkets,
  getSportTypeToIcon,
  tournamentNameHelper
} from 'toolkit/utils';
import Loading from 'components/general/loading';

const MatchContainer = (props) => {
  const { t } = useTranslation('translations');
  const { onMatchTrackerSet, onMatchDetailIdSet, loading } = props;
  const desktopSearchParams = useSelector((state) => state.match.desktopSearchParams);
  const categoryNames = useSelector((state) => state.match.categoryNames);
  const tournamentNames = useSelector((state) => state.match.tournamentNames);
  const [page, setPage] = useState(1);
  let showMoreButton = false;
  const events = useSelector(
    (state) => {
      if (
        desktopSearchParams.mode === 'clearFavorites' ||
        desktopSearchParams.mode === 'topMatches'
      ) {
        const matches = state.match[desktopSearchParams.mode];
        showMoreButton = matches.length > page * 10;
        return matches.map((providerId) => state.match.events[providerId]);
      }
      const matches = state.match.filteredEventIds
        .map((providerId) => state.match.events[providerId])
        .filter((e) => !!e)
        .filter((event) => {
          if (desktopSearchParams.Tournament)
            return event.leagueId === desktopSearchParams.Tournament;
          if (desktopSearchParams.Category)
            return event.categoryId === desktopSearchParams.Category;
          if (desktopSearchParams.Sport !== null) return event.sport === desktopSearchParams.Sport;
          return true;
        });
      showMoreButton = matches.length > page * 10;
      return matches.slice(0, page * 10);
    },
    (a, b) => _.isEqual(a, b)
  );
  const [selectedGroup, setSelectedGroup] = useState([]);

  useEffect(() => {
    const tempGroups = eventsToGroup(events);
    if (desktopSearchParams.Sport !== null) {
      setSelectedGroup(tempGroups[desktopSearchParams.Sport]);
    } else if (
      desktopSearchParams.mode === 'clearFavorites' ||
      desktopSearchParams.mode === 'topMatches'
    ) {
      setSelectedGroup(tempGroups[0]);
    }
  }, [events, desktopSearchParams]);

  useEffect(() => {
    setPage(1);
  }, [desktopSearchParams]);

  const title = () => {
    const { mode, Sport, Category, Tournament } = desktopSearchParams;
    if (mode === 'topMatches' || mode === 'clearFavorites') {
      return t(`pages.start.${mode === 'topMatches' ? 'titleTopMatches' : 'titleClearFavorites'}`);
    }
    return `${t(`sports.${Sport}`)}${
      Category
        ? ` - ${categoryNameHelper(Category, categoryNames, t(`categories.${Category}`))}`
        : ''
    }${Tournament ? ` - ${tournamentNameHelper(Tournament, tournamentNames, t)}` : ''}`;
  };

  const renderGroupMatches = () => {
    if (selectedGroup)
      return (
        <div className="desktop-league">
          {selectedGroup
            .filter((g) => g.matches.every((m) => !checkIfLiveEvent(m)))
            .map((leagueGroup, index) => (
              <div
                className="mb-3 mt-3 d-flex flex-column gap-2"
                key={leagueGroup.category + leagueGroup.league + index}>
                <div className="desktop-league-header h5 fw-bold">
                  <span className="me-2 icon-color icon-white">
                    {getSportTypeToIcon(desktopSearchParams.Sport || 0)}
                    {/* <img width="16" height="16" src={`${AWS_BUCKET_URL}/Icons/football.svg`} /> */}
                  </span>
                  <span className="text-white">
                    {categoryNameHelper(
                      leagueGroup.category,
                      categoryNames,
                      t(`categories.${leagueGroup.category}`)
                    )}
                  </span>
                  <span className="me-2 ms-2 fw-bold text-truncate"> {leagueGroup.league}</span>
                  {index === 0 && desktopDefaultMarkets[Number(desktopSearchParams.Sport || 0)] && (
                    <div className="ms-auto bets-header-wrapper">
                      {desktopDefaultMarkets[Number(desktopSearchParams.Sport || 0)].map((m) => (
                        <span className="bet-group-header" key={m}>
                          {t('markets.' + m)}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                {leagueGroup.matches
                  .filter((l) => !checkIfLiveEvent(l))
                  .map((match) => (
                    <MatchCard
                      key={match.providerId}
                      providerId={match.providerId}
                      path={location.pathname}
                      desktop={true}
                      desktopMarkets={desktopDefaultMarkets[desktopSearchParams.Sport || 0]}
                      hideMarketLabel={true}
                      onMatchTrackerSet={onMatchTrackerSet}
                      onMatchDetailIdSet={onMatchDetailIdSet}
                    />
                  ))}
              </div>
            ))}
          <div className="flex-center">
            {showMoreButton && (
              <button
                className="btn text-color-green"
                onClick={() => {
                  setPage(page + 1);
                }}>
                {t('pages.desktop.show_more')}
              </button>
            )}
          </div>
        </div>
      );
  };

  if (loading) return <Loading message={t('search.loadingMatches')} />;

  return (
    <div className="">
      <h3 className="text-color-green">{title()}</h3>
      <hr />
      {renderGroupMatches()}
    </div>
  );
};

export default MatchContainer;
