import { Tabs, Tab } from 'react-bootstrap';
export default function DE() {
  return (
    <Tabs justify className="mb-4 py-2 text-start me-1 ms-1">
      <Tab eventKey="overview" className="py-2 text-start me-1 ms-1" title="Übersicht">
        <h2 className="text-danger">ÜBERSICHT</h2>
        <p className="mt-3">
          &quot;Folge deinem Instinkt&quot;. Unser Unternehmensmotto charakterisiert genau die
          Eigenschaft, die euch beim Wetten auszeichnet: das Gefühl, auf das Richtige zu setzen.
          Folge auch du deinem Instinkt und profitiere vom großen Wettangebot, schnellster
          Quotenaktualisierung und innovativen Wettarten. Du willst mehr über Game90 erfahren? Hier
          gibt’s die wichtigsten Infos für’s Wetten bei Game90. <br />
          <br />
          <b> Zusätzliche Spielrelevante Informationen. </b>
        </p>
        <p className="mt-3">
          1. Alle Kosten, die mit der Teilnahme veranlasst sind,
          <br />
          <br /> Mit der Teilnahme an einer Sportwette auf der game90.bet sind außer dem Wetteinsatz
          sowie der Wettsteuer keine weiteren Kosten verbunden.
        </p>
        <p className="mt-75">
          2. die Höhe aller Gewinne,
          <br />
          <br />
          Der Höchstgewinn berechnet sich aus dem Wetteinsatz x Wettquote.
        </p>
        <p className="mt-75">
          3. wann und wo alle Gewinne veröffentlicht werden,
          <br />
          <br /> Gewinne werden im Spielerkonto unter &quot;Meine Wetten&quot; veröffentlich und
          alle Spielergebnisse lassen sich unter game90.bet//results einsehen
        </p>
        <p className="mt-75">
          4. der Prozentsatz der Auszahlungen für Gewinne vom Einsatz (Auszahlungsquote),
          <br />
          <br /> Eine fixe Auszahlungsquote existiert nicht bei Sportwetten, da es sich um
          ergebnisorientierte Ereignisse handelt.
        </p>
        <p className="mt-75">
          5. Informationen zu den Gewinn- und Verlustwahrscheinlichkeiten,
          <br />
          <br /> Anders als bei Casinospielen sind die tatsächlichen Wahrscheinlichkeiten über den
          Ausgang eines Sportereignisses nicht bekannt. Der Wettanbieter bietet zu diesen
          Ereignissen individuelle Quoten mit impliziten Wahrscheinlichkeiten an. Die mögliche
          Gewinnsumme wird vor Wettabgabe dem Kunden angezeigt und ebenso auf dem Wettschein
          ausgegeben. Bei der Angabe der Quote legt die Game90 Ltd. Quotenschlüssel zwischen 103%
          und 117% zu Grunde. Ein Quotenschlüssel von z.B. 105% besagt, dass wenn ein Spieler bei
          einer Wette auf alle möglichen Ereignisse mit gleichem Betrag wettet, der Spieler
          statistisch (1-100/105) % = 4,76% des Wetteinsatzes verliert
        </p>
        <p className="mt-75">
          6. der Annahmeschluss der Teilnahme,
          <br />
          <br /> Der Annahmeschluss ist der Zeitstempel bei der Wettabgabe.
        </p>
        <p className="mt-75">
          7. das Verfahren, nach dem der Gewinner ermittelt wird, insbesondere die Information über
          den Zufallsmechanismus, der der Generierung der zufallsabhängigen Spielergebnisse zu
          Grunde liegt,
          <br />
          <br /> Die Bewertung eines Spieltipps richtet sich nach dem Ausgang von Sportereignissen.
          Diese Ergebnisse beziehen wir von etablierten Dienstleistern wie Sportsradar, Perform
          Group und Betgenius, sowie von den offiziellen Ergebnisdiensten der jeweiligen Verbände.
          Einen Zufallsmechanismus wie bei Automaten und Casinospielen gibt es bei Sportwetten
          nicht.
        </p>
        <p className="mt-75">
          8. wie die Gewinne zwischen den Gewinnern aufgeteilt werden,
          <br />
          <br /> Eine Aufteilung der Gewinne auf verschiedene Gewinner findet nicht statt. Es gibt
          einen Wettschein, der den Wettvertrag zwischen Wettkunde und Wettveranstalter abbildet.
          Bei Gewinn erhält der Wettkunde den ausgewiesenen Gewinn. Einen sogenannten Totalisator
          wie bei Pferdewetten üblich, gibt es in dem Wettangebot der Game90 Ltd. nicht
        </p>
        <p className="mt-75">
          9. die Ausschlussfrist, bis wann Gewinner Anspruch auf ihren Gewinn erheben müssen,
          <br />
          <br /> Die Gewinne werden nach Eintritt der Wette oder mit dem Ausgang der Sportereignisse
          dem Spielerkonto automatisch gutgeschrieben.
        </p>
        <p className="mt-75">
          10. der Name des Erlaubnisinhabers sowie seine Kontaktdaten (Anschrift, E-Mail, Telefon),
          <br />
          <br /> Game90 Ltd. vertreten durch Herrn Simon Ellul Sullivan mit folgender Firmenadresse:
          1st Floor of Melita Court, Giuseppe Cali St, c/w Abate Rigord St, XBX1420 Ta&apos;Xbiex,
          Malta. Das Unternehmen ist erreichbar unter folgender E-Mail: info@game90.bet
        </p>
        <p className="mt-75">
          11. die Handelsregisternummer (soweit vorhanden),
          <br />
          <br /> Game90 Ltd. ist ein in Malta mit der Handelsregisternummer C50436 eingetragenes
          Unternehmen.
        </p>
        <p className="mt-75">
          12. wie der Spieler Beschwerden vorbringen kann,
          <br />
          <br /> Der Spieler hat die Möglichkeit uns direkt über den Livechat, support@game90.bet zu
          erreichen. Wenn der Spieler mit der Art und Weise, wie die Beschwerde vom
          Game90-Kundendienstteam bearbeitet oder gelöst wurde, nicht zufrieden ist, können der
          Kunde seine Streitigkeiten alternativ der ADR-Stelle (Alternative Dispute Resolution)
          unter folgendem EADR-Link einreichen: https://eadr.org/eadr-form/. Sobald der Spieler die
          endgültige Entscheidung von Game90 erhalten hat, hat er das freie Recht, auf diese ADR
          zuzugreifen und die Streitigkeiten dort weiterzuleiten.
        </p>
        <p className="mt-75">
          13. das Datum der ausgestellten Erlaubnis.
          <br />
          <br /> Internationale Lizenz: Malta Gaming Authority (MGA) Lizenznummer: MGA/B2C/191/2010
          Lizenz erteilt am: 18.12.2011
          <br />
          <br /> Deutsche Lizenz: Behördlich zugelassener Sportwettveranstalter unter Aufsicht der
          Gemeinsame Glücksspielbehörde der Länder (GGL). Der Game90 Ltd. wurde mit Bescheid vom
          19.11.2020 (erstmalig) die behördliche Erlaubnis zur Veranstaltung von Sportwetten im
          Internet und stationären Bereich erteilt, diese wurde am 22.12.2022 verlängert (GGL
          Whitelist).
        </p>
      </Tab>
      <Tab
        eventKey="companyInformation"
        className="py-2 text-start me-1 ms-1"
        title="Unternehmensinformationen">
        <h2 className="text-danger">UNTERNEHMENSINFORMATIONEN</h2>
        <p className="mt-3">
          Game90 wurde 2010 in Malta gegründet, ist dort als Unternehmen registriert und seit 2011
          offiziell als Wettanbieter lizenziert. Die Gründer von Game90 verfügen über eine
          langjährige Wettexpertise und innovative Ideen – bereits 2005 entwickelten sie mit dem
          Konzept der Livewette 0:0 ein völlig neuartiges Format. Die Livewette 0:0, oft auch
          Restzeitwette genannt, ist heute fester Bestandteil jedes Wettanbieters und gewinnt
          international an Bedeutung. Game90 setzt sich in diesem immer rasanter wachsenden Markt
          unter anderem durch ein eigenes Quotenmanagement und die daraus resultierende
          sekundenschnelle Aktualisierung der Quoten von der Konkurrenz ab. Dank der intuitiv
          bedienbaren Nutzeroberfläche wird das Wetten bei Game90 für jedermann zu einem Erlebnis.
          Für einen einfachen, reibungslosen Ablauf, sekundenschnelle Aktualisierungen der Quoten
          und eine sichere Plattform sorgen im Hintergrund ein großes Team aus Entwicklern und eine
          Vielzahl von Quotenspezialisten. Insgesamt arbeiten im Partnerverbund mehr als 200
          Mitarbeiter in ganz Europa sowie über 3.000 Spielbeobachter auf allen Kontinenten
          zusammen, um die Qualität der Quoten und der Plattform zu sichern.
        </p>
        <div>
          <h5 className="text-danger mt-75"> Deine Vorteile mit Game90 </h5>
          <ul>
            <li key={1} className="mt-3">
              <b>Schnell wetten:</b> Livewetten leben von der Schnelligkeit – umso besser, dass es
              bei Game90 die schnellsten Quotenaktualisierungen am Markt gibt.
            </li>
            <li key={2} className="mt-3">
              <b>Stabile Quoten:</b> Dank der permanenten Überwachung sind unsere Quoten merklich
              stabiler und stehen schneller wieder zur Verfügung. So können unmittelbar nach
              Spielereignissen sofort wieder Wetten platziert werden
            </li>
            <li key={3} className="mt-3">
              <b>Kein Ausschluss von Highrollern:</b> Hohe Wetteinsätze sind bei uns kein Tabu. Bei
              Game90 kannst du bis zu EUR 4.000,- pro Wettschein einsetzen.
            </li>
            <li key={4} className="mt-3">
              <b>Großes Wettangebot:</b> Du hast die Wahl – mit Wetten auf bis zu 1.000 Spiele
              täglich.
            </li>
            <li key={5} className="mt-3">
              <b>Sicherheit geht vor:</b> game90.bet entspricht den höchsten Sicherheitsstandards
              und sorgt mit der eigenen Programmierung für kontinuierliche Weiterentwicklung.
            </li>

            <li key={7} className="mt-3">
              <b>Erfahrung und Wissen:</b> Game90 wurde von Profiwettern gegründet, die ihr Know-how
              noch heute in die Produkte und Wettarten von Game90 einfließen lassen.
            </li>
          </ul>
          <br />
        </div>
        <h5 className="text-danger mt-75">Unsere Philosophie</h5>
        <p>
          Unser Anspruch ist es, Wetten so einfach wie möglich zu machen. Somit geben wir unseren
          Kunden mehr Zeit, das Wetten und natürlich die Partien zu genießen. Wir konnten Game90 als
          Marke mit fairen und schnellen Quoten etablieren – denn das sind die Qualitäten, die uns
          als Wettanbieter auszeichnen. Und wir haben noch viel vor: Wir wollen unser Image als
          innovativer Wettanbieter weiter ausbauen, damit noch mehr Sport- und Wettbegeisterte ihrem
          Instinkt folgen und sich für Game90 entscheiden.
        </p>
        <h5 className="text-danger mt-75">Unsere Werte</h5>
        <ul>
          <li key={8} className="mt-3">
            <b>Fairness:</b> Ob Kunde oder Geschäftspartner – wir behandeln alle nach fairen
            Maßstäben.
          </li>
          <li key={9} className="mt-3">
            <b>Kompetenz:</b> Wir wissen, was wir leisten – dank jahrelanger Erfahrung im
            Sportwettenbereich.
          </li>
          <li key={10} className="mt-3">
            <b>Sicherheit:</b> Wir behandeln sensible Kunden- und Partnerdaten mit der allergrößten
            Sicherheit.
          </li>
          <li key={11} className="mt-3">
            <b>Ehrlichkeit: </b> Wir geben ehrliche Antworten – auch wenn diese für uns unangenehm
            sein können.
          </li>
          <li key={12} className="mt-3">
            <b>Hingabe:</b>Wir stecken all unsere Energie in Game90, damit unsere Kunden und Partner
            zufrieden sind.
          </li>
          <li key={13} className="mt-3">
            <b>Zuverlässigkeit</b> Wir halten unsere Versprechen und respektieren Vereinbarungen.
          </li>
        </ul>
        <h5 className="text-danger mt-75">Unsere Lizenz</h5>
        <p className="mt-3">
          Game90 Ltd. ist ein in Malta mit der Handelsregisternummer C50436 eingetragenes
          Unternehmen und mit folgender Firmenadresse registriert: Game90 Ltd., 1st Floor of Melita
          Court, Giuseppe Cali St, c/w Abate Rigord St, XBX1420 Ta&apos;Xbiex, Malta. Game90 Ltd.
          ist durch die Malta Gaming Authority für Remote Gaming lizenziert (MGA/B2C/191/2010).
        </p>
        <h5 className="text-danger mt-75">Deutsche Lizenz</h5>
        <p className="mt-3">
          Behördlich zugelassener Sportwettveranstalter unter Aufsicht der Gemeinsame
          Glücksspielbehörde der Länder (GGL). Der Game90 Ltd. wurde mit Bescheid vom 19.11.2020
          (erstmalig) die behördliche Erlaubnis zur Veranstaltung von Sportwetten im Internet und
          stationären Bereich erteilt, diese wurde am 22.12.2022 verlängert (GGL Whitelist).
        </p>
      </Tab>
      <Tab eventKey="dataProtection" className="py-2 text-start me-1 ms-1" title="Datenschutz">
        <h2 className="text-danger">DATENSCHUTZ</h2>
        <p className="mt-3">
          Game90 nimmt den Schutz deiner persönlichen Daten sehr ernst. Diese Datenschutzrichtlinie
          beschreibt unsere Datenverarbeitungspraktiken sowie deine Auswahlmöglichkeiten
          hinsichtlich der Verwendung deiner personenbezogenen Informationen. Game90 hat gemäß
          Artikel 6 (1)(f) DSGVO ein legitimes berechtigtes und erforderliches Interesse an der
          Speicherung und Verarbeitung von personenbezogenen Daten. Für deine Fragen und Wünsche zum
          Umgang mit deinen persönlichen Daten stehen wir dir gerne unter dpo@game90.bet zur
          Verfügung.
        </p>
        <h5 className="text-danger mt-75">Welche Informationen sammeln wir?</h5>
        <p className="mt-3">
          Wir sammeln die persönlichen Daten der Besucher dieser Website anhand von
          Online-Formularen sowie jedes Mal, wenn du uns deine Daten per E-Mail schickst. Wir
          sammeln auch Informationen (mittels unserer sicheren Zahlungsabwicklung) über die von dir
          vorgenommenen Transaktionen, einschließlich der Daten der verwendeten Kreditkarten.
          Weitere Daten sammeln wir automatisch, wenn du unsere Website aufrufst.
        </p>
        <h5 className="text-danger mt-75">
          Wozu benötigen wir die gesammelten Informationen und wie verwenden wir sie?
        </h5>
        <p>
          Eure Daten, die wir über diese Website sammeln, werden zu folgenden Zwecken verwendet:
        </p>
        <ul>
          <li key={14} className="mt-3">
            Zur Erbringung und individuellen Gestaltung unserer Dienstleistungen
          </li>
          <li key={15} className="mt-3">
            Zur Bearbeitung eurer Fragen und Wünsche
          </li>
          <li key={16} className="mt-3">
            Zur Durchführung von Marktforschungsstudien
          </li>
          <li key={17} className="mt-3">
            Zur Kontaktaufnahme mit euch (per E-Mail) bezüglich unseres Angebots
          </li>{' '}
          <li key={18} className="mt-3">
            Zur Information über unsere Produkte und Leistungen
          </li>
        </ul>
        <p className="mt-3">
          Darüber hinaus geben wir nur mit eurer ausdrücklichen Zustimmung eure Daten weiter an
          Dritte, die Produkte oder Dienstleistungen anbieten, die euch unserer Ansicht nach
          interessieren könnten. Diese Zustimmung könnt ihr bei der Anmeldung durch Anhaken des
          entsprechenden Kästchens geben. Sie kann jederzeit in den Kontoeinstellungen oder per
          E-Mail an dpo@game90.bet widerrufen werden. Zudem findet ihr in jeder E-Mail, die nicht
          der Wettabwicklung auf game90.bet dient, ganz unten einen Link, mit dem ihr eure Adresse
          aus dem Verteiler entfernen können. <br />
          In unseren Datenschutzrichtlinien werden unsere Datenverarbeitungspraxis sowie die
          Optionen hinsichtlich der Nutzung eurer persönlichen Daten geregelt. Wenn ihr Auskünfte
          bezüglich eurer persönlichen Daten wünscht oder Fragen zu diesen Richtlinien habt, dann
          wendet euch bitte an dpo@game90.bet.
        </p>
        <h5 className="text-danger mt-75">
          PCI-Standard – Wir wickeln Zahlungen nachweislich sicher ab.
        </h5>
        <p className="mt-3">
          Game90 Ltd. arbeitet nur mit Zahlungsdienstleistern zusammen, die nach dem
          PCI-Sicherheitsstandard (Payment Card Industry Data Security Standards) agieren. Dadurch
          ist gewährleistet, dass bei der Speicherung und Verarbeitung von Daten die strengen
          PCI-Anforderungen erfüllt werden.
        </p>
      </Tab>
      <Tab eventKey="prevention" className="py-2 text-start me-1 ms-1" title="Prävention">
        <h2 className="text-danger">PRÄVENTION</h2>
        <p className="mt-3">
          Nur wer verantwortungsvoll und bewusst wettet, hat langfristig Spaß daran. Spielen kann
          unterhaltsam und profitabel sein. Es gehört jedoch auch immer eine Portion Glück zum
          Wetterfolg dazu. Aus diesem Grund solltest du kein zu hohes Risiko eingehen und nicht
          unverhältnismäßig viel Geld setzen. Wir möchten, dass du Spaß am Spielen hast und wir
          können dir helfen, dein Spielverhalten korrekt einzuschätzen. Daher haben wir hier einige
          Informationen zur Prävention und zu Hilfestellen zusammengetragen. Game90 kann dich auch
          jederzeit dabei unterstützen, dein Spielverhalten zu regulieren. Bei Fragen und Problemen
          bieten wir Kontakt zu diesen Anlaufstellen. Glücksspiel kann süchtig machen. Spiele stets
          verantwortungsbewusst
        </p>
        <p className="mt-3">
          Für Minderjährige ist die Teilnahme an Glücksspiel jeglicher Art, also auch Sportwetten,
          strengstens verboten. Der Spielerschutzbeauftragte der Game90 Ltd. ist Steffen Schröder,
          der jederzeit per E-Mail erreichbar ist unter der Adresse rgaming@game90.bet oder
          telefonisch unter +356 2276 9103. Wir sind gesetzlich verpflichtet, von Minderjährigen
          platzierte Wetten und ggf. erzielte Gewinne zu stornieren
        </p>
        <h5 className="text-danger mt-75">So setzt du dir Grenzen</h5>
        <ul>
          <li key={18} className="mt-3">
            Lege deinen maximalen Gesamteinsatz für die nächste Woche oder den kommenden Monat im
            Voraus fest und erhöhe diesen Betrag nicht nachträglich.
          </li>
          <li key={19} className="mt-3">
            Zahle nicht mehr Geld auf dein Wettkonto ein als diesen zu Beginn festgelegten Betrag.
            Dies gilt auch im Zuge von verlorenen Spielen.
          </li>
          <li key={20} className="mt-3">
            Entscheide im vorhinein, bei welchem Gewinn du das Spiel beendest.
          </li>
          <li key={21} className="mt-3">
            Lege vorab fest, wie hoch dein Spielverlust maximal sein darf.
          </li>
          <li key={22} className="mt-3">
            Reduziere ggf. dein Limit, um sicherzustellen, dass du nicht mehr Geld setzen kannst,
            als du dir vorgenommen hast.
          </li>
          <li key={23} className="mt-3">
            Versuche stets, konzentriert und ausgeruht zu spielen.
          </li>
          <li key={24} className="mt-3">
            Lege regelmäßig Pausen ein, in denen du etwas anderes machst.
          </li>
          <li key={25} className="mt-3">
            Spiele nie unter dem Einfluss von Drogen, starken Medikamenten oder übermäßigem
            Alkoholgenuss.
          </li>{' '}
          <li key={26} className="mt-3">
            Spiele nicht, wenn es dir besonders schlecht geht oder du dich in einer depressiven
            Stimmung befindest.
          </li>
        </ul>
        <p className="mt-3">
          Wir geben allen Spielern die Möglichkeit, sich eigene Limits zu setzen oder das eigene
          Konto für einen gewissen Zeitraum zu sperren. Eigene Limits kannst du ändern, indem du uns
          eine Nachricht über das Supportformular zukommen lässt, oder diese Limits in deinem
          Spielerkonto unter „Sicherheit“ und „Meine Limits“ selbst einstellst. Bitte beachte, dass
          eine Erhöhung des/der Limits erst sieben Tage nach Eingang der Anfrage in Kraft tritt.
          Wird ein Limit heruntergesetzt, ist das neue Limit ab sofort gültig. Game90 nimmt in der
          Folge keine Wetten an, die die vom Kunden gesetzten Limits überschreiten. Für eine
          Sperrung wende dich bitte an den Game90-Support. Du hast die Möglichkeit, dein Konto für
          beschränkte Zeiträume oder dauerhaft sperren zu lassen.
        </p>
        <h5 className="text-danger mt-75">Altersbeschränkung 18+</h5>
        <p className="mt-3">
          Die Eröffnung eines Kontos und die Teilnahme an Wetten bei Game90 sind Minderjährigen
          gesetzlich untersagt. Um bei uns wetten zu können, musst du bei deiner Registrierung
          bestätigen, dass du mindestens 18 Jahre alt bist und dein Geburtsdatum angeben. Dies muss
          aus rechtlichen Gründen bei deiner ersten Auszahlungsanforderung anhand einer Kopie deines
          amtlichen Lichtbildausweises geprüft werden. Game90 Ltd. behält sich darüber hinaus das
          Recht vor, Nachweise zum Alter eines Nutzers unabhängig von einer Auszahlung anzufordern
          und das entsprechende Konto bis zum erbrachten Nachweis zu schließen. Unsere Nutzerkonten
          werden regelmäßig auf Betrugsversuche und das Unterlaufen des Jugendschutzes kontrolliert.
        </p>
        <h5 className="text-danger mt-75">Kinderschutz</h5>
        <p className="mt-3">
          Bist du dir unsicher, ob deine Kinder eventuell Zutritt zu dieser Seite haben? Wenn du
          deinen Rechner mit Minderjährigen teilst, solltest du sicherstellen, dass der Zugriff auf
          Benutzernamen, Kennwörter und Zahlungsdetails nicht möglich ist. Auf der Seite
          www.klicksafe.de findest du nützliche Informationen zum kindgerechten Schutz von Computer
          und Daten sowie zu Filterlösungen.
          <br /> Weitere Informationen zum Schutz Minderjähriger im Internet findest du zudem auf
          den folgenden Webseiten: www.netnanny.com und www.cyberpatrol.com.
        </p>
        <h5 className="text-danger mt-75">Selbsttest</h5>
        <p className="mt-3">
          Du bist dir nicht sicher, ob du möglicherweise unter Spielsucht leidest? Auf den folgenden
          Webseiten kannst du einen Selbsttest durchführen, um eine Einschätzung zu deinem
          Spielverhalten zu erhalten: <br />
          www.ag-spielsucht.charite.de <br /> www.check-dein-spiel.de
        </p>
        <h5 className="text-danger mt-75">An wen kann ich mich wenden und wo finde ich Hilfe?</h5>
        <p className="mt-3">
          Game90 bietet dir umgehend Hilfe und Unterstützung bei Spielsucht oder bereits zur
          Vorbeugung von Spielsucht an. Du kannst jederzeit Kontakt zu unserem
          Spielerschutzbeauftragten Steffen Schröder aufnehmen, der entsprechend dafür geschult ist
          und dir bei Problemen weiterhelfen kann. Du erreichst ihn per E-Mail unter der Adresse
          rgaming@game90.bet.
        </p>
        <p className="mt-3">
          Of course you can also get confidential advice and help from our Game90 prevention expert
          Steffen Schröder at rgaming@game90.bet
        </p>
        <p className="mt-3">
          Wenn du nicht mit unserem Game90-Team direkt reden möchtest, kannst du dich auch
          vertrauensvoll an eine offizielle Behörde wenden:
        </p>
        <p className="mt-3">
          Die Bundeszentrale für gesundheitliche Aufklärung (BZgA) ist unter der kostenfreien
          Telefonnummer 0800-1372700 erreichbar und wird dir immer behilflich sein.
        </p>
      </Tab>
    </Tabs>
  );
}
