import { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListGroup } from 'react-bootstrap';
import { TopLeagues, AWS_BUCKET_URL } from 'toolkit/constants.js';
import { calculateMatchCountByLeagues } from 'components/base/constants';
import { categoryNameHelper } from 'toolkit/utils';
import { setDesktopSearchParams } from 'stores/match';
import Sport from './_components/sport';

export default function LeftSidebar() {
  const dispatch = useDispatch();
  const desktopSearchParams = useSelector((state) => state.match.desktopSearchParams);
  const counts = useSelector((state) => {
    if (desktopSearchParams.dateFilter > 0) {
      return state.match.timeCounts;
    }
    return state.match.counts;
  });
  const categoryNames = useSelector((state) => state.match.categoryNames);
  const { t } = useTranslation('translations');
  const dateFilters = {
    0: 'all',
    1: 'today',
    2: 'tomorrow',
    3: 'weekend',
    4: '4hours',
    5: 'upcomingLiveMatches'
  };
  const iconArray = ['football.svg', 'clearfavouritesicon.svg', 'todayicon.svg'];

  const handleTopLeagueClick = (category) => {
    dispatch(
      setDesktopSearchParams({
        Sport: 0,
        Category: category,
        Tournament: null,
        mode: null
      })
    );
  };

  const handleTopLeagueTournamentClick = (category, tournament) => {
    dispatch(
      setDesktopSearchParams({
        Sport: 0,
        Category: category,
        Tournament: tournament,
        mode: null
      })
    );
  };

  const handleTopLinkClick = (mode) => {
    if (mode === 'today') {
      dispatch(
        setDesktopSearchParams({
          mode: mode,
          Sport: 0,
          Category: null,
          Tournament: null,
          dateFilter: '1'
        })
      );
    } else {
      dispatch(
        setDesktopSearchParams({
          mode: mode,
          Sport: null,
          Category: null,
          Tournament: null
        })
      );
    }
  };

  const handleDateFilterClick = (dateFilter) => {
    dispatch(
      setDesktopSearchParams({
        dateFilter
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [desktopSearchParams.Category, desktopSearchParams.Tournament]);

  function topLeagueItems() {
    if (!counts.Football) return <div></div>;
    return Object.keys(counts.Football)
      .filter((category) => TopLeagues.includes(category))
      .sort((a, b) => TopLeagues.indexOf(a) - TopLeagues.indexOf(b))
      .map((category, index) => {
        const code = category.split(':')[2];
        const categoryText = categoryNameHelper(
          category,
          categoryNames,
          t(`categories.${category}`)
        );
        if (code == 1) {
          const tournament = counts['Football'][category];
          if (
            Object.keys(tournament).find((x) => x === 'sr:tournament:17') &&
            Object.keys(tournament).find((x) => x === 'sr:tournament:18')
          ) {
            return (
              <Fragment key="premier">
                <ListGroup.Item
                  action
                  className="bg-list-dark"
                  onClick={() => handleTopLeagueTournamentClick(category, 'sr:tournament:17')}
                  active={desktopSearchParams.Tournament === 'sr:tournament:17'}>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="">
                      <img
                        width="20"
                        height="13"
                        src={`${AWS_BUCKET_URL}/ClientFlags/${code}.svg`}
                        alt={code}
                      />
                    </div>
                    <div className="">
                      {t('categories.Premier')} {t('league')}
                    </div>
                    <div className="ms-auto badge rounded">{tournament['sr:tournament:17']}</div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className="bg-list-dark"
                  onClick={() => handleTopLeagueTournamentClick(category, 'sr:tournament:18')}
                  active={desktopSearchParams.Tournament === 'sr:tournament:18'}>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="">
                      <img
                        width="20"
                        height="13"
                        src={`${AWS_BUCKET_URL}/ClientFlags/${code}.svg`}
                        alt={code}
                      />
                    </div>
                    <div className="">{t('categories.Championship')}</div>
                    <div className="ms-auto badge rounded">{tournament['sr:tournament:18']}</div>
                  </div>
                </ListGroup.Item>
              </Fragment>
            );
          }
        }
        if (code == 30) {
          const tournament = counts['Football'][category];
          if (
            Object.keys(tournament).find((x) => x === 'sr:tournament:35') &&
            Object.keys(tournament).find((x) => x === 'sr:tournament:44')
          ) {
            return (
              <Fragment key="bundesliga">
                <ListGroup.Item
                  action
                  className="bg-list-dark"
                  onClick={() => handleTopLeagueTournamentClick(category, 'sr:tournament:35')}
                  active={desktopSearchParams.Tournament === 'sr:tournament:35'}>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="">
                      <img
                        width="20"
                        height="13"
                        src={`${AWS_BUCKET_URL}/ClientFlags/${code}.svg`}
                        alt={code}
                      />
                    </div>
                    <div className="">{t('categories.Bundesliga')}</div>
                    <div className="ms-auto badge rounded">{tournament['sr:tournament:35']}</div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className="bg-list-dark"
                  onClick={() => handleTopLeagueTournamentClick(category, 'sr:tournament:44')}
                  active={desktopSearchParams.Tournament === 'sr:tournament:44'}>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="">
                      <img
                        width="20"
                        height="13"
                        src={`${AWS_BUCKET_URL}/ClientFlags/${code}.svg`}
                        alt={code}
                      />
                    </div>
                    <div className="">{t('categories.Bundesliga')} 2</div>
                    <div className="ms-auto badge rounded">{tournament['sr:tournament:44']}</div>
                  </div>
                </ListGroup.Item>
              </Fragment>
            );
          }
        }
        return (
          <ListGroup.Item
            key={index}
            action
            className="bg-list-dark"
            onClick={() => handleTopLeagueClick(category)}
            active={desktopSearchParams.Category === category}>
            <div className="d-flex gap-2 align-items-center">
              <div className="">
                <img
                  width="20"
                  height="13"
                  src={`${AWS_BUCKET_URL}/ClientFlags/${code}.svg`}
                  alt={code}
                />
              </div>
              <div className="">{categoryText}</div>
              <div className="ms-auto badge rounded">
                {calculateMatchCountByLeagues(counts.Football, category)}
              </div>
            </div>
          </ListGroup.Item>
        );
      });
  }

  const topLinkItems = () => {
    const topLinksArr = ['topMatches', 'clearFavorites', 'today'];
    return topLinksArr.map((topLink, index) => (
      <ListGroup.Item
        key={index}
        action
        className="bg-list-dark"
        onClick={() => handleTopLinkClick(topLink)}
        active={topLink === desktopSearchParams.mode}>
        <div className="d-flex gap-2 align-items-center">
          <img
            className={topLink === desktopSearchParams.mode ? 'image-passive' : 'image-passive'}
            width="20"
            height="13"
            src={`${AWS_BUCKET_URL}/Icons/` + iconArray[index]}
          />
          <span>{t(`pages.desktop.filterSidebar.${topLink}`)}</span>
        </div>
      </ListGroup.Item>
    ));
  };

  function sportItems() {
    return Object.keys(counts).map((sport) => <Sport sport={sport} key={sport} />);
  }

  return (
    <div>
      <div className="mb-4">
        <h5 className="mb-0 text-color-green">{t('pages.desktop.filterSidebar.topLeagues')}</h5>
        <hr className="my-2 border-top-0 border-left-green opacity-100" />
        <ListGroup defaultActiveKey="0">{topLeagueItems()}</ListGroup>
      </div>
      <div className="mb-4">
        <h5 className="mb-0 text-color-green">{t('pages.desktop.filterSidebar.topLinks')}</h5>
        <hr className="my-2 border-top-0 border-left-green opacity-100" />
        <ListGroup defaultActiveKey="0">{topLinkItems()}</ListGroup>
      </div>
      <div className="">
        <h5 className="mb-0 text-color-green">{t('pages.desktop.filterSidebar.sportsBetting')}</h5>
        <hr className="my-2 border-top-0 border-left-green opacity-100" />
        <div className="overflow-auto text-nowrap mb-2 text-left">
          {Object.keys(dateFilters).map((dateFilter) => {
            return (
              <div
                key={dateFilter}
                className={`d-inline-block text-center px-2 py-2 text-decoration-none ${
                  desktopSearchParams.dateFilter === dateFilter
                    ? 'text-desktop-time-active'
                    : 'text-desktop-time'
                }`}
                onClick={() => handleDateFilterClick(dateFilter)}>
                {t('pages.desktop.filterSidebar.' + dateFilters[dateFilter])}
              </div>
            );
          })}
        </div>
        <div className="d-flex flex-column gap-1">{sportItems()}</div>
      </div>
    </div>
  );
}
