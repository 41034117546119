import { tmmInstance } from 'libraries/axios';

export const fetchFullEventReq = (body) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/BetEvent/GetFullEvent', body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const fetchTopMatchesReq = (params) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/BetEvent/TopMatches', null, {
        params
      })
      .then((response) => {
        resolve(response.data.data.topMatches);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const fetchClearFavoritesReq = (params) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/BetEvent/ClearFavorites', null, {
        params
      })
      .then((response) => {
        resolve(response.data.data.clearFavorites);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const fetchLiveMatchesReq = (params) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/BetEvent/LiveMatches', null, {
        params
      })
      .then((response) => {
        resolve(response.data.data.liveMatches);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const fetchNextLiveMatchesReq = (params) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/BetEvent/GetEventsBySportByPagination', params)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const fetchCompetitorsNameByLanguageReq = (lang) => {
  return new Promise((resolve) => {
    tmmInstance
      .post(`/BetEvent/GetCompetitorsByLang`, null, {
        params: {
          lang
        }
      })
      .then((response) => {
        resolve(response.data.data.competitors);
      })
      .catch(() => {
        // error
      });
  });
};

export const fetchSportCountsWithHoursReq = (params) => {
  return new Promise((resolve) => {
    tmmInstance
      .post(`/BetEvent/CountsWithHours`, null, {
        params
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch(() => {
        // error
      });
  });
};

export const fetchSportCountsReq = (body) => {
  return new Promise((resolve) => {
    tmmInstance
      .post(`/BetEvent/Counts`, body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch(() => {
        // error
      });
  });
};

export const fetchEventsByCategoryWithHoursReq = (data) => {
  return new Promise((resolve) => {
    tmmInstance
      .post(`/BetEvent/GetEventsByCategoryWithHours`, data)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch(() => {
        // error
      });
  });
};

export const fetchEventsByCategoryReq = (data) => {
  return new Promise((resolve) => {
    tmmInstance
      .post(`/BetEvent/GetEventsByCategory`, data)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch(() => {
        // error
      });
  });
};

export const fetchEventsBySportReq = (data) => {
  return new Promise((resolve) => {
    tmmInstance
      .post(`/BetEvent/GetEventsBySport`, data)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch(() => {
        // error
      });
  });
};

export const fetchResultsReq = (body) => {
  return new Promise((resolve) => {
    tmmInstance
      .post('/BetEvent/GetResults', body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch(() => {
        // error
      });
  });
};

export const fetchResultCategoriesReq = () => {
  return new Promise((resolve) => {
    tmmInstance
      .post('/BetEvent/ResultCategories')
      .then((response) => {
        resolve(response.data.data);
      })
      .catch(() => {
        // error
      });
  });
};

export const postSearchReq = (body) => {
  return new Promise((resolve) => {
    tmmInstance
      .post('/BetEvent/SearchMatchByCompetitor', body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch(() => {
        // error
      });
  });
};
