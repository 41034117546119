import { configureStore } from '@reduxjs/toolkit';
import matchReducer from './match';
import authReducer from './auth';
import betSlipReducer from './betSlip';

export const store = configureStore({
  reducer: {
    match: matchReducer,
    auth: authReducer,
    betSlip: betSlipReducer
  }
});
