import { useState, useEffect, useCallback, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import MatchCard from 'components/general/match_card.js';
import { getSportTypeToActiveIcon, getSportTypeToWhiteIcon } from 'toolkit/utils';
import TitleHistoryBack from 'components/general/TitleHistoryBack.js';
import SportDetailItem from 'components/SportDetailItem.js';
import { fetchLiveMatches, setNextLiveMatches, setSelectedSport } from 'stores/match';
import eventsToGroup from 'toolkit/eventsToGroup.js';
// import Loading from 'components/general/loading';
import NextLiveMatches from './NextLiveMatches';

function SportDetail() {
  const matchCardListRef = useRef(null);
  const dispatch = useDispatch();
  const selectedSport = useSelector((state) => state.match.selectedSport);
  const selectedMatch = useSelector((state) => state.match.selectedMatch);
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const locales = useSelector((state) => state.match.locales);
  const liveEvents = useSelector((state) => {
    const { liveMatches, events } = state.match;
    return liveMatches.map((c) => events[c]);
  }, shallowEqual);
  const { t } = useTranslation('translations', { useSuspense: false });
  const [lastScrollPosition, setLastScrollPosition] = useState(
    localStorage.getItem('scrollPosition') || 0
  );
  const [sports, setSports] = useState({});
  const [sport, setSport] = useState('0');
  const [selectedMarketType, setSelectedMarketType] = useState(7);
  const location = useLocation();

  const handleLocationScroll = () => {
    const currentPosition = window.pageYOffset || document.documentElement.scrollTop;
    setLastScrollPosition(currentPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleLocationScroll);

    return () => {
      window.removeEventListener('scroll', handleLocationScroll);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('scrollPosition', lastScrollPosition.toString());
  }, [lastScrollPosition]);

  // useEffect(() => {
  //   const savedScrollPosition = localStorage.getItem('scrollPosition');

  //   if (savedScrollPosition) {
  //     window.scrollTo(0, parseInt(savedScrollPosition));
  //   }
  // }, []);

  const getData = async () => {
    try {
      // setLoading(true);
      // await dispatch(fetchLiveMatches()).unwrap();
      dispatch(fetchLiveMatches());
    } catch (error) {
      // error
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // if (loading) return;

    if (selectedMatch) {
      const selectedMatchCard = document.querySelector(`a[href*="${selectedMatch}"]`);
      if (selectedMatchCard) {
        selectedMatchCard.scrollIntoView({ block: 'center' });
      }
    }
  }, []);

  useEffect(() => {
    // if (loading) return;
    const groups = eventsToGroup(liveEvents);
    setSports(groups);
    if (sport === '' && liveEvents.length > 0) {
      const type =
        selectedSport !== null || sports.hasOwnProperty(Number.parseInt(selectedSport)) === true
          ? selectedSport
          : Object.keys(groups)[0];

      setSport(type);
    }
  }, [sport, liveEvents]);

  useEffect(() => {
    if (sport !== '') {
      dispatch(setSelectedSport(sport));
    }
  }, [sport]);

  const selectSport = useCallback((id) => {
    setSport(id);
    dispatch(setNextLiveMatches());
  });

  const handleScroll = () => {
    const container = window;
    const stickyEl = document.querySelector('.tip-types');
    if (!stickyEl) return;
    const scrollTop = container.scrollY;
    const direction = scrollTop < lastScrollPosition ? 'up' : 'down';
    setLastScrollPosition(scrollTop);

    if (scrollTop > 110) {
      stickyEl.classList.add('fixed');
      if (direction === 'down') {
        if (scrollTop > 200) {
          stickyEl.classList.remove('fixed');
        }
      } else {
        if (scrollTop > 200) {
          stickyEl.classList.add('fixed');
        }
      }
    } else {
      stickyEl.classList.remove('fixed');
    }
  };

  if (window) {
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
  }

  function calculateMenuItemsDynamic() {
    const menuItems = [];
    let icon = '';
    Object.keys(sports).map((sportType, index) => {
      if (sportType === sport) {
        icon = getSportTypeToActiveIcon(sport);
      } else {
        icon = getSportTypeToWhiteIcon(sportType);
      }
      if (sports[sportType].length > 0) {
        menuItems.push(
          <SportDetailItem
            key={index}
            active={sport === sportType}
            count={_.sum(sports[sportType].map((l) => l.matches.length))}
            icon={icon}
            onClick={() => {
              selectSport(sportType);
            }}
            text={t('sports.' + sportType)}
            className={'d-flex flex-column p-2 fs-8'}
          />
        );
      }
    });

    return menuItems;
  }

  function getBetType() {
    const betTypes = [
      {
        label: 'RoM',
        id: 7
      },
      {
        label: 'Winner',
        id: 1
      },
      {
        label: 'O/U',
        id: 18
      },
      {
        label: 'HC',
        id: 14
      },
      {
        label: 'NG',
        id: 8
      },
      {
        label: 'BS',
        id: 29
      }
    ];
    return (
      <div className="tip-types shadow">
        {betTypes.map((betType) => (
          <div className="d-inline-block w-25 fw-bold " key={betType.id}>
            <button
              className={`btn btn-light btn-sm w-100 border fs-8 ${
                selectedMarketType === betType.id ? 'match-date-bg-color fw-bold text-white' : ''
              }`}
              onClick={() => setSelectedMarketType(betType.id)}>
              {betType.label}
            </button>
          </div>
        ))}
      </div>
    );
  }

  function getBets() {
    const matchCards = [];
    if (sports[sport] && sports[sport].length > 0) {
      sports[sport]
        .filter((l) => l.matches.length > 0)
        .forEach((liveMatch) => {
          matchCards.push(
            <Fragment key={liveMatch.league + liveMatch.matches[0].providerId}>
              <div className="text-start pb-2 fw-bold pt-1 fs-7">
                {liveMatch.category + ' / ' + liveMatch.league}
              </div>
              {liveMatch.matches.map((match, index) => (
                <MatchCard
                  key={match.providerId}
                  providerId={match.providerId}
                  marketType={selectedMarketType}
                  index={index}
                  sport={sport}
                  path={location.pathname}
                  locales={locales}
                  selectedLocale={selectedLocale}
                />
              ))}
            </Fragment>
          );
        });
    }

    return matchCards;
  }

  // if (loading) return <Loading message={t('search.loadingMatches')} />;

  return (
    <div className="container-fluid scrollable-y">
      <div className="overflow-auto text-nowrap bg-dark mx-n3 d-flex">
        {calculateMenuItemsDynamic()}
      </div>
      <TitleHistoryBack title={t('start')} subTitle={t('live')} />
      {sport === '0' && (
        <Row className="mt-n2">
          <Col className="tip-types-container">{getBetType()}</Col>
        </Row>
      )}
      <div className="mt-n2">
        <div ref={matchCardListRef}>{getBets()}</div>
      </div>
      <NextLiveMatches sport={sport} selectedMarketType={selectedMarketType} />
    </div>
  );
}

export default SportDetail;
