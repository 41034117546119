import { useEffect } from 'react';
import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { TMM_URL } from '../toolkit/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMatch,
  deleteMatch,
  updateMatch,
  setProducerStatus,
  fetchLiveMatches,
  fetchFullEvent
} from 'stores/match';
import {
  getBetSlipMatches,
  checkEventBetStatus_UpdateMsg,
  checkEventBetStatus_DeleteMsg
} from 'stores/betSlip';

const useMakeHubConnection = () => {
  const dispatch = useDispatch();
  const selectedMatch = useSelector((state) => state.match.selectedMatch);

  const onReconnect = () => {
    console.log('onReconnect');
    dispatch(getBetSlipMatches());
    dispatch(fetchLiveMatches());
    if (selectedMatch) {
      dispatch(fetchFullEvent(selectedMatch));
    }
  };

  useEffect(() => {
    const conn = new HubConnectionBuilder()
      .configureLogging(LogLevel.Warning)
      .withAutomaticReconnect([0, 2000, 2000, 2000])
      .withUrl(`${TMM_URL}/hubs/sportEvent`, {
        headers: {
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
          'Access-Control-Allow-Credentials': 'true'
        }
      })
      .build();

    const restart = async () => {
      // dispatch({ type: SAVE_SLIP });
      if (!conn) {
        return;
      }
      if (conn.state !== HubConnectionState.Disconnected) {
        return;
      }

      await conn
        .start()
        .then(async () => {
          console.log('Connected to SignalR');
          onReconnect();
          // location.reload();
        })
        .catch(() => {
          console.log('Error starting SignalR');
          setTimeout(restart, 1000);
        });
    };

    conn.onreconnecting(() => {
      console.log('onreconnecting');
      onReconnect();
      // location.reload();
    });

    conn.onreconnected(() => {
      console.log('onreconnected');
      onReconnect();
    });

    conn.onclose(() => {
      console.log('SignalR Disconnected. Restarting.');
      restart();
    });

    conn
      .start()
      .then(async () => {
        console.log('Connected to SignalR');
      })
      .catch(() => {
        console.log('Error starting SignalR');
        restart();
      });

    conn.on('BetEventAdded', (data) => {
      dispatch(addMatch(data));
    });

    conn.on('BetEventUpdated', (data) => {
      dispatch(updateMatch(data));
      dispatch(checkEventBetStatus_UpdateMsg(data));
    });

    conn.on('BetEventDeleted', (data) => {
      dispatch(deleteMatch(data));
      dispatch(checkEventBetStatus_DeleteMsg(data));
    });

    conn.on('LocalizationAdded', (data) => {
      console.log(data);
      // dispatch({ type: LOCALIZATION_ADDED, payload: data });
    });

    conn.on('ProducerStatusChanged', (data) => {
      console.log(data);
      dispatch(setProducerStatus(data));
    });
  }, []);
};

export { useMakeHubConnection };
