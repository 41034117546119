import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Check2Square } from 'react-bootstrap-icons';
import { removeBetFromBetSlip, setBetSlipBanker, setBetSlipEnabled } from 'stores/betSlip';
import { getMatchTime, MarketIdToMarketName, OddsValueTypeTextCreator } from 'toolkit/utils';
import { getMatchDetail, getTeamNameHelper } from 'helper/matchDetailHelper';
import { sportTypeToSportId } from 'toolkit/utils.js';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { EnumMarketName, EnumEventStatus } from 'toolkit/Enums.js';
import { gameDateToMatchDay, gameDateToMatchTime } from 'toolkit/utils';
import useOddTransformer from 'toolkit/useHooks/useOddsTransformer';
import { checkIfLiveEvent } from 'toolkit/utils';

export const BetSlipRow = ({ bet, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations', { useSuspense: false });
  const betSlip = useSelector((state) => state.betSlip);
  const match = useSelector((state) => state.match.events[bet.providerId]);
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const locales = useSelector((state) => state.match.locales);
  const [oddsClass, setOddsClass] = useState('');
  const prevOddsRef = useRef(null);

  const oddsTransformer = useOddTransformer();
  let marketName = '';
  let playerOrTeam = '';

  const { matchInfo, outcomeData } = useMemo(() => {
    if (!match) {
      return {};
    }
    const matchInfo = getMatchDetail(match);
    const market = match?.markets.find((m) => m.stringId === bet.marketStringId);
    const outcome = market?.outcomes.find((o) => o.id === bet.outcomeId);

    if (!outcome) {
      dispatch(
        removeBetFromBetSlip({
          providerId: bet.providerId,
          marketStringId: bet.marketStringId,
          outcomeId: bet.outcomeId
        })
      );

      return { matchInfo, outcomeData: { odds: 0 } };
    }

    return { matchInfo, outcomeData: outcome };
  }, [match]);

  useEffect(() => {
    if (!prevOddsRef.current) {
      prevOddsRef.current = outcomeData.odds;
      return;
    }
    const prevOdd = prevOddsRef.current;
    const newOdd = outcomeData.odds;

    if (newOdd !== prevOdd) {
      setOddsClass(`odd-${newOdd > prevOdd ? 'increased-slip' : 'decreased-slip'}`);

      const highlightResetTimer = setTimeout(() => {
        setOddsClass('');
      }, 5000);

      return () => {
        clearTimeout(highlightResetTimer);
      };
    }
  }, [outcomeData.odds]);

  const removeOdds = useCallback((b) => {
    dispatch(
      removeBetFromBetSlip({
        providerId: b.providerId,
        marketStringId: b.marketStringId,
        outcomeId: b.outcomeId
      })
    );
  });

  const toggleMatch = useCallback(() => {
    dispatch(setBetSlipEnabled({ providerId: bet.providerId, enabled: !bet.enabled }));
  });

  const toggleBanker = useCallback(() => {
    dispatch(setBetSlipBanker({ providerId: bet.providerId, banker: !bet.banker }));
  });

  {
    if (!match || !matchInfo) return <div />;
  }

  return (
    <div
      className="bet-slip-row fs-8"
      style={{
        opacity: bet.enabled ? 1 : 0.3,
        backgroundColor: type == 'desktop' ? '#EFEFEF' : ''
      }}>
      <Row className="px-3 ">
        <div className="col-auto">
          <a onClick={() => toggleMatch()}>
            <Check2Square fill={bet.enabled ? 'green' : 'grey'} size={22}></Check2Square>
          </a>
        </div>
        <div className="col">
          <div className="d-flex justify-content-between mb-2">
            <span>
              {getTeamNameHelper(locales, selectedLocale, matchInfo.home, t)} -
              {getTeamNameHelper(locales, selectedLocale, matchInfo.away, t)}
            </span>
            <div className="col-auto">
              {betSlip.system ? (
                <span>
                  <a onClick={() => toggleBanker()}>
                    <div
                      className="banker-bg"
                      style={{ backgroundColor: bet.banker ? '#3AA843' : '#E5E5E5' }}>
                      <span className="text-white fw-bold fs-8 m-0 d-flex justify-content-center">
                        B
                      </span>
                    </div>
                  </a>
                </span>
              ) : null}
            </div>
            {match.eventStatus == EnumEventStatus.RUNNING ? (
              <span>
                {matchInfo.home.homeScore} : {matchInfo.away.awayScore}
              </span>
            ) : (
              <span>
                <div>{gameDateToMatchDay(match.gameDate)}</div>
                <div>{gameDateToMatchTime(match.gameDate)}</div>
              </span>
            )}
          </div>
          <hr className="mt-1 mb-1" />
          {betSlip.bets.map((b) => {
            if (b.providerId === bet.providerId) {
              const market = match.markets.find((m) => m.stringId === b.marketStringId);
              let outcome = market?.outcomes.find((o) => o.id === b.outcomeId);
              if (outcome === undefined || outcome === null)
                outcome = { id: b.outcomeId, name: b.outcomeName, odds: 0 };
              let playerName = outcome.name.split('&')[0];
              playerName = playerName.split(',')[1] + ' ' + playerName.split(',')[0];
              if (b.marketId == 534) {
                if (b.outcomeName.includes(',')) {
                  marketName = t('whogoalscorer');
                  playerOrTeam = b.outcomeName.split(',')[1] + ' ' + b.outcomeName.split(',')[0];
                } else {
                  marketName = t('whochampion');
                  playerOrTeam = OddsValueTypeTextCreator(
                    match.sport.toString(),
                    b.marketId.toString(),
                    outcome.id,
                    outcome.name
                  );
                }
              } else {
                marketName = MarketIdToMarketName(b.marketId, b.marketStringId, false);
              }

              return (
                <div key={b.providerId + b.marketStringId + outcome.id} className="py-3 px-0">
                  <div className="d-flex justify-content-between">
                    <div className="col-auto">
                      <span>{marketName}</span>
                    </div>
                    <div className="col-auto">
                      <span>
                        {b.marketId == EnumMarketName.ANYTIMEGOALSCORERWINNER_888 ||
                        b.marketId == EnumMarketName.NEXTGOALSCORER_892 ||
                        b.martketId == EnumMarketName.NEXTGOALSCORERWINNER_891
                          ? OddsValueTypeTextCreator(
                              match.sport.toString(),
                              b.marketId.toString(),
                              outcome.id,
                              outcome.name
                            ) +
                            ',' +
                            playerName
                          : b.marketId == 534
                          ? playerOrTeam
                          : OddsValueTypeTextCreator(
                              match.sport.toString(),
                              b.marketId.toString(),
                              outcome.id,
                              outcome.name
                            )}
                      </span>
                    </div>
                    <div className="col-auto">
                      {market && (
                        <>
                          {market.marketStatus !== 6 && (
                            <img
                              width="20"
                              height="20"
                              className="light-black-svg"
                              src={`${AWS_BUCKET_URL}/Icons/lockicon.svg`}
                            />
                          )}
                          {market.marketStatus === 6 && (
                            <span className={oddsClass}>
                              {oddsTransformer.transformWithDisplayFormatted(
                                checkIfLiveEvent(b.match),
                                b?.match?.isSeason,
                                b?.marketId,
                                new Date(b?.match?.gameDate),
                                b?.odds
                              )}
                            </span>
                          )}
                          <br />
                          {match.eventStatus == EnumEventStatus.RUNNING &&
                            match.sport === sportTypeToSportId('Football') &&
                            getMatchTime(match, t)}
                          {match.eventStatus == EnumEventStatus.RUNNING &&
                            match.sport !== sportTypeToSportId('Football') && (
                              <span>
                                {t(
                                  `components.matchcard.time.byMatchStatusId.${match.matchStatusId}`
                                )}
                              </span>
                            )}
                        </>
                      )}
                    </div>
                    <div className="col-auto">
                      <span>
                        <a onClick={() => removeOdds(b)}>
                          <img src={`${AWS_BUCKET_URL}/Icons/116.png`}></img>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </Row>
    </div>
  );
};
