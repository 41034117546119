import { Container, Accordion, Row, Col } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
export default function TR() {
  return (
    <Tabs justify className="mb-4 py-2 text-start me-1 ms-1">
      <Tab eventKey="faq" title="SSS">
        <h2 className="text-danger">SIKÇA SORULAN SORULAR</h2>
        <p key={1}>
          En sık sorulan soruları sizin için derledik ve yanıtladık. Sorunuz burada yanıtlanamıyorsa
          lütfen desteğimizle iletişime geçin. En kısa sürede sizinle iletişime geçeceğiz.
        </p>
        <Container className="py-2 text-start me-1 ms-1">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" key={0}>
              <Accordion.Header>SSS</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Row>
                    <Col className="text-danger" xs={4}>
                      Aşağıdaki adrese e-posta ile: <hr />
                    </Col>
                    <Col className="text-danger" xs={4}>
                      Aşağıdaki numaraya faks ile: <hr />
                    </Col>
                    <Col className="text-danger" xs={4}>
                      Sohbetimizi kullanın: <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>support@game90.bet</Col> <Col xs={4}>+356 2276 9002</Col>
                    <Col xs={4}></Col>
                  </Row>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" key={1}>
              <Accordion.Header>Bahislerimin sonuçlanması ne kadar sürer?</Accordion.Header>
              <Accordion.Body>
                Bahis yaptığınız etkinlik kararlaştırıldığı anda bahisiniz sonuçlandırılacaktır.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" key={2}>
              <Accordion.Header>Kazanıp kazanmadığımı nasıl öğrenirim?</Accordion.Header>
              <Accordion.Body>
                Tarafınızca oynanan tüm bahislerin durum özetini &apos;Bahislerim&apos; altında
                bulabilirsiniz. Sonuçlanan bahislerden elde edilen toplam kazanç hesabınıza
                aktarılır.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" key={3}>
              <Accordion.Header>Bahis hesabımda kullanılan para birimi nedir?</Accordion.Header>
              <Accordion.Body>
                Bahis hesabınız euro (EUR) cinsindendir. Diğer para birimlerinde para yatırırsanız,
                bunlar geçerli döviz kuru üzerinden Euro&apos;ya (EUR) dönüştürülecektir.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" key={4}>
              <Accordion.Header>Bahis ve kazanç limitleri nelerdir?</Accordion.Header>
              <Accordion.Body>
                Bahis kuponu başına minimum bahis miktarı 1 Euro&apos;dur. Bahis kuponu başına
                maksimum ödeme miktarı 100.000 Euro&apos;dur. Daha fazla bilgiyi
                &apos;Limitler&apos; altında bulabilirsiniz.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" key={5}>
              <Accordion.Header>
                Ödeme talebim ile paranın hesabıma ödenmesi arasında ne kadar zaman geçiyor?
              </Accordion.Header>
              <Accordion.Body>
                İlk kez ödeme talebinde bulunduğunuzda, geçerli fotoğraflı kimliğinizin bir
                kopyasına ihtiyacımız var. Bu belgeyi alır almaz transferi gerçekleştiriyoruz. Diğer
                ödeme talepleri anında işleme alınır.
                <br />
                Ulusal veya uluslararası ödeme işleminin transfer süresini lütfen bankanızdan
                öğrenin.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" key={6}>
              <Accordion.Header>Limitleri nasıl azaltabilirim ve arttırabilirim?</Accordion.Header>
              <Accordion.Body>
                Hesap sahibi game90 hesap ayarlarının değiştirilmesini isteyebilir. Lütfen
                support@game90.bet adresine bir e-posta gönderin:
                <br />
                <br />
                (a) hesap sahibinin belirli bir tarihteki bahislere yatırmak istediği bahis
                miktarına ilişkin bir limit zaman; <br />
                (b) seans süresine ilişkin bir üst sınır; <br />
                (c) EUR cinsinden maksimum bekleyen bahislere ilişkin bir limit. <br />
                <br />
                Yukarıdaki (a), (b) ve (c)&apos;de olduğu gibi bir limit koyan hesap sahibi,
                support@game90.bet adresine e-posta göndererek bu limiti değiştirebilir veya iptal
                edebilir. Limit(ler)deki artış, e-postanın alınmasından bir gün (24 saat) sonra
                yürürlüğe girer. Limit(ler)in azaltılması, e-postanın alınmasından itibaren derhal
                yürürlüğe girer. game90, müşteri tarafından tanımlanan limitleri aşan oyunculardan
                veya oyun hesaplarını bloke eden müşterilerden gelen bahisleri kabul etmez.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" key={7}>
              <Accordion.Header>
                game90.bet&apos;yi kullanarak nasıl bahis oynarım?
              </Accordion.Header>
              <Accordion.Body>
                game90.bet&apos;de bahis oynamak için Game90 müşteri kartına ihtiyacınız vardır.
                Yakınınızdaki bir Game90 mağazasından bir tane alabilirsiniz. Kartınızı bir bahis
                terminalinde çevrimiçi bahis için etkinleştirdiyseniz, verdiğiniz erişim verilerini
                kullanarak game90.bet adresinde oturum açabilirsiniz. Yeterli krediyi elden
                çıkarmanız koşuluyla burada bahis oynayabilirsiniz.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Tab>
      <Tab eventKey="betTypes" title="Bahis Türleri">
        <h2 className="text-danger">BAHİS TÜRLERİ</h2>
        <p key={2}>
          Game90 size tekli bahislerden sistem bahislerine kadar sadece en cazip bahis türlerini
          sunuyor. con&apos;la bahis oynama seçenekleri hakkında ayrıntılı bilgi edinin.
        </p>
        <Container className="py-2 text-start me-1 ms-1">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Maçın Geri Kalanı</Accordion.Header>
              <Accordion.Body>
                Maçın Geri Kalanı bahis türü ile bahisinizi koyduğunuz andan maç sonuna kadar hangi
                takımın daha fazla gol atacağını tahmin edersiniz. Bahisinizi koyduğunuz anda,
                mevcut skor yalnızca bahsiniz için 0:0 olarak sıfırlanır. Bahislerinizi
                değerlendirmek için yalnızca bahisinizi yaptıktan sonra atılan goller sayılır.
                <br />
                <br /> <b>Olası Bahisler </b>
                <li className="mt-3" key={1}>
                  <b>Bahis 1:</b> Bahsinizi koyduğunuz andan maçın sonuna kadar ev sahibi takım,
                  konuk takımdan daha fazla gol atacaktır.
                </li>
                <li key={2}>
                  <b>Bahis 0:</b> Bahsinizi yaptığınız andan itibaren her iki takım da eşit sayıda
                  gol atar.
                </li>
                <li key={3}>
                  <b>Bahis 2:</b> Bahsinizi koyduğunuz andan maçın sonuna kadar konuk takım ev
                  sahibi takımdan daha fazla gol atacaktır.
                </li>
                <br /> <b> Örnek 1 </b> <br /> Bahsinizi koyduğunuz anda skor 0:1 olur. Maç sonunda
                skor 1:1. Bahis 1&apos;i (ev sahibi takım) seçtiyseniz kazanırsınız, çünkü
                bahisinizi koyduktan sonra ev sahibi takım konuk takımdan daha fazla gol attı.
                <br />
                <br /> <b> Örnek 2 </b> <br /> Bahsinizi koyduğunuz anda skor 0:1 olur. Maç sonunda
                skor 1:2 oldu. Eğer 0 (beraberlik) bahisini seçerseniz kazanırsınız, çünkü bahsinizi
                koyduktan sonra her iki takım da eşit sayıda gol atmıştır.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Winner</Accordion.Header>
              <Accordion.Body>
                Kazanan ve Kazanan bahis türüyle oyunun nasıl sonuçlanacağını önceden söylersiniz.
                Spor türüne bağlı olarak, Kazanan bahisi ve Kazanma bahisi, tüm oyunun (örneğin
                futbol) veya tüm maçın (örneğin tenis) sonucuna ilişkindir. <br /> <br /> <br />
                <b>Olası Bahisler </b>
                <li className="mt-3" key={4}>
                  <b>Bahis 1:</b> Ev sahibi takım veya 1. oyuncu kazanır.
                </li>
                <li key={5}>
                  <b>Bahis 0:</b> Oyun berabere biter.
                </li>
                <li key={6}>
                  <b>Bahis 2:</b> Konuk takım veya 2. oyuncu kazanır.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Fantezi</Accordion.Header>
              <Accordion.Body>
                Fantezi bahis türü Fantezi oyunlarına bahis yapmak içindir. Bir bahisçi, Fantezi
                maçlar için aslında birbirlerine karşı değil, diğer iki futbol takımına karşı
                oynayacak iki takım seçiyor. <br />
                <br />
                Canlı Bahis 0:0 prensibi Fantezi bahisler için geçerlidir. Fantezi bahiste, bahis
                yaptığınız andan itibaren her iki gerçek maçın sonuna kadar Fantezi maçta iki
                takımdan hangisinin daha fazla gol atacağını tahmin edersiniz. Bahisinizi koyduğunuz
                anda, Fantezi maçın mevcut skoru göz ardı edilir ve sadece bahsiniz için 0:0&apos;a
                sıfırlanır. Bahislerinizi değerlendirmek için, yalnızca Fantezi maç takımlarının
                gerçek maçlarda attıkları goller dikkate alınır. Bahsinizi yatırdınız. Gerçek
                maçlarda rakip takımın attığı gollerin Fantezi bahisin değerlendirmesine hiçbir
                etkisi yoktur. Fantezi bahislerde ev sahibi avantajı söz konusu değildir. Fantezi
                maçları Fantezi ligine atanır. Fantezi maçlar oranlar bölümünde turuncu renkle
                vurgulanır.
                <br />
                <br />
                <b>Olası Bahisler </b>
                <li className="mt-3" key={7}>
                  <b>Bahis 1:</b> Ev sahibi takım veya 1. oyuncu kazanır.
                </li>
                <li key={8}>
                  <b>Bahis 0:</b> Oyun berabere biter.
                </li>
                <li key={9}>
                  <b>Bahis 2:</b> Konuk takım veya 2. oyuncu kazanır.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Fantezi Üst/Alt</Accordion.Header>
              <Accordion.Body>
                Fantezi Üst/Alt bahis türüyle, bir Fantezi maçında atılan toplam gol sayısının G
                sütununda belirtilen gol çizgisinin altında mı yoksa üstünde mi olacağını tahmin
                edersiniz. Bir Fantezi maçında atılan tüm goller, bahisin sonuçlandırılmasında
                sayılır ( bahis oynanmadan önce atılan goller dahil). Gerçek maçlarda rakip takımın
                attığı gollerin Fantezi Üst/Alt bahisinin değerlendirmesine hiçbir etkisi yoktur.
                <br />
                <br />
                <b>Örnek: </b> Verilen gol çizgisi 2.5&apos;tir. Fantezi maçta iki veya daha az gol
                atılırsa, Under&apos;a oynanan bahis kazanır. Fantezi maçında üç veya daha fazla gol
                atılırsa Over&apos;a oynanan bahis kazanır.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>İlk Yarı</Accordion.Header>
              <Accordion.Body>
                Maçın Geri Kalanı bahis türünde olduğu gibi İlk Yarı bahis türünde de aynı prensip
                geçerlidir. İlk Yarı bahis türünde bahisinizi yaptığınız andan itibaren ilk yarının
                sonuna kadar hangi takımın daha fazla gol atacağını tahmin edersiniz. Bahsinizi
                koyduğunuz anda, mevcut skor sadece bahsiniz için 0:0&apos;a sıfırlanır.
                Bahislerinizin değerlendirilmesi için yalnızca bahsinizi yatırdıktan sonraki ilk
                yarıda atılan goller dikkate alınır.
                <br />
                <br />
                <b>Olası Bahisler </b>
                <li className="mt-3" key={10}>
                  <b>Bahis 1:</b> Bahsinizi koyduğunuz andan ilk yarının sonuna kadar ev sahibi
                  takım, konuk takımdan daha fazla gol atacaktır.
                </li>
                <li key={11}>
                  <b>Bahis 0:</b> Bahsinizi koyduğunuz andan ilk yarının sonuna kadar her iki takım
                  da eşit sayıda gol atar.
                </li>
                <li key={12}>
                  <b>Bahis 2:</b> Bahsi koyduğunuz andan ilk yarının sonuna kadar konuk takım ev
                  sahibi takımdan daha fazla gol atacaktır.
                </li>
                <br />
                <b>Örnek 1:</b> Bahsinizi koyduğunuz anda skor 0:1 olur. İlk yarının sonunda skor
                1:1 oldu. Bahis 1&apos;i (ev sahibi takım) seçerseniz kazanırsınız, çünkü bahisinizi
                koyduğunuz andan ilk yarının sonuna kadar ev sahibi takım deplasman takımından daha
                fazla gol attı.
                <br />
                <br />
                <b>Örnek 2:</b>Bahsinizi koyduğunuz anda skor 0:1 olur. İlk yarının sonunda skor 1:2
                oldu. Eğer 0 (beraberlik) bahisini seçerseniz kazanırsınız, çünkü bahisinizi
                koyduğunuz andan ilk yarının sonuna kadar her iki takım da eşit sayıda gol atmıştır.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Sonraki Hedef</Accordion.Header>
              <Accordion.Body>
                Sonraki Gol bahis türü ile bir sonraki golü hangi takımın atacağını tahmin
                edersiniz.
                <br />
                <br />
                <b>Olası Bahisler </b>
                <li className="mt-3" key={13}>
                  <b>Bahis 1:</b> Bir sonraki golü ev sahibi takım atar.
                </li>
                <li key={14}>
                  <b>Bahis 0:</b> Maç bitmeden hiçbir takım başka gol atamaz.
                </li>
                <li key={15}>
                  <b>Bahis 2:</b> Bir sonraki golü konuk takım atacaktır.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Handikap</Accordion.Header>
              <Accordion.Body>
                Handikap bahis türünde takım bir veya daha fazla gol şeklinde kurgusal bir avantaj
                elde eder. Handikap bahisi ile handikapı dikkate alarak hangi takımın daha fazla gol
                atacağını tahmin edersiniz. Sporun türüne bağlı olarak handikap gollere, puanlara
                veya set galibiyetlerine yöneliktir.
                <br />
                <br />
                <b>Olası Bahisler </b>
                <li className="mt-3" key={16}>
                  <b>Bahis 1:</b> Bahsin oynandığı andan maç sonuna kadar handikap dikkate alınarak
                  ev sahibi takım misafir takımdan daha fazla gol atacaktır.
                </li>
                <li key={17}>
                  <b>Bahis 0:</b> Bahsin oynandığı andan maç sonuna kadar handikap dikkate alınarak
                  her iki takım da aynı sayıda gol atacaktır.
                </li>
                <li key={18}>
                  <b>Bahis 2:</b> Bahsin oynandığı andan maç sonuna kadar handikap dikkate alınarak
                  konuk takım ev sahibi takımdan daha fazla gol atacaktır.
                </li>
                <br />
                <br />
                Ek handikap bahis türleri <br />
                <br />
                <li key={19}>
                  {' '}
                  <b>Handikap Uzatma Zamanı:</b> Handikap Uzatma Zamanı bahisi, uzatmalar ve varsa
                  penaltı atışları da dahil olmak üzere oyunun sonundaki handikap anlamına gelir.
                </li>
                <li key={20}>
                  {' '}
                  <b>Handikap Setleri:</b>Handikap Setleri bahisi örneğin tenis sporu için
                  sunulmaktadır. Handikap kazanılan setleri ifade eder.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Çift şans</Accordion.Header>
              <Accordion.Body>
                Çifte Şans bahis türünde tek bir bahisle aynı anda iki farklı sonuca bahis
                oynarsınız.
                <br />
                <br />
                <b>Olası Bahisler </b>
                <li className="mt-3" key={21}>
                  <b>Bahis 1X:</b> Ev sahibi takım kazanır veya oyun berabere biter.
                </li>
                <li key={22}>
                  <b>Bahis 12:</b> Ev sahibi veya misafir takım kazanır.
                </li>
                <li key={23}>
                  <b>Bahis X2:</b> Konuk takım kazanır veya oyun berabere biter.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Köşeler Üst/Alt</Accordion.Header>
              <Accordion.Body>
                Korner Üst/Alt bahis türünde, bir maçtaki toplam köşe vuruşu sayısının belirlenen
                korner sayısının (C) altında mı üstünde mi olacağına dair bahis oynarsınız. Bu
                bahisin sonucu değerlendirilirken bir maçtaki tüm köşe vuruşları sayılır.
                <br />
                <br />
                <b>Örnek: </b>
                <br />
                Belirtilen köşe vuruşu sayısı 12,5&apos;tir. Maçta on iki veya daha az köşe vuruşu
                varsa, Under&apos;a oynanan bahis kazanır. Maçta on üç veya daha fazla köşe vuruşu
                olması durumunda Over&apos;a oynanan bahis kazanır.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>Her iki takım puanı</Accordion.Header>
              <Accordion.Body>
                Her İki Takım da Gol Atar bahisi yaptığınızda, her iki takımın da en az bir gol atıp
                atmayacağına bahis yapmış olursunuz.
                <br />
                <br />
                <b>Olası Bahisler </b>
                <li className="mt-3" key={24}>
                  <b>Bahis Evet:</b> Her iki takım da en az bir gol atar.
                </li>
                <li key={25}>
                  <b>Bahis Hayır:</b> Takımlardan biri veya her ikisi de gol atamayacak.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>Hak kazanmak için</Accordion.Header>
              <Accordion.Body>
                Hak Kazanacak bahis türüyle, hangi takımın bir müsabakanın bir sonraki turuna
                çıkacağını önceden belirtirsiniz.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>İlk Yarı/Final Score</Accordion.Header>
              <Accordion.Body>
                İlk Yarı/Final Skoru üzerine bahis yaparken tek bir bahisle ilk yarının nasıl
                geçeceğini ve oyunun nasıl sonuçlanacağını tahmin edersiniz. Genel olarak, bu bahis
                türünde seçebileceğiniz 9 bahis vardır. Bir bahis iki sayıdan oluşur. Bahisteki ilk
                sayı ilk yarıyı kimin kazanacağını gösterir. Bahisteki ikinci sayı kimin
                kazanacağını gösterir oyun.
                <br />
                <br />
                <b>Olası Bahisler </b>
                <li className="mt-3" key={25}>
                  1 = Ev sahibi takım
                </li>
                <li key={26}>0 = Beraberlik</li> <li key={27}>2 = Deplasman Takımı</li>
                <b>Örnek 1: </b> Bahis 1/2: İlk yarıyı ev sahibi takım kazanır ve ilk yarıyı konuk
                takım kazanır. oyun. <br />
                <b>Örnek 2:</b> Bahis 0/1: İlk yarı berabere biter ve ev sahibi takım maçı kazanır.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>Sonuç</Accordion.Header>
              <Accordion.Body>
                Sonuç bahisi ile oyunun kesin sonucunu tahmin edersiniz.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>1. Setin Kazananı</Accordion.Header>
              <Accordion.Body>
                1. Setin Kazananı bahis türünde ilk seti hangi oyuncunun kazanacağını önceden
                belirtirsiniz.
                <br />
                <br />
                <b>Olası Bahisler </b>
                <li className="mt-3" key={28}>
                  <b>Bahis 1:</b> Oyuncu 1 ilk seti kazanır.
                </li>
                <li key={29}>
                  <b>Bahis 2:</b> Oyuncu 2 ilk seti kazanır.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>Oyunlar Üst/Alt</Accordion.Header>
              <Accordion.Body>
                Oyunlar Üst/Alt bahis türüyle, bir karşılaşmadaki tüm setlerdeki toplam oyun
                sayısının önceden verilen oyun çizgisinin (GT) üstünde mi yoksa altında mı olacağını
                önceden belirtirsiniz. Oyunlar Alt/Üst bahisi hem maçın tamamı hem de ilk set için
                sunulur.
                <br />
                <br />
                <b>Örnek:</b> Verilen oyun satırı 30,5&apos;tir. Bir karşılaşmada 30 veya daha az
                oyun varsa, bahis Under ipucuyla kazanacaktır. Bir karşılaşmada 31 veya daha fazla
                oyun varsa bahis, Tip Over ile kazanacaktır.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
              <Accordion.Header>Tekli Bahis</Accordion.Header>
              <Accordion.Body>
                Tekli bahis ile tek bir etkinliğin sonucunu tahmin edersiniz. Tek bir futbol maçı
                çerçevesinde birden fazla bahis koyarsanız, bunlar bahis kuponuna tekli bahisler
                olarak girilir ve birbirinden bağımsız olarak sonuçlandırılır. Sonucu doğru tahmin
                ederseniz bahis kazanırsınız.
                <br />
                <br />
                Vadesi gelen kazançlar şu şekilde hesaplanır: Kazançlar = bahis tutarı x oranlar
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
              <Accordion.Header>İlk 180</Accordion.Header>
              <Accordion.Body>
                İlk 180 bahisi ile ilk olarak hangi oyuncunun 180 (üç atışta 180 puan) atacağını
                tahmin edersiniz.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
              <Accordion.Header>180&apos;lerin çoğu</Accordion.Header>
              <Accordion.Body>
                En Çok 180&apos;ler bahisi ile bir oyunda hangi oyuncunun en fazla 180&apos;lik (üç
                atışta 180 puan) puan alacağını tahmin edersiniz.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="18">
              <Accordion.Header>180 Oyuncu 1 Üst/Alt</Accordion.Header>
              <Accordion.Body>
                180 Oyuncu 1 Üst/Alt bahisiyle, oyuncu 1&apos;in bir oyunda attığı toplam 180
                sayısının (üç atışta 180 sayı) P sütununda gösterilen puanın üstünde mi yoksa
                altında mı olacağını tahmin edersiniz.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="19">
              <Accordion.Header>180 Oyuncu 2 Üst/Alt</Accordion.Header>
              <Accordion.Body>
                180 Oyuncu 2 Üst/Alt bahisiyle, bir oyunda 2. oyuncu tarafından atılan toplam 180
                sayısının (üç atışta 180 sayı) P sütununda gösterilen puanın üstünde mi yoksa
                altında mı olacağını tahmin edersiniz.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="20">
              <Accordion.Header>Uzun Vadeli Bahis</Accordion.Header>
              <Accordion.Body>
                Uzun vadeli bahiste, bir turnuvadaki veya sezondaki bir etkinliğe bahis oynarsınız.
                Sonuç, kural olarak ancak bir süre sonra bilinecektir.
                <br />
                <br />
                <b>Uzun vadeli bahis örnekleri</b>
                <br />- Dünya şampiyonu kim olacak? <br />- Kim yükselecek? <br />- Kim finale
                çıkacak? <br />- Bu grubun zirvesini kim bitirecek?
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="21">
              <Accordion.Header>Çoklu Bahis</Accordion.Header>
              <Accordion.Body>
                Çoklu bahis ile birçok farklı spor etkinliğinin sonucunu tahmin edersiniz.
                Bahisleriniz bahis kuponunda tek bir bahiste birleştirilir. Tek bir bahiste en fazla
                10 etkinliği birleştirebilirsiniz. Çoklu bahisinizin tüm sonuçlarını doğru tahmin
                ederseniz bahis kazanılır. En az bir olayı yanlış tahmin etmeniz durumunda bahsin
                tamamı kaybedilmiş sayılır.
                <br />
                <br />
                Vadesi gelen kazançlar aşağıdaki şekilde hesaplanır:
                <br />
                Kazançlar = bahis miktarı x (oran A x oran B x oran C x oran ...)
                <br />
                <br />
                <b> Not</b>
                <br />
                Tek bir futbol maçı çerçevesinde birden fazla bahis koyarsanız, bunlar bahis
                kuponuna Çoklu bahis olarak değil, Tekli bahis olarak girilir ve birbirlerinden
                bağımsız olarak sonuçlandırılır.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="22">
              <Accordion.Header>Sistem Bahis</Accordion.Header>
              <Accordion.Body>
                Sistem bahisinde, bir bahis kuponuna aynı anda birden fazla Çoklu bahis koyarsınız.
                Birkaç farklı spor etkinliğinin sonuçlarını tahmin edersiniz ve kazanç elde etmek
                için doğru tahmin edilmesi gereken minimum sonuç sayısını tanımlarsınız (örn. 4
                üzerinden 2). Artık bahis yaptığınız etkinlikleri kapsayan çeşitli Çoklu bahisler
                oluşturuluyor (Sistem bahisleri tablosuna bakın).
                <br />
                <br />
                Bir Sistem bahisi bir veya daha fazla yanlış tahmin içerebilir ve yine de
                kazanabilir.
                <br />
                <br />
                <b>Örnek</b>
                <br />
                Dört etkinliğe bahis yaparsınız ve 4 üzerinden 2 sistemini seçersiniz. Aşağıdaki
                altı adet iki katlı Çoklu bahis artık bu dört etkinlikten oluşur: AB, AC, AD, BC,
                BD, CD. En az iki olayı doğru tahmin ederseniz bahsi kazanırsınız.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="23">
              <Accordion.Header>Banker Sistem Bahsi</Accordion.Header>
              <Accordion.Body>
                Banker Sistem bahisi, Sistem bahisinin bir uzantısıdır. Bir olayın
                gerçekleşeceğinden eminseniz, bunu Sistem bahsinizde banker olarak
                işaretleyebilirsiniz. Ayrıca çeşitli etkinlikleri bankacı olarak da
                işaretleyebilirsiniz.
                <br />
                <br />
                <b>Bankacı Sistem bahis örneği </b>
                <br />
                Beş etkinliğe (A, B, C, D, E) bahis koyarsınız ve bir etkinliği (örneğin E) bankacı
                olarak işaretlersiniz. Diğer dört olay için (A, B, C, D) 4 üzerinden 2 sistemini
                seçersiniz. Şimdi aşağıdaki altı adet iki katlı Çoklu bahis oluşturulur: AB, AC, AD,
                BC, BD, CD. Bankacı E olarak işaretlenen etkinlik artık her kombinasyona ek olarak
                işleniyor: ABE, ACE, ADE, BCE, BDE, CDE. Banker, E olarak işaretlediğiniz etkinlik
                ve diğer 4 etkinlikten (A, B, C, D) en az 2&apos;si doğru tahmin edilmişse bahsi
                kazanırsınız.
                <br />
                <br />
                Banker olarak işaretlenen etkinliği doğru tahmin etmediyseniz, tüm bahis (doğru
                tahmin edilen diğer etkinliklerden bağımsız olarak) kaybedilmiş sayılır.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="24">
              <Accordion.Header>Üst/Alt</Accordion.Header>
              <Accordion.Body>
                Üst/Alt bahis türüyle, bir maçta atılan toplam gol sayısının G sütununda belirtilen
                gol çizgisinin altında mı yoksa üstünde mi olacağını tahmin edersiniz. Bir maçta
                atılan tüm goller, bahisin sonuçlandırılmasında dikkate alınır (bahis oynanmadan
                önce atılan goller dahil).
                <br />
                <br />
                Alt/Üst bahisi maçın tamamını veya yalnızca ilk yarısını kapsayabilir.
                <br />
                <br />
                Spora bağlı olarak Üst/Alt bahisleri toplam gol (kale çizgisi), puan (puan çizgisi)
                veya oyun (oyun çizgisi) sayısı üzerine yapılır.
                <br />
                <br />
                <b> Örnek</b> <br />
                Verilen gol çizgisi: 2.5. <br />
                Maçta iki veya daha az gol atılırsa, Under&apos;a oynanan bahis kazanır.
                <br />
                Maçta üç veya daha fazla gol atılırsa Over bahisi kazanır.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Tab>
    </Tabs>
  );
}
