import { useNavigate } from 'react-router-dom';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { useMediaQuery } from 'react-responsive';

export default function GoBack(props) {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });
  const goBack = () => {
    if (props.location != null) {
      if (props.selectSport != null) {
        navigate(props.location, {
          state: props.selectSport
        });
      } else {
        navigate(props.location);
      }
    } else {
      navigate(-1);
    }
  };

  // const titles = (
  //   <div>
  //     <div className="sport_time_detail mt-1">
  //       <SubTitleH6 title="Sport" />
  //     </div>
  //     <div className="sport_time_detail title2">
  //       <TitleH6 title={props.title} />
  //     </div>
  //   </div>
  // );

  return (
    <div className="" onClick={goBack}>
      {isDesktop ? (
        <img
          className="icon-color"
          width="18"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/backicon-caret.svg`}
        />
      ) : (
        <img width="18" height="25" src={`${AWS_BUCKET_URL}/Icons/backicon-caret.svg`} />
      )}
    </div>
  );
}
