import React, { useState } from 'react';
import { AWS_BUCKET_URL } from '../../toolkit/constants';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);
  return (
    <img
      onClick={scrollToTop}
      style={{ display: visible ? 'inline' : 'none' }}
      width="30"
      height={30}
      src={`${AWS_BUCKET_URL}/Icons/149.png`}
    />
  );
};

export default ScrollButton;
