import { useSelector } from 'react-redux';
import oddsTransformer from '../oddsTransformer';

const _oddsTransformer = oddsTransformer();

const useOddTransformer = () => {
  const customerCustomOddsMultiplier = useSelector(
    (state) => state.auth.userLimits?.customOddsMultiplier
  );

  const customOddsMultiplier = customerCustomOddsMultiplier;
  const transform = (isLive, isOutRights, marketNumericId, sportEventStartTime, odds) => {
    const customOdds = (customOddsMultiplier ?? 1.0) * odds;

    return _oddsTransformer.transform(
      new Date(),
      isLive,
      isOutRights,
      marketNumericId,
      sportEventStartTime,
      customOdds
    );
  };

  const transformWithDisplayFormatted = (
    isLive,
    isOutRights,
    marketNumericId,
    sportEventStartTime,
    odds
  ) => {
    const transformed = transform(isLive, isOutRights, marketNumericId, sportEventStartTime, odds);

    if (transformed >= 100) {
      return transformed.toFixed(0);
    }

    return transformed > 10 ? transformed.toFixed(1) : transformed.toFixed(2);
  };

  return { transform, transformWithDisplayFormatted };
};

export default useOddTransformer;
