import { useCallback, useState, useEffect, useRef } from 'react';
import usePrevious from '../../toolkit/useHooks/usePrevious';
import { AWS_BUCKET_URL } from '../../toolkit/constants';
import { useDispatch, useSelector } from 'react-redux';
import { addBetToBetSlip, removeBetFromBetSlip } from 'stores/betSlip';
import useOddTransformer from '../../toolkit/useHooks/useOddsTransformer.js';
import { checkIfLiveEvent } from '../../toolkit/utils';
import { useMediaQuery } from 'react-responsive';

function OddButton(props) {
  const dispatch = useDispatch();
  const betSlip = useSelector((state) => state.betSlip);
  const { providerId, marketStringId, outcomeId } = props;
  const match = useSelector((state) => state.match.events[providerId]);
  const [selected, setSelected] = useState(false);
  const [outcome, setOutcome] = useState({});
  const [market, setMarket] = useState({});
  const [oddsClass, setOddsClass] = useState('');
  const [renderNull, setRenderNull] = useState(false);
  const prevOdds = usePrevious(outcome?.odds);
  const updateTimer = useRef(null);
  const hasOdds = outcome?.odds !== 0;
  const oddsTransformer = useOddTransformer();
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });

  useEffect(() => {
    try {
      const newMarket = match.markets.find((m) => m.stringId === marketStringId);
      const newOutcome = newMarket.outcomes?.find((o) => o.id === outcomeId);
      setOutcome(newOutcome);
      setMarket(newMarket);
    } catch (error) {
      setRenderNull(true);
    }
  }, [match]);

  const actionBetSlipOdds = useCallback((e) => {
    e.preventDefault();
    if (!selected) {
      dispatch(
        addBetToBetSlip({
          providerId: providerId,
          marketStringId: marketStringId,
          marketId: market.id,
          outcomeId: outcomeId,
          outcomeName: outcome?.name,
          odds: outcome?.odds,
          match: match
        })
      );
    } else {
      dispatch(
        removeBetFromBetSlip({
          providerId: providerId,
          marketStringId: marketStringId,
          outcomeId: outcomeId
        })
      );
    }
  });

  useEffect(() => {
    const selectionStatus = betSlip.bets.some(
      (b) =>
        b.providerId === providerId &&
        b.marketStringId === marketStringId &&
        b.outcomeId === outcomeId
    );
    if (selected !== selectionStatus) {
      setSelected(selectionStatus);
    }
  }, [betSlip]);

  //   const usePreviousValue = (value) => {
  //     const ref = useRef();
  //     useEffect(() => {
  //       ref.current = value;
  //     });
  //     return ref.current;
  //   };

  function setUpdate(oddsClass) {
    updateTimer.current = setTimeout(() => {
      setOddsClass(oddsClass);
      updateTimer.current = null;
    }, 5000);
  }

  useEffect(() => {
    const newOdds = outcome?.odds;
    if (prevOdds && newOdds !== prevOdds) {
      setOddsClass(` odd-${newOdds > prevOdds ? 'increased' : 'decreased'}`);
      setUpdate('');
    }
  }, [outcome?.odds, prevOdds]);

  useEffect(() => {
    return () => {
      if (updateTimer.current) {
        clearTimeout(updateTimer.current);
        updateTimer.current = null;
      }
    };
  }, []);

  if (renderNull || outcome?.odds == 1.0) {
    if (isDesktop) {
      return (
        <div className="border-tertiary rounded justify-content-center text-dark px-1 d-inline-flex w-100 fw-bold mt-auto pt-1 pb-1 fs-8">
          <img
            className="icon-white"
            width="17"
            height="18"
            src={`${AWS_BUCKET_URL}/Icons/lockicon.svg`}
          />
        </div>
      );
    } else {
      return (
        <div className="border-tertiary rounded justify-content-center text-dark px-1 d-inline-flex w-100 fw-bold mt-auto pt-1 pb-1 fs-8">
          <img width="17" height="18" src={`${AWS_BUCKET_URL}/Icons/lockicon.svg`} />
        </div>
      );
    }
  }

  return (
    <div
      key={outcomeId}
      className={
        isDesktop
          ? selected
            ? 'rounded justify-content-center px-1 d-inline-flex w-100 fw-bold mt-auto pt-1 pb-1 fs-8 text-dark' +
              oddsClass
            : 'rounded justify-content-center text-white px-1 d-inline-flex w-100 fw-bold mt-auto pt-1 pb-1 fs-8' +
              oddsClass
          : 'border-tertiary rounded justify-content-center text-dark px-1 d-inline-flex w-100 fw-bold mt-auto pt-1 pb-1 fs-8' +
            oddsClass
      }
      style={{
        backgroundColor: isDesktop
          ? selected
            ? hasOdds
              ? '#FFCC00'
              : '#696767'
            : '#696767'
          : selected
          ? hasOdds
            ? '#FFCC00'
            : '#696767'
          : 'transparent'
      }}
      onClick={actionBetSlipOdds}>
      {hasOdds
        ? oddsTransformer.transformWithDisplayFormatted(
            checkIfLiveEvent(match),
            match.isSeason,
            market.id,
            new Date(match.gameDate),
            outcome.odds
          )
        : '-'}
      {selected}
    </div>
  );
}
export default OddButton;
