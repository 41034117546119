import MatchDetail from 'components/general/match_detail';

const MatchDetailsDesktop = ({ matchDetailId, onMatchDetailIdSet }) => {
  return (
    <div>
      <MatchDetail matchDetailId={matchDetailId} onMatchDetailIdSet={onMatchDetailIdSet} />
    </div>
  );
};

export default MatchDetailsDesktop;
