import { useState, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { setLanguageCode } from 'toolkit/utils';
// import { useDispatch } from 'react-redux';
// import { fetchCompetitorsNameByLanguage } from 'stores/match';
import 'dayjs/locale/en';
import 'dayjs/locale/tr';

const Language = (props) => {
  const { isDesktop } = props;
  // const dispatch = useDispatch();
  const { i18n } = useTranslation('translations');
  // console.log(i18n.language);
  const en = `${AWS_BUCKET_URL}/Icons/127.png`;
  const de = `${AWS_BUCKET_URL}/Icons/126.png`;
  const tr = `${AWS_BUCKET_URL}/Icons/128.png`;
  const nl = `${AWS_BUCKET_URL}/Icons/nl.png`;
  const bg = `${AWS_BUCKET_URL}/Icons/bulgaria.png`;
  const fr = `${AWS_BUCKET_URL}/Icons/france.png`;

  const languages = [
    {
      label: 'English',
      shortLabel: 'EN',
      id: 'en-US',
      icon: en
    },
    {
      label: 'Turkce',
      shortLabel: 'TR',
      id: 'tr-TR',
      icon: tr
    },
    {
      label: 'Deutsch',
      shortLabel: 'DE',
      id: 'de-DE',
      icon: de
    },
    {
      label: 'Dutch',
      shortLabel: 'NL',
      id: 'nl-NL',
      icon: nl
    },
    {
      label: 'Bulgarian',
      shortLabel: 'BG',
      id: 'bg-BG',
      icon: bg
    },
    {
      label: 'French',
      shortLabel: 'FR',
      id: 'fr-FR',
      icon: fr
    }
  ];
  const [language, setLanguage] = useState(
    languages.find((l) => l.id === i18n.language) || languages[0]
  );

  useEffect(() => {
    if (language.id !== undefined && language.id !== null) {
      languageChanged();
    }
  }, []);

  useEffect(() => {
    languageChanged();
    setLanguageCode(language.id);
    i18n.changeLanguage(language.id);
    dayjs.locale(language.id);
  }, [language]);

  function languageChanged() {
    // dispatch(fetchCompetitorsNameByLanguage(language));
  }

  return (
    <Dropdown className="language-dropdown" align="end">
      <Dropdown.Toggle
        variant="link"
        id="dropdown-basic"
        className={`text-decoration-none text-white no-arrow d-flex align-items-center ${
          isDesktop ? 'p-0' : 'pb-3'
        }`}>
        <img src={language.icon} className="me-2" alt="Language" />
        <span>{language.shortLabel}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {languages.map((lang) => (
          <Dropdown.Item key={lang.id} as={Button} onClick={() => setLanguage(lang)}>
            <img src={lang.icon} alt="" className="me-2" />
            <span>{lang.label}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Language;
