import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { login } from 'stores/auth';
import FetchButton from 'components/general/FetchButton';
import { errorToaster } from 'mixin/general';

export default function Login() {
  const { t } = useTranslation('translations', { useSuspense: false });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (!form.checkValidity()) throw new Error('Please fill all required fields');
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      handleSubmit(e);
      setIsSubmitting(true);
      const response = await dispatch(login({ username, password })).unwrap();
      if (!response) {
        throw new Error(t('invalid'));
      }
      setTimeout(() => {
        navigate('/');
      });
    } catch (error) {
      errorToaster(error);
    }
    setIsSubmitting(false);
  };

  return (
    <Container
      fluid
      className="bg-dark-light text-white min-vh-100 d-flex flex-column justify-content-center">
      <div className="Login">
        <Form noValidate validated={validated} onSubmit={handleLogin}>
          <div className="mb-3">
            <Form.Control
              required
              label={t('username')}
              placeholder="E-Mail/Customer Card"
              autoFocus
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
            />
            <Form.Control.Feedback type="invalid">Please enter a valid email</Form.Control.Feedback>
          </div>
          <div className="mt-3"></div>
          <div className="mb-3">
            <Form.Control
              required
              label={t('password')}
              placeholder={t('password')}
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
            />
            <Form.Control.Feedback type="invalid">{t('validpassword')}</Form.Control.Feedback>
          </div>
          <div className="mt-3"></div>
          <Form.Group>
            <FetchButton
              className="w-100 bg-danger text-white p-2 fw-bold mt-3 border border-danger"
              type="submit"
              variant="success"
              fetching={isSubmitting}>
              {t('login')}
            </FetchButton>
          </Form.Group>
        </Form>
      </div>
    </Container>
  );
}
