import Desktop from 'components/breakpoints/Desktop.js';
import Default from 'components/breakpoints/Default.js';
import LiveDesktop from './live_desktop';
import LiveMobile from './live_mobile';

function Live() {
  return (
    <>
      <Desktop>
        <LiveDesktop />
      </Desktop>
      <Default>
        <LiveMobile />
      </Default>
    </>
  );
}
export default Live;
