import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { fetchClearFavorites, setSelectedClearFavoritesSport } from 'stores/match/index.js';
import MatchCard from 'components/general/match_card.js';
import SportDetailItem from 'components/SportDetailItem.js';
import eventsToGroup from 'toolkit/eventsToGroup.js';
import { getSportTypeToActiveIcon, getSportTypeToWhiteIcon } from 'toolkit/utils';
import TitleHistoryBack from 'components/general/TitleHistoryBack.js';
import { Col, Row } from 'react-bootstrap';

function ClearFavorites() {
  const dispatch = useDispatch();
  const selectedClearFavoritesSport = useSelector(
    (state) => state.match.selectedClearFavoritesSport
  );
  const clearFavoritesEvents = useSelector(
    (state) => {
      const { clearFavorites, events } = state.match;
      return clearFavorites
        .map((c) => events[c])
        .filter((match) => {
          if (!match || !match.markets) return false;
          return (
            match.markets[0].outcomes[0]?.odds !== null ||
            match.markets[0].outcomes[1]?.odds !== null ||
            match.markets[0].outcomes[2]?.odds !== null
          );
        });
    },
    (a, b) => a.length === b.length
  );
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const locales = useSelector((state) => state.match.locales);
  const { t } = useTranslation('translations', { useSuspense: false });
  const [sports, setSports] = useState({});
  const [sport, setSport] = useState('0');
  const location = useLocation();
  const [selectedMarketType, setSelectedMarketType] = useState(1);

  useEffect(() => {
    dispatch(fetchClearFavorites());
  }, []);

  useEffect(() => {
    const groups = eventsToGroup(clearFavoritesEvents);
    setSports(groups);

    if ((sport === '' || Object.keys(sport).length === 0) && clearFavoritesEvents.length > 0) {
      setSport(
        selectedClearFavoritesSport !== null ||
          sports.hasOwnProperty(Number.parseInt(selectedClearFavoritesSport)) === true
          ? selectedClearFavoritesSport
          : Number.parseInt(Object.keys(groups)[0])
      );
    }
  }, [clearFavoritesEvents]);

  const selectSport = useCallback((id) => {
    setSport(id);
    dispatch(setSelectedClearFavoritesSport(id));
  });

  const calculateMenuItemsDynamic = () => {
    const menuItems = [];
    let icon = '';
    Object.keys(sports).map((sportType, index) => {
      if (sportType === sport) {
        icon = getSportTypeToActiveIcon(sportType);
      } else {
        icon = getSportTypeToWhiteIcon(sportType);
      }

      if (sports[sportType].length > 0) {
        menuItems.push(
          <SportDetailItem
            key={'SportDetailItemKey' + sportType + index}
            active={sport === sportType}
            count={_.sum(sports[sportType].map((l) => l.matches.length))}
            icon={icon}
            onClick={() => {
              selectSport(sportType);
            }}
            text={t('sports.' + sportType)}
            className={'d-flex flex-column p-2 fs-8'}
          />
        );
      }
    });
    return menuItems;
  };

  function getBetType() {
    const betTypes = [
      {
        label: 'Winner',
        id: 1
      },
      {
        label: 'O/U',
        id: 18
      },
      {
        label: 'HC',
        id: 14
      },
      {
        label: 'NG',
        id: 8
      },
      {
        label: 'BS',
        id: 29
      },
      {
        label: 'DC',
        id: 10
      }
    ];
    return (
      <div className="tip-types shadow">
        {betTypes.map((betType) => (
          <div className="d-inline-block w-25 fw-bold " key={betType.id}>
            <button
              className={`btn btn-light btn-sm w-100 border fs-8 ${
                selectedMarketType === betType.id ? 'match-date-bg-color fw-bold text-white' : ''
              }`}
              onClick={() => setSelectedMarketType(betType.id)}>
              {betType.label}
            </button>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="container-fluid scrollable-y">
      <div className="overflow-auto text-nowrap bg-dark mx-n3 d-flex">
        {calculateMenuItemsDynamic()}
      </div>
      <TitleHistoryBack title={t('start')} subTitle={t('clearfavourites')} />
      {sport === '0' && (
        <Row className="mt-n2">
          <Col className="tip-types-container">{getBetType()}</Col>
        </Row>
      )}
      <div className="">
        {sport !== '' &&
          sports[sport] !== undefined &&
          sports[sport].length > 0 &&
          sports[sport].map((leagueGroup, index) => {
            return (
              <Fragment
                key={
                  'leaguenamesfragment' +
                  leagueGroup.category +
                  leagueGroup.league +
                  leagueGroup.matches[0].gameNumber +
                  index
                }>
                <div
                  className="text-start pb-2 fw-bold pt-1 fs-7"
                  key={
                    'leaguenames' +
                    leagueGroup.category +
                    leagueGroup.league +
                    leagueGroup.matches[0].gameNumber
                  }>
                  {leagueGroup.category + ' / ' + leagueGroup.league}
                </div>
                {leagueGroup.matches.map((match, index) => (
                  <MatchCard
                    key={match.providerId}
                    providerId={match.providerId}
                    marketType={selectedMarketType}
                    index={index}
                    sport={sport}
                    path={location.pathname}
                    locales={locales}
                    selectedLocale={selectedLocale}
                  />
                ))}
              </Fragment>
            );
          })}
      </div>
    </div>
  );
}

export default ClearFavorites;
