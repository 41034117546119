import { useEffect, useRef } from 'react';

export default function SportDetailItem(props) {
  const ref = useRef();
  const { icon, active, text, count, className, onClick } = props;

  useEffect(() => {
    if (active && ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [active]);

  return (
    <button
      ref={ref}
      onClick={onClick}
      className={`btn btn-dark text-white flex-center px-3 ${className}`}>
      <span className={active ? 'active-icon-color' : ''}>{icon}</span>
      <span className={'text-nowrap ' + (active === true ? 'text-white' : '')}>
        {text}
        {count !== undefined ? ' (' + count + ')' : null}
      </span>
    </button>
  );
}
