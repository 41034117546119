import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchTopMatches } from 'stores/match/index.js';
import MatchCard from 'components/general/match_card.js';
import TitleHistoryBack from 'components/general/TitleHistoryBack.js';
import { Col, Row } from 'react-bootstrap';
import eventsToGroup from 'toolkit/eventsToGroup.js';

export default function TopMatchesList() {
  const dispatch = useDispatch();
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const locales = useSelector((state) => state.match.locales);
  const [selectedMarketType, setSelectedMarketType] = useState(1);
  const { t } = useTranslation('translations');
  const [sports, setSports] = useState({});
  const topMatchesEvents = useSelector(
    (state) => {
      const { topMatches, events } = state.match;
      return topMatches
        .map((c) => events[c])
        .filter((match) => {
          if (!match || !match.markets) return false;
          return (
            match.markets[0].outcomes[0]?.odds !== null ||
            match.markets[0].outcomes[1]?.odds !== null ||
            match.markets[0].outcomes[2]?.odds !== null
          );
        });
    },
    (a, b) => a.length === b.length
  );

  useEffect(() => {
    const groups = eventsToGroup(topMatchesEvents);
    setSports(groups);
  }, [topMatchesEvents]);

  useEffect(() => {
    dispatch(fetchTopMatches());
  }, []);

  function getBetType() {
    const betTypes = [
      {
        label: 'Winner',
        id: 1
      },
      {
        label: 'O/U',
        id: 18
      },
      {
        label: 'HC',
        id: 14
      },
      {
        label: 'NG',
        id: 8
      },
      {
        label: 'BS',
        id: 29
      },
      {
        label: 'DC',
        id: 10
      }
    ];
    return (
      <div className="tip-types shadow">
        {betTypes.map((betType) => (
          <div className="d-inline-block w-25 fw-bold " key={betType.id}>
            <button
              className={`btn btn-light btn-sm w-100 border fs-8 ${
                selectedMarketType === betType.id ? 'match-date-bg-color fw-bold text-white' : ''
              }`}
              onClick={() => setSelectedMarketType(betType.id)}>
              {betType.label}
            </button>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="container">
      <TitleHistoryBack title={t('start')} subTitle={t('topmatches')} />
      <Row className="mt-n2">
        <Col className="tip-types-container">{getBetType()}</Col>
      </Row>
      <div className="">
        {sports[0] !== undefined &&
          sports[0].length > 0 &&
          sports[0].map((leagueGroup, index) => {
            return (
              <Fragment
                key={
                  'leaguenamesfragment' +
                  leagueGroup.category +
                  leagueGroup.league +
                  leagueGroup.matches[0].gameNumber +
                  index
                }>
                <div
                  className="text-start pb-2 fw-bold pt-1 fs-7"
                  key={
                    'leaguenames' +
                    leagueGroup.category +
                    leagueGroup.league +
                    leagueGroup.matches[0].gameNumber
                  }>
                  {leagueGroup.category + ' / ' + leagueGroup.league}
                </div>
                {leagueGroup.matches.map((match, index) => (
                  <MatchCard
                    key={match.providerId}
                    providerId={match.providerId}
                    marketType={selectedMarketType}
                    index={index}
                    sport={'0'}
                    path={location.pathname}
                    locales={locales}
                    selectedLocale={selectedLocale}
                  />
                ))}
              </Fragment>
            );
          })}
      </div>
    </div>
  );
}
