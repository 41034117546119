import { Carousel } from 'react-bootstrap';
import BetMatchCard from 'components/general/bet_match_card';
import BettingHistoryTitle from 'components/general/betting_history_title';
import BetBottom from 'components/general/bet_bottom';
import { EnumBetslipStatus } from '../../../toolkit/Enums';

const BettingHistoryItem = ({ bettingHistoryItem }) => {
  const matchStatusTypes = ['lost', 'won', 'undecided', 'cancelled', 'cashout'];

  return (
    <div className="shadow p-1 mx-n3">
      <div className="px-3 fw-bold bg-gray-600">
        <BettingHistoryTitle
          count={bettingHistoryItem.matches.filter((i) => i.banker === false).length}
          betSlipType={bettingHistoryItem.betSlipType}
          betSlipStatus={bettingHistoryItem.betSlipStatus}
          combinations={bettingHistoryItem.combinations}
          numberOfBets={bettingHistoryItem.numberOfBets}
        />
      </div>
      <div className="px-2">
        <Carousel
          className="betting-history-carousel"
          variant="dark"
          indicatorLabels={bettingHistoryItem.matches.map(
            (m) =>
              matchStatusTypes[
                bettingHistoryItem.betSlipStatus === EnumBetslipStatus.CASHOUT ? 4 : m.outcomeStatus
              ]
          )}>
          {bettingHistoryItem.matches.map((match, index1) => {
            return (
              <Carousel.Item key={index1}>
                <BetMatchCard
                  key={'BetMatchCard' + index1}
                  match={match}
                  eventId={match.eventId}
                  count={bettingHistoryItem.matches.length}
                  betSlipType={bettingHistoryItem.betSlipType}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      <div className="ps-3 mt-n2">
        <BetBottom bettingHistoryItem={bettingHistoryItem} />
      </div>
    </div>
  );
};

export default BettingHistoryItem;
