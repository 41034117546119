import { getLanguageCode } from 'toolkit/utils';
import EN from './EN';
import DE from './DE';
import TR from './TR';
export default function LegalNote() {
  if (getLanguageCode() == 'tr-TR') {
    return (
      <>
        <TR />
      </>
    );
  } else if (getLanguageCode() == 'de-DE') {
    return (
      <>
        <DE />
      </>
    );
  } else
    return (
      <>
        <EN />
      </>
    );
}
