import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { setSelectedLiveDesktopSport } from 'stores/match';
import { sportIdToSportType } from 'toolkit/utils';
// import Loading from '../../../components/general/loading.js';
import eventsToGroup from 'toolkit/eventsToGroup';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { AWS_BUCKET_URL, sports as sportsObj } from 'toolkit/constants';
import { getSportTypeToWhiteIcon, getSportTypeToGreenIcon } from 'toolkit/utils';

export default function TopSidebar() {
  const dispatch = useDispatch();
  const liveEvents = useSelector(
    (state) => {
      const { liveMatches, events } = state.match;
      return liveMatches.map((c) => events[c]);
    },
    (a, b) => a.length === b.length
  );
  const selectedLiveSportDesktop = useSelector((state) => state.match.selectedLiveSportDesktop);
  const { t } = useTranslation('translations');
  const swiperRef = useRef();
  const [sports, setSports] = useState({});

  const sliderSettings = {
    440: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    680: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 10
    }
  };

  useEffect(() => {
    const groups = eventsToGroup(liveEvents);
    setSports(groups);
    if (selectedLiveSportDesktop === null && liveEvents.length > 0) {
      const type =
        selectedLiveSportDesktop !== null ||
        sports.hasOwnProperty(Number.parseInt(selectedLiveSportDesktop)) === true
          ? selectedLiveSportDesktop
          : Object.keys(groups)[0];
      dispatch(setSelectedLiveDesktopSport(type));
    }
  }, [selectedLiveSportDesktop, liveEvents]);

  // if (state.loadingLiveMatches) {
  //   return <Loading message={t('pages.live.messageLoadingLiveMatches')} />;
  // }

  const handleTopSportClick = (sportType, swiper, index) => {
    if (selectedLiveSportDesktop === sportType) {
      dispatch(setSelectedLiveDesktopSport(null));
    } else {
      dispatch(setSelectedLiveDesktopSport(sportType));
    }
    swiper.slideTo(index);
  };

  return (
    <div className="row justify-content-between align-items-center pt-5">
      <div className="col-5">
        <h3 className="text-color-green text-start">
          {sportIdToSportType(selectedLiveSportDesktop)}
        </h3>
      </div>
      <div className="col-1">
        <button
          className="card card-body bg-button-dark align-items-center gap-2 border-2"
          onClick={() => swiperRef.current?.slidePrev()}>
          <img
            className="icon-white"
            width="18"
            height="25"
            src={`${AWS_BUCKET_URL}/Icons/backicon-caret.svg`}
          />
        </button>
      </div>
      <div className="col-5">
        <Swiper
          slidesPerView={3}
          breakpoints={sliderSettings}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}>
          {Object.keys(sports).map((sportType, index) => (
            <SwiperSlide key={index}>
              {() => {
                const swiper = useSwiper();
                return (
                  <div
                    className={`card card-body bg-button-dark  flex-center gap-2 p-2 border-2 ${
                      sportType === selectedLiveSportDesktop ? 'text-color-green' : 'text-white'
                    }`}
                    onClick={() => handleTopSportClick(sportType, swiper, index)}>
                    <div className="p-1">
                      <span>
                        {sportType === selectedLiveSportDesktop
                          ? getSportTypeToGreenIcon(sportType)
                          : getSportTypeToWhiteIcon(sportType)}
                      </span>
                      <span
                        className={`badge ${
                          sportType === selectedLiveSportDesktop ? 'text-color-green' : ''
                        } rounded mt-n5`}>
                        {_.sum(sports[sportType].map((l) => l.matches.length))}
                      </span>
                    </div>
                    <h6 className="small mb-0 text-truncate">{sportIdToSportType(sportType)}</h6>
                  </div>
                );
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="col-1">
        <button
          className="card card-body bg-button-dark align-items-center gap-2 border-2"
          onClick={() => swiperRef.current?.slideNext()}>
          <img
            className="icon-white"
            width="18"
            height="25"
            src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`}
          />
        </button>
      </div>
    </div>
  );
}
