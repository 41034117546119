import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { errorToaster } from 'mixin/general';
import FetchButton from 'components/general/FetchButton';
import TitleHistoryBack from 'components/general/TitleHistoryBack';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from 'stores/auth';

export default function EditProfile() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation('translations');
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    email: '',
    gender: '',
    name: '',
    surname: '',
    birthday: '',
    street: '',
    city: '',
    country: '',
    telephone: '',
    zip: ''
  });

  const phoneCodes = [
    { id: 1, name: '+43', countryCode: 'AT' },
    { id: 2, name: '+49', countryCode: 'DE' },
    { id: 3, name: '+423', countryCode: 'CH' },
    { id: 4, name: '+41', countryCode: 'LI' },
    { id: 5, name: '+355', countryCode: 'AR' }
  ];
  const [selectedPhone, setSelectedPhone] = useState(phoneCodes[0].name);

  useEffect(() => {
    if (user) {
      const userData = { ...formData };
      Object.keys(user).forEach((field) => {
        if (Object.keys(userData).indexOf(field) > -1 && user[field]) {
          userData[field] = user[field];
        }
      });
      if (new Date(userData.birthday.split('T')[0]) !== 'Invalid Date') {
        userData.birthday = userData.birthday.split('T')[0];
      } else {
        userData.birthday = '';
      }
      setFormData({ ...userData });
    }
  }, [user]);

  const setPhoneChange = (e) => {
    console.log(e);
    setSelectedPhone(e.target.value);
  };

  const handleFormDataChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (!form.checkValidity()) throw new Error('Please fill all required fields');
  };

  const handleEditProfile = async (e) => {
    try {
      e.preventDefault();
      handleSubmit(e);
      setIsSubmitting(true);
      const response = await dispatch(updateProfile(formData)).unwrap();
      if (response.statusCode !== 200) {
        throw response.errors;
      }
    } catch (error) {
      errorToaster(error);
    }
    setIsSubmitting(false);
  };

  return (
    <section className="py-2 text-start">
      <Container>
        <TitleHistoryBack title={t('myprofile')} />
        <Form noValidate validated={validated} onSubmit={handleEditProfile}>
          <h2 className="mb-3">{t('editprofile')}</h2>
          <h6 className="mb-2">{t('personaldata')}</h6>
          <div className="mb-3">
            <Form.Label htmlFor="gender" className="text-muted opacity-50 fs-2">
              {t('gender')}
            </Form.Label>
            <Form.Select
              id="gender"
              value={formData.gender}
              onChange={(e) => handleFormDataChange('gender', e.target.value)}
              disabled={!user}
              required>
              <option value="" disabled>
                Please select a gender
              </option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="other">Other</option>
            </Form.Select>
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="email" className="text-muted opacity-50 fs-2">
              {t('email')}
            </Form.Label>
            <Form.Control
              type="email"
              id="email"
              placeholder={t('email')}
              value={formData.email}
              onChange={(e) => handleFormDataChange('email', e.target.value)}
              disabled={!user}
              required
            />
            <Form.Control.Feedback type="invalid">Please enter a valid email</Form.Control.Feedback>
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="firstname" className="text-muted opacity-50 fs-2">
              {t('firstname')}
            </Form.Label>
            <Form.Control
              type="text"
              id="firstname"
              placeholder={t('firstname')}
              value={formData.name}
              onChange={(e) => handleFormDataChange('name', e.target.value)}
              disabled={!user}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid first name
            </Form.Control.Feedback>
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="lastname" className="text-muted opacity-50 fs-2">
              {t('lastname')}
            </Form.Label>
            <Form.Control
              type="text"
              id="lastname"
              placeholder={t('lastname')}
              value={formData.surname}
              onChange={(e) => handleFormDataChange('surname', e.target.value)}
              disabled={!user}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid last name
            </Form.Control.Feedback>
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="dateofbirth" className="text-muted opacity-50 fs-2">
              {t('dateofbirth')}
            </Form.Label>
            <Form.Control
              type="date"
              id="dateofbirth"
              value={formData.birthday}
              onChange={(e) => handleFormDataChange('birthday', e.target.value)}
              disabled={!user}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid date of birth
            </Form.Control.Feedback>
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="streethousenumber" className="text-muted opacity-50 fs-2">
              {t('streethousenumber')}
            </Form.Label>
            <Form.Control
              type="text"
              id="streethousenumber"
              placeholder={t('streethousenumber')}
              value={formData.street}
              onChange={(e) => handleFormDataChange('street', e.target.value)}
              disabled={!user}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid street/house number
            </Form.Control.Feedback>
          </div>
          <Row className="mb-3">
            <Col>
              <Form.Label htmlFor="postcode" className="text-muted opacity-50 fs-2">
                {t('postcode')}
              </Form.Label>
              <Form.Control
                type="text"
                id="postcode"
                placeholder={t('postcode')}
                value={formData.zip}
                onChange={(e) => handleFormDataChange('zip', e.target.value)}
                disabled={!user}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid postcode
              </Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label htmlFor="city" className="text-muted opacity-50 fs-2">
                {t('city')}
              </Form.Label>
              <Form.Control
                type="text"
                id="city"
                placeholder={t('city')}
                value={formData.city}
                onChange={(e) => handleFormDataChange('city', e.target.value)}
                disabled={!user}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid city
              </Form.Control.Feedback>
            </Col>
          </Row>
          <div className="mb-3">
            <Form.Label htmlFor="country" className="text-muted opacity-50 fs-2">
              {t('country')}
            </Form.Label>
            <Form.Control
              type="text"
              id="country"
              placeholder={t('country')}
              value={formData.country}
              onChange={(e) => handleFormDataChange('country', e.target.value)}
              disabled={!user}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid country
            </Form.Control.Feedback>
          </div>
          <Row className="mt-2">
            <Col xs={5}>
              <Form.Select value={selectedPhone} onChange={(e) => setPhoneChange(e)}>
                {phoneCodes.map((phoneCode) => {
                  return (
                    <option key={phoneCode.id} value={phoneCode.name}>
                      {phoneCode.countryCode + ' ' + phoneCode.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            <Col xs={1}>
              <h1 className="text-muted opacity-50 fs-2 mt-2">{selectedPhone}</h1>
            </Col>
            <Col xs={6}>
              <Form.Control
                type="text"
                id="phonenumber"
                placeholder={t('phonenumber')}
                value={formData.telephone.split(selectedPhone)[1]}
                onChange={(e) => handleFormDataChange('telephone', selectedPhone + e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <p className="text-muted opacity-50">
                In Accordance with the General Data protection Regulation we inform you that the
                data collected is transferred to the Department of Data Protection and processed
              </p>
            </Col>
          </Row>
          <div className="">
            <FetchButton className="w-100" type="submit" variant="success" fetching={isSubmitting}>
              Send
            </FetchButton>
          </div>
        </Form>
      </Container>
    </section>
  );
}
