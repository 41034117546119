import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import {
  gameDateToMatchTime,
  gameDateToMatchDay,
  MarketIdToMarketName,
  getSportTypeToIconForBetSlip,
  MyBetsTextCreator,
  sportTypeToSportId
} from 'toolkit/utils';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { getTeamNameHelper } from 'helper/matchDetailHelper';
import { EnumMarketName, EnumMatchStatusName } from 'toolkit/Enums.js';
import { useMediaQuery } from 'react-responsive';

function BetMatchCard(props) {
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const liveEvents = useSelector((state) => {
    const { liveMatches, events } = state.match;
    return liveMatches.map((c) => events[c]);
  }, shallowEqual);
  const match = props.match;
  const locales = useSelector((state) => state.match.locales);
  const { t } = useTranslation('translations', { useSuspense: false });
  const teams =
    match.home === null || match.away === null
      ? ['Home', 'Away']
      : [
          getTeamNameHelper(locales, selectedLocale, match.home, t),
          getTeamNameHelper(locales, selectedLocale, match.away, t)
        ];
  const sportType = match.sportType;
  let homeScore1,
    homeScore2,
    awayScore1,
    awayScore2,
    homeScore3,
    awayScore3 = 0;
  let firstHalfHomeScore, firstHalfAwayScore;
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });

  if (match.periodScores) {
    homeScore1 = match.periodScores[0]?.homeScore;
    homeScore2 = match.periodScores[1]?.homeScore;
    homeScore3 = match.periodScores[2]?.homeScore;
    awayScore1 = match.periodScores[0]?.awayScore;
    awayScore2 = match.periodScores[1]?.awayScore;
    awayScore3 = match.periodScores[2]?.awayScore;
  }
  if (homeScore1 && homeScore2) {
    firstHalfHomeScore = homeScore1 + homeScore2;
    firstHalfAwayScore = awayScore1 + awayScore2;
  }

  function getTime(props) {
    let timeLabel = '';
    if (match) {
      const { matchStatus } = props;
      switch (matchStatus) {
        case EnumMatchStatusName.HALFTIME: //half time
          timeLabel = t('components.matchcard.halftime');
          break;
        case EnumMatchStatusName.FIRST_HALF: //first half
        case EnumMatchStatusName.SECOND_HALF: //second half
          // eslint-disable-next-line no-case-declarations
          const liveMatch = liveEvents.find((e) => e.gameNumber === match.eventId) ?? props.match;
          const timeText = liveMatch?.timeText ? Number(liveMatch.timeText?.split(':')[0]) : null;
          if (matchStatus == EnumMatchStatusName.FIRST_HALF && timeText >= 45) {
            console.log(1);
            const stoppageTime = timeText - 45;
            timeLabel = (
              <span>
                {matchStatus == EnumMatchStatusName.FIRST_HALF ? '45' : '90'}
                <sup>+{stoppageTime + 1}&apos;</sup>
              </span>
            );
            break;
          }
          if (matchStatus == EnumMatchStatusName.SECOND_HALF && timeText >= 90) {
            const stoppageTime = timeText - 90;
            timeLabel = (
              <span>
                {matchStatus == EnumMatchStatusName.FIRST_HALF ? '45' : '90'}
                <sup>+{stoppageTime + 1}&apos;</sup>
              </span>
            );
            break;
          }
          timeLabel = liveMatch?.timeText ? (
            <span>{Number(liveMatch.timeText?.split(':')[0]) + 1}&apos;</span>
          ) : null;
          break;
        case EnumMatchStatusName.PENALTIES: //Penalties
          timeLabel = t('components.matchcard.penalties');
          break;
        case EnumMatchStatusName.RETIRED: //retired
          timeLabel = t('components.matchcard.retired');
          break;
        default:
          timeLabel = '';
      }
    }
    return <span className="px-2 py-1 bg-dark text-white rounded-end-5 fs-8">{timeLabel}</span>;
  }

  function getMatchStatusMatchDateCard(match, sportType) {
    const { matchStatus } = match;
    const result = [];
    result.push(
      <span className="px-2 py-1 bg-success text-white fs-8" key={'matchStatus-live'}>
        {t('components.matchcard.live')}
      </span>
    );
    const gameDayandTime = (
      <>
        <span className="px-2 py-1 match-date-bg-color text-white fs-8">
          {gameDateToMatchDay(props.startDate ? props.startDate : match.startDate)}
        </span>
        <span className="px-2 py-1 bg-dark text-white rounded-end-5 fs-8">
          {gameDateToMatchTime(props.startDate ? props.startDate : match.startDate)}
        </span>
      </>
    );
    if (matchStatus == EnumMatchStatusName.ENDED) {
      return (
        <>
          <span className="px-2 py-1 match-date-bg-color text-white fs-8">
            {gameDateToMatchDay(props.startDate ? props.startDate : match.startDate)}
          </span>
          <span className="px-2 py-1 bg-dark text-white rounded-end-5 fs-8">
            {t('components.matchcard.end')}
          </span>
        </>
      );
    } else {
      if (sportType == sportTypeToSportId('Football')) {
        if (
          matchStatus > EnumMatchStatusName.NOT_STARTED &&
          matchStatus <= EnumMatchStatusName.SECOND_HALF
        ) {
          result.push(
            <span
              className="px-2 py-1 bg-dark text-white rounded-end-5 fs-8"
              key={'matchStatus-time'}>
              {getTime(match)}
            </span>
          );
          return <>{result}</>;
        } else if (matchStatus == EnumMatchStatusName.HALFTIME) {
          result.push(
            <span
              className="px-2 py-1 bg-dark text-white rounded-end-5 fs-8"
              key={'matchStatus-halftime'}>
              {t('components.matchcard.halftime')}
            </span>
          );
          return <>{result}</>;
        } else if (matchStatus == EnumMatchStatusName.PENALTIES) {
          result.push(
            <span
              className="px-2 py-1 bg-dark text-white rounded-end-5 fs-8"
              key={'matchStatus-penalties'}>
              {t('components.matchcard.penalties')}
            </span>
          );
          return <>{result}</>;
        } else {
          return gameDayandTime;
        }
      } else {
        if (matchStatus == EnumMatchStatusName.NOT_STARTED || matchStatus == -1) {
          return gameDayandTime;
        } else {
          return (
            <>
              <span className="px-2 py-1 bg-dark-green text-white fs-8">
                {t('components.matchcard.live')}
              </span>
              <span className="px-2 py-1 bg-dark text-white rounded-end-5 fs-8">
                {t(`components.matchcard.time.byMatchStatusId.${match.matchStatus}`)}
              </span>
            </>
          );
        }
      }
    }
  }

  const homeScores = [];
  const awayScores = [];
  const periodScoresLenght = match.periodScores?.length;
  match.periodScores?.forEach((element, index) => {
    if (index === periodScoresLenght - 1 && match.matchStatus != EnumMatchStatusName.ENDED) {
      homeScores.push(
        <span className="text-success" key={index}>
          {element.homeScore}{' '}
        </span>
      );
      awayScores.push(
        <span className="text-success" key={index}>
          {element.awayScore}{' '}
        </span>
      );
    } else {
      homeScores.push(<span key={index}>{element.homeScore} </span>);
      awayScores.push(<span key={index}>{element.awayScore} </span>);
    }
  });

  if (match.matchStatus == EnumMatchStatusName.ENDED) {
    homeScores.push(<span key={'homeScore'}> | {match.homeScore}</span>);
    awayScores.push(<span key={'awayScore'}> | {match.awayScore} </span>);
  }

  const getScore = (team, sportType, matchStatus) => {
    if (matchStatus != -1 && matchStatus != 0 && matchStatus != 100) {
      periodScoresLenght;
      if (
        sportType == sportTypeToSportId('Volleyball') ||
        sportType == sportTypeToSportId('Tennis') ||
        sportType == sportTypeToSportId('TableTennis')
      ) {
        return <span>{team == 'team1' ? homeScores : awayScores}</span>;
      }
    }
    return team == 'team1' ? match.homeScore : match.awayScore;
  };

  function getFirstHalfScore(team, sportType) {
    if (match.periodScores && match.periodScores.length >= 1) {
      let firstHalfScore;
      if (team == 'team1') {
        firstHalfScore =
          sportType == sportTypeToSportId('Football')
            ? homeScore1 + '|'
            : sportType == 1
            ? homeScore3
              ? firstHalfHomeScore + '|'
              : firstHalfHomeScore
            : '';
      } else {
        firstHalfScore =
          sportType == sportTypeToSportId('Football')
            ? awayScore1 + '|'
            : sportType == 1
            ? awayScore3
              ? firstHalfAwayScore + '|'
              : firstHalfAwayScore
            : '';
      }
      return <span>{firstHalfScore}</span>;
    } else {
      return null;
    }
  }

  const get = (team, sportType, match) => {
    const { matchStatus } = match;
    const array = [];
    if (match.matchStatus == EnumMatchStatusName.HALFTIME) {
      if (team == 'team1') {
        // array.push(
        //   <span className="position-absolute top-50 start-0 ms" key={'halftime'}>
        //     {t('components.matchcard.halftime')}
        //   </span>
        // );
      }
      if (
        sportType == sportTypeToSportId('Basketball') ||
        sportType == sportTypeToSportId('Football')
      ) {
        array.push(<span key={'firstHalfScore'}>{getFirstHalfScore(team, sportType)}</span>);
      }
    } else {
      array.push(
        <span key={'score'}>
          {getFirstHalfScore(team, sportType)} {getScore(team, sportType, matchStatus)}
        </span>
      );
    }
    return array;
  };

  const getTeams = (team, sportType, match) => {
    const teamName = team == 'team1' ? teams[0] : teams[1];
    return (
      <div>
        <span>{teamName}</span>
        <span className={`float-end ${isDesktop ? 'text-white' : 'text-muted'} `}>
          {get(team, sportType, match)}
        </span>
      </div>
    );
  };

  let playerName = match.outcomeName.split('&');
  playerName = playerName[0].split(',')[1] + ' ' + playerName[0].split(',')[0];
  const marketId = match.marketId.split('-')[0];

  return (
    <div
      className={`px-2 py-1 rounded mb-1  ${
        isDesktop ? 'bg-list-dark border-card' : ' border-gray-300'
      } `}>
      <div className="d-flex justify-content-between mb-n3 ms-n2 lh-sm fs-7">
        <div className="d-flex text-center mb-3">
          {getMatchStatusMatchDateCard(match, sportType)}
        </div>
        {match.banker && (
          <div className="banker-bg " style={{ marginLeft: '30px', backgroundColor: '#3AA843' }}>
            <span className="text-white fw-bold fs-8 m-0 d-flex justify-content-center align-items-center text-center">
              B
            </span>
          </div>
        )}
        <div className="ms-auto">
          <div className={`pt-1 pb-2${isDesktop ? ' icon-white' : ''} `}>
            {getSportTypeToIconForBetSlip(sportType) == null
              ? t(`sports.${sportType}`)
              : getSportTypeToIconForBetSlip(sportType)}
            {/* - {t(`tournaments.${match.tournamentId}`)} */}
          </div>
        </div>
      </div>
      <Row>
        <div className={`pb-0 pt-1 fs-7 ${isDesktop ? 'text-white' : 'text-dark'}`}>
          {getTeams('team1', sportType, match)}
          {getTeams('team2', sportType, match)}
        </div>
      </Row>
      <div className="text-center my-1 fs-8 mt-n1">
        {/* <Col xs={3} className="bets_name"></Col> */}
        <div className={`m-auto ${isDesktop ? 'text-white' : 'text-dark'}`}>
          <small>
            Placed Score : {match.homeScoreAtPlaced} - {match.awayScoreAtPlaced}{' '}
          </small>
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-between mb-n1">
          <div className={`fs-8 ${isDesktop ? 'text-white' : 'text-muted'}`}>
            {MarketIdToMarketName(match.marketId.split('-')[0], match.marketId, false)}{' '}
            <span className="fw-bolder">
              {marketId === EnumMarketName.ANYTIMEGOALSCORERWINNER_888.toString() ||
              marketId === EnumMarketName.NEXTGOALSCORERWINNER_891.toString() ||
              marketId === EnumMarketName.ANYTIMEGOALSCORER_40.toString() ||
              marketId === EnumMarketName.NEXTGOALSCORER_38.toString() ||
              marketId === EnumMarketName.LASTGOALSCORER_39.toString()
                ? MyBetsTextCreator(sportType, match.marketId.split('-')[0], match.outcomeId) +
                  ',' +
                  playerName
                : MyBetsTextCreator(
                    sportType,
                    match.marketId.split('-')[0],
                    match.outcomeId,
                    match?.outcomeName
                  )}
            </span>
          </div>
          <div className="d-flex align-items-center">
            <span className={`me-2 fw-bolder fs-8 ${isDesktop ? 'text-white' : 'text-muted'} `}>
              {match.placeBetOdds.toString()}
            </span>
            {match.outcomeStatus === 0 && (
              <img width="15" height="15" src={`${AWS_BUCKET_URL}/Icons/lost.svg`} />
            )}
            {match.outcomeStatus === 1 && (
              <img width="15" height="15" src={`${AWS_BUCKET_URL}/Icons/right.svg`} />
            )}
            {match.outcomeStatus === 3 && (
              <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/ban.svg`} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default BetMatchCard;
