import { Container, Accordion, Row, Col } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
export default function DE() {
  return (
    <Tabs justify className="mb-4 py-2 text-start me-1 ms-1">
      <Tab eventKey="faq" title="FAQ">
        <h2 className="text-danger">FAQ</h2>
        <p key={1}>
          Wir haben für euch die häufigsten Fragen zusammengestellt und beantwortet. Ist deine Frage
          immer noch unbeantwortet, dann kontaktiere unseren Support. Wir melden uns dann
          schnellstmöglich bei dir.
        </p>
        <Container className="py-2 text-start me-1 ms-1">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" key={0}>
              <Accordion.Header>Wie kann ich Kontakt zu Game90 aufnehmen?</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Row>
                    <Col className="text-danger" xs={4}>
                      Über diese E-Mail-Adresse:
                      <hr />
                    </Col>
                    <Col className="text-danger" xs={4}>
                      Per Fax über diese Nummer:
                      <hr />
                    </Col>
                    <Col className="text-danger" xs={4}>
                      Nutze unser Kontaktformular:
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>support@game90.de</Col> <Col xs={4}>+356 2276 9002</Col>
                    <Col xs={4}></Col>
                  </Row>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" key={1}>
              <Accordion.Header>
                Wie lange dauert es, bis meine Wetten bewertet werden?
              </Accordion.Header>
              <Accordion.Body>
                Sobald das von dir bewettete Ereignis entschieden ist, wird deine Wette bewertet.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" key={2}>
              <Accordion.Header>Wie erfahre ich, ob ich gewonnen habe?</Accordion.Header>
              <Accordion.Body>
                Eine Übersicht über den Bewertungsstand aller von dir getätigten Wetten findest du
                unter &quotMeine Wetten&quot. Der Gesamtgewinn der abgerechneten Wetten wird deinem
                Kontostand gutgeschrieben.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" key={3}>
              <Accordion.Header>In welcher Währung wird mein Wettkonto geführt?</Accordion.Header>
              <Accordion.Body>
                Dein Wettkonto wird in Euro (EUR) geführt. Nimmst du Einzahlungen in anderen
                Währungen vor, werden diese zum tagesaktuellen Wechselkurs in Euro (EUR)
                umgerechnet.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" key={4}>
              <Accordion.Header>Wie sind die Einsatz- bzw. Gewinnlimits?</Accordion.Header>
              <Accordion.Body>
                Der Mindesteinsatz pro Wettschein beträgt EUR 1,-. Die Höchstauszahlung pro
                Wettschein beträgt EUR 100.000,-. Weitere Erläuterungen findest du unter Limits.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" key={5}>
              <Accordion.Header>
                Wieviel Zeit vergeht zwischen meiner Auszahlungsanforderung und dem Geldeingang auf
                meinem Konto?
              </Accordion.Header>
              <Accordion.Body>
                Bei deiner ersten Auszahlungsanforderung benötigen wir eine Kopie deines gültigen
                Lichtbildausweises. Sobald uns dieses Dokument vorliegt, führen wir die Überweisung
                durch. Weitere Auszahlungsanforderungen werden sofort bearbeitet. Bitte informiere
                dich bei deiner Bank über die Überweisungsdauer im nationalen oder internationalen
                Zahlungsverkehr.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" key={6}>
              <Accordion.Header>Wie reduziere und erhöhe ich die Limits?</Accordion.Header>
              <Accordion.Body>
                (b) ein Limit auf die Obergrenze für die Dauer der Sitzung;
                <br />
                (c) ein Limit auf maximal offene Wetten in EUR.
                <br />
                <br />
                Ein Kontoinhaber, der ein Limit gesetzt hat, wie oben in (a), (b) und (c) kann
                dieses mit einer E-Mail an support@game90.de ändern oder widerrufen. Eine Erhöhung
                des/der Limits tritt erst sieben Tagen nach Eingang der E-Mail in Kraft.. Das
                Heruntersetzen des/der Limits tritt mit sofortiger Wirkung nach Eingang per E-Mail
                in Kraft. Game90 nimmt keine Wetten an, die die vom Kunden gesetzten Limits
                überschreiten. Zudem nimmt Game90 keine Wetten von Kunden an, die ihr Spielerkonto
                gesperrt haben.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" key={7}>
              <Accordion.Header>Wie kann ich auf game90.bet wetten?</Accordion.Header>
              <Accordion.Body>
                Um eine Wette auf game90.bet zu platzieren, benötigst du eine Game90-Kundenkarte.
                Diese erhältst du in einem Game90-Shop in deiner Nähe. Wenn du deine Kundenkarte an
                einem Wettautomaten für Wetten im Internet aktiviert hast, kannst du dich mit deinen
                erhaltenen Zugangsdaten auf game90.bet anmelden. Sofern du über ausreichend Guthaben
                verfügst, kannst du dort deine Wette platzieren.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Tab>
      <Tab eventKey="betTypes" title="Wettarten">
        <h2 className="text-danger">WETTARTEN</h2>
        <p key={2}>
          Von der Einzelwette bis zur Systemwette bietet dir Game90 nur die attraktivsten Wettarten.
          Du möchtest eine neue Wette ausprobieren? Hier erklären wir dir alle Wettarten im Detail.
        </p>
        <Container className="py-2 text-start me-1 ms-1">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Restzeitwette</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Restzeitwette sagst du voraus, welche Mannschaft ab dem Zeitpunkt
                deiner Wettabgabe bis zum Spielende mehr Tore erzielt. Zum Zeitpunkt deiner
                Wettabgabe wird der aktuelle Spielstand für deine Wette auf 0:0 zurückgesetzt. Für
                die Bewertung deiner Wette zählen also nur die Tore, die nach der Wettabgabe fallen.
                <br />
                <br />
                <b> Mögliche Wetten</b>
                <li className="mt-3" key={1}>
                  <b>Tipp 1:</b> Die Heimmannschaft erzielt ab dem Zeitpunkt der Wettabgabe bis zum
                  Spielende mehr Tore als die Gastmannscha
                </li>
                <li key={2}>
                  <b> Tipp 0:</b> Beide Mannschaften erzielen ab dem Zeitpunkt der Wettabgabe gleich
                  viele Tore.
                </li>
                <li key={3}>
                  <b>Tipp 2:</b> Die Gastmannschaft erzielt ab dem Zeitpunkt der Wettabgabe bis zum
                  Spielende mehr Tore als die Heimmannschaft.
                </li>
                <br /> <br /> <b>Beispiel 1</b> <br /> Zum Zeitpunkt der Wettabgabe lautet der
                Spielstand 0:1. Das Spiel endet mit dem Stand 1:1. Die Wette mit dem Tipp 1
                (Heimmannschaft) gewinnt, da nach der Wettabgabe die Heimmannschaft mehr Tore
                erzielt hat als die Gastmannschaft.
                <br />
                <br />
                <b> Beispiel 2</b>
                <br />
                Zum Zeitpunkt der Wettabgabe lautet der Spielstand 0:1. Das Spiel endet mit dem
                Stand 1:2. Die Wette mit dem Tipp 0 (Unentschieden) gewinnt, da nach der Wettabgabe
                beide Mannschaften gleich viele Tore erzielt haben.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Sieger</Accordion.Header>
              <Accordion.Body>
                Bei den Wettarten Sieger und Siegwette sagst du voraus, wie das Spiel ausgehen wird.
                Je nach Sportart geht es bei den Wettarten Sieger und Siegwette um den Ausgang des
                gesamten Spiels (z. B. Fußball) oder des gesamten Matches (z. B. Tennis).
                <br /> <br /> <b> Mögliche Tipps</b>
                <li className="mt-3" key={4}>
                  <b>Tipp 1:</b> Die Heimmannschaft oder Spieler 1 gewinnt.
                </li>
                <li key={5}>
                  <b>Tipp 0:</b> Das Spiel endet mit einem Unentschieden.
                </li>
                <li key={6}>
                  <b>Tipp 2:</b> Die Gastmannschaft oder Spieler 2 gewinnt.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Fantasywette</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Fantasy wettest du auf Fantasy-Spiele. Die Spielpaarung für ein
                Fantasy-Spiel wird vom Buchmacher aus zwei Mannschaften zusammengesetzt, die in
                Wirklichkeit nicht gegeneinander, sondern gegen zwei andere Fußballmannschaften
                spielen. <br />
                <br />
                Bei der Wettart Fantasy gilt das Prinzip der Livewette 0:0. Du sagst hierbei voraus,
                welche der beiden Mannschaften des Fantasy-Spiels ab dem Zeitpunkt Ihrer Wettabgabe
                bis zum Ende der beiden realen Spiele mehr Tore erzielt. Zum Zeitpunkt Ihrer
                Wettabgabe wird der aktuelle Spielstand des Fantasy-Spiels fiktiv und nur für deine
                Wette auf 0:0 zurückgesetzt.
                <br />
                <br />
                Für die Bewertung deiner Wette zählen nur die Tore, die die Mannschaften des
                Fantasy-Spiels in den realen Spielbegegnungen nach der Wettabgabe erzielen. Die Tore
                der gegnerischen Mannschaften in den realen Spielen haben keine Auswirkung auf die
                Bewertung der Fantasy-Wette. Bei der Wettart Fantasy gibt es keinen Heimvorteil.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Fantasy Über/Unter</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Fantasy Über/Unter sagst du voraus, ob die Gesamtanzahl der in einem
                Fantasy-Spiel erzielten Tore über oder unter dem in der Spalte &quot;G&quot;
                angezeigten Torwert liegen wird. Für die Bewertung der Wette zählen alle im
                Fantasy-Spiel erzielten Tore (auch die vor der Wettabgabe erzielten Tore). Die Tore
                der gegnerischen Mannschaften in den realen Spielen haben keine Auswirkung auf die
                Bewertung der Wettart Fantasy Über/Unter.
                <br />
                <br />
                <b> Beispiel </b>
                <li className="mt-3" key={7}>
                  Vorgegebener Torwert: 2,5.
                </li>
                <li key={8}>
                  Wenn im Fantasy-Spiel zwei oder weniger Tore erzielt werden, gewinnt die Wette mit
                  dem Tipp Unter.
                </li>
                <li key={9}>
                  Wenn im Fantasy-Spiel drei oder mehr Tore erzielt werden, gewinnt die Wette mit
                  dem Tipp Über.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Erste Halbzeit</Accordion.Header>
              <Accordion.Body>
                Für die Bewertung der Wettart Erste Halbzeit gilt das gleiche Prinzip wie für die
                Bewertung der Wettart Livewette 0:0. Bei der Wettart Erste Halbzeit sagst du voraus,
                welche Mannschaft ab dem Zeitpunkt deiner Wettabgabe bis zum Ende der ersten
                Halbzeit mehr Tore erzielt. Zum Zeitpunkt deiner Wettabgabe wird der aktuelle
                Spielstand für deine Wette auf 0:0 zurückgesetzt. Für die Bewertung deiner Wette
                zählen nur die Tore, die in der ersten Halbzeit nach der Wettabgabe fallen.
                <br />
                <br />
                <b>Beispiel 1:</b>
                <br />
                Zum Zeitpunkt der Wettabgabe lautet der Spielstand 0:1. Die erste Halbzeit endet mit
                dem Stand 1:1. Die Wette mit dem Tipp 1 (Heimmannschaft) gewinnt, da nach der
                Wettabgabe bis zum Ende der ersten Halbzeit die Heimmannschaft mehr Tore erzielt hat
                als die Gastmannschaft.
                <br />
                <br />
                <b> Beispiel 2:</b> <br /> Zum Zeitpunkt der Wettabgabe lautet der Spielstand 0:1.
                Die erste Halbzeit endet mit dem Stand 1:2. Die Wette mit dem Tipp 0 (Unentschieden)
                gewinnt, da nach der Wettabgabe bis zum Ende der ersten Halbzeit beide Mannschaften
                gleich viele Tore erzielt haben.
                <br />
                <br />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Nächstes Tor</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Nächstes Tor sagst du voraus, welche Mannschaft das nächste Tor
                erzielt.
                <br />
                <br />
                <b>Beispiele</b>
                <li className="mt-3" key={10}>
                  <b>Tipp 1:</b>Die Heimmannschaft erzielt das nächste Tor.
                </li>
                <li key={11}>
                  <b>Tipp 0:</b> Beide Mannschaften erzielen bis zum Spielende kein Tor mehr.
                </li>
                <li key={12}>
                  <b>Tipp 2:</b> Die Gastmannschaft erzielt das nächste Tor.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Handicap</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Handicap bekommt eine Mannschaft einen fiktiven Vorsprung in Form
                eines oder mehrerer Tore. Bei der Wettart Handicap sagst du voraus, welche
                Mannschaft unter Berücksichtigung des Handicaps mehr Tore erzielt. Je nach Sportart
                bezieht sich das Handicap auf Tore, Punkte, Satzgewinne usw.
                <br />
                <br />
                <b>Mögliche Tipps</b>
                <br />
                <br />
                <li className="mt-3" key={13}>
                  <b>Tipp 1:</b>Die Heimmannschaft erzielt unter Berücksichtigung des Handicaps ab
                  dem Zeitpunkt der Wettabgabe bis zum Spielende mehr Tore als die Gastmannschaft.
                </li>
                <li key={14}>
                  <b>Tipp 0:</b> Beide Mannschaften erzielen unter Berücksichtigung des Handicaps
                  gleich viele Tore.
                </li>
                <li key={15}>
                  <b>Tipp 2:</b> Die Gastmannschaft erzielt unter Berücksichtigung des Handicaps ab
                  dem Zeitpunkt der Wettabgabe bis zum Spielende mehr Tore als die Heimmannschaft.
                </li>
                <br />
                <br />
                Beispiele von weiteren Handicap-Wettarten
                <li className="mt-3" key={16}>
                  Handicap Verlängerung: Bei der Wettart Handicap Verlängerung bezieht sich das
                  Handicap auf das Endergebnis des Spiels inklusive Verlängerung und ggf.
                  Elfmeterschießen.
                </li>
                <li key={17}>
                  Handicap Sätze: Die Wettart Handicap Sätze wird zum Beispiel für die Sportart
                  Tennis angeboten. Das Handicap bezieht sich auf die Satzgewinne.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Doppelchance</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Doppelchance setzt du mit nur einem Tipp gleichzeitig auf zwei
                verschiedene Spielausgänge.
                <br />
                <br />
                <b>Mögliche Tipps</b>
                <li className="mt-3" key={18}>
                  <b>-Tipp 1X:</b> Die Heimmannschaft gewinnt oder das Spiel endet mit einem
                  Unentschieden.
                </li>
                <li key={19}>
                  <b>-Tipp 12:</b> Die Heimmannschaft oder die Gastmannschaft gewinnt.
                </li>
                <li key={20}>
                  <b>-Tipp X2:</b> Die Gastmannschaft gewinnt oder das Spiel endet mit einem
                  Unentschieden.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Ecken Über/Unter</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Ecken Über/Unter sagst du voraus, ob die Gesamtanzahl der Eckbälle
                in einem Spiel über oder unter dem vorgegebenen Eckballwert (E) liegen wird. Für die
                Bewertung der Wette zählen alle Eckbälle, die es im Spiel gab.
                <br />
                <br />
                <b>Beispiel :</b>
                <li className="mt-3" key={21}>
                  Vorgegebener Eckballwert: 12,5
                </li>
                <li key={22}>
                  Wenn es im Spiel 12 oder weniger Eckbälle gibt, gewinnt die Wette mit dem Tipp
                  Unter.
                </li>
                <li key={23}>
                  Wenn es im Spiel 13 oder mehr Eckbälle gibt, gewinnt die Wette mit dem Tipp Über.
                </li>
                <br />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>Beide schießen ein Tor</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Beide schießen ein Tor sagst du voraus, ob beide Mannschaften im
                Spiel jeweils mindestens ein Tor erzielen.
                <br />
                <br />
                <b> Mögliche Tipps</b>
                <li className="mt-3" key={24}>
                  <b>Tipp Ja:</b> Beide Mannschaften erzielen im Spiel jeweils mindestens ein Tor.
                </li>
                <li key={25}>
                  <b>Tipp Nein:</b> Eine oder beide Mannschaften erzielen kein Tor.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>Wer kommt weiter?</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Wer kommt weiter? sagst du voraus, welche Mannschaft die nächste
                Runde erreicht. Diese Wette wird nur bei Wettbewerben angeboten, die im K.o.-Modus
                ausgetragen werden.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>Halbzeit/Endstand</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Halbzeit/Endstand sagst du mit nur einem Tipp voraus, wie die erste
                Halbzeit ausgehen und wie das Ergebnis nach 90 Minuten lauten wird. Insgesamt stehen
                bei dieser Wettart neun Tipps zur Auswahl. Ein Tipp besteht aus zwei Zahlen. Die
                erste Zahl im Tipp sagt aus, wer die erste Halbzeit gewinnen wird. Die zweite Zahl
                im Tipp sagt aus, wie das Spiel nach 90 Minuten endet.
                <li className="mt-3" key={26}>
                  1 = Heimmannschaft
                </li>
                <li key={27}>0 = Unentschieden</li> <li key={28}>2 = Gastmannschaft</li> <br />
                <b> Beispiele</b> <br />
                <li key={29}>
                  <b> -Tipp 1/2:</b> Die Heimmannschaft gewinnt die erste Halbzeit und die
                  Gastmannschaft gewinnt das Spiel. <br />
                </li>
                <li key={30}>
                  <b> -Tipp 0/1:</b> Die erste Halbzeit endet mit einem Unentschieden und die
                  Heimmannschaft gewinnt das Spiel.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>Ergebniswette</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Ergebnis sagst du voraus, mit welchem Endstand das Spiel endet.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>Sieger 1. Satz</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Sieger 1. Satz sagst du voraus, welcher Spieler den ersten Satz
                gewinnt. Diese Wette wird zum Beispiel im Tennis angeboten.
                <br />
                <br />
                <b>Mögliche Tipps </b>
                <li key={31} className="mt-3">
                  <b>Tipp 1:</b> Spieler 1 gewinnt den ersten Satz.
                </li>
                <li key={32}>
                  <b>Tipp 2:</b> Spieler 2 gewinnt den ersten Satz.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>Spiele Über/Unter</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Spiele Über/Unter sagst du voraus, ob die Gesamtanzahl der Spiele
                aller Sätze in einer Begegnung über oder unter dem vorgegebenen Spielwert (S) liegen
                wird. Die Wettart Spiele Über/Unter wird sowohl für die gesamte Begegnung als auch
                für den ersten Satz angeboten.
                <br />
                <br />
                <b>Beispiel</b>
                <li key={33}>
                  Wenn es in der Begegnung 30 oder weniger Spiele gibt, gewinnt die Wette mit dem
                  Tipp Unter.
                </li>
                <li key={34}>
                  Wenn es in der Begegnung 31 oder mehr Spiele gibt, gewinnt die Wette mit dem Tipp
                  Über.
                </li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
              <Accordion.Header>Einzelwette</Accordion.Header>
              <Accordion.Body>
                Bei der Einzelwette sagst du das Ergebnis eines einzelnen Ereignisses voraus. Wenn
                du mehrere Tipps im Rahmen eines einzelnen Fußballspiels abgibst, werden sie als
                Einzelwetten auf dem Wettschein erfasst und unabhängig voneinander bewertet. Die
                Wette ist gewonnen, wenn du das Ergebnis richtig vorausgesagst hast.
                <br />
                <br />
                Der mögliche Gewinn berechnet sich wie folgt: Gewinn = Wetteinsatz x Quote
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
              <Accordion.Header>Erster 180er</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Erster 180er sagst du voraus, welcher Dartspieler zuerst einen 180er
                (180 Punkte mit drei Würfen) erzielt.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
              <Accordion.Header>Meiste 180er</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Meiste 180er sagst du voraus, welcher Spieler die meisten 180er (180
                Punkte mit drei Würfen) in einem Spiel erzielt.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="18">
              <Accordion.Header>180er Spieler 1 Über/Unter</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart 180er Spieler 1 Über/Unter sagst du voraus, ob die Gesamtanzahl der
                von Spieler 1 in einem Spiel erzielten 180er (180 Punkte mit drei Würfen) über oder
                unter dem in der Spalte P angezeigten Punktwert liegen wird.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="19">
              <Accordion.Header>180er Spieler 2 Über/Unter</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart 180er Spieler 2 Über/Unter sagst du voraus, ob die Gesamtanzahl der
                von Spieler 2 in einem Spiel erzielten 180er (180 Punkte mit drei Würfen) über oder
                unter dem in der Spalte P angezeigten Punktwert liegen wird.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="20">
              <Accordion.Header>Langzeitwette</Accordion.Header>
              <Accordion.Body>
                Bei einer Langzeitwette wettest du auf ein Ereignis im Rahmen eines Turniers oder
                einer Saison. Das Ergebnis der Wette steht in der Regel erst nach längerer Zeit
                fest.
                <br />
                <br />
                <b>Beispiele für Langzeitwetten</b> <br />
                <br />- Wer wird Weltmeister? <br />- Wer kommt weiter? <br />- Wer kommt ins
                Finale? <br />- Wer wird Gruppensieger?
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="21">
              <Accordion.Header>Kombinationswette</Accordion.Header>
              <Accordion.Body>
                Bei der Kombinationswette (Kombiwette) sagst du das Ergebnis mehrerer
                unterschiedlicher Sportereignisse voraus. Deine Tipps werden auf dem Wettschein in
                einer Wette kombiniert. Du kannst bis zu 10 Ereignisse in einer Wette kombinieren.
                Die Wette ist gewonnen, wenn du alle Ereignisse deiner Kombinationswette richtig
                vorausgesagt hast. Die gesamte Wette gilt als verloren, wenn du mindestens ein
                Ereignis falsch vorausgesagt hast.
                <br />
                <br />
                Der mögliche Gewinn berechnet sich wie folgt:
                <br />
                Gewinn = Wetteinsatz x (Quote A x Quote B x Quote C x Quote ...)
                <br />
                <br />
                <b> Hinweis</b>
                <br />
                Wenn du mehrere Tipps im Rahmen eines einzelnen Fußballspiels abgibst, werden sie
                nicht als Kombinationswette, sondern als Einzelwetten auf dem Wettschein erfasst und
                unabhängig voneinander bewertet.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="22">
              <Accordion.Header>Systemwette</Accordion.Header>
              <Accordion.Body>
                Bei einer Systemwette gibst du auf einem Wettschein gleichzeitig mehrere
                Kombinationswetten ab. Du sagst das Ergebnis mehrerer unterschiedlicher
                Sportereignisse voraus und bestimmst, wie viele von den getippten Ereignissen
                mindestens eintreffen müssen, damit du einen Gewinn erzielst (z. B. 2 aus 4). Aus
                den getippten Ereignissen werden nun unterschiedliche Kombinationswetten gebildet
                (siehe Tabelle für Systemwetten).
                <br />
                <br />
                Eine Systemwette kann einen oder ggf. mehrere falsch vorausgesagte Tipps enthalten
                und dennoch gewinnen.
                <br />
                <br />
                <b>Beispiel</b>
                <br />
                Du tippst auf vier Ereignisse und wählst das System 2 aus 4. Aus diesen 4
                Ereignissen werden nun folgende 6 2er-Kombinationswetten gebildet: AB, AC, AD, BC,
                BD, CD. Du gewinnst die Wette, wenn du mindestens 2 Ereignisse richtig vorausgesagt
                hast.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="23">
              <Accordion.Header>Banksystemwette</Accordion.Header>
              <Accordion.Body>
                Die Banksystemwette ist eine Erweiterung der Systemwette. Wenn du dir sicher bist,
                dass ein Ereignis eintreffen wird, kannst du es in deiner Systemwette als Bank
                markieren. Du kannst auch mehrere Ereignisse als Bank markieren.
                <br />
                <br />
                <b>Beispiel für eine Banksystemwette</b>
                <br />
                Du tippst auf fünf Ereignisse (A, B, C, D, E) und markierst ein Ereignis (z. B. E)
                als Bank. Für die restlichen vier Ereignisse (A, B, C, D) wählst du das System 2 aus
                4. Es werden nun folgende sechs 2er-Kombinationswetten gebildet: AB, AC, AD, BC, BD,
                CD. Das als Bank markierte Ereignis E wird nun zusätzlich in jede Kombination
                eingebunden: ABE, ACE, ADE, BCE, BDE, CDE.
                <br />
                <br />
                Du gewinnst die Wette, wenn du das als Bank markierte Ereignis E und mindestens 2
                aus 4 anderen Ereignissen (A, B, C, D) richtig vorausgesagt hast. Wenn du das als
                Bank markierte Ereignis nicht richtig vorausgesagt hast, gilt die gesamte Wette
                (unabhängig von anderen richtig vorausgesagten Ereignissen) als verloren.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="24">
              <Accordion.Header>Über/Unter</Accordion.Header>
              <Accordion.Body>
                Bei der Wettart Über/Unter sagst du voraus, ob die Gesamtanzahl der in einem Spiel
                erzielten Tore über oder unter dem in der Spalte G vorgegebenen Torwert liegen wird.
                Für die Bewertung der Wette zählen alle im Spiel erzielten Tore (auch die vor der
                Wettabgabe erzielten Tore).
                <br />
                <br />
                Die Wette Über/Unter wird sowohl für das gesamte Spiel als auch für die erste
                Halbzeit angeboten.
                <br />
                <br />
                Je nach Sportart geht es bei der Wettart Über/Unter um die Gesamtanzahl der
                erzielten Tore (Torwert), Punkte (Punktwert) oder Spiele (Spielwert).
                <br />
                <br />
                <b>Beispiel</b> <br />
                Vorgegebener Torwert: 2,5.
                <br />
                Wenn im Spiel 2 oder weniger Tore erzielt werden, gewinnt die Wette mit dem Tipp
                Unter.
                <br />
                Wenn im Spiel 3 oder mehr Tore erzielt werden, gewinnt die Wette mit dem Tipp Über.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Tab>
    </Tabs>
  );
}
