import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TitleHistoryBack from 'components/general/TitleHistoryBack';
import { AWS_BUCKET_URL } from '../../toolkit/constants';

export default function ResponsibleGaming() {
  const { t } = useTranslation('translations');
  const [tabValue, setTabValue] = useState(0);

  const cashes = [
    { id: 1, name: '10' },
    { id: 2, name: '50' },
    { id: 3, name: '100' },
    { id: 4, name: '200' },
    { id: 5, name: '1000' }
  ];

  const [selectedCash, setCashChange] = useState(cashes[0].name);

  const durations = [
    { id: 1, name: 'Per day' },
    { id: 2, name: 'Per week' },
    { id: 3, name: 'Per month' }
  ];

  const [selectedDuration, setDuration] = useState(durations[0].name);

  const setDurationChange = (e) => {
    setDuration(e.target.value);
  };

  const setCashesChange = (e) => {
    setCashChange(e.target.value);
  };

  const changeTabValue = (tabValueParam) => {
    if (tabValueParam === tabValue) {
      setTabValue(0);
    } else {
      setTabValue(tabValueParam);
    }
  };

  return (
    <Container>
      {' '}
      <TitleHistoryBack title={t('myprofile')} subTitle={t('responsiblegaming')} />
      <Row onClick={() => changeTabValue(1)}>
        <Col xs={9} sm={9}>
          <h6 className="float-start">{t('depositlimit')}</h6>
        </Col>
        <Col xs={3}>
          <span className="float-end text-muted">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      {tabValue === 1 && (
        <Container fluid>
          <Row>
            <Col>
              <h6 className="text-muted opacity-50 text-start ">
                Here you can set limit for the maximum Deposit amount by filling the details listed
                below
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="text-muted opacity-50 text-start ">
                Please note that if you increase your limit then a cooling peroid of 24 hours will
                be applicable and if you decrease your limit then the limit will be applied
                immediately
              </h6>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h5 className="mt-4">{t('amount')}</h5>
            </Col>
            <Col xs={9}>
              <Form.Select
                value={selectedCash}
                onChange={(e) => setCashesChange(e)}
                placeholder="Select"
                className="mt-3">
                {cashes.map((cash) => {
                  return (
                    <option key={cash.id} value={cash.name}>
                      {cash.name + 'EUR'}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          <hr className="mt-2"></hr>
          <Row>
            <Col xs={3}>
              <h5 className="mt-4">{t('duration')}</h5>
            </Col>
            <Col xs={9}>
              <Form.Select
                value={selectedDuration}
                onChange={(e) => setDurationChange(e)}
                placeholder="Select"
                className="mt-3">
                {durations.map((duration) => {
                  return (
                    <option key={duration.id} value={duration.name}>
                      {duration.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          <hr className="mt-2"></hr>
          <Row>
            <Button variant="success">{t('setnewlimits')}</Button>
          </Row>
        </Container>
      )}
      <hr className="mt-2"></hr>
      <Row onClick={() => changeTabValue(2)}>
        <Col xs={9} sm={9}>
          <h6 className="float-start mt-2">{t('betlimit')}</h6>
        </Col>
        <Col xs={3}>
          <span className="float-end text-muted mt-2">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      {tabValue === 2 && (
        <Container fluid>
          <Row>
            <Col>
              <h6 className="text-muted opacity-50 text-start ">
                Here you can set limit for the maximum Wager amount by filling the details listed
                below
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="text-muted opacity-50 text-start ">
                Please note that if you increase your limit then a cooling peroid of 24 hours will
                be applicable and if you decrease your limit then the limit will be applied
                immediately
              </h6>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h5 className="mt-4">{t('amount')}</h5>
            </Col>
            <Col xs={9}>
              <Form.Select
                value={selectedCash}
                onChange={(e) => setCashesChange(e)}
                placeholder="Select"
                className="mt-3">
                {cashes.map((cash) => {
                  return (
                    <option key={cash.id} value={cash.name}>
                      {cash.name + 'EUR'}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col xs={3}>
              <h5 className="mt-4">{t('duration')}</h5>
            </Col>
            <Col xs={9}>
              <Form.Select
                value={selectedDuration}
                onChange={(e) => setDurationChange(e)}
                placeholder="Select"
                className="mt-3">
                {durations.map((duration) => {
                  return (
                    <option key={duration.id} value={duration.name}>
                      {duration.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Button variant="success">{t('setnewlimits')}</Button>
          </Row>
        </Container>
      )}
      <hr className="mt-2"></hr>
      <Row onClick={() => changeTabValue(3)}>
        <Col xs={9} sm={9}>
          <h6 className="float-start mt-2">{t('losslimit')}</h6>
        </Col>
        <Col xs={3}>
          <span className="float-end text-muted mt-2">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      {tabValue === 3 && (
        <Container fluid>
          <Row>
            <Col>
              <h6 className="text-muted opacity-50 text-start ">
                Here you can set limit for the maximum loss amount by filling the details listed
                below
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="text-muted opacity-50 text-start ">
                Please note that if you increase your limit then a cooling peroid of 24 hours will
                be applicable and if you decrease your limit then the limit will be applied
                immediately
              </h6>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h5 className="mt-4">{t('amount')}</h5>
            </Col>
            <Col xs={9}>
              <Form.Select
                value={selectedCash}
                onChange={(e) => setCashesChange(e)}
                placeholder="Select"
                className="mt-3">
                {cashes.map((cash) => {
                  return (
                    <option key={cash.id} value={cash.name}>
                      {cash.name + 'EUR'}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          <hr className="mt-2"></hr>
          <Row>
            <Col xs={3}>
              <h5 className="mt-4">{t('duration')}</h5>
            </Col>
            <Col xs={9}>
              <Form.Select
                value={selectedDuration}
                onChange={(e) => setDurationChange(e)}
                placeholder="Select"
                className="mt-3">
                {durations.map((duration) => {
                  return (
                    <option key={duration.id} value={duration.name}>
                      {duration.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          <hr className="mt-2"></hr>
          <Row>
            <Button variant="success">{t('setnewlimits')}</Button>
          </Row>
        </Container>
      )}
      <hr className="mt-2"></hr>
      <Row onClick={() => changeTabValue(4)}>
        <Col xs={9} sm={9}>
          <h6 className="float-start mt-2">{t('blockaccount')}</h6>
        </Col>
        <Col xs={3}>
          <span className="float-end text-muted mt-2">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      {tabValue === 4 && (
        <Container fluid>
          <Row>
            <Col>
              <h6 className="text-muted opacity-50 text-start ">
                Here you can set limit for the maximum Deposit amount by filling the details listed
                below
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="text-muted opacity-50 text-start ">
                Please note that if you increase your limit then a cooling peroid of 24 hours will
                be applicable and if you decrease your limit then the limit will be applied
                immediately
              </h6>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h5 className="mt-4">Please Select</h5>
            </Col>
            <Col xs={9}>
              <Form.Select
                value={selectedCash}
                onChange={(e) => setCashesChange(e)}
                placeholder="Select"
                className="mt-3">
                {cashes.map((cash) => {
                  return (
                    <option key={cash.id} value={cash.name}>
                      {cash.name + 'EUR'}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Button variant="success">{t('setnewlimits')}</Button>
          </Row>
        </Container>
      )}
      <hr className="mt-2"></hr>
      <Row onClick={() => changeTabValue(5)}>
        <Col xs={9} sm={9}>
          <h6 className="float-start mt-2">{t('lockaccount')}</h6>
        </Col>
        <Col xs={3}>
          <span className="float-end text-muted mt-2">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      {tabValue === 5 && (
        <Container fluid>
          <h6 className="text-muted opacity-50 ">
            Click on the lock account button to lock your account
          </h6>
          <Row>
            <Button variant="success">{t('lockaccount')}</Button>
          </Row>
        </Container>
      )}
      <hr className="mt-2"></hr>
      <Row onClick={() => changeTabValue(6)}>
        <Col xs={9} sm={9}>
          <h6 className="float-start mt-2">{t('limithistory')}</h6>
        </Col>
        <Col xs={3}>
          <span className="float-end text-muted mt-2">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      {tabValue === 6 && <h6 className="text-muted opacity-50 ">Record not found for request</h6>}
      <hr className="mt-2"></hr>
    </Container>
  );
}
