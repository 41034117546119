import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TitleHistoryBack from 'components/general/TitleHistoryBack';
import { AWS_BUCKET_URL } from '../../toolkit/constants';

export default function UploadDocument() {
  const { t } = useTranslation('translations');
  const [detailsShown, setDetailsShown] = useState(false);

  return (
    <Container>
      <TitleHistoryBack title={t('myprofile')} subTitle={t('uploaddocuments')} />
      <Row>
        <Col>
          <h6 className="float-start text-muted opacity-50 mt-2  text-start">
            To verify your identify please upload a copy of your identifaction document.
          </h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="float-start mt-2 ">{t('senddocumentsnorobetsupport')}</h6>
        </Col>
      </Row>
      <Row className="border border-1 border-light rounded me-1 ms-1">
        <Col xs={12}>
          <h6 className="float-start ">{t('personalidcard')}</h6>
        </Col>
        <Col xs={12}>
          <h6 className="float-start text-muted opacity-50  text-start">
            To verify your identity please upload a copy of your identification document. Adoptable
            documents are: Driving License, National ID Card.
          </h6>
        </Col>
        <Col xs={6}>
          <div className="float-start">
            <Form.Label htmlFor="upload-photo">
              <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/71.png`} />
            </Form.Label>
            <input
              type="file"
              name="photo"
              id="upload-photo"
              className="position-absolute opacity-0 z-index-n1"
            />
          </div>
          <div className="float-start text-muted opacity-50 ms-1">PNG OR JPG</div>
        </Col>
        <Col xs={6}>
          <Button variant="secondary" className="float-end mb-2" size="sm">
            {t('upload')}
          </Button>
        </Col>
      </Row>
      <Row className="border border-1 border-light rounded me-1 ms-1 mt-2">
        <Col xs={12}>
          <h6 className="float-start ">{t('addressinformation')}</h6>
        </Col>
        <Col xs={12}>
          <h6 className="float-start text-muted opacity-50  text-start">
            For confirming your address please upload a copy of your latest Utility Bill, showing
            your name and address. Acceptable documents are Water,Electrically, Telephone
          </h6>
        </Col>
        <Col xs={6}>
          <div className="float-start">
            <Form.Label htmlFor="upload-address-photo">
              <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/71.png`} />
            </Form.Label>
            <input
              type="file"
              name="photo"
              id="upload-address-photo"
              className="position-absolute opacity-0 z-index-n1"
            />
          </div>
          <div className="float-start text-muted opacity-50 ms-1">PNG OR JPG</div>
        </Col>
        <Col xs={6}>
          <Button variant="secondary" className="float-end mb-2" size="sm">
            {t('upload')}
          </Button>
        </Col>
      </Row>
      <Row className="border border-1 border-light rounded me-1 ms-1 mt-2">
        <Col xs={12}>
          <h6 className="float-start ">{t('bankaccountstatement')}</h6>
        </Col>
        <Col xs={12}>
          <h6 className="float-start text-muted opacity-50  text-start">
            To verify your account please upload your account statement here. Your bank statemment
            must show the following: IBAN and BIC, logo and name of the bank and your full name
          </h6>
        </Col>
        <Col xs={6}>
          <div className="float-start">
            <Form.Label htmlFor="upload-bank-account-photo">
              <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/71.png`} />
            </Form.Label>
            <input
              type="file"
              name="photo"
              id="upload-bank-account-photo"
              className="position-absolute opacity-0 z-index-n1"
            />
          </div>
          <div className="float-start text-muted opacity-50 ms-1">PNG OR JPG</div>
        </Col>
        <Col xs={6}>
          <Button variant="secondary" className="float-end mb-2" size="sm">
            {t('upload')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h6 className="float-start mt-2">{t('otherdetails')}</h6>
        </Col>
      </Row>
      <Row>
        <Col xs={9} sm={9}>
          <h6 className="float-start mt-2">{t('documenthistory')}</h6>
        </Col>
        <Col xs={3}>
          <span className="float-end text-muted">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col xs={9} sm={9}>
          <h6 onClick={() => setDetailsShown(!detailsShown)} className="float-start">
            {t('kycdetails')}
          </h6>
        </Col>
        <Col xs={3} sm={3}>
          <span className="float-end text-muted">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      {detailsShown && (
        <Row>
          <Col sm={12}>
            <div className="float-start text-muted opacity-50">{t('depositlimit')}</div>
          </Col>
          <Col sm={12}>
            <div className="float-start text-muted opacity-50">{t('withdrawallimit')}</div>
            <div className="float-end">{t('false')}</div>
          </Col>
          <Col sm={12}>
            <div className="float-start text-muted opacity-50">{t('allowedtoplaywithcash')}</div>
            <div className="float-end">{t('false')}</div>
          </Col>
          <Col sm={12}>
            <div className="float-start text-muted opacity-50">{t('allowedtodeposit')}</div>
            <div className="float-end">{t('false')}</div>
          </Col>
          <Col sm={12}>
            <div className="float-start text-muted opacity-50">{t('allowedtowithdrawal')}</div>
            <div className="float-end">{t('false')}</div>
          </Col>
        </Row>
      )}
      <hr></hr>
    </Container>
  );
}
