import { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { AWS_BUCKET_URL } from '../../toolkit/constants';
import { useSelector } from 'react-redux';

export default function Numpad(props) {
  const userSettings = useSelector((state) => state.auth.userSettings);
  const [value, setValue] = useState(props.initialValue || '1');
  const [willClear, setWillClear] = useState(true);
  const initialValue = props.initialValue || '1';
  const [preset, setPreset] = useState([5, 15, 20, 30, 50]);
  const stakeColor = ['#111516', '#222423', '#313332', '#414342', '#505251'];

  function onClick(v) {
    if (willClear && v !== '.' && v !== '0') {
      setValue(v);
      setWillClear(false);
    } else {
      let allowed = true;
      if (value.includes('.')) {
        if (v === '.') {
          allowed = false;
        } else {
          allowed = value.split('.')[1].length < 2;
        }
      }
      if (allowed) {
        let newValue = value + v;
        if (parseFloat(newValue) > 4000) {
          newValue = '4000.00';
        }
        setValue(newValue);
      }
    }
  }

  function deleteValue() {
    if (value.length === 1 || willClear) {
      setValue('1');
      setWillClear(true);
    } else {
      setValue(value.substr(0, value.length - 1));
    }
  }

  function onSuccess(value) {
    if (props.onSuccess) {
      props.onSuccess(parseFloat(value));
    }
  }

  useEffect(() => {
    if (userSettings) {
      const userSettingsPresets = [];
      for (let index = 1; index < 6; index++) {
        userSettingsPresets.push(userSettings[`presetStake${index}`]);
      }
      setPreset(userSettingsPresets);
    }
  }, [userSettings]);

  return (
    <div className="numpad-container">
      <div className="d-flex justify-content-between border-bottom-1 border-dark bg-dark p-2">
        <span className="text-secondary fs-5 text-start">Eur</span>
        <span className="text-white fs-4 fw-bold text-end">{value}</span>
      </div>
      <div className="d-flex gap-2 p-2">
        {preset.map((val, index) => (
          <button
            key={index}
            onClick={() => onSuccess(val)}
            className="btn py-2 flex-fill text-white"
            style={{ backgroundColor: `${stakeColor[index]}` }}>
            {val}
          </button>
        ))}
      </div>
      <Row>
        <button onClick={() => onClick('1')} className="col numpad-button">
          1
        </button>
        <button onClick={() => onClick('2')} className="col numpad-button">
          2
        </button>
        <button onClick={() => onClick('3')} className="col numpad-button">
          3
        </button>
      </Row>
      <Row>
        <button onClick={() => onClick('4')} className="col numpad-button">
          4
        </button>
        <button onClick={() => onClick('5')} className="col numpad-button">
          5
        </button>
        <button onClick={() => onClick('6')} className="col numpad-button">
          6
        </button>
      </Row>
      <Row>
        <button onClick={() => onClick('7')} className="col numpad-button">
          7
        </button>
        <button onClick={() => onClick('8')} className="col numpad-button">
          8
        </button>
        <button onClick={() => onClick('9')} className="col numpad-button">
          9
        </button>
      </Row>
      <Row>
        <button onClick={() => onClick('.')} className="col numpad-button bg-dark">
          .
        </button>
        <button onClick={() => onClick('0')} className="col numpad-button">
          0
        </button>
        <button onClick={() => deleteValue()} className="col numpad-button bg-dark">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/72.png`} />
        </button>
      </Row>
      <Row>
        <button onClick={() => onSuccess(initialValue)} className="col numpad-button">
          Cancel
        </button>
        <button onClick={() => onSuccess(value)} className="col numpad-button">
          Ok
        </button>
      </Row>
    </div>
  );
}
