import GoBack from 'components/general/go_back';
import { useMediaQuery } from 'react-responsive';

const TitleHistoryBack = (props) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });
  const { title, subTitle } = props;
  return (
    <div>
      <div className="d-flex align-items-center gap-3 py-2">
        <GoBack />
        <div className="">
          <div
            className={`fs-7 text-uppercase mb-1 lh-1 ${
              isDesktop ? 'text-white' : 'text-secondary'
            } `}>
            {title}
          </div>
          <h6 className={`mb-0 fw-bold ${isDesktop ? 'text-white' : 'text-secondary'} `}>
            {subTitle}
          </h6>
        </div>
      </div>
      <hr className="mt-1 mb-3" />
    </div>
  );
};

export default TitleHistoryBack;
