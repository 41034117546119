import { useTranslation } from 'react-i18next';
import { EnumBetslipStatus, EnumBetSlipType } from 'toolkit/Enums';
// * BetSlipType *
// Single 1
// Multi 2
// System 3
//matchstatus 0: lost, 1: won

function BettingHistoryTitle(props) {
  const { t } = useTranslation('translations', { useSuspense: false });
  const betSlipType = Number(props.betSlipType);
  const betSlipStatus = Number(props.betSlipStatus);

  return (
    <div className="d-flex justify-content-between fs-7">
      {betSlipType === EnumBetSlipType.SYSTEM && <span>{t('system')}</span>}
      {betSlipType === EnumBetSlipType.SYSTEM &&
        props.combinations.map((combination, index) => (
          <span key={index}>
            {combination}/{props.count}
          </span>
        ))}

      {betSlipType == EnumBetSlipType.SINGLE && <span>{t('single')}</span>}
      {betSlipType === EnumBetSlipType.MULTIPLE && (
        <span>
          {t('combinations')} - {props.count} tips
        </span>
      )}

      {betSlipStatus == EnumBetslipStatus.OPEN ? (
        ''
      ) : betSlipStatus == EnumBetslipStatus.LOST ? (
        <span className="text-danger">{t('lost')}</span>
      ) : betSlipStatus == EnumBetslipStatus.WON ? (
        <span className="text-success">{t('won')}</span>
      ) : (
        <span className="text-info">{t('cashout')}</span>
      )}
    </div>
  );
}
export default BettingHistoryTitle;
